import {AccessRightList} from '../pages/ObjectRoleAccessRightSettings/objectRoleAccessRightSettingsReducer';
import {apiRequest} from '../utils/backend';
import {ServiceConfig} from '../utils/backend/apiRequest';

export interface ObjectRole {
  id: number;
  name: string;
  code: string;
  accessRights: {accessRightType: string}[];
}

export const getObjectRoles = async (
  objectType: string,
  objectId: number,
  serviceConfig: ServiceConfig
): Promise<ObjectRole[]> => {
  const getObjectRolesApiResponse = await apiRequest<ObjectRole[]>(
    `/object-roles?objectType=${objectType}&objectId=${objectId}`,
    serviceConfig
  );
  return getObjectRolesApiResponse;
};

export const saveObjectRoleAccessRights = async (
  objectRoleId: number,
  accessRightList: AccessRightList,
  serviceConfig: ServiceConfig
): Promise<ObjectRole> => {
  return await apiRequest<ObjectRole>(
    `/object-roles/${objectRoleId}/access-rights`,
    serviceConfig,
    {
      method: 'PATCH',
      body: JSON.stringify({
        accessRights: (
          Object.keys(accessRightList) as (keyof AccessRightList)[]
        ).filter((key) => {
          return accessRightList[key];
        }),
      }),
    }
  );
};

import {Create as EditIcon} from '@material-ui/icons';
import React, {useState} from 'react';
import {NxpButton} from '../../../components/NxpButton';

import {Album} from '../../../services/album';
import AlbumForm from './';
import {useTranslation} from 'react-i18next';
interface AlbumEditButtonProps {
  album: Album;
  onAlbumUpdated: (album: Album) => void;
}

const AlbumEditButton: React.FC<AlbumEditButtonProps> = ({
  album,
  onAlbumUpdated,
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const {t: tran} = useTranslation();
  const handleEditClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  return (
    <>
      <NxpButton
        title={`${tran('common.edit')} ${tran('album')}`}
        onClick={handleEditClick}
        variant="text"
        size="large"
      >
        {<EditIcon color="primary" />}
      </NxpButton>
      <AlbumForm
        modalOpen={modalOpen}
        album={album}
        onModalClose={handleModalClose}
        onAlbumSaved={onAlbumUpdated}
      />
    </>
  );
};

export default AlbumEditButton;

import React, {useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';

import {
  CircularProgress,
  createStyles,
  Fade,
  makeStyles,
  Theme,
} from '@material-ui/core';
import _ from 'lodash';
import {useSnackbar} from 'notistack';
import * as yup from 'yup';

import AppUploadDropzone from '../../../components/AppUploadDropzone';
import {NxpButton} from '../../../components/NxpButton';
import {NxpDialog} from '../../../components/NxpDialog';
import useAppContext from '../../../hooks/useAppContext';
import {useValidate} from '../../../hooks/useValidate';
import {
  Photo,
  UploadPhoto,
  UploadPhotoFile,
  uploadPhotos,
} from '../../../services/photo';
import CustomApiError from '../../../utils/backend/customApiError';
import UpLoadingFilesModal from './UploadingFilesModal';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      margin: theme.spacing(1),
      position: 'relative',
    },
    buttonProgress: {
      color: theme.palette.primary.main,
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    },
  })
);
interface UploadingFilesModalProps {
  showModal: boolean;
  handleDialogClose: () => void;
  onPhotoCreated?: (photo: Photo[]) => void;
}

const UploadingFilesContainer: React.FC<UploadingFilesModalProps> = ({
  showModal,
  handleDialogClose,
  onPhotoCreated,
}) => {
  const {t: tran} = useTranslation();
  const classes = useStyles();
  const appContext = useAppContext();
  const {enqueueSnackbar} = useSnackbar();
  const [formValid, setFormValid] = useState<boolean>(false);
  const [onUploadSuccess, setUploadSuccess] = useState<boolean>(false);
  const initalState = useMemo(
    () =>
      ({
        tags: [],
        files: [],
        keyPhoto: false,
        album: _.get(appContext, 'activeAlbum.id'),
      } as UploadPhoto),
    [appContext]
  );
  const [formGridState, setFormGridState] = useState<UploadPhoto>(initalState);
  const handleFormSave = () => {
    saveWithValidate(undefined);
  };

  const [fileList, setFileList] = useState<Partial<UploadPhotoFile>[]>([]);
  const handleFormGridStateChange = (fieldName: string, value: unknown) => {
    setFormGridState(
      (prevState) =>
        ({
          ...prevState,
          [fieldName]: value,
        } as UploadPhoto)
    );
  };

  const formSchema = yup.object().shape({
    caption: yup.string(),
    tags: yup.array(),
    location: yup.string(),
    dateTaken: yup.date().nullable(),
    photoType: yup.string(),
    keyPhoto: yup.boolean().default(false),
    files: yup.array().min(1, tran('upload.photoWarning')),
  });

  const handleFormSaveValidated = async () => {
    setFormValid(true);

    try {
      const photos = await uploadPhotos(
        formGridState,
        appContext.serviceConfig
      );
      setUploadSuccess(true);
      if (onPhotoCreated !== undefined) {
        onPhotoCreated(photos);
      }
    } catch (e) {
      if (e instanceof CustomApiError) {
        const apiError: CustomApiError = e;
        if (apiError.status === 403) {
          enqueueSnackbar(`${tran('error.occurred')} ${tran('refresh.page')}`, {
            variant: 'error',
          });
        } else {
          enqueueSnackbar(
            `${tran('error.occurred')} ${apiError.statusText} : ${
              apiError.message
            }`,
            {
              variant: 'error',
            }
          );
        }
      } else {
        // Timeout will fall into here
        enqueueSnackbar(`${tran('error.occurred')} ${e}`, {
          variant: 'error',
        });
      }
    }
  };

  const [validationError, , , saveWithValidate] = useValidate<any>(
    formGridState,
    formSchema,
    handleFormSaveValidated
  );
  const onClose = () => {
    setFormValid(false);
    setUploadSuccess(false);
    handleDialogClose();
    handleFormGridStateChange('files', []);
    setFileList([]);
    setFormGridState(initalState);
  };

  return (
    <NxpDialog
      maxWidth="md"
      fullWidth
      open={showModal}
      titleContent={`${_.capitalize(tran('common.upload'))} ${_.capitalize(
        tran('common.files')
      )}`}
      actionContent={
        !formValid ? (
          <>
            <NxpButton variant="text" onClick={onClose}>
              {tran('common.cancel')}
            </NxpButton>
            <NxpButton onClick={handleFormSave}>
              {tran('common.save')}
            </NxpButton>
          </>
        ) : (
          <div className={classes.wrapper}>
            <NxpButton onClick={onClose} disabled={!onUploadSuccess}>
              {tran('common.done')}
            </NxpButton>
            {!onUploadSuccess && (
              <CircularProgress size={24} className={classes.buttonProgress} />
            )}
          </div>
        )
      }
      onClose={onClose}
      closeByClickOutside={false}
    >
      <AppUploadDropzone
        heigth={formValid ? 500 : 230}
        setFileList={setFileList}
        fileList={fileList}
        formValid={formValid}
        onUploadSuccess={onUploadSuccess}
        validationError={validationError}
        handleFormGridStateChange={handleFormGridStateChange}
      />

      {!formValid ? (
        <Fade in={!formValid}>
          <UpLoadingFilesModal
            validationError={validationError}
            onFormSave={handleFormSave}
            handleFormGridStateChange={handleFormGridStateChange}
            formGridState={formGridState}
          />
        </Fade>
      ) : (
        <></>
      )}
    </NxpDialog>
  );
};

export default UploadingFilesContainer;

import {useSnackbar} from 'notistack';
import {useCallback} from 'react';

export const useErrorHandler = () => {
  const {enqueueSnackbar} = useSnackbar();

  return useCallback(
    (
      error: Error,
      taskDescription: string, // task in format such as 'getting locations', 'updating photo detail', 'deleting album'
      errorReference?: string | number | object, // information that could be useful for tracing error
      hideSnackbar?: boolean
    ) => {
      // should add logic for logging error here
      if (!hideSnackbar) {
        enqueueSnackbar(
          `Error occurred${
            taskDescription ? ` when ${taskDescription}` : ''
          } - ${error.message ? error.message : error}`,
          {variant: 'error'}
        );
      }
    },
    [enqueueSnackbar]
  );
};

export default useErrorHandler;

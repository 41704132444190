import React, {ReactNode} from 'react';
import {useTranslation} from 'react-i18next';
import {Route, RouteProps} from 'react-router-dom';

import {useKeycloak} from '@react-keycloak/web';

import useAppContext from '../../hooks/useAppContext';
import AppLayout from '../AppLayout';
import {NxpButton} from '../NxpButton';
import AppProtectedRouteLoader from './AppProtectedRouteLoader';
import useRouteAppContextUpdate from './useRouteAppContextUpdate';

interface AppProtectedRouteProps extends RouteProps {
  requireActiveAlbrum?: boolean;
  children: ReactNode;
}

const AppProtectedRoute: React.FC<AppProtectedRouteProps> = ({
  requireActiveAlbrum,
  children,
  ...restProps
}) => {
  const {t: tran} = useTranslation();
  const {keycloak} = useKeycloak();
  const appContext = useAppContext();
  useRouteAppContextUpdate();
  if (keycloak?.authenticated) {
    if (requireActiveAlbrum && !appContext.activeAlbum) {
      return null;
    }
    return (
      <Route {...restProps}>
        <AppLayout>{children}</AppLayout>
      </Route>
    );
  }
  setTimeout(() => {
    if (!keycloak?.authenticated) {
      appContext.enqueueSnackbar(tran('error.serverConnection'), {
        variant: 'error',
        autoHideDuration: null,
        action: (key) => (
          <NxpButton
            onClick={() => {
              appContext.notistackRef.current?.closeSnackbar(key);
            }}
            size="small"
            variant="text"
          >
            {tran('commom.dismiss')}
          </NxpButton>
        ),
      });
    }
  }, 15000);
  return <AppProtectedRouteLoader />;
};

export default AppProtectedRoute;

import React from 'react';
import {NavLink, useLocation} from 'react-router-dom';

import useAppContext from '../../hooks/useAppContext';

import styles from './AppSidebar.module.scss';
import {Button, makeStyles} from '@material-ui/core';
import image from '../../images/icons/sidebar-access.svg';
import getCurrentLink from '../../utils/currentRouteComparison';
import _ from 'lodash';
import {useTranslation} from 'react-i18next';
interface AppSidebarProps {
  toggleDrawer: (toogle: boolean) => void;
}

const useStyles = makeStyles({
  security: {
    display: 'inline-block',
    transition: '0.15s ease-in-out',
    textDecoration: 'none',
    width: 88,
    padding: '0px 0px 0px 0px',
    borderRadius: 'inherit',
    lineHeight: '1.5715',
    fontFamily: `-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
      'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
      'Helvetica Neue', sans-serif`,

    backgroundColor: (props: Partial<Location>) =>
      getCurrentLink(
        props,
        ['role-access-right', 'roles'],
        '#eff1f2',
        'inherit'
      ),

    boxShadow: (props: Partial<Location>) =>
      getCurrentLink(
        props,
        ['role-access-right', 'roles'],
        '3px 0 #005794 inset',
        'none'
      ),

    '&:hover': {
      backgroundColor: (props: Partial<Location>) =>
        getCurrentLink(
          props,
          ['role-access-right', 'roles'],
          '#eff1f2',
          '#fafafa'
        ),
      boxShadow: '3px #bccbcb inset',
    },

    '& .MuiButton-startIcon': {
      height: 40,
      margin: '15px 0px 0px 0px',
      filter: (props: Partial<Location>) =>
        getCurrentLink(
          props,
          ['role-access-right', 'roles'],
          'inherit',
          'grayscale(100%) brightness(2)'
        ),
    },
    '& * p': {
      color: (props: Partial<Location>) =>
        getCurrentLink(
          props,
          ['role-access-right', 'roles'],
          '#005794',
          '#8a9999'
        ),
    },
    '& * img': {
      transform: 'scale(1.2)',
    },
  },
  fullList: {
    width: 'auto',
  },
});
const AppSidebar: React.FC<AppSidebarProps> = ({toggleDrawer}) => {
  const location: Partial<Location> = useLocation();
  const appContext = useAppContext();
  const classes = useStyles(location);
  const {t: tran} = useTranslation();

  const hasAnyPhotoAccessRight =
    appContext.hasUserAccessRight('photo-view', 'album') ||
    appContext.hasUserAccessRight('photo-upload', 'album') ||
    appContext.hasUserAccessRight('photo-edit', 'album') ||
    appContext.hasUserAccessRight('photo-delete', 'album') ||
    appContext.hasUserAccessRight('photo-download', 'album');

  return (
    <nav className={styles.appSidebar}>
      <ul>
        <li
          hidden={!appContext.activeAlbum || !hasAnyPhotoAccessRight}
          className={styles.fadeInIcon}
        >
          <NavLink
            className={styles.navLink}
            to={`/entities/${appContext.serviceConfig.entityId}/albums/${appContext.activeAlbum?.id}/explorer`}
            title={_.capitalize(tran('explorer'))}
            isActive={(_, loc) => {
              return loc.pathname.includes('/explorer');
            }}
          >
            <div className={styles.nxpSidebarItemWrapper}>
              <i className={styles.explorer} />
              <p className={styles.nxpSidebarCaption}>
                {_.capitalize(tran('explorer'))}
              </p>
              <div className={styles.nxpSidebarItemBorder}></div>
            </div>
          </NavLink>
        </li>
        <li
          hidden={
            !appContext.activeAlbum ||
            !appContext.hasUserAccessRight('photo-view', 'album')
          }
          className={styles.fadeInIcon}
        >
          <NavLink
            className={styles.navLink}
            to={`/entities/${appContext.serviceConfig.entityId}/albums/${appContext.activeAlbum?.id}/search`}
            title={_.capitalize(tran('common.search'))}
            isActive={(_, loc) => {
              return loc.pathname.includes('/search');
            }}
          >
            <div className={styles.nxpSidebarItemWrapper}>
              <i className={styles.search} />
              <p className={styles.nxpSidebarCaption}>
                {_.capitalize(tran('common.search'))}
              </p>
              <div className={styles.nxpSidebarItemBorder}></div>
            </div>
          </NavLink>
        </li>
        <li
          hidden={!appContext.activeAlbum || !hasAnyPhotoAccessRight}
          className={styles.fadeInIcon}
        >
          <NavLink
            className={styles.navLink}
            to={`/entities/${appContext.serviceConfig.entityId}/albums/${appContext.activeAlbum?.id}/map`}
            title={`${_.capitalize(tran('photo'))}${_.capitalize(tran('map'))}`}
            style={{width: '100%'}}
          >
            <div className={styles.nxpSidebarItemWrapper}>
              <i className={styles.photoMap} />
              <p className={styles.nxpSidebarCaption}>
                {`${_.capitalize(tran('photo'))}${_.capitalize(tran('map'))}`}
              </p>
              <div className={styles.nxpSidebarItemBorder}></div>
            </div>
          </NavLink>
        </li>
        <li
          hidden={
            !appContext.activeAlbum ||
            (appContext.activeAlbumLightboxes?.length === 0 &&
              !appContext.hasUserAccessRight(
                'lightbox-create',
                'album',
                appContext.activeAlbum.id
              ))
          }
          className={styles.fadeInIcon}
        >
          <NavLink
            className={styles.navLink}
            to={`/entities/${appContext.serviceConfig.entityId}/albums/${appContext.activeAlbum?.id}/lightboxes`}
            title={_.capitalize(tran('common.lightbox'))}
            isActive={(_, loc) => {
              return loc.pathname.includes('/lightbox');
            }}
          >
            <div className={styles.nxpSidebarItemWrapper}>
              <i className={styles.lightbox} />
              <p className={styles.nxpSidebarCaption}>
                {_.capitalize(tran('common.lightbox'))}
              </p>
              <div className={styles.nxpSidebarItemBorder}></div>
            </div>
          </NavLink>
        </li>
        <li
          hidden={!appContext.hasUserAccessRight('album-view')}
          className={styles.fadeInIcon}
        >
          <NavLink
            exact
            className={styles.navLink}
            to={`/entities/${appContext.serviceConfig.entityId}/albums`}
            title={_.capitalize(tran('common.albums'))}
          >
            <div className={styles.nxpSidebarItemWrapper}>
              <i className={styles.albums} />
              <p className={styles.nxpSidebarCaption}>
                {_.capitalize(tran('common.albums'))}
              </p>
              <div className={styles.nxpSidebarItemBorder}></div>
            </div>
          </NavLink>
        </li>
        <li
          hidden={
            !appContext.activeAlbum ||
            !appContext.hasUserAccessRight('dashboard-view', 'album')
          }
          className={styles.fadeInIcon}
        >
          <NavLink
            className={styles.navLink}
            to={`/entities/${appContext.serviceConfig.entityId}/albums/${appContext.activeAlbum?.id}/dashboard`}
            title={_.capitalize(tran('common.dashboard'))}
          >
            <div className={styles.nxpSidebarItemWrapper}>
              <i className={styles.dashboard} />
              <p className={styles.nxpSidebarCaption}>
                {_.capitalize(tran('common.dashboard'))}
              </p>
              <div className={styles.nxpSidebarItemBorder}></div>
            </div>
          </NavLink>
        </li>
        <li
          className={styles.navLink}
          hidden={
            !appContext.hasUserAccessRight('role-view', 'album') &&
            !appContext.hasUserAccessRight('access-right-view', 'album')
          }
        >
          <Button
            className={classes.security}
            startIcon={<img src={image} alt="access" />}
            onClick={() => {
              toggleDrawer(true);
            }}
            title={_.capitalize(tran('common.security'))}
          >
            <div className={styles.nxpSidebarItemWrapper}>
              <p className={styles.nxpSidebarCaption}>
                {_.capitalize(tran('common.security'))}
              </p>
              <div className={styles.nxpSidebarItemBorder}></div>
            </div>
          </Button>
        </li>

        {/* predefined tags will be handle in cpcs dictionary for now */}
        <li hidden>
          <NavLink
            className={styles.navLink}
            to={`/entities/${appContext.serviceConfig.entityId}/settings`}
            title={_.capitalize(tran('common.settings'))}
          >
            <div className={styles.nxpSidebarItemWrapper}>
              <i className={styles.settings} />
              <p className={styles.nxpSidebarCaption}>
                {_.capitalize(tran('common.settings'))}
              </p>
              <div className={styles.nxpSidebarItemBorder}></div>
            </div>
          </NavLink>
        </li>
      </ul>
    </nav>
  );
};

export default AppSidebar;

import React, {useState} from 'react';
import clsx from 'clsx';
import {makeStyles} from '@material-ui/core';
import {Create as EditIcon} from '@material-ui/icons';

import {NxpButton} from '../NxpButton';
import NxpPanel, {NxpPanelProps} from './NxpPanel';

const useStyles = makeStyles((theme) => ({
  root: {
    '&.is-Editing .allow-collapse': {
      cursor: 'default',
    },
    '& .NxpPanel-bar': {
      '& .NxpPanel-bar-edit-icon': {
        fontSize: theme.typography.fontSize + 2,
        marginTop: -3,
      },
    },
  },
}));

export interface NxpPanelEditableProps
  extends Omit<NxpPanelProps, 'actionContent' | 'children'> {
  onPanelCancel?: (panelActionArgs?: unknown) => void;
  onPanelSave?: (panelActionArgs?: unknown) => void;
  panelActionArgs?: unknown; // arbitary arguments for action calls;
  children: (
    editing: boolean,
    onSetEditing: (editing: boolean) => void
  ) => JSX.Element;
}

const NxpPanelEditable: React.FC<NxpPanelEditableProps> = ({
  className,
  defaultCollapsed,
  collapsed: collapsedProp, // panel will handle collapsed state if this prop is undefined
  disableCollapse,
  onCollapseClick,
  onPanelCancel,
  onPanelSave,
  panelActionArgs,
  children,
  ...restProps
}) => {
  const [collapsedState, setCollapsedState] = useState(defaultCollapsed);

  const collapsedComputed =
    collapsedProp === undefined ? collapsedState : collapsedProp;

  const [editing, setEditing] = useState(false);

  const handleCollapseClick = () => {
    if (!disableCollapse && !editing) {
      if (onCollapseClick) {
        onCollapseClick(!collapsedComputed);
      }
      if (collapsedProp === undefined) {
        setCollapsedState(!collapsedComputed);
      }
    }
  };

  const handleSetEditing = (editing__: boolean) => {
    setEditing(editing__);
    if (editing__) {
      if (onCollapseClick) {
        onCollapseClick(false);
      } else if (collapsedProp === undefined) {
        setCollapsedState(false);
      }
    }
  };

  const handleEditClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    handleSetEditing(true);
  };

  const handleCancelClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    setEditing(false);
    e.stopPropagation();
    if (onPanelCancel) {
      onPanelCancel(panelActionArgs);
    }
  };

  const handleSaveClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    setEditing(false);
    e.stopPropagation();
    if (onPanelSave) {
      onPanelSave(panelActionArgs);
    }
  };

  const classes = useStyles();

  return (
    <NxpPanel
      className={clsx(className, classes.root, editing && 'is-Editing')}
      collapsed={collapsedComputed}
      onCollapseClick={handleCollapseClick}
      actionContent={
        editing ? (
          <>
            <NxpButton variant="text" onClick={handleCancelClick}>
              Cancel
            </NxpButton>
            <NxpButton onClick={handleSaveClick}>Save</NxpButton>
          </>
        ) : (
          <NxpButton
            variant="text"
            onClick={handleEditClick}
            startIcon={<EditIcon className="NxpPanel-bar-edit-icon" />}
          >
            Edit
          </NxpButton>
        )
      }
      {...restProps}
    >
      {typeof children === 'function'
        ? children(editing, handleSetEditing)
        : children}
    </NxpPanel>
  );
};

export default NxpPanelEditable;

import React from 'react';
import {Card, CardContent, makeStyles} from '@material-ui/core';
import ChartTitle from '../ChartTitle';
import Table from '../Table';
import _ from 'lodash';
import {UserDateMatrix} from '../../../services/dashboard';
import {useTranslation} from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: 'center',
  },
}));

interface DailyUploadCardProps {
  userDateMatrix: null | UserDateMatrix;
}

const DailyUploadCard: React.FC<DailyUploadCardProps> = ({userDateMatrix}) => {
  const classes = useStyles();
  const {t: tran} = useTranslation();
  const dict: any = {};
  const totalByUser: any = {};
  const totalByDate: any = {};
  const names: string[] = [];
  let dates: string[] = [];
  let total = 0;

  if (userDateMatrix) {
    dates = userDateMatrix.dates;
    const entries = userDateMatrix.groups;

    for (const entry of entries) {
      const {user, date, count} = entry;
      dict[`${user}|${date}`] = count;
      if (!names.includes(user)) names.push(user);
      totalByUser[user] = (totalByUser[user] || 0) + count;
      totalByDate[date] = (totalByDate[date] || 0) + count;
      total += count;
    }
  }

  return (
    <Card>
      <CardContent>
        <div className={classes.root}>
          <ChartTitle>
            {_.startCase(_.toLower(tran('dashboard.dailyUpload')))}
          </ChartTitle>
          <Table>
            <thead>
              <tr>
                <th>User Name</th>
                {dates.map((date) => (
                  <th key={`th_${date}`}>{date}</th>
                ))}
                <th>Total</th>
              </tr>
            </thead>
            <tbody>
              {names.map((name) => (
                <tr key={name}>
                  <td>{name}</td>
                  {dates.map((date) => (
                    <td key={`td_${name}|${date}`}>
                      {_.get(dict, `${name}|${date}`, 0).toLocaleString()}
                    </td>
                  ))}
                  <td>{totalByUser[name].toLocaleString()}</td>
                </tr>
              ))}
              <tr>
                <td>All</td>
                {dates.map((date) => (
                  <td key={`td_${date}`}>
                    {_.get(totalByDate, date, 0).toLocaleString()}
                  </td>
                ))}
                <td>{total.toLocaleString()}</td>
              </tr>
            </tbody>
          </Table>
        </div>
      </CardContent>
    </Card>
  );
};

export default DailyUploadCard;

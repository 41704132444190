import React, {useRef} from 'react';
import {useTranslation} from 'react-i18next';

import {
  Box,
  Chip,
  createStyles,
  Divider,
  Fade,
  Grid,
  makeStyles,
  Paper,
  Theme,
  Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import _ from 'lodash';

import useAppContext from '../../../../hooks/useAppContext';
import {Tag, TagType} from '../../../../services/tag';
import {NxpButton} from '../../../NxpButton';

interface AppPhotoTagsProps {
  tags: Tag[];
  handleTagsUpdate: (tags: Tag[], actionType: string) => void;
}
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: 0,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '100%',
      '& input': {
        width: 180,
        height: 32,
        border: '0.75px solid #8A9999',
        borderRadius: 3,
        padding: '0 8px',
      },
      '& .MuiButtonBase-root': {
        minHeight: 32,
        marginLeft: 8,
        '& .MuiButton-label': {
          fontSize: theme.typography.pxToRem(8),
          fontWeight: theme.typography.fontWeightBold as number,
        },
      },
    },
    Chip: {
      '& > .MuiGrid-container': {
        '& .section-title': {
          fontSize: theme.typography.pxToRem(10),
          color: '#8A9999',
          fontWeight: theme.typography.fontWeightBold as number,
          padding: theme.spacing(0.5),
          '&.USER': {
            color: theme.palette.primary.main,
          },
        },
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
      },
      '& .MuiChip-root': {
        margin: 4,
        '&.USER': {
          background: 'rgba(0, 87, 148, 0.2)',
        },
      },
    },
    input: {
      marginLeft: theme.spacing(1),
      flex: 1,
    },
    iconButton: {
      padding: 10,
    },
    divider: {
      height: 28,
      margin: 4,
    },
  })
);
const AppPhotoTags: React.FC<AppPhotoTagsProps> = ({
  tags,
  handleTagsUpdate,
}) => {
  const appContext = useAppContext();
  const {t: tran} = useTranslation();
  const tagInput = useRef<HTMLInputElement>(null);
  const classes = useStyles();
  const handleDelete = (index: number) => {
    handleTagsUpdate(
      tags.filter((tag) => tag.id === index),
      'delete'
    );
  };
  const submited = () => {
    if (tagInput && tagInput.current) {
      handleTagsUpdate([...tags, {name: tagInput.current?.value}], 'update');
    }
  };
  const formatTags: {type: TagType; data: Tag[]}[] = [
    {
      type: 'ML',
      data: tags.filter((tag) => tag.type === 'ML'),
    },
    {
      type: 'PREDEFINED',
      data: tags.filter((tag) => tag.type === 'PREDEFINED'),
    },
    {
      type: 'USER',
      data: tags.filter((tag) => tag.type === 'USER'),
    },
  ];
  return (
    <>
      <Grid container alignItems="center" className="photo-tags">
        <Grid item xs={4}>
          <Typography variant="h1" component="h2">
            {_.capitalize(tran('photo.tags'))}
          </Typography>
        </Grid>
      </Grid>
      <Divider />
      <Box component="div" className={classes.Chip} overflow="visible">
        <Grid container alignItems="center" alignContent="center">
          {tags !== undefined &&
            formatTags.map((taglist) => {
              return (
                <>
                  {taglist.data.length ? (
                    <Grid key={`$tag-${taglist.type}`} item xs={12}>
                      <Typography
                        variant="subtitle1"
                        component="div"
                        className={`section-title ${taglist.type}`}
                      >
                        {`${
                          taglist.type === 'ML'
                            ? `${tran('photo.tag.auto')} TAG`
                            : taglist.type === 'USER'
                            ? `${tran('photo.tag.manual')} TAG `
                            : taglist.type
                        }(${taglist.data.length})`}
                      </Typography>
                    </Grid>
                  ) : (
                    <></>
                  )}

                  {taglist.data.map((tag, i) => {
                    return (
                      <>
                        <Grid key={`$tag${tag.id}`} item>
                          <Fade in={!_.isEmpty(tag)}>
                            <Chip
                              className={tag.type}
                              label={tag.name}
                              onDelete={
                                appContext.hasUserAccessRight('photo-edit')
                                  ? () => {
                                      if (tag.id !== undefined)
                                        handleDelete(tag.id);
                                    }
                                  : undefined
                              }
                              deleteIcon={<CloseIcon />}
                              size="small"
                            />
                          </Fade>
                        </Grid>
                      </>
                    );
                  })}
                </>
              );
            })}
        </Grid>
        {appContext.hasUserAccessRight('photo-edit') && (
          <Paper
            component="form"
            className={classes.root}
            elevation={0}
            onSubmit={(e) => {
              e.preventDefault();
              submited();
            }}
          >
            <input type="text" ref={tagInput} placeholder="Please enter" />
            <NxpButton size="small" type="submit">
              {tran('common.add')} {tran('common.tag')}
            </NxpButton>
          </Paper>
        )}
      </Box>
    </>
  );
};
export default AppPhotoTags;

import {useKeycloak} from '@react-keycloak/web';
import {useMemo} from 'react';
import {useRouteMatch} from 'react-router-dom';
import {ServiceConfig} from '../utils/backend';

const useServiceConfig = (): ServiceConfig => {
  const {keycloak} = useKeycloak();
  const entityRoute = useRouteMatch<{entityId: string}>('/entities/:entityId');
  const albumRoute = useRouteMatch<{entityId: string; albumId: string}>(
    '/entities/:entityId/albums/:albumId'
  );
  const serviceConfig = useMemo(
    () => ({
      entityId: entityRoute?.params.entityId,
      albumId: albumRoute?.params.albumId,
      token: keycloak?.token,
      apiBaseUrl: process.env.REACT_APP_API_BASE_URL || '',
      userDirectoryServiceApiBaseUrl:
        process.env.REACT_APP_USER_DIRECTORY_SERVICE_API_BASE_URL || '',
    }),
    [entityRoute?.params.entityId, albumRoute?.params.albumId, keycloak?.token]
  );

  return serviceConfig;
};

export default useServiceConfig;

import React from 'react';

import PieChartCardLayout from './PieChartCardLayout';

interface PieChartCardContainerProps {
  title?: string;
  entries: {name: string; value: number}[];
}

const PieChartCardContainer: React.FC<PieChartCardContainerProps> = (props) => {
  return <PieChartCardLayout {...props} />;
};

export default PieChartCardContainer;

import {useCallback, useLayoutEffect, useState} from 'react';
import {AsyncResult} from '../utils/typing/AsyncResult';
export interface AsyncHandlers<T> {
  onLoad?: () => void;
  onSuccess?: (data: T) => void;
  onError?: (ex: Error) => void;
}

export function useAsync<T = any>(
  initialRequest?: () => Promise<T>, // use it only when you want to trigger the async operation at initiation
  handlers?: AsyncHandlers<T>,
  defaultResultData?: T
) {
  useLayoutEffect(() => {
    if (initialRequest) {
      setAsyncRequest(initialRequest);
    }
    // initialRequest is not intended to be updated, so below
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [asyncResult, setAsyncResult] = useState<AsyncResult<T>>({
    loading: false,
    data: defaultResultData,
    error: undefined,
  });

  const setAsyncRequest = useCallback(
    (asyncRequest: (() => Promise<T>) | undefined) => {
      if (asyncRequest !== undefined) {
        setAsyncResult((prevState) => ({
          loading: true,
          data: prevState.data,
          error: undefined,
        }));

        if (handlers?.onLoad) {
          handlers.onLoad();
        }

        asyncRequest()
          .then((data) => {
            if (handlers?.onSuccess) {
              handlers.onSuccess(data);
            }
            setAsyncResult({
              loading: false,
              data,
              error: undefined,
            });
          })
          .catch((error) => {
            if (handlers?.onError) {
              handlers.onError(error);
            }
            setAsyncResult({
              loading: false,
              data: defaultResultData,
              error,
            });
          });
      } else {
        setAsyncResult({
          loading: false,
          data: defaultResultData,
          error: undefined,
        });
      }
    },
    // defaultResultData and setAsyncResult are not supposed to change
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [handlers]
  );

  return [asyncResult, setAsyncRequest, setAsyncResult] as const;
}

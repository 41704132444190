import {makeStyles, Theme} from '@material-ui/core';
import React from 'react';

import {ValidationResult} from '../../../hooks/useValidate';
import {AddToLightboxForm, Lightbox} from '../../../services/lightbox';
import {NxpButton} from '../../NxpButton';
import {NxpDialog} from '../../NxpDialog';
import {NxpFormGrid} from '../../NxpFormGrid';
import {useTranslation} from 'react-i18next';
const useStyles = makeStyles<Theme>((theme) => ({
  columnAddToExisting: {
    maxWidth: 50,
    flexGrow: 0,
  },
  columnFull: {
    minWidth: 350,
    flexGrow: 1,
  },
  columnAddToNewOption: {
    minWidth: 200,
    flexGrow: 1,
    marginLeft: 10,
  },
}));

interface AddToLightboxModalProps {
  modalOpen: boolean;
  form: AddToLightboxForm;
  lightboxOptions: Lightbox[];
  canCreateLightbox: boolean;
  formError: ValidationResult<AddToLightboxForm>;
  submitLoading: boolean;
  onFormStateChange: (
    fieldName: keyof AddToLightboxForm,
    value: unknown
  ) => void;
  onSaveClick: () => void;
  onModalClose: () => void;
}

const AddToLightboxModal: React.FC<AddToLightboxModalProps> = ({
  modalOpen,
  form,
  lightboxOptions,
  canCreateLightbox,
  formError,
  submitLoading,
  onFormStateChange,
  onSaveClick,
  onModalClose,
}) => {
  const classes = useStyles();
  const {t: tran} = useTranslation();
  return (
    <NxpDialog
      maxWidth="xs"
      onClose={onModalClose}
      open={modalOpen}
      titleContent={`${tran('common.add')} ${tran('common.to')} ${tran(
        'photo.lightbox'
      )}`}
      actionContent={
        <NxpButton disabled={submitLoading} onClick={onSaveClick}>
          {tran('common.save')}
        </NxpButton>
      }
    >
      <NxpFormGrid
        editing={true}
        gridColumnCount={4}
        formState={form}
        onFormStateChange={onFormStateChange}
        fields={[
          {
            className: classes.columnAddToExisting,
            itemColumnSpan: 1,
            fieldName: 'addToExisting',
            fieldType: 'checkbox',
            onFormStateChange: (_, value) =>
              onFormStateChange('addToExisting', value),
            errorContent: formError.addToExisting,
          },
          lightboxOptions.length === 0
            ? {
                disabled: true,
                className: classes.columnFull,
                itemColumnSpan: 3,
                fieldName: 'lightboxId',
                fieldType: 'text',
                label: `${tran('common.add')} ${tran('common.to')} ${tran(
                  'common.existing'
                )} ${tran('photo.lightbox')}`,
                placeholder: 'no editable lightbox',
                required: form.addToExisting,
                errorContent: formError.lightboxId,
              }
            : {
                disabled: !form.addToExisting || lightboxOptions.length === 0,
                className: classes.columnFull,
                itemColumnSpan: 3,
                fieldName: 'lightboxId',
                fieldType: 'select',
                selectItems: lightboxOptions.map((item) => ({
                  value: item.id,
                  name: item.name,
                })),
                label: `${tran('common.add')} ${tran('common.to')} ${tran(
                  'common.existing'
                )} ${tran('photo.lightbox')}`,
                required: form.addToExisting,
                errorContent: formError.lightboxId,
              },
          canCreateLightbox
            ? {
                className: classes.columnAddToExisting,
                itemColumnSpan: 1,
                fieldName: 'addToExistingFalse' as keyof AddToLightboxForm,
                fieldType: 'checkbox',
                disabled: !canCreateLightbox,
                onFormStateChange: (_, value) =>
                  onFormStateChange('addToExisting', !value),
                value: !form.addToExisting,
                errorContent: formError.addToExisting,
              }
            : null,
          canCreateLightbox
            ? {
                disabled: form.addToExisting,
                className: classes.columnFull,
                itemColumnSpan: 3,
                fieldName: 'name',
                fieldType: 'text',
                label: `${tran('common.add')} ${tran('common.to')} ${tran(
                  'common.new'
                )} ${tran('photo.lightbox')}`,
                required: !form.addToExisting,
                errorContent: formError.name,
              }
            : null,
        ]}
      />
    </NxpDialog>
  );
};

export default AddToLightboxModal;

import React, {useState} from 'react';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import UploadingFilesContainer from '.';
import {NxpButton} from '../../../components/NxpButton';
import {Photo} from '../../../services/photo';
import {useTranslation} from 'react-i18next';

interface UploadingFilesButtonProps {
  onPhotoCreated?: (photo: Photo[]) => void;
}

const UploadingFilesButton: React.FC<UploadingFilesButtonProps> = ({
  onPhotoCreated,
}) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const handleDialogClose = () => {
    setDialogOpen(false);
  };
  const {t: tran} = useTranslation();

  return (
    <>
      <NxpButton
        variant="contained"
        color="primary"
        startIcon={<CloudUploadIcon />}
        onClick={() => setDialogOpen(true)}
      >
        {tran('common.upload')}
      </NxpButton>
      <UploadingFilesContainer
        showModal={dialogOpen}
        handleDialogClose={handleDialogClose}
        onPhotoCreated={onPhotoCreated}
      />
    </>
  );
};

export default UploadingFilesButton;

import React from 'react';
import {makeStyles, Theme} from '@material-ui/core';

export interface TableProps {
  children?: string | JSX.Element | JSX.Element[];
}

const useStyles = makeStyles((theme: Theme) => ({
  div: {
    borderRadius: 5,
    overflow: 'hidden',
    border: '1px solid rgba(138, 153, 153, 0.5)',
  },
  table: {
    width: '100%',
    fontSize: 14,
    color: '#3E3F42',
    lineHeight: '20px',
    textAlign: 'center',
    boxSizing: 'border-box',
    borderSpacing: '1px',
    borderCollapse: 'collapse',
    tableLayout: 'fixed',
    '& th': {
      border: '1px solid rgba(138, 153, 153, 0.5)',
      backgroundColor: 'rgba(138, 153, 153, 0.25)',
      fontWeight: 'normal',
    },
    '& td': {
      border: '1px solid rgba(138, 153, 153, 0.5)',
    },
  },
}));

const Table: React.FC<TableProps> = ({children}) => {
  const classes = useStyles();
  return (
    <div className={classes.div}>
      <table className={classes.table}>{children}</table>
    </div>
  );
};
export default Table;

import React, {useRef} from 'react';
import {NxpFormGrid} from '../../../components/NxpFormGrid';
import {ValidationResult} from '../../../hooks/useValidate';
import {UploadPhoto} from '../../../services/photo';
import {DateFnsFormatPattern} from '../../../utils/date/formatDate';
import _ from 'lodash';
import useAppContext from '../../../hooks/useAppContext';
import {
  Chip,
  InputAdornment,
  Grid,
  makeStyles,
  TextField,
  FormControl,
  Input,
  InputLabel,
  IconButton,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {NxpButton} from '../../../components/NxpButton';
import CancelRoundedIcon from '@material-ui/icons/CancelRounded';
import {useTranslation} from 'react-i18next';

interface UpLoadingFilesModalProps {
  handleFormGridStateChange: (fieldName: string, value: unknown) => void;
  formGridState: UploadPhoto;
  onFormSave: () => void;
  validationError: ValidationResult<UploadPhoto>;
}
const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiInputBase-root ': {
      border: '1px solid rgba(188, 203, 203)',
      fontSize: theme.typography.pxToRem(14),
      borderRadius: 5,
      color: '#3E3F42',
    },
    '& .infinite-scroll-component': {
      height: ['auto', '!important'],
      overflow: ['auto', '!important'],
      maxHeight: ['180px', '!important'],
    },
  },
  tagContainer: {
    // margin: theme.spacing(0, 1),
    '& .chip-list': {
      margin: theme.spacing(1, 0),
      '& .MuiChip-root': {
        marginRight: 4,
      },
    },
    '& .chip-input': {
      '& .MuiInput-underline:hover:before': {
        borderBottom: 'none',
      },
    },
    '& .MuiIconButton-root': {
      padding: 0,
    },
    '& .MuiInput-underline:before, & .MuiInput-underline:after,': {
      border: 'none',
    },
    '& .MuiInputBase-root ': {
      border: '1px solid rgba(188, 203, 203)',
      borderRadius: 5,
      '& .MuiInputBase-input': {
        margin: theme.spacing(0, 1),
      },
    },
    '& .MuiButtonBase-root': {
      borderTopLeftRadius: 0,
      borderTopRightRadius: 4,
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 4,
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
    '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': {
      padding: '0 9px',
    },
    padding: theme.spacing(0, 1),
    '& .MuiFormControl-root': {},
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  textField: {
    width: '100%',
  },
}));
const UpLoadingFilesModal: React.FC<UpLoadingFilesModalProps> = ({
  formGridState,
  handleFormGridStateChange,
  validationError,
}) => {
  const appContext = useAppContext();
  const classes = useStyles();
  const [tag, seTtag] = React.useState<string>('');
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    seTtag(event.target.value);
  };
  const formErrors = {
    ...validationError,
    // ignore for poc
    // ...(fieldSchemaAsyncResult.error as CustomApiError)?.serverResponseData,
  };

  const locationSelectRef = useRef<HTMLDivElement>(undefined!);
  const {t: tran} = useTranslation();

  return (
    <>
      <NxpFormGrid
        editing={true}
        formState={formGridState}
        onFormStateChange={handleFormGridStateChange}
        className={classes.root}
        fields={[
          {
            itemColumnSpan: 1,
            fieldName: 'caption',
            fieldType: 'text',
            onFormStateChange: handleFormGridStateChange,
            label: tran('photo.caption'),
            fullWidth: true,
            errorContent: _.get(formErrors, 'caption', ''),
            placeholder: `${_.capitalize(tran('common.please'))} ${_.capitalize(
              tran('common.enter')
            )} `,
          },
          {
            itemColumnSpan: 1,
            fieldName: 'dateTaken',
            fieldType: 'date',
            onFormStateChange: handleFormGridStateChange,
            label: tran('photo.dateTaken'),
            readModeDatePattern: DateFnsFormatPattern.date,
            // onFormatReadModeDate: (date) =>
            //   format(date, DateMappingFormat.date),
          },
          {
            itemColumnSpan: 1,
            fieldName: 'location',
            fieldType: 'tree-select',
            onFormStateChange: handleFormGridStateChange,
            label: tran('photo.location'),
            fullWidth: true,
            treeSelectItems: appContext.locationOptions,
            errorContent: _.get(formErrors, 'location', ''),
            placeholder: `${_.capitalize(tran('common.please'))} ${_.capitalize(
              tran('common.select')
            )} `,
            selectRef: locationSelectRef,
          },
          {
            itemColumnSpan: 1,
            fieldName: 'photoType',
            fieldType: 'select',
            onFormStateChange: handleFormGridStateChange,
            label: tran('photo.photoType'),
            fullWidth: true,
            selectItems: appContext.photoTypeOptions,
            errorContent: _.get(formErrors, 'photoType', ''),
            placeholder: `${_.capitalize(tran('common.please'))} ${_.capitalize(
              tran('common.select')
            )} `,
            value: _.get(formGridState, 'photoType', ''),
          },
        ]}
        gridColumnCount={2}
      />
      <Grid
        container
        justify="space-between"
        className={classes.tagContainer}
        spacing={3}
      >
        <Grid item xs={6}>
          <Autocomplete
            multiple
            id="photo-tags"
            onChange={(event, newValue) => {
              handleFormGridStateChange('tagIds', [...newValue]);
            }}
            disableClearable
            options={_.get(appContext, 'predefinedTagOptions', [])}
            getOptionLabel={(option) => option.name}
            renderTags={(tagValue, getTagProps) => {
              return tagValue.map((option, index) => {
                return (
                  <Chip
                    key={index}
                    style={{
                      background: '#e0e0e0',
                      border: 'none',
                    }}
                    variant="outlined"
                    label={option.name}
                    size="small"
                    {...getTagProps({index})}
                  />
                );
              });
            }}
            renderInput={(params) => {
              return (
                <>
                  <TextField
                    {...params}
                    variant="standard"
                    value={tag}
                    label={tran('photo.tags')}
                    className="chip-input"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </>
              );
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <FormControl className={classes.textField}>
            <InputLabel htmlFor="standard-adornment-password" shrink>
              {tran('common.other')} {tran('photo.tags')}
            </InputLabel>
            <Input
              id="standard-adornment-password"
              className="chip-input"
              type="text"
              onChange={handleChange}
              disableUnderline
              fullWidth
              value={tag}
              endAdornment={
                <>
                  {tag && (
                    <IconButton
                      color="primary"
                      aria-label="toggle visibility"
                      onClick={(e) => {
                        seTtag('');
                      }}
                    >
                      <CancelRoundedIcon />
                    </IconButton>
                  )}
                  <InputAdornment position="end">
                    <NxpButton
                      variant="contained"
                      color="primary"
                      size="small"
                      onClick={() => {
                        handleFormGridStateChange('userTags', [
                          tag,
                          ..._.get(formGridState, 'userTags', []),
                        ]);
                        seTtag('');
                      }}
                    >
                      {tran('common.add')} {tran('photo.tags')}
                    </NxpButton>
                  </InputAdornment>
                </>
              }
            />
          </FormControl>
          <Grid container className="chip-list">
            <Grid item>
              {formGridState?.userTags?.map((option: string, index: number) => (
                <Chip
                  key={index}
                  label={option}
                  size="small"
                  onDelete={(e) => {
                    handleFormGridStateChange('userTags', [
                      ..._.get(formGridState, 'userTags', []).filter(
                        (tag: string, i: number) => i !== index
                      ),
                    ]);
                  }}
                />
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <NxpFormGrid
        editing={true}
        formState={formGridState}
        onFormStateChange={handleFormGridStateChange}
        fields={[
          {
            itemColumnSpan: 1,
            fieldName: 'keyPhoto',
            fieldType: 'checkbox',
            onFormStateChange: handleFormGridStateChange,
            label: tran('photo.keyPhoto'),
            fullWidth: true,
          },
        ]}
        gridColumnCount={2}
      />
    </>
  );
};

export default UpLoadingFilesModal;

import {useEffect, useState} from 'react';
import BatchAssignModalLayout from './AppBatchAssignModalLayout';

interface BatchAssignModalContainerProps {
  open: boolean;
  onSave: (userIds: string[]) => void;
  onDismiss: () => void;
}

export interface BatchAssignFormGridState {
  userIds: string[];
}

const BatchAssignModalContainer: React.FC<BatchAssignModalContainerProps> = (
  props
) => {
  const [formGridState, setFormGridState] = useState({
    userIds: [],
  } as BatchAssignFormGridState);

  useEffect(() => {
    if (props.open) {
      setFormGridState({
        userIds: [],
      });
    }
  }, [props.open]);

  const handleFormGridStateChange = (fieldName: string, value: unknown) => {
    setFormGridState({
      ...formGridState,
      [fieldName]: value,
    });
  };

  const handleSave = () => {
    if (props.open) {
      props.onSave(formGridState.userIds);
    }
  };

  return (
    <BatchAssignModalLayout
      open={props.open}
      formGridState={formGridState}
      onFormGridStateChange={handleFormGridStateChange}
      onSave={handleSave}
      onDismiss={props.onDismiss}
    />
  );
};

export default BatchAssignModalContainer;

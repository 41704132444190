import {createStyles, makeStyles, Theme, Typography} from '@material-ui/core';
import React from 'react';
import {NxpButton} from '../../NxpButton';
import {NxpDialog} from '../../NxpDialog';
import {useTranslation} from 'react-i18next';
interface AppPhotoDeleteModalProps {
  showModal: boolean;
  handleDialogClose: () => void;
  handlePhotoDelete: (cd: Function) => void;
}
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },
  })
);

const AppPhotoDelete: React.FC<AppPhotoDeleteModalProps> = ({
  showModal,
  handleDialogClose,
  handlePhotoDelete,
}) => {
  const classes = useStyles();
  const {t: tran} = useTranslation();
  const onClose = () => {
    handleDialogClose();
  };

  return (
    <NxpDialog
      maxWidth="sm"
      fullWidth
      open={showModal}
      titleContent="Delete Photo"
      actionContent={
        <>
          <NxpButton variant="text" onClick={onClose}>
            {tran('common.cancel')}
          </NxpButton>
          <NxpButton onClick={() => handlePhotoDelete(onClose)}>
            {tran('common.confirm')}
          </NxpButton>
        </>
      }
      onClose={onClose}
      closeByClickOutside
    >
      <Typography
        variant="subtitle1"
        component="h1"
        display="block"
        className={classes.root}
      >
        {tran('warning.delete.photo')}
      </Typography>
    </NxpDialog>
  );
};

export default AppPhotoDelete;

import {
  ExplorerFilterTree,
  ExplorerFilterTreeItem,
  ExplorerFilterViewBy,
} from './ExplorerFilterContainer';

export interface ExplorerFilterState {
  activeViewBy: ExplorerFilterViewBy;
  filterTrees: ExplorerFilterTree[];
}

export type ExplorerFilterAction =
  | {
      type: 'viewByChange';
      viewBy: ExplorerFilterViewBy;
    }
  | {
      type: 'filterTreeClear';
    }
  | {
      type: 'filterTreeLoaded';
      viewBy: ExplorerFilterViewBy;
      filterTreeItems: ExplorerFilterTreeItem[];
    }
  | {
      type: 'filterTreeToggle';
      nodeIds: string[];
    }
  | {
      type: 'filterTreeSelect';
      nodeIds: string[];
    };

export const explorerFilterReducer = (
  state: ExplorerFilterState,
  action: ExplorerFilterAction
): ExplorerFilterState => {
  switch (action.type) {
    case 'viewByChange': {
      return {
        ...state,
        activeViewBy: action.viewBy,
      };
    }
    case 'filterTreeClear': {
      return {
        ...state,
        filterTrees: [],
      };
    }
    case 'filterTreeLoaded': {
      return {
        ...state,
        filterTrees: state.filterTrees.find(
          (tree) => tree.viewBy === action.viewBy
        )
          ? // this case is not supposed to happen, but it's handled anyway
            [
              ...state.filterTrees.filter(
                (item) => item.viewBy !== action.viewBy
              ),
              {
                viewBy: action.viewBy,
                items: action.filterTreeItems,
                expandedNodeIds: [],
                selectedNodeIds: [],
              },
            ]
          : [
              ...state.filterTrees,
              {
                viewBy: action.viewBy,
                items: action.filterTreeItems,
                expandedNodeIds: [],
                selectedNodeIds: [],
              },
            ],
      };
    }
    case 'filterTreeToggle': {
      return {
        ...state,
        filterTrees: state.filterTrees.map((item) => {
          if (item.viewBy === state.activeViewBy) {
            return {
              ...item,
              expandedNodeIds: action.nodeIds,
            };
          }
          return item;
        }),
      };
    }
    case 'filterTreeSelect': {
      return {
        ...state,
        filterTrees: state.filterTrees.map((item) => {
          if (item.viewBy === state.activeViewBy) {
            return {
              ...item,
              selectedNodeIds: action.nodeIds,
            };
          }
          return item;
        }),
      };
    }
    default:
      return state;
  }
};

import React, {useEffect, useReducer, useRef} from 'react';
import {useAsync} from '../../../hooks/useAsync';
import useServiceConfig from '../../../hooks/useServiceConfig';
import {getFilterTreeData} from '../../../services/explorer';

import ExplorerFilterLayout from './ExplorerFilterLayout';
import {explorerFilterReducer} from './explorerFilterReducer';

export enum ExplorerFilterViewBy {
  dateTaken = 'dateTaken',
  uploadDate = 'uploadDate',
  location = 'location',
  uploader = 'uploader',
  photoType = 'type',
  tag = 'tag',
}

export interface ExplorerFilterTree {
  viewBy: ExplorerFilterViewBy;
  items: ExplorerFilterTreeItem[];
  expandedNodeIds: string[];
  selectedNodeIds: string[];
}

export interface ExplorerFilterTreeItem {
  label: string;
  nodeId: string;
  items?: ExplorerFilterTreeItem[];
}

interface ExplorerFilterContainerProps {
  entityId?: string;
  albumId?: number;
  refreshTracker?: Date;
  onFilterChange: (viewBy: ExplorerFilterViewBy, filterValue: string) => void;
}

const ExplorerFilterContainer: React.FC<ExplorerFilterContainerProps> = ({
  entityId,
  albumId,
  refreshTracker,
  onFilterChange,
}) => {
  const serviceConfig = useServiceConfig();

  const [filterState, dispatch] = useReducer(explorerFilterReducer, {
    activeViewBy: ExplorerFilterViewBy.dateTaken,
    filterTrees: [],
  });

  const prevRefreshTracker = useRef<Date>();

  const [treeDataAsyncResult, setTreeDataRequest] = useAsync<{
    viewBy: ExplorerFilterViewBy;
    filterTreeItems: ExplorerFilterTreeItem[];
  }>(
    () => getFilterTreeData(albumId, filterState.activeViewBy, serviceConfig),
    {
      onSuccess: (data) => {
        dispatch({
          type: 'filterTreeLoaded',
          viewBy: data.viewBy,
          filterTreeItems: data.filterTreeItems,
        });
      },
    }
  );

  useEffect(() => {
    if (
      refreshTracker !== undefined &&
      refreshTracker !== prevRefreshTracker.current
    ) {
      dispatch({
        type: 'filterTreeClear',
      });
      setTreeDataRequest(() =>
        getFilterTreeData(albumId, filterState.activeViewBy, serviceConfig)
      );
    }
    prevRefreshTracker.current = refreshTracker;
  }, [
    refreshTracker,
    filterState.activeViewBy,
    entityId,
    albumId,
    setTreeDataRequest,
    serviceConfig,
  ]);

  const handleViewByChange = (viewBy: ExplorerFilterViewBy) => {
    dispatch({
      type: 'viewByChange',
      viewBy,
    });

    const tree = filterState.filterTrees.find((item) => item.viewBy === viewBy);

    if (tree === undefined) {
      setTreeDataRequest(() =>
        getFilterTreeData(albumId, viewBy, serviceConfig)
      );
    } else if (tree.selectedNodeIds.length) {
      onFilterChange(viewBy, tree.selectedNodeIds[0]);
    }
  };

  const handleFilterTreeToggle = (
    _: React.ChangeEvent<{}>,
    nodeIds: string[]
  ) => {
    dispatch({
      type: 'filterTreeToggle',
      nodeIds,
    });
  };

  const handleFilterTreeSelect = (
    _: React.ChangeEvent<{}>,
    nodeIds: string[]
  ) => {
    const tree = filterState.filterTrees.find(
      (item) => item.viewBy === filterState.activeViewBy
    );
    // update only when selected node is changed
    if (tree?.selectedNodeIds[0] !== nodeIds[0]) {
      dispatch({
        type: 'filterTreeSelect',
        nodeIds: [nodeIds[0]], // support only one item at the moment
      });
      onFilterChange(filterState.activeViewBy, nodeIds[0]);
    }
  };

  return (
    <ExplorerFilterLayout
      loadingTreeData={treeDataAsyncResult.loading}
      viewBy={filterState.activeViewBy}
      filterTree={filterState.filterTrees.find(
        (item) => item.viewBy === filterState.activeViewBy
      )}
      onViewByChange={handleViewByChange}
      onFilterTreeToggle={handleFilterTreeToggle}
      onFilterTreeSelect={handleFilterTreeSelect}
    />
  );
};

export default ExplorerFilterContainer;

// from online sample - https://github.com/mrdoob/three.js/blob/master/examples/webgl_panorama_equirectangular.html
// eslint-disabled

import {makeStyles, Modal} from '@material-ui/core';
import {useEffect} from 'react';
import * as THREE from 'three';

let camera: any, scene: any, renderer: any;

let isUserInteracting = false,
  onPointerDownMouseX = 0,
  onPointerDownMouseY = 0,
  lon = 0,
  onPointerDownLon = 0,
  lat = 0,
  onPointerDownLat = 0,
  phi = 0,
  theta = 0;

function init(src: string) {
  const container = document.getElementById('panorama')!;

  camera = new THREE.PerspectiveCamera(
    75,
    window.innerWidth / window.innerHeight,
    1,
    1100
  );

  scene = new THREE.Scene();

  const geometry = new THREE.SphereGeometry(500, 60, 40);
  // invert the geometry on the x-axis so that all of the faces point inward
  geometry.scale(-1, 1, 1);

  const texture = new THREE.TextureLoader().load(src);
  const material = new THREE.MeshBasicMaterial({map: texture});

  const mesh = new THREE.Mesh(geometry, material);

  scene.add(mesh);

  renderer = new THREE.WebGLRenderer();
  renderer.setPixelRatio(window.devicePixelRatio);
  renderer.setSize(window.innerWidth - 70, window.innerHeight - 100);
  container.appendChild(renderer.domElement);

  container.style.touchAction = 'none';
  container.addEventListener('pointerdown', onPointerDown);

  document.addEventListener('wheel', onDocumentMouseWheel);

  //

  document.addEventListener('dragover', function (event: any) {
    event.preventDefault();
    event.dataTransfer.dropEffect = 'copy';
  });

  document.addEventListener('dragenter', function () {
    document.body.style.opacity = '0.5';
  });

  document.addEventListener('dragleave', function () {
    document.body.style.opacity = '1';
  });

  document.addEventListener('drop', function (event) {
    event.preventDefault();

    const reader = new FileReader();
    reader.addEventListener('load', function (event: any) {
      material.map!.image.src = event.target.result;
      material.map!.needsUpdate = true;
    });
    reader.readAsDataURL(event.dataTransfer!.files[0]);

    document.body.style.opacity = '1';
  });

  //

  window.addEventListener('resize', onWindowResize);

  /////////////////////////////////////////////////////////
  //   var scene = new THREE.Scene();
  //   var camera = new THREE.PerspectiveCamera(
  //     75,
  //     window.innerWidth / (window.innerHeight - 100),
  //     0.1,
  //     1000
  //   );
  //   var renderer = new THREE.WebGLRenderer();
  //   renderer.setSize(window.innerWidth, window.innerHeight - 100);
  //   // document.body.appendChild( renderer.domElement );
  //   // use ref as a mount point of the Three.js scene instead of the document.body
  //   container.appendChild(renderer.domElement);
  //   var geometry = new THREE.BoxGeometry(1, 1, 1);
  //   var material = new THREE.MeshBasicMaterial({color: 0x00ff00});
  //   var cube = new THREE.Mesh(geometry, material);
  //   scene.add(cube);
  //   camera.position.z = 5;
  //   var animate = function () {
  //     requestAnimationFrame(animate);
  //     cube.rotation.x += 0.01;
  //     cube.rotation.y += 0.01;
  //     renderer.render(scene, camera);
  //   };
  //   animate();
  /////////////////////////////////////////////////////////
}

function onWindowResize() {
  camera.aspect = window.innerWidth / window.innerHeight;
  camera.updateProjectionMatrix();

  renderer.setSize(window.innerWidth - 50, window.innerHeight - 100);
}

function onPointerDown(event: any) {
  if (event.isPrimary === false) return;

  isUserInteracting = true;

  onPointerDownMouseX = event.clientX;
  onPointerDownMouseY = event.clientY;

  onPointerDownLon = lon;
  onPointerDownLat = lat;

  document.addEventListener('pointermove', onPointerMove);
  document.addEventListener('pointerup', onPointerUp);
}

function onPointerMove(event: any) {
  if (event.isPrimary === false) return;

  lon = (onPointerDownMouseX - event.clientX) * 0.1 + onPointerDownLon;
  lat = (event.clientY - onPointerDownMouseY) * 0.1 + onPointerDownLat;
}

function onPointerUp(event: any) {
  if (event.isPrimary === false) return;

  isUserInteracting = false;

  document.removeEventListener('pointermove', onPointerMove);
  document.removeEventListener('pointerup', onPointerUp);
}

function onDocumentMouseWheel(event: any) {
  const fov = camera.fov + event.deltaY * 0.05;

  camera.fov = THREE.MathUtils.clamp(fov, 10, 75);

  camera.updateProjectionMatrix();
}

function animate() {
  requestAnimationFrame(animate);
  update();
}

function update() {
  if (isUserInteracting === false) {
    // disable animation
    //lon += 0.1;
    lon += 0;
  }

  lat = Math.max(-85, Math.min(85, lat));
  phi = THREE.MathUtils.degToRad(90 - lat);
  theta = THREE.MathUtils.degToRad(lon);

  const x = 500 * Math.sin(phi) * Math.cos(theta);
  const y = 500 * Math.cos(phi);
  const z = 500 * Math.sin(phi) * Math.sin(theta);

  camera.lookAt(x, y, z);

  renderer.render(scene, camera);
}

const useStyles = makeStyles((theme) => ({
  buttons: {
    textAlign: 'right',
    padding: 10,
    backgroundColor: 'rgba(0,0,0,0.75)',
    '& button.close': {
      width: 40,
      height: 35,
      cursor: 'pointer',
      background: `url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZlcnNpb249IjEuMSIgd2lkdGg9IjIwIiBoZWlnaHQ9IjIwIj48cGF0aCBkPSJtIDEsMyAxLjI1LC0xLjI1IDcuNSw3LjUgNy41LC03LjUgMS4yNSwxLjI1IC03LjUsNy41IDcuNSw3LjUgLTEuMjUsMS4yNSAtNy41LC03LjUgLTcuNSw3LjUgLTEuMjUsLTEuMjUgNy41LC03LjUgLTcuNSwtNy41IHoiIGZpbGw9IiNGRkYiLz48L3N2Zz4=')
        no-repeat center`,
      border: 'none',
      color: '#fff',
      opacity: 0.7,
    },
    '& button.prev': {
      cursor: 'pointer',
      background: `rgba(0,0,0,.2) url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMCIgaGVpZ2h0PSIzNCI+PHBhdGggZD0iTTE5IDNsLTItMkwxIDE3bDE2IDE2IDEtMUwzIDE3IDE4IDJ6IiBmaWxsPSIjRkZGIi8+PC9zdmc+") no-repeat 50%`,
      border: 'none',
      color: '#fff',
      opacity: 0.7,
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      width: 20,
      height: 34,
      padding: '40px 30px',
      margin: 'auto',
    },
    '& button.next': {
      cursor: 'pointer',
      background: `rgba(0,0,0,.2) url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMCIgaGVpZ2h0PSIzNCI+PHBhdGggZD0iTTEgM2wyLTIgMTYgMTZMMyAzM2wtMS0xIDE1LTE1TDIgMnoiIGZpbGw9IiNGRkYiLz48L3N2Zz4=") no-repeat 50%`,
      border: 'none',
      color: '#fff',
      opacity: 0.7,
      position: 'absolute',
      top: 0,
      bottom: 0,
      right: 0,
      width: 20,
      height: 34,
      padding: '40px 30px',
      margin: 'auto',
    },
  },
  panorama: {
    paddingLeft: 35,
  },
}));

interface PanoramaZoomProps {
  imageSrc: string;
  onClose: () => void;
  onMovePrevRequest: () => void;
  onMoveNextRequest: () => void;
}

const PanoramaZoom: React.FC<PanoramaZoomProps> = ({
  imageSrc,
  onClose,
  onMovePrevRequest,
  onMoveNextRequest,
}) => {
  useEffect(() => {
    setTimeout(() => {
      init(imageSrc);
      animate();
    }, 200);
  }, [imageSrc]);

  const classes = useStyles();

  return (
    <Modal
      open={true}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <>
        <div className={classes.buttons}>
          <button
            title="Previous"
            className="prev"
            onClick={onMovePrevRequest}
          />
          <button title="Next" className="next" onClick={onMoveNextRequest} />
          <button title="Close" className="close" onClick={onClose} />
        </div>
        <div className={classes.panorama} id="panorama"></div>
      </>
    </Modal>
  );
};

export default PanoramaZoom;

import React, {useReducer, useState} from 'react';
import {useTranslation} from 'react-i18next';

import {capitalize, lowerCase} from 'lodash';
import {useSnackbar} from 'notistack';

import {AddRoleFormGridState} from '../../components/AppAddRoleModal/AppAddRoleModalContainer';
import useAppContext from '../../hooks/useAppContext';
import {useAsync} from '../../hooks/useAsync';
import {
  applyAssigneeList,
  createModuleRole,
  deleteModuleRole,
  editModuleRole,
  getModuleRolesWithAssignees,
  ModuleRole,
} from '../../services/moduleRoleAssignment';
import ModuleRoleAssignmentLayout from './ModuleRoleAssignmentLayout';
import {moduleRoleAssignmenReducer} from './ModuleRoleAssignmentReducer';

interface ModuleRoleAssignmentSettingsContainerProps {}

const ModuleRoleAssignmentContainer: React.FC<ModuleRoleAssignmentSettingsContainerProps> =
  () => {
    const appContext = useAppContext();
    const {t: tran} = useTranslation();
    const {enqueueSnackbar} = useSnackbar();

    const [reducerState, dispatch] = useReducer(moduleRoleAssignmenReducer, {
      moduleRoles: [],
      assigneeIdsByRoleId: {},
      modifiedModuleRoleIds: [],
      deletedModuleRoleIds: [],
    });

    const [keyword, setKeyword] = useState('');
    const [addRoleModalOpen, setAddRoleModalOpen] = useState(false);
    const [batchAssignModalOpen, setBatchAssignModalOpen] = useState(false);

    const [selectedModuleRoleIds, setSelectedModuleRoleIds] = useState(
      [] as number[]
    );

    const [pendingRoleEdit, setPendingRoleEdit] = useState(
      null as unknown as ModuleRole
    );
    const [pendingRoleDeletion, setPendingRoleDeletion] = useState(0);

    const handleBatchAssignModalOpen = (open: boolean) => {
      if (open && selectedModuleRoleIds.length === 0) {
        enqueueSnackbar(tran('warning.role'));
        return;
      }
      setBatchAssignModalOpen(open);
    };

    const handleAssignmentChange = (
      moduleRoleId: number,
      assigneeIds: string[]
    ) => {
      dispatch({
        type: 'assigneesChanged',
        moduleRoleId,
        assigneeIds,
      });
    };

    const handleDiscard = () => {
      dispatch({
        type: 'assigneesDiscarded',
      });
    };

    const handleAssignmentSave = async () => {
      const moduleRoleIds = reducerState.modifiedModuleRoleIds.filter(
        (id) => !reducerState.deletedModuleRoleIds.includes(id)
      );

      if (moduleRoleIds.length === 0) {
        enqueueSnackbar(`${tran('warning.noChange')}`, {variant: 'error'});
        return;
      }

      let successCount = 0;
      let failureCount = 0;

      for (const moduleRoleId of moduleRoleIds) {
        try {
          const moduleRole = await applyAssigneeList(
            moduleRoleId,
            reducerState.assigneeIdsByRoleId[moduleRoleId],
            appContext.serviceConfig
          );

          dispatch({
            type: 'assigneesSaved',
            moduleRole,
          });
        } catch (ex) {
          failureCount++;
        }
        successCount++;
      }

      if (successCount) {
        enqueueSnackbar(
          `${capitalize(tran('common.user'))} ${lowerCase(
            tran('common.list')
          )}  ${lowerCase(tran('common.hasBeen'))} ${lowerCase(
            tran('common.updated')
          )} ${lowerCase(tran('common.success'))}`,
          {
            variant: 'success',
          }
        );
      }

      if (failureCount) {
        enqueueSnackbar(
          `Failed to apply assignees to ${failureCount} Module Role(s)`,
          {
            variant: 'error',
          }
        );
      }
    };

    const handleModuleRoleSelect = (
      moduleRoleId: number,
      selected: boolean
    ) => {
      const ids = [...selectedModuleRoleIds] as number[];
      const index = selectedModuleRoleIds.indexOf(moduleRoleId);

      if (selected) {
        if (index === -1) {
          ids.push(moduleRoleId);
        }
      } else {
        if (index !== -1) {
          ids.splice(index, 1);
        }
      }
      setSelectedModuleRoleIds(ids);
    };

    const handleBatchAssign = (userIds: string[]) => {
      for (const roleId of selectedModuleRoleIds) {
        const assigneeIds = [...reducerState.assigneeIdsByRoleId[roleId]];
        for (const userId of userIds) {
          if (!assigneeIds.includes(userId)) assigneeIds.push(userId);
        }
        dispatch({
          type: 'assigneesChanged',
          moduleRoleId: roleId,
          assigneeIds: assigneeIds,
        });
      }

      enqueueSnackbar(
        `${capitalize(tran('common.added'))} ${userIds.length} ${tran(
          'common.person'
        )} ${lowerCase(tran('common.to'))} ${lowerCase(
          tran('common.list')
        )}${tran('common.warning.notYetSave')}`
      );

      setBatchAssignModalOpen(false);
    };

    const handleRoleCreate = async (formGridState: AddRoleFormGridState) => {
      try {
        const newModuleRole = await createModuleRole(
          formGridState,
          appContext.serviceConfig
        );
        enqueueSnackbar(
          `${tran('role')} ${lowerCase(tran('common.added'))} ${lowerCase(
            tran('common.success')
          )}`,
          {
            variant: 'success',
          }
        );
        dispatch({
          type: 'moduleRoleAdded',
          moduleRole: newModuleRole,
        });
        setAddRoleModalOpen(false);
      } catch (ex) {
        appContext.errorHandler(
          ex as Error,
          'creating module role',
          formGridState
        );
      }
    };

    const handleRoleEditRequest = async (moduleRoleId: number) => {
      const moduleRole = reducerState.moduleRoles.find(
        (role) => role.id === moduleRoleId
      );
      if (!moduleRole) return;
      setPendingRoleEdit(moduleRole);
    };

    const handleRoleEditCancel = async () => {
      setPendingRoleEdit(null as unknown as ModuleRole);
    };

    const handleRoleEditConfirm = async (
      moduleRoleId: number,
      name: string,
      description: string
    ) => {
      try {
        const moduleRole = await editModuleRole(
          moduleRoleId,
          name,
          description,
          appContext.serviceConfig
        );
        enqueueSnackbar(
          `${capitalize(tran('role'))} ${capitalize(
            tran('common.name')
          )}  ${capitalize(tran('common.edited'))} ${capitalize(
            lowerCase(tran('common.success'))
          )}`,
          {
            variant: 'success',
          }
        );
        dispatch({
          type: 'moduleRoleEdited',
          moduleRole,
        });
        setPendingRoleEdit(null as unknown as ModuleRole);
      } catch (ex) {
        appContext.errorHandler(ex as Error, 'updating module role');
      }
    };

    const handleRoleDeletionRequest = async (moduleRoleId: number) => {
      setPendingRoleDeletion(moduleRoleId);
    };

    const handleRoleDeletionCancel = async () => {
      setPendingRoleDeletion(0);
    };

    const handleRoleDeletionConfirm = async () => {
      try {
        await deleteModuleRole(pendingRoleDeletion, appContext.serviceConfig);
        enqueueSnackbar(
          `${tran('role')} ${lowerCase(
            tran('common.delete.capitalize')
          )} ${lowerCase(tran('common.success'))}`
        );
        dispatch({
          type: 'moduleRoleDeleted',
          moduleRoleId: pendingRoleDeletion,
        });
        setPendingRoleDeletion(0);
      } catch (ex) {
        appContext.errorHandler(
          ex as Error,
          'deleting module role',
          pendingRoleDeletion
        );
      }
    };

    const [,] = useAsync<ModuleRole[]>(
      () => getModuleRolesWithAssignees(appContext.serviceConfig),
      {
        onSuccess: (moduleRoles: ModuleRole[]) => {
          if (moduleRoles) {
            dispatch({
              type: 'moduleRolesLoaded',
              moduleRoles: moduleRoles,
            });
          }
        },
        onError: (ex) =>
          appContext.errorHandler(ex as Error, 'loading module roles'),
      }
    );

    return (
      <ModuleRoleAssignmentLayout
        moduleRoles={reducerState.moduleRoles}
        assigneeIdsByRoleId={reducerState.assigneeIdsByRoleId}
        selectedModuleRoleIds={selectedModuleRoleIds}
        modifiedModuleRoleIds={reducerState.modifiedModuleRoleIds}
        deletedModuleRoleIds={reducerState.deletedModuleRoleIds}
        keyword={keyword}
        onKeywordChange={(keyword) => setKeyword(keyword)}
        addRoleModalOpen={addRoleModalOpen}
        onAddRoleModalOpen={() => setAddRoleModalOpen(true)}
        onAddRoleModalClose={() => setAddRoleModalOpen(false)}
        batchAssignModalOpen={batchAssignModalOpen}
        onBatchAssignModalOpen={() => handleBatchAssignModalOpen(true)}
        onBatchAssignModalClose={() => handleBatchAssignModalOpen(false)}
        onBatchAssign={handleBatchAssign}
        onRoleCreate={handleRoleCreate}
        pendingRoleEdit={pendingRoleEdit}
        onRoleEditRequest={handleRoleEditRequest}
        onRoleEditCancel={handleRoleEditCancel}
        onRoleEditConfirm={handleRoleEditConfirm}
        pendingRoleDeletion={pendingRoleDeletion}
        onRoleDeletionRequest={handleRoleDeletionRequest}
        onRoleDeletionCancel={handleRoleDeletionCancel}
        onRoleDeletionConfirm={handleRoleDeletionConfirm}
        onModuleRoleSelect={handleModuleRoleSelect}
        onAssignmentChange={handleAssignmentChange}
        onAssignmentsDiscard={handleDiscard}
        onAssignmentsSave={handleAssignmentSave}
      />
    );
  };

export default ModuleRoleAssignmentContainer;

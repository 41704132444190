import React from 'react';

import {NxpFormControlPropsForExtends} from '../NxpFormControl/NxpFormControl';
import buildRandomId from '../../utils/buildRandomId';
import {useConst} from '../../hooks/useConst';
import NxpDatePickerBase, {NxpDatePickerBaseProps} from './NxpDatePickerBase';

export interface NxpDatePickerProps
  extends NxpDatePickerBaseProps,
    NxpFormControlPropsForExtends {}

const NxpDatePicker = React.forwardRef<HTMLDivElement, NxpDatePickerProps>(
  function NxpDatePicker(
    {
      id,
      label,
      errorContent,
      helperText,
      formControlProps,
      error,
      ...restProps
    },
    ref // ref of mui DatePicker is not being set properly at the moment, try use the another property - TextFieldComponent - https://stackoverflow.com/a/60441524/8252769
  ) {
    const idConst = useConst(() => id || buildRandomId('datePicker'));

    return (
      <NxpDatePickerBase
        id={idConst}
        label={label}
        helperText={errorContent ? errorContent : helperText}
        error={!!errorContent || error}
        InputLabelProps={formControlProps?.inputLabelProps}
        FormHelperTextProps={formControlProps?.formHelperTextProps}
        ref={ref}
        {...restProps}
      />
    );
  }
);
export default NxpDatePicker;

import {ModuleRole} from '../../services/moduleRoleAssignment';

interface ModuleRoleAssignmentState {
  moduleRoles: ModuleRole[];
  assigneeIdsByRoleId: Record<string, string[]>;
  modifiedModuleRoleIds: number[];
  deletedModuleRoleIds: number[];
}

type ModuleRoleAssignmentAction =
  | {
      type: 'moduleRolesLoaded';
      moduleRoles: ModuleRole[];
    }
  | {
      type: 'moduleRoleAdded';
      moduleRole: ModuleRole;
    }
  | {
      type: 'moduleRoleEdited';
      moduleRole: ModuleRole;
    }
  | {
      type: 'moduleRoleDeleted';
      moduleRoleId: number;
    }
  | {
      type: 'assigneesChanged';
      moduleRoleId: number;
      assigneeIds: string[];
    }
  | {
      type: 'assigneesSaved';
      moduleRole: ModuleRole;
    }
  | {
      type: 'assigneesDiscarded';
    };

export const moduleRoleAssignmenReducer = (
  state: ModuleRoleAssignmentState,
  action: ModuleRoleAssignmentAction
): ModuleRoleAssignmentState => {
  switch (action.type) {
    case 'moduleRolesLoaded': {
      const assigneeIdsByRoleId = {} as Record<string, string[]>;
      for (const role of action.moduleRoles) {
        assigneeIdsByRoleId[role.id] = role.users.map((user) => user.id);
      }
      return {
        ...state,
        moduleRoles: action.moduleRoles,
        assigneeIdsByRoleId: assigneeIdsByRoleId,
        modifiedModuleRoleIds: [],
        deletedModuleRoleIds: [],
      };
    }
    case 'moduleRoleAdded': {
      const moduleRole = action.moduleRole;
      if (!moduleRole.users) {
        moduleRole.users = [];
      }
      return {
        ...state,
        moduleRoles: [...state.moduleRoles, action.moduleRole],
        assigneeIdsByRoleId: {
          ...state.assigneeIdsByRoleId,
          [moduleRole.id]: moduleRole.users.map((user) => user.id),
        },
      };
    }
    case 'moduleRoleEdited': {
      const moduleRoles = [...state.moduleRoles];
      const moduleRole = action.moduleRole;

      const index = moduleRoles.findIndex((role) => role.id === moduleRole.id);
      if (index !== -1) {
        moduleRoles[index] = moduleRole;
      }

      return {
        ...state,
        moduleRoles,
      };
    }
    case 'moduleRoleDeleted': {
      return {
        ...state,
        deletedModuleRoleIds: [
          ...state.deletedModuleRoleIds,
          action.moduleRoleId,
        ],
      };
    }
    case 'assigneesChanged': {
      const modifiedModuleRoleIds = [...state.modifiedModuleRoleIds];
      if (!modifiedModuleRoleIds.includes(action.moduleRoleId)) {
        modifiedModuleRoleIds.push(action.moduleRoleId);
      }

      return {
        ...state,
        assigneeIdsByRoleId: {
          ...state.assigneeIdsByRoleId,
          [action.moduleRoleId]: action.assigneeIds,
        },
        modifiedModuleRoleIds,
      };
    }
    case 'assigneesSaved': {
      const moduleRole = action.moduleRole;

      const moduleRoles = state.moduleRoles;
      const index = moduleRoles.findIndex((role) => role.id === moduleRole.id);
      moduleRoles[index] = moduleRole;

      const modifiedModuleRoleIds = state.modifiedModuleRoleIds.filter(
        (id) => id !== moduleRole.id
      );

      return {
        ...state,
        moduleRoles,
        assigneeIdsByRoleId: {
          ...state.assigneeIdsByRoleId,
          [moduleRole.id]: moduleRole.users.map((user) => user.id),
        },
        modifiedModuleRoleIds,
      };
    }
    case 'assigneesDiscarded': {
      const assigneeIdsByRoleId = {} as Record<string, string[]>;
      for (const role of state.moduleRoles) {
        assigneeIdsByRoleId[role.id] = role.users.map((user) => user.id);
      }
      return {
        ...state,
        assigneeIdsByRoleId: assigneeIdsByRoleId,
        modifiedModuleRoleIds: [],
      };
    }
    default:
      return state;
  }
};

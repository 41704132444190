import {useEffect, useState} from 'react';
import {ModuleRole} from '../../services/moduleRoleAssignment';
import EditRoleModalLayout from './AppEditRoleModalLayout';

interface EditRoleModalContainerProps {
  editingRole: ModuleRole | null;
  onSave: (moduleRoleId: number, name: string, description: string) => void;
  onDismiss: () => void;
}

export interface EditRoleFormGridState {
  name: string;
  code: string;
  description: string;
}

const EditRoleModalContainer: React.FC<EditRoleModalContainerProps> = (
  props
) => {
  const [formGridState, setFormGridState] = useState({
    name: '',
    code: '',
    description: '',
  } as EditRoleFormGridState);

  useEffect(() => {
    if (props.editingRole) {
      setFormGridState({
        name: props.editingRole.name,
        code: props.editingRole.code,
        description: props.editingRole.description,
      });
    }
  }, [props.editingRole]);

  const handleFormGridStateChange = (fieldName: string, value: unknown) => {
    setFormGridState({
      ...formGridState,
      [fieldName]: value,
    });
  };

  const handleSave = () => {
    if (!props.editingRole) return;
    props.onSave(
      props.editingRole.id,
      formGridState.name,
      formGridState.description
    );
  };

  return (
    <EditRoleModalLayout
      open={props.editingRole ? true : false}
      formGridState={formGridState}
      onFormGridStateChange={handleFormGridStateChange}
      onSave={handleSave}
      onDismiss={props.onDismiss}
    />
  );
};

export default EditRoleModalContainer;

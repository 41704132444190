import React, {HTMLAttributes} from 'react';

import {
  createStyles,
  Fab,
  makeStyles,
  useScrollTrigger,
  Zoom,
} from '@material-ui/core';
import {KeyboardArrowUp} from '@material-ui/icons';

import clsx from 'clsx';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      position: 'fixed',
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
  })
);

interface NxpBackToTopProps extends HTMLAttributes<HTMLDivElement> {}

const NxpBackToTop: React.FC<NxpBackToTopProps> = ({
  className,
  children,
  ...restProps
}) => {
  const classes = useStyles();

  const trigger = useScrollTrigger({
    target: undefined,
    disableHysteresis: true,
    threshold: 100,
  });

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    // ignore approach suggested in material document
    // const anchor = (
    //   (event.target as HTMLDivElement).ownerDocument || document
    // ).querySelector('#back-to-top-anchor');
    // if (anchor) {
    //   anchor.scrollIntoView({behavior: 'smooth', block: 'center'});
    // }

    window.scrollTo({behavior: 'smooth', top: 0});
  };

  return (
    <Zoom in={trigger}>
      <div
        onClick={handleClick}
        role="presentation"
        className={clsx('NxpBackToTop', classes.root, className)}
        {...restProps}
      >
        {children ? (
          children
        ) : (
          <Fab color="primary" size="small" aria-label="scroll back to top">
            <KeyboardArrowUp />
          </Fab>
        )}
      </div>
    </Zoom>
  );
};

export default NxpBackToTop;

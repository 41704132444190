import querystring from 'querystring';

export default function buildQueryString(query: {[key: string]: unknown} | {}) {
  const refinedQuery = {...query};
  Object.entries(query).forEach((entry) => {
    if (typeof entry[1] === 'boolean') {
      Object.assign(refinedQuery, {
        [entry[0]]: entry[1].toString(),
      });
    } else if (entry[1] instanceof Date) {
      Object.assign(refinedQuery, {
        [entry[0]]: (entry[1] as Date).toISOString(),
      });
    }
  });
  return querystring.encode(refinedQuery as {[key: string]: string | number});
}

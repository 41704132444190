import React from 'react';
import {
  Box,
  Button,
  InputAdornment,
  makeStyles,
  TextField,
} from '@material-ui/core';

import NxpHeader from '../../components/NxpHeader';
import ObjectRoleItem from './ObjectRoleItem/ObjectRoleItem';
import {ObjectRole} from '../../services/objectRoleAssignment';
import AddRoleModal from '../../components/AppAddRoleModal';
import {AddRoleFormGridState} from '../../components/AppAddRoleModal/AppAddRoleModalContainer';
import BatchAssignModal from '../../components/AppBatchAssignModal';
import AppConfirmDialog from '../../components/AppConfirmDialog';
import EditRoleModal from '../../components/AppEditRoleModal';
import TableHead from './TableHead';
import useAppContext from '../../hooks/useAppContext';
import {Search} from '@material-ui/icons';
import {useTranslation} from 'react-i18next';
import _ from 'lodash';

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: 'calc(100vh - 85px)',
  },
  result: {
    flexGrow: 1,
    padding: theme.spacing(3, 5),
  },
  searchBar: {
    '& .MuiOutlinedInput-input': {
      padding: '9px 14px',
    },
  },
}));

interface ObjectRoleAssignmentLayoutProps {
  objectRoles: ObjectRole[];
  assigneeIdsByRoleId: Record<string, string[]>;
  selectedObjectRoleIds: number[];
  modifiedObjectRoleIds: number[];
  deletedObjectRoleIds: number[];
  keyword: string;
  onKeywordChange: (keyword: string) => void;
  addRoleModalOpen: boolean;
  onAddRoleModalOpen: () => void;
  onAddRoleModalClose: () => void;
  batchAssignModalOpen: boolean;
  onBatchAssignModalOpen: () => void;
  onBatchAssignModalClose: () => void;
  onBatchAssign: (userIds: string[]) => void;
  onRoleCreate: (formGridState: AddRoleFormGridState) => void;
  pendingRoleEdit: null | ObjectRole;
  onRoleEditRequest: (objectRoleId: number) => void;
  onRoleEditCancel: () => void;
  onRoleEditConfirm: (
    objectRoleId: number,
    name: string,
    description: string
  ) => void;
  pendingRoleDeletion: number;
  onRoleDeletionRequest: (objectRoleId: number) => void;
  onRoleDeletionCancel: () => void;
  onRoleDeletionConfirm: () => void;
  onObjectRoleSelect: (objectRoleId: number, selected: boolean) => void;
  onAssignmentChange: (objectRoleId: number, assigneeIds: string[]) => void;
  onAssignmentsDiscard: () => void;
  onAssignmentsSave: () => void;
}

const ObjectRoleAssignmentLayout: React.FC<ObjectRoleAssignmentLayoutProps> = ({
  objectRoles,
  assigneeIdsByRoleId,
  selectedObjectRoleIds,
  modifiedObjectRoleIds,
  deletedObjectRoleIds,
  keyword,
  onKeywordChange,
  addRoleModalOpen,
  onAddRoleModalOpen,
  onAddRoleModalClose,
  batchAssignModalOpen,
  onBatchAssignModalOpen,
  onBatchAssignModalClose,
  onBatchAssign,
  onRoleCreate,
  pendingRoleEdit,
  onRoleEditRequest,
  onRoleEditCancel,
  onRoleEditConfirm,
  pendingRoleDeletion,
  onRoleDeletionRequest,
  onRoleDeletionCancel,
  onRoleDeletionConfirm,
  onObjectRoleSelect,
  onAssignmentChange,
  onAssignmentsDiscard,
  onAssignmentsSave,
}) => {
  const classes = useStyles();
  const {t: tran} = useTranslation();
  const appContext = useAppContext();

  return (
    <>
      <div className={classes.root}>
        <div className={classes.result}>
          <NxpHeader
            titleContent={`${_.capitalize(tran('manage'))} ${_.capitalize(
              tran('roles')
            )} (${_.capitalize(tran('album'))})`}
            actionContent={
              <>
                <TextField
                  className={classes.searchBar}
                  id="input-with-icon-textfield"
                  variant="outlined"
                  placeholder="Search"
                  value={keyword}
                  onChange={(ev) => onKeywordChange(ev.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Search />
                      </InputAdornment>
                    ),
                  }}
                />{' '}
                {appContext.hasUserAccessRight('role-create') && (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={onAddRoleModalOpen}
                  >
                    {_.capitalize(tran('common.add'))}{' '}
                    {_.capitalize(tran('role'))}
                  </Button>
                )}{' '}
                {appContext.hasUserAccessRight('role-assign') && (
                  <Button variant="contained" onClick={onBatchAssignModalOpen}>
                    {_.capitalize(tran('batch'))} {_.capitalize(tran('assign'))}
                  </Button>
                )}
              </>
            }
          />

          <TableHead />

          {objectRoles.map((objectRole) =>
            objectRole.name.toLowerCase().indexOf(keyword.toLowerCase()) !==
            -1 ? (
              <ObjectRoleItem
                key={`ObjectRoleItem_${objectRole.id}`}
                objectRole={objectRole}
                selected={selectedObjectRoleIds.includes(objectRole.id)}
                modified={modifiedObjectRoleIds.includes(objectRole.id)}
                deleted={deletedObjectRoleIds.includes(objectRole.id)}
                assigneeIds={assigneeIdsByRoleId[objectRole.id]}
                onSelect={onObjectRoleSelect}
                onRoleEdit={onRoleEditRequest}
                onRoleDelete={onRoleDeletionRequest}
                onAssignmentChange={onAssignmentChange}
              />
            ) : null
          )}

          {appContext.hasUserAccessRight('role-assign') && (
            <Box display="flex" justifyContent="flex-end">
              <Box>
                <Button onClick={onAssignmentsDiscard} variant="contained">
                  {_.capitalize(tran('discard'))}{' '}
                  {_.capitalize(tran('common.change'))}
                </Button>
                <Button
                  onClick={onAssignmentsSave}
                  variant="contained"
                  color="primary"
                >
                  {`${tran('common.save')} ${tran('common.change')}`}
                </Button>
              </Box>
            </Box>
          )}
        </div>
      </div>
      <AddRoleModal
        open={addRoleModalOpen}
        onSave={onRoleCreate}
        onDismiss={onAddRoleModalClose}
      />
      <EditRoleModal
        editingRole={pendingRoleEdit}
        onSave={onRoleEditConfirm}
        onDismiss={onRoleEditCancel}
      />
      <BatchAssignModal
        open={batchAssignModalOpen}
        onSave={onBatchAssign}
        onDismiss={onBatchAssignModalClose}
      />
      <AppConfirmDialog
        show={pendingRoleDeletion !== 0}
        title={`${tran('common.delete.capitalize')} ${tran(
          'common.role.capitalize'
        )} ?`}
        message={tran('warning.role.delete')}
        onConfirm={onRoleDeletionConfirm}
        onCancel={onRoleDeletionCancel}
      />
    </>
  );
};

export default ObjectRoleAssignmentLayout;

import React from 'react';

import NxpFormControl, {
  NxpFormControlPropsForExtends,
} from '../NxpFormControl/NxpFormControl';
import buildRandomId from '../../utils/buildRandomId';
import {useConst} from '../../hooks/useConst';
import NxpTextInputBase, {NxpTextInputBaseProps} from './NxpTextInputBase';

export interface NxpTextInputProps
  extends Omit<NxpTextInputBaseProps, 'ref'>,
    Omit<NxpFormControlPropsForExtends, 'ref'> {}

const NxpTextInput = React.forwardRef<HTMLDivElement, NxpTextInputProps>(
  function NxpTextInput(
    {
      label,
      errorContent,
      id,
      formControlProps,
      required,
      fullWidth,
      ...restProps
    },
    ref
  ) {
    const idConst = useConst(() => id || buildRandomId('textInput'));

    return (
      <NxpFormControl
        ref={ref}
        label={label}
        errorContent={errorContent}
        inputControlId={idConst}
        required={required}
        fullWidth={fullWidth}
        {...formControlProps}
      >
        <NxpTextInputBase id={idConst} {...restProps} />
      </NxpFormControl>
    );
  }
);

export default NxpTextInput;

import React from 'react';

import NxpFormControl, {
  NxpFormControlPropsForExtends,
} from '../NxpFormControl/NxpFormControl';
import buildRandomId from '../../utils/buildRandomId';
import {useConst} from '../../hooks/useConst';
import NxpCheckboxBase, {NxpCheckdoxBaseProps} from './NxpCheckboxBase';

export interface NxpCheckboxProps
  extends Omit<NxpCheckdoxBaseProps, 'ref'>,
    Omit<NxpFormControlPropsForExtends, 'ref'> {
  checkboxRef?: React.Ref<unknown>;
}

const NxpCheckbox = React.forwardRef<HTMLDivElement, NxpCheckboxProps>(
  function NxpSelect(
    {
      errorContent,
      id,
      required,
      formControlProps,
      fullWidth,
      checkboxRef,
      label,
      ...restProps
    },
    ref
  ) {
    const idConst = useConst(() => id || buildRandomId('checkbox'));
    return (
      <NxpFormControl
        errorContent={errorContent}
        required={required}
        fullWidth={fullWidth}
        {...formControlProps}
        inputLabelProps={{id: `${idConst}-label`}}
        // inputControlId={id} don't set inputControlId as the <label> element's for attribute does not work with mui select control
        ref={ref}
      >
        <NxpCheckboxBase
          label={label}
          ref={checkboxRef}
          id={idConst}
          {...restProps}
        />
      </NxpFormControl>
    );
  }
);

export default NxpCheckbox;

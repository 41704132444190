import '@material-ui/core/styles';

import {createMuiTheme} from '@material-ui/core';

declare module '@material-ui/core/styles/createMuiTheme' {
  interface Theme {
    dimension: {
      circularProgressSize: {
        small: number;
        main: number;
      };
    };
  }

  interface ThemeOptions {
    dimension: {
      circularProgressSize: {
        small: number;
        main: number;
      };
    };
  }
}

declare module '@material-ui/core/styles/createPalette' {
  interface Palette {
    border: {
      light: string;
      main: string;
    };
    label?: string;
  }

  interface PaletteOptions {
    border: {
      light: string;
      main: string;
    };
    label?: string;
  }

  interface TypeBackground {
    soft: string;
    dark: string;
    darker: string;
  }
}

export const theme = createMuiTheme({
  props: {
    MuiButtonBase: {
      disableRipple: true,
    },
  },
  dimension: {
    // custom prop defined in Theme.d.ts
    circularProgressSize: {
      small: 20,
      main: 38,
    },
  },
  shape: {
    borderRadius: 4,
  },
  typography: {
    fontSize: 14,
    h1: {
      fontSize: '1.6rem',
    },
    h2: {
      fontSize: '1.25rem',
    },
    h3: {
      fontSize: '1.15rem',
    },
    h4: {
      fontSize: '1.1rem',
    },
    h5: {
      fontSize: '1.05rem',
    },
    h6: {
      fontSize: '1rem',
    },
  },
  palette: {
    label: 'rgb(138,153,153)',
    background: {
      soft: 'rgb(250, 250, 252)',
      dark: 'rgb(239, 241, 242)',
      darker: 'rgb(138, 153, 153)',
    },
    border: {
      light: 'rgb(234, 237, 243)',
      main: 'rgba(188, 203, 203)',
    },
    primary: {
      // light: '',
      main: 'rgb(0, 87, 148)',
      // dark: '',
      // contrastText: '',
    },
    warning: {
      main: 'rgb(220,53,69)',
    },
    // secondary: {
    //   main: '',
    // },
  },
});

theme.overrides = {
  // redefined here to allow overrides to use theme settings such as palette - https://stackoverflow.com/a/57632144/8252769

  MuiInputBase: {
    disabled: {
      backgroundColor: theme.palette.background.dark,
      cursor: 'not-allowed !important',
      '& .Mui-checked svg.MuiSvgIcon-root': {
        color: theme.palette.background.darker,
      },
    },
  },

  MuiFormControl: {
    ...theme.overrides?.MuiFormControl,
    root: {
      '& .MuiInputLabel-root.MuiInputLabel-formControl': {
        fontSize: theme.typography.caption.fontSize,
        fontWeight: theme.typography.fontWeightBold as number,
        textTransform: 'uppercase',
        color: theme.palette.label,
        '& .MuiFormLabel-asterisk.MuiInputLabel-asterisk': {
          color: theme.palette.error.main,
        },
      },
    },
  },
};

import React, {useReducer, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useRouteMatch} from 'react-router-dom';

import {useSnackbar} from 'notistack';

import useAppContext from '../../hooks/useAppContext';
import {useAsync} from '../../hooks/useAsync';
import {
  getObjectRoles,
  ObjectRole,
  saveObjectRoleAccessRights,
} from '../../services/objectRoleAccessRight';
import ObjectRoleAccessRightSettingsLayout from './ObjectRoleAccessRightSettingsLayout';
import {
  AccessRightList,
  objectRoleAccessRightSettingsReducer,
} from './objectRoleAccessRightSettingsReducer';

interface ObjectRoleAccessRightItem {
  title: string;
  value: keyof AccessRightList;
}

export type ObjectRoleAccessRightSettingsType =
  | 'managePhotos'
  | 'lightbox'
  | 'dashboard'
  | 'manageRoles'
  | 'AccessRights'
  | 'setup';
export interface ObjectRoleAccessRightItems {
  [key: string]: ObjectRoleAccessRightItem[];
}
interface ObjectRoleAccessRightSettingsContainerProps {}

const ObjectRoleAccessRightSettingsContainer: React.FC<ObjectRoleAccessRightSettingsContainerProps> =
  () => {
    const appContext = useAppContext();
    const {enqueueSnackbar} = useSnackbar();
    const {t: tran} = useTranslation();
    let objectType = '';
    let objectId = 0;

    {
      const route = useRouteMatch<{entityId: string; albumId: string}>(
        '/entities/:entityId/albums/:albumId'
      );
      if (route) {
        objectType = 'album';
        objectId = parseInt(route.params.albumId);
      }
    }

    const [objectRoleAccessRightItems, ,] =
      useState<ObjectRoleAccessRightItems>({
        managePhotos: [
          {
            title: `${tran('right.view.photo')}`,
            value: 'photo-view',
          },
          {
            title: `${tran('right.upload.photo')}`,
            value: 'photo-upload',
          },
          {
            title: `${tran('right.edit.photo')}`,
            value: 'photo-edit',
          },
          {
            title: `${tran('right.delete.photo')}`,
            value: 'photo-delete',
          },
          {
            title: `${tran('right.download.photo')}`,
            value: 'photo-download',
          },
        ],
        lightbox: [
          {
            title: `${tran('right.create.lightbox')}`,
            value: 'lightbox-create',
          },
          {
            title: `${tran('right.view.lightbox')}`,
            value: 'lightbox-view',
          },
          {
            title: `${tran('right.edit.lightbox')}`,
            value: 'lightbox-edit',
          },
        ],
        album: [
          {
            title: `${tran('right.view.album')}`,
            value: 'album-view',
          },
          {
            title: `${tran('right.edit.album')}`,
            value: 'album-edit',
          },
          {
            title: `${tran('right.delete.album')}`,
            value: 'album-delete',
          },
        ],
        dashboard: [
          {
            title: `${tran('right.view.album')}`,
            value: 'dashboard-view',
          },
        ],
        manageRoles: [
          {
            title: `${tran('right.create.role')}`,
            value: 'role-view',
          },
          {
            title: `${tran('right.create.role')}`,
            value: 'role-create',
          },
          {
            title: `${tran('right.create.role')}`,
            value: 'role-edit',
          },
          {
            title: `${tran('right.create.role')}`,
            value: 'role-assign',
          },
          {
            title: `${tran('right.create.role')}`,
            value: 'role-delete',
          },
        ],
        accessRights: [
          {
            title: `${tran('right.view.access')}`,
            value: 'access-right-view',
          },
          {
            title: `${tran('right.assign.access')}`,
            value: 'access-right-assign',
          },
        ],
        setup: [
          {
            title: `${tran('right.add.item')}`,
            value: 'tag-add',
          },
          {
            title: `${tran('right.edit.item')}`,
            value: 'tag-edit',
          },
          {
            title: `${tran('right.delete.item')}`,
            value: 'tag-delete',
          },
        ],
      });

    const [objectRoleAccessRightSettingsState, dispatch] = useReducer(
      objectRoleAccessRightSettingsReducer,
      {
        objectRoles: [],
        selectedObjectRole: null,
        accessRightList: {} as AccessRightList,
        accessRightListTouched: false,
      }
    );

    const [,] = useAsync<ObjectRole[]>(
      () => getObjectRoles(objectType, objectId, appContext.serviceConfig),
      {
        onSuccess: (objectRoles: ObjectRole[]) => {
          if (objectRoles) {
            dispatch({
              type: 'objectRolesLoaded',
              objectRoles: objectRoles,
            });
          }
        },
        onError: (ex) =>
          appContext.errorHandler(ex as Error, 'loading object roles'),
      }
    );

    const handleAccessRightCheck = (value: string, checked: boolean) => {
      dispatch({type: 'accessRightChecked', value, checked});
      return;
    };

    const handleObjectRoleSelect = (objectRoleId: number) => {
      dispatch({type: 'objectRoleSelected', objectRoleId});
    };

    const handleSaveButton = async () => {
      if (!objectRoleAccessRightSettingsState.selectedObjectRole) return;
      try {
        const objectRole = await saveObjectRoleAccessRights(
          objectRoleAccessRightSettingsState.selectedObjectRole.id,
          objectRoleAccessRightSettingsState.accessRightList,
          appContext.serviceConfig
        );
        enqueueSnackbar('Access Right has been applied');
        dispatch({
          type: 'objectRoleSaved',
          objectRole,
        });
      } catch (ex: any) {
        appContext.errorHandler(ex as Error, 'applying object access right');
      }
    };

    return (
      <ObjectRoleAccessRightSettingsLayout
        objectRoles={objectRoleAccessRightSettingsState.objectRoles}
        selectedObjectRole={
          objectRoleAccessRightSettingsState.selectedObjectRole
        }
        accessRightList={objectRoleAccessRightSettingsState.accessRightList}
        onAccessRightCheck={handleAccessRightCheck}
        onObjectRoleSelect={handleObjectRoleSelect}
        onSaveButton={handleSaveButton}
        objectRoleAccessRightItems={objectRoleAccessRightItems}
      />
    );
  };

export default ObjectRoleAccessRightSettingsContainer;

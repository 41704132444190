import {saveAs} from 'file-saver';

const saveAsUrl = (url: string, filename: string) => {
  fetch(url)
    .then((res) => res.blob())
    .then((blob) => {
      saveAs(blob, filename);
    });
};

export default saveAsUrl;

import {makeStyles} from '@material-ui/core';
import _ from 'lodash';
import {useTranslation} from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  divContent: {
    marginTop: 36,
    width: '100%',
    marginLeft: 24,
    marginRight: 24,
    color: 'rgb(138,153,153)',
    fontSize: '0.75rem',
    fontWeight: 700,
    textTransform: 'uppercase',
  },
  div1: {display: 'inline-block', width: '5%'},
  div2: {display: 'inline-block', width: '20%'},
  div3: {
    display: 'inline-block',
    width: '69%',
  },
  div4: {display: 'inline-block', width: '6%', textAlign: 'center'},
}));

const TableHead = () => {
  const classes = useStyles();
  const {t: tran} = useTranslation();
  return (
    <div className={classes.divContent}>
      <div className={classes.div1}></div>
      <div className={classes.div2}>{_.capitalize(tran('role'))}</div>
      <div className={classes.div3}>{_.capitalize(tran('user'))}</div>
      <div className={classes.div4}></div>
    </div>
  );
};

export default TableHead;

import React, {ReactNode} from 'react';
import {
  FormControl,
  FormControlProps,
  FormHelperText,
  FormHelperTextProps,
  InputLabel,
  InputLabelProps,
} from '@material-ui/core';

export interface NxpFormControlProps extends FormControlProps {
  label?: ReactNode;
  errorContent?: ReactNode;
  helperText?: ReactNode;
  inputControlId?: string;
  inputLabelProps?: InputLabelProps;
  formHelperTextProps?: FormHelperTextProps;
}

export interface NxpFormControlPropsForExtends // reusable interface for specific field controls (eg, NxpTextInput) to extend
  extends Pick<
    NxpFormControlProps,
    'label' | 'errorContent' | 'id' | 'required' | 'fullWidth'
  > {
  formControlProps?: Omit<
    NxpFormControlProps,
    | 'children'
    | 'label'
    | 'errorContent'
    | 'inputControlId'
    | 'required'
    | 'fullWidth'
  >;
}

const NxpFormControl = React.forwardRef<HTMLDivElement, NxpFormControlProps>(
  function NxpFormControl(
    {
      label,
      errorContent,
      error,
      helperText,
      inputControlId,
      inputLabelProps,
      formHelperTextProps,
      required,
      children,
      fullWidth = true,
      ...restProps
    },
    ref
  ) {
    return (
      <FormControl
        ref={ref}
        fullWidth={fullWidth}
        error={!!errorContent || error}
        {...restProps}
      >
        {label ? (
          <InputLabel
            required={required}
            shrink
            {...inputLabelProps}
            htmlFor={inputControlId}
          >
            {label}
          </InputLabel>
        ) : null}
        {children}
        {errorContent ? (
          <FormHelperText {...formHelperTextProps}>
            {errorContent}
          </FormHelperText>
        ) : helperText ? (
          <FormHelperText {...formHelperTextProps}>{helperText}</FormHelperText>
        ) : null}
      </FormControl>
    );
  }
);
export default NxpFormControl;

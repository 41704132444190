import React from 'react';

import {
  Checkbox,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
} from '@material-ui/core';

import useAppContext from '../../hooks/useAppContext';
import {AccessRightList as ModuleAccessRightList} from '../../pages/ModuleRoleAccessRightSettings/moduleRoleAccessRightSettingsReducer';
import {AccessRightList as ObjectAccessRightList} from '../../pages/ObjectRoleAccessRightSettings/objectRoleAccessRightSettingsReducer';
import {NxpPanel} from '../NxpPanel';

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: 'center',
    '& .MuiListItemSecondaryAction-root': {
      right: 12,
    },
  },
  panel: {
    '& .NxpPanel-bar': {
      padding: theme.spacing(1, 0, 1, 5),
    },
    '& .collapsed': {
      '& .NxpPanel-bar-title': {
        margin: theme.spacing(0, 1),
        color: '#3E3F42',
        '& .NxpPanel-bar-title-header': {
          fontWeight: theme.typography.fontWeightRegular,
          fontSize: theme.typography.pxToRem(16),
        },
      },
    },
    '& .MuiCollapse-container': {
      '& .NxpPanel-content': {
        padding: theme.spacing(1, 0, 1, 5),
      },
    },
    '& ul.MuiList-root': {
      '& li.MuiListItem-container': {
        borderBottom: '1px solid #EAEDF3',
        '&:last-child': {
          border: 'none',
        },
      },
    },
    '& .NxpPanel-bar-title': {
      margin: theme.spacing(0, 1),
      color: '#005794',
      '& .NxpPanel-bar-title-header': {
        fontWeight: theme.typography.fontWeightBold as number,
        fontSize: theme.typography.pxToRem(16),
      },
    },
  },
}));

interface AppAccessRightGroupPanelProps {
  title: string;
  accessRightList: ModuleAccessRightList | ObjectAccessRightList;
  items: {title: string; value: keyof ModuleAccessRightList}[];
  onItemCheck: (values: string, checked: boolean) => void;
}

const AppAccessRightGroupPanel: React.FC<AppAccessRightGroupPanelProps> = ({
  title,
  accessRightList,
  items,
  onItemCheck,
}: AppAccessRightGroupPanelProps) => {
  const classes = useStyles({root: {}, content: {}});
  const appContext = useAppContext();

  const masterChecked = items.every(
    (item: {title: string; value: keyof ModuleAccessRightList}) =>
      accessRightList[item.value]
  );

  const indeterminateChecked = items.some(
    (item: {title: string; value: keyof ModuleAccessRightList}) =>
      accessRightList[item.value]
  );

  return (
    <NxpPanel
      title={title}
      className={classes.panel}
      titleContent={title}
      defaultCollapsed={true}
      actionContent={
        <Checkbox
          disabled={!appContext.hasUserAccessRight('access-right-assign')}
          checked={masterChecked}
          indeterminate={indeterminateChecked && !masterChecked}
          onClick={(e) => e.stopPropagation()}
          onChange={(e) => {
            for (const item of items) {
              onItemCheck(item.value, e.target.checked ? true : !masterChecked);
            }
            return true;
          }}
          color="primary"
          inputProps={{'aria-labelledby': title + ' Select All'}}
        ></Checkbox>
      }
    >
      <List className={classes.root}>
        {items.map((item) => {
          const {title, value} = item;
          const labelId = `checkbox-list-secondary-label-${value}`;
          return (
            <ListItem
              disableGutters
              key={value}
              onChange={() => {
                onItemCheck(value, !accessRightList[value]);
              }}
              onClick={() => {}}
            >
              <ListItemText id={labelId} primary={title} />
              <ListItemSecondaryAction>
                <Checkbox
                  disabled={
                    !appContext.hasUserAccessRight('access-right-assign')
                  }
                  color="primary"
                  edge="end"
                  onChange={() => {
                    onItemCheck(value, !accessRightList[value]);
                  }}
                  checked={accessRightList[value] || masterChecked}
                  inputProps={{
                    'aria-labelledby':
                      accessRightList[value] || masterChecked
                        ? labelId + ' Selected'
                        : labelId + ' Deselected',
                  }}
                />
              </ListItemSecondaryAction>
            </ListItem>
          );
        })}
      </List>
    </NxpPanel>
  );
};

export default AppAccessRightGroupPanel;

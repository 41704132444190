import React from 'react';

import ControlCardLayout from './ControlCardLayout';

interface ControlCardContainerProps {
  dateRangeStart: null | Date;
  dateRangeEnd: null | Date;
  expanded: boolean;
  countInDateRange: number;
  countsByLocation: {name: string; value: number}[];
  onDateRangeStartChange: (date: null | Date) => void;
  onDateRangeEndChange: (date: null | Date) => void;
  onExpand: () => void;
  onCollapse: () => void;
}

const ControlCardContainer: React.FC<ControlCardContainerProps> = (props) => {
  return (
    <>
      <ControlCardLayout {...props} />
    </>
  );
};

export default ControlCardContainer;

import React from 'react';

import {NxpFormField, NxpFormFieldProps} from '../NxpFormField';
import NxpFormGridBase, {
  GridColumnCount,
  NxpFormGridBaseProps,
} from './NxpFormGridBase';
import NxpFormGridItem, {NxpFormGridItemProps} from './NxpFormGridItem';

export type NxpFormGridField<T> = NxpFormFieldProps &
  Omit<NxpFormGridItemProps, 'gridColumnCount'> & {
    editing?: boolean;
    firstItemInRow?: boolean;
    fieldName: keyof T;
  };

export interface NxpFormGridProps<T> extends NxpFormGridBaseProps {
  gridColumnCount: GridColumnCount;
  fields: (NxpFormGridField<T> | null)[];
  editing?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formState: T;
  onFormStateChange: (fieldName: keyof T, value: unknown) => void;
}

function NxpFormGrid<T>(props: NxpFormGridProps<T>): JSX.Element {
  const {
    gridColumnCount,
    fields,
    editing,
    formState,
    onFormStateChange,
    ...restProps
  } = props;

  let columnSpanTotal = 0;

  return (
    <NxpFormGridBase {...restProps}>
      {fields.map((field) => {
        if (!field) return null;

        if (field.value === undefined) {
          field.value = formState[field.fieldName];
        }

        const {className, ...fieldPropsWithoutClassName} = field;

        // render item to as the first item in a row
        if (field.firstItemInRow) {
          const colSpanToFillRow =
            gridColumnCount - (columnSpanTotal % gridColumnCount);
          columnSpanTotal += field.itemColumnSpan + colSpanToFillRow;
          if (colSpanToFillRow > 0) {
            return (
              <React.Fragment key={field.fieldName}>
                <NxpFormGridItem
                  gridColumnCount={gridColumnCount}
                  itemColumnSpan={colSpanToFillRow}
                />
                <NxpFormGridItem
                  gridColumnCount={gridColumnCount}
                  itemColumnSpan={field.itemColumnSpan}
                  className={className}
                >
                  <NxpFormField
                    editing={editing}
                    onFormStateChange={
                      onFormStateChange as (
                        fieldName: string,
                        value: unknown
                      ) => void
                    }
                    {...fieldPropsWithoutClassName}
                  />
                </NxpFormGridItem>
              </React.Fragment>
            );
          }
        }

        columnSpanTotal += field.itemColumnSpan;
        return (
          <NxpFormGridItem
            key={field.fieldName}
            gridColumnCount={gridColumnCount}
            itemColumnSpan={field.itemColumnSpan}
            className={className}
          >
            <NxpFormField
              editing={editing}
              onFormStateChange={
                onFormStateChange as (fieldName: string, value: unknown) => void
              }
              {...fieldPropsWithoutClassName}
            />
          </NxpFormGridItem>
        );
      })}
    </NxpFormGridBase>
  );
}

export default NxpFormGrid;

import React, {ReactNode} from 'react';
import clsx from 'clsx';

import {NxpDatePicker} from '../NxpDatePicker';
import {NxpFormControl} from '../NxpFormControl';
import {NxpSelect} from '../NxpSelect';
import {NxpNumericInput, NxpTextInput} from '../NxpTextInput';
import formatDate from '../../utils/date/formatDate';
import {NxpFormFieldProps} from './NxpFormField';
import {NxpCheckbox} from '../NxpCheckbox';
import {NxpTreeSelect} from '../NxpTreeSelect';
import {NxpMultiChip} from '../NxpMultiChip';

export const getFormFieldContent = (
  editing: boolean,
  props: NxpFormFieldProps,
  classNameForEditContent?: string
): ReactNode => {
  // remove props incompatible with mui input controls
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const propsForChild: any = {...props};
  delete propsForChild.onFormStateChange;
  delete propsForChild.fieldName;
  delete propsForChild.fieldType;
  delete propsForChild.editing;

  switch (props.fieldType) {
    case 'text':
      return editing ? (
        <NxpTextInput
          className={classNameForEditContent}
          id={`textInput-${props.fieldName}`}
          {...{
            ...propsForChild,
            onChange:
              props.onChange ||
              ((e) =>
                props.onFormStateChange
                  ? props.onFormStateChange(props.fieldName, e?.target.value)
                  : undefined),
          }}
        />
      ) : (
        <>{props.value}</>
      );
    case 'checkbox':
      return editing ? (
        <NxpCheckbox
          className={classNameForEditContent}
          id={`checkboxInput-${props.fieldName}`}
          {...{
            ...propsForChild,
            checked: props.value,
            onChange:
              props.onChange ||
              ((e) =>
                props.onFormStateChange
                  ? props.onFormStateChange(props.fieldName, e?.target.checked)
                  : undefined),
          }}
        />
      ) : (
        <>{props.checked ? 'yes' : 'No'}</>
      );
    case 'numeric':
      return editing ? (
        <NxpNumericInput
          className={classNameForEditContent}
          id={`textInput-${props.fieldName}`}
          {...{
            ...propsForChild,
            onChange:
              props.onChange ||
              ((_, value) =>
                props.onFormStateChange
                  ? props.onFormStateChange(props.fieldName, value)
                  : undefined),
          }}
        />
      ) : (
        <>{`${props.prefix || ''}${
          Number.isFinite(props.value) && props.fixDecimalScale
            ? props.value?.toFixed(props.decimalScale || 2)
            : props.value
        }${props.suffix || ''}`}</>
      );
    case 'date':
      return editing ? (
        <NxpDatePicker
          className={classNameForEditContent}
          id={`datepicker-${props.fieldName}`}
          {...{
            ...propsForChild,
            value: props.value,
            onChange:
              props.onChange ||
              ((date) =>
                props.onFormStateChange
                  ? props.onFormStateChange(props.fieldName, date)
                  : undefined),
          }}
        />
      ) : (
        <>
          {props.onFormatReadModeDate
            ? props.onFormatReadModeDate(props.value as Date)
            : formatDate(props.value as Date, props.readModeDatePattern)}
        </>
      );
    case 'select':
      return editing ? (
        <NxpSelect
          className={classNameForEditContent}
          id={`select-${props.fieldName}`}
          {...{
            ...propsForChild,
            onChange:
              props.onChange ||
              ((e) => {
                if (props.onFormStateChange) {
                  props.onFormStateChange(props.fieldName, e?.target.value);
                }
              }),
          }}
        />
      ) : props.multiline ? (
        props.selectItems
          ?.filter((item) =>
            (props.value as (string | number)[]).includes(
              item.value as string | number
            )
          )
          .join(', ')
      ) : (
        props.selectItems?.find((item) => item.value === props.value)?.name
      );
    case 'multi-chip':
      return editing ? (
        <NxpMultiChip
          className={classNameForEditContent}
          id={`select-${props.fieldName}`}
          {...{
            ...propsForChild,
            onChange:
              props.onChange ||
              ((e) => {
                if (props.onFormStateChange) {
                  props.onFormStateChange(props.fieldName, e?.target.value);
                }
              }),
            onValueChange: (value) => {
              if (props.onFormStateChange) {
                props.onFormStateChange(props.fieldName, value);
              }
            },
          }}
        />
      ) : props.multiline ? (
        props.selectItems
          ?.filter((item) =>
            (props.value as (string | number)[]).includes(
              item.value as string | number
            )
          )
          .join(', ')
      ) : (
        props.selectItems?.find((item) => item.value === props.value)?.name
      );
    case 'tree-select':
      return editing ? (
        <NxpTreeSelect
          className={classNameForEditContent}
          id={`select-${props.fieldName}`}
          onValueChange={(value: string) => {
            if (props.onFormStateChange) {
              props.onFormStateChange(props.fieldName, value);
            }
          }}
          {...propsForChild}
        />
      ) : props.multiline ? (
        props.treeSelectItems
          ?.filter((item) =>
            (props.value as (string | number)[]).includes(
              item.value as string | number
            )
          )
          .join(', ')
      ) : (
        props.treeSelectItems?.find((item) => item.value === props.value)?.name
      );
    case 'custom':
      return editing ? (
        <NxpFormControl
          className={classNameForEditContent}
          {...props.formControlProps}
        >
          {props.children || null}
        </NxpFormControl>
      ) : (
        props.children
      );
    case 'read-only':
      return editing ? (
        <NxpFormControl
          className={clsx(classNameForEditContent, 'read-only')}
          {...props.formControlProps}
        >
          <div className="NxpFormField-content">
            {props.children || props.readModeContent}
          </div>
        </NxpFormControl>
      ) : (
        props.readModeContent || props.children
      );
  }
};

import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useHistory} from 'react-router';

import useAppContext from '../../hooks/useAppContext';
import {useAsync} from '../../hooks/useAsync';
import {Album, deleteAlbum, getAlbumList} from '../../services/album';
import {getLightboxList} from '../../services/lightbox';
import CreateAlbumModal from './AlbumFormModal';
import AlbumsLayout from './AlbumsLayout';
import {lowerCase} from 'lodash';

interface AlbumsContainerProps {}

const AlbumsContainer: React.FC<AlbumsContainerProps> = () => {
  const appContext = useAppContext();
  const {t: tran} = useTranslation();
  const [createModalOpen, setCreateModalOpen] = useState(false);

  const history = useHistory();

  const handleBackClick = () => {
    appContext.onAppContextCacheItemUpdate('activeAlbum', undefined);
    appContext.onAppContextCacheItemUpdate('activeAlbumLightboxes', undefined);
  };

  const handleCreateClick = () => {
    setCreateModalOpen(true);
  };

  const handleCreateModalClose = () => setCreateModalOpen(false);

  const [albumsAsyncResult, , setAlbumAsyncResult] = useAsync(() =>
    getAlbumList(appContext.serviceConfig)
  );

  const handleAlbumCreated = (album: Album) => {
    setAlbumAsyncResult({
      ...albumsAsyncResult,
      data: [album, ...(albumsAsyncResult.data || [])],
    });
  };

  const handleAlbumUpdated = (album: Album) => {
    setAlbumAsyncResult((prevState) => ({
      ...prevState,
      data: (prevState.data || []).map((item) =>
        item.id === album.id ? album : item
      ),
    }));
  };

  const handleSelectClick = (album: Album) => {
    appContext.onAppContextCacheItemUpdate('activeAlbum', album);
    getLightboxList(album.id, appContext.serviceConfig).then((data) => {
      appContext.onAppContextCacheItemUpdate('activeAlbumLightboxes', data);
    });
    history.push(`./albums/${album.id}/explorer`);
  };

  const handleDeleteConfirm = async (albumId: number) => {
    try {
      await deleteAlbum(albumId, appContext.serviceConfig);
      appContext.enqueueSnackbar(
        `${lowerCase(tran('common.albums'))} ${lowerCase(
          tran('common.delete')
        )} ${tran('common.saved')} ${tran('common.success')}.`,
        {
          variant: 'success',
        }
      );
      setAlbumAsyncResult((prevState) => ({
        ...prevState,
        data: (prevState.data || []).filter((item) => item.id !== albumId),
      }));
    } catch (ex) {
      appContext.errorHandler(ex as any, 'album deletion', albumId);
    }
  };
  console.log('albumsAsyncResult', albumsAsyncResult);
  return (
    <>
      <CreateAlbumModal
        modalOpen={createModalOpen}
        onModalClose={handleCreateModalClose}
        onAlbumSaved={handleAlbumCreated}
      />
      <AlbumsLayout
        albumsAsyncResult={albumsAsyncResult}
        onBackClick={appContext.activeAlbum?.id ? handleBackClick : undefined}
        onCreateClick={handleCreateClick}
        onSelectClick={handleSelectClick}
        activeAlbumId={appContext.activeAlbum?.id}
        onAlbumUpdated={handleAlbumUpdated}
        onDeleteConfirm={handleDeleteConfirm}
      />
    </>
  );
};

export default AlbumsContainer;

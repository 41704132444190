import React from 'react';
import {useDropzone} from 'react-dropzone';
import {useTranslation} from 'react-i18next';

import {
  Box,
  Fade,
  FormControlLabel,
  FormHelperText,
  Grid,
  LinearProgress,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
  Paper,
  Theme,
  Typography,
} from '@material-ui/core';
import {green, grey} from '@material-ui/core/colors';
import AddIcon from '@material-ui/icons/Add';
import CheckCircleSharpIcon from '@material-ui/icons/CheckCircleSharp';
import DescriptionIcon from '@material-ui/icons/Description';
import _ from 'lodash';

import {ValidationResult} from '../../hooks/useValidate';
import {UploadPhoto, UploadPhotoFile} from '../../services/photo';
import AppCreateIcon from '../AppCreateIcon';
import {NxpButton} from '../NxpButton';

type Props = {
  error?: boolean;
};
const useStyles = makeStyles<Theme, Props>((theme: Theme) => ({
  container: {
    transform: 'translateZ(0px)',
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    '& > .MuiFormHelperText-root': {
      color: '#f44336',
    },
    '& > .dropzone': {
      backgroundColor: '#FBFBFD',
      border: ({error}) => (error ? '1px solid #f44336' : '1px solid #EAEDF3'),
      padding: theme.spacing(2),
      height: 230,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      '& .divider': {
        fontSize: theme.typography.pxToRem(10),
        color: 'rgba(138,153,153,1)',
        marginTop: 10,
        marginBottom: 10,
      },
      '& .label': {
        fontWeight: theme.typography.fontWeightBold as number,
      },
      '& .MuiButton-root': {
        width: 120,
        fontSize: theme.typography.pxToRem(12),
        fontWeight: theme.typography.fontWeightBold as number,
        margin: '0 auto',
      },
    },
    margin: `${theme.spacing(2)}px auto`,
    '& .MuiButtonBase-root': {
      marginTop: 16,
    },
  },
  root: {
    width: '100%',
    position: 'relative',
    overflow: 'auto',
    backgroundColor: theme.palette.background.paper,
    '& .header-list': {
      border: '1px solid #EAEDF3',
      backgroundColor: '#FBFBFD',
      borderRadius: 5,
      color: theme.palette.primary.main,
      fontWeight: theme.typography.fontWeightBold as number,
      padding: theme.spacing(2),
      '& .MuiPaper-outlined': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#EAEDF3',
        border: 'none',
        '& .MuiSvgIcon-root': {
          fontSize: theme.typography.pxToRem(40),
        },
        marginRight: theme.spacing(1),
      },
      '& .MuiFormControlLabel-root': {
        margin: 0,
        textAlign: 'center',
        '& .MuiTypography-root': {
          fontSize: theme.typography.pxToRem(14),
          textTransform: 'uppercase',
          fontWeight: theme.typography.fontWeightBold as number,
        },
      },
    },
    '& .MuiListItem-root': {
      marginTop: 10,
      marginBottom: 10,
      border: '1px solid #EAEDF3',
      borderRadius: 5,
      padding: 20,
      '& .MuiLinearProgress-root': {
        width: 200,
      },
      '& .MuiSvgIcon-root': {
        color: grey[600],
        fontSize: theme.typography.pxToRem(33),
        marginRight: theme.spacing(1),
      },
      '& .status': {
        color: green[500],
        '& .MuiSvgIcon-root': {
          color: green[500],
        },
        '& .MuiTypography-root': {
          color: green[500],
          fontWeight: 'bold',
          fontSize: theme.typography.pxToRem(12),
          padding: '0 4px',
        },
      },
    },
    '& .file-name': {
      width: '100%',
      margin: 0,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    '& .MuiListItemSecondaryAction-root .MuiButton-root .MuiButton-label': {
      color: theme.palette.warning.main,
      fontWeight: theme.typography.fontWeightBold as number,
      fontSize: theme.typography.pxToRem(14),
    },
  },
}));
type filelist = Partial<UploadPhotoFile>[];
interface AppUploadDropzoneProps {
  fileList: filelist;
  setFileList: (files: filelist) => void;
  handleFormGridStateChange: (fieldName: string, value: unknown) => void;
  heigth: number;
  formValid: boolean;
  validationError: ValidationResult<UploadPhoto>;
  onUploadSuccess: boolean;
}

const AppUploadDropzone: React.FC<AppUploadDropzoneProps> = ({
  fileList,
  setFileList,
  heigth,
  formValid,
  validationError,
  handleFormGridStateChange,
  onUploadSuccess,
}) => {
  const {t: tran} = useTranslation();
  const classes = useStyles({
    error: _.get(validationError, 'files') ? true : false,
  });
  const {getRootProps, getInputProps} = useDropzone({
    accept: 'image/jpeg, image/png, image/tiff',
    multiple: true,
    onDropAccepted: (event: filelist) => {
      setFileList([...event, ...fileList]);
      handleFormGridStateChange('files', [...event, ...fileList]);
    },
  });
  const deleteItem = (index: number) => () => {
    setFileList([...fileList.filter((file, i) => i !== index)]);
    handleFormGridStateChange('files', [
      ...fileList.filter((file, i) => i !== index),
    ]);
  };
  return (
    <Box component="div" m={1} className={classes.container} height={heigth}>
      {fileList.length <= 0 ? (
        <Fade in={fileList.length <= 0}>
          <div
            {...getRootProps({
              className: `dropzone ${
                _.get(validationError, 'files', false) ? 'error' : ''
              }`,
            })}
          >
            <input
              {...getInputProps()}
              className="upload"
              data-testid="upload"
            />
            <Box
              component="div"
              width={50}
              height={50}
              margin="16px auto"
              textAlign="center"
            >
              <AppCreateIcon />
            </Box>
            <Typography
              component="div"
              align="center"
              color="primary"
              className="label"
            >
              {tran('dragToUpload')}
            </Typography>
            <Typography component="div" align="center" className="divider">
              {tran('common.or')}
            </Typography>
            <NxpButton variant="contained" color="primary">
              {tran('browseFile')}
            </NxpButton>
          </div>
        </Fade>
      ) : (
        <Fade in={fileList.length > 0}>
          <List className={classes.root}>
            {!formValid ? (
              <div {...getRootProps({className: 'dropzone'})}>
                <input {...getInputProps()} />
                <ListItem className="header-list" button>
                  <ListItemText
                    primary={
                      <Grid
                        container
                        justify="center"
                        alignContent="center"
                        alignItems="center"
                        component="div"
                      >
                        <Grid item xs={8}>
                          <FormControlLabel
                            control={
                              <Paper variant="outlined">
                                <AddIcon color="primary" />
                              </Paper>
                            }
                            label={`${fileList.length} ${tran(
                              'common.files'
                            )} ${tran('common.added')}. ${tran(
                              'dragToUpload'
                            )}`}
                          />
                        </Grid>
                      </Grid>
                    }
                  />
                </ListItem>
              </div>
            ) : (
              <></>
            )}
            {fileList.map((value, i) => {
              const labelId = `file-list-label-${i}`;
              return (
                <div className="dropzone" key={i}>
                  <ListItem key={i} role={undefined}>
                    <ListItemText
                      id={labelId}
                      primary={
                        <Grid
                          container
                          justify="space-between"
                          alignItems="center"
                          component="div"
                        >
                          <Grid item xs={8}>
                            <FormControlLabel
                              className="file-name"
                              control={<DescriptionIcon />}
                              label={value?.name}
                            />
                          </Grid>
                          {formValid ? (
                            onUploadSuccess ? (
                              <Grid item>
                                <Grid
                                  container
                                  className="status "
                                  alignItems="center"
                                >
                                  <Grid item>
                                    <Typography
                                      variant="subtitle2"
                                      component="span"
                                    >
                                      {`${tran('common.upload')} ${tran(
                                        'common.success'
                                      )}`}
                                    </Typography>
                                  </Grid>
                                  <Grid item>
                                    <CheckCircleSharpIcon color="inherit" />
                                  </Grid>
                                </Grid>
                              </Grid>
                            ) : (
                              <LinearProgress color="primary" />
                            )
                          ) : (
                            <></>
                          )}
                        </Grid>
                      }
                    />
                    {!formValid ? (
                      <ListItemSecondaryAction onClick={deleteItem(i)}>
                        <NxpButton color="primary" variant="text">
                          {tran('common.remove')}
                        </NxpButton>
                      </ListItemSecondaryAction>
                    ) : (
                      <></>
                    )}
                  </ListItem>
                </div>
              );
            })}
          </List>
        </Fade>
      )}
      {_.get(validationError, 'files', false) ? (
        <FormHelperText {...validationError}>
          {_.get(validationError, 'files')}
        </FormHelperText>
      ) : (
        <></>
      )}
    </Box>
  );
};
export default AppUploadDropzone;

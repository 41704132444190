import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  IconButton,
  makeStyles,
  Theme,
} from '@material-ui/core';
import {Close as CloseIcon} from '@material-ui/icons';
import clsx from 'clsx';
import React, {ReactNode} from 'react';
import _ from 'lodash';
export interface NxpDialogProps extends Omit<DialogProps, 'onClose'> {
  titleContent?: ReactNode;
  hideCloseButton?: boolean;
  actionContent?: ReactNode;
  closeByClickOutside?: boolean;
  onClose: () => void;
  bold?: boolean;
}
const useStyles = makeStyles<Theme>((theme) => ({
  root: {},
  titleContent: {
    borderBottom: `1px solid ${theme.palette.border.light}`,
    '& h2': {
      fontSize: theme.typography.h4.fontSize,
      margin: 0,
    },
    '& .container-with-close-button': {
      display: 'flex',
      '& > div:first-child': {
        flexGrow: 1,
        fontWeight: (props) => {
          return _.get(props, 'bold', false)
            ? theme.typography.fontWeightBold
            : theme.typography.fontWeightRegular;
        },
      },
      '& > div:last-child': {
        borderLeft: `1px solid ${theme.palette.border.light}`,

        '& .MuiIconButton-root': {
          margin: theme.spacing(-2),
          marginLeft: theme.spacing(1),

          '& .MuiSvgIcon-root': {
            fontWeight: theme.typography.fontWeightLight,
          },
        },
      },
    },
  },
  dialogContent: {
    paddingBottom: theme.spacing(2),
  },
  actionContent: {
    borderTop: `1px solid ${theme.palette.border.light}`,
    padding: theme.spacing(2),
  },
}));

const NxpDialog: React.FC<NxpDialogProps> = ({
  titleContent,
  hideCloseButton = false,
  actionContent,
  closeByClickOutside = false,
  className,
  children,
  onClose,
  ...restProps
}) => {
  const classes = useStyles(restProps);
  return (
    <Dialog
      fullWidth
      {...restProps}
      className={clsx('NxpDialog', classes.root, className)}
      onClose={closeByClickOutside ? onClose : undefined}
    >
      {titleContent && (
        <DialogTitle className={classes.titleContent}>
          {hideCloseButton ? (
            titleContent
          ) : (
            <div className="container-with-close-button">
              <div>{titleContent}</div>
              <div>
                <IconButton color="primary" onClick={onClose}>
                  <CloseIcon />
                </IconButton>
              </div>
            </div>
          )}
        </DialogTitle>
      )}
      <DialogContent className={classes.dialogContent}>
        {children}
      </DialogContent>
      {actionContent && (
        <DialogActions className={classes.actionContent}>
          {actionContent}
        </DialogActions>
      )}
    </Dialog>
  );
};

export default NxpDialog;

import React, {ReactNode} from 'react';
import {makeStyles} from '@material-ui/core';

import AppCreateIcon from '../AppCreateIcon';

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.primary.main,
    textTransform: 'uppercase',
    border: `1px dashed ${theme.palette.primary.main}`,
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.background.default,
    marginBottom: theme.spacing(3),
    width: 300,
    minHeight: 280,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.background.paper,
    },
  },
}));

interface AppCardListCreateItemProps {
  onCreateClick: () => void;
  createText?: ReactNode;
}

const AppCardListCreateItem: React.FC<AppCardListCreateItemProps> = ({
  onCreateClick,
  createText,
}) => {
  const classes = useStyles();
  return (
    <button className={classes.root} onClick={onCreateClick}>
      <AppCreateIcon />
      <p>{createText || 'Create'}</p>
    </button>
  );
};

export default AppCardListCreateItem;

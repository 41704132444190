import React from 'react';
import {useTranslation} from 'react-i18next';

import {IconButtonProps} from '@material-ui/core';
import {capitalize} from 'lodash';

import useAppContext from '../../../hooks/useAppContext';
import {useAsync} from '../../../hooks/useAsync';
import {Lightbox, toggleLightboxFavorite} from '../../../services/lightbox';
import LightboxFavoriteButtonLayout from './LightboxFavoriteButtonLayout';

interface LightboxFavoriteButtonContainerProps extends IconButtonProps {
  lightbox: Lightbox;
  onLightboxUpdated: (lightbox: Lightbox) => void;
}

const LightboxFavoriteButtonContainer: React.FC<LightboxFavoriteButtonContainerProps> =
  ({lightbox, onLightboxUpdated, ...restProps}) => {
    const appContext = useAppContext();
    const {t: tran} = useTranslation();
    const [, setUpdateLightboxRequest] = useAsync<Lightbox>(undefined, {
      onSuccess: (data) => {
        appContext.enqueueSnackbar(
          `${capitalize(tran('common.lightbox'))} ${capitalize(
            tran('common.update')
          )} ${capitalize(tran('common.success'))}`,
          {
            variant: 'success',
          }
        );
        onLightboxUpdated(data);
      },
      onError: (ex) => {
        appContext.errorHandler(
          ex as Error,
          'updating lightbox favorite',
          lightbox
        );
      },
    });

    const handleFavoriteClick = () => {
      setUpdateLightboxRequest(() =>
        toggleLightboxFavorite(lightbox, appContext.serviceConfig)
      );
    };

    const currentUserId = appContext.userList.find(
      (user) => user.email === appContext.currentUser?.email
    )?.id;

    return (
      <LightboxFavoriteButtonLayout
        lightbox={lightbox}
        onFavoriteClick={handleFavoriteClick}
        disabled={
          !(
            lightbox.owner?.id === currentUserId ||
            lightbox.shares?.find(
              (access) => access.allowEdit && access.userId === currentUserId
            )
          )
        }
        {...restProps}
      />
    );
  };

export default LightboxFavoriteButtonContainer;

import React, {ReactNode, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {NavLink, useLocation} from 'react-router-dom';

import {
  Drawer,
  List,
  ListItem as MuiListItem,
  ListItemText,
  makeStyles,
  withStyles,
} from '@material-ui/core';
import clsx from 'clsx';
import _ from 'lodash';

import useAppContext from '../../hooks/useAppContext';
import AppNavBar from '../AppNavBar';
import AppSidebar from '../AppSidebar';

import styles from './AppLayout.module.scss';

const useStyles = makeStyles((theme) => ({
  drawer: {
    '& .MuiDrawer-paper': {
      minWidth: '275px',
      backgroundColor: 'white',
      minHeight: '100vh',
      position: 'fixed',
      left: 89,
      top: 85,
      zIndex: 100,
      borderRight: '1px solid #eaedf3',
      '& a': {
        textDecoration: 'none',
      },
      '& .MuiListItemText-root': {
        '& .MuiTypography-root': {
          fontSize: theme.typography.pxToRem(14),
          margin: theme.spacing(1),
        },
      },
    },
  },
  list: {
    '& .MuiList-root': {
      paddingTop: 0,
    },
    width: '100%',
  },
  fullList: {
    width: 'auto',
  },
}));
type Anchor = 'top' | 'left' | 'bottom' | 'right';

const AppLayout: React.FC<{children: ReactNode}> = ({children}) => {
  const classes = useStyles();
  const appContext = useAppContext();
  const [manageDrawer, setManageDrawer] = useState<boolean>(false);
  const {t: tran} = useTranslation();
  const location: Partial<Location> = useLocation();
  useEffect(() => {
    setManageDrawer(false);
  }, [location]);
  const ListItem = withStyles((theme) => ({
    root: {
      '& .MuiListItemText-root': {
        color: '#000',
      },
      '&$selected': {
        backgroundColor: 'red',
        color: 'white',
        '& .MuiListItemText-root': {
          color: 'white',
        },
      },
      '&$selected:hover': {
        backgroundColor: theme.palette.primary.main,
        color: 'white',
        '& .MuiListItemText-root': {
          color: 'white',
        },
      },
      '&:hover': {
        backgroundColor: theme.palette.primary.main,
        color: 'white',
        '& .MuiListItemText-root': {
          color: 'white',
        },
      },
    },
    selected: {},
  }))(MuiListItem);

  const list = (anchor: Anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === 'top' || anchor === 'bottom',
      })}
      role="presentation"
      onClick={() => setManageDrawer(false)}
      onKeyDown={() => setManageDrawer(false)}
    >
      <List>
        {[
          {
            label: `${_.capitalize(tran('manage'))} ${_.capitalize(
              tran('roles')
            )} (${_.capitalize(tran('module'))})`,
            link: `/entities/${appContext.serviceConfig.entityId}/module-roles`,
            show: () =>
              !appContext.activeAlbum &&
              appContext.hasUserAccessRight('role-view'),
          },
          {
            label: `${_.capitalize(tran('common.access'))} ${_.capitalize(
              tran('rights')
            )} (${_.capitalize(tran('module'))})`,
            link: `/entities/${appContext.serviceConfig.entityId}/module-role-access-right`,
            show: () =>
              !appContext.activeAlbum &&
              appContext.hasUserAccessRight('access-right-view'),
          },
          {
            label: `${_.capitalize(tran('manage'))} ${_.capitalize(
              tran('roles')
            )} (${_.capitalize(tran('album'))})`,
            link: `/entities/${appContext.serviceConfig.entityId}/albums/${appContext.activeAlbum?.id}/roles`,
            show: () =>
              appContext.activeAlbum &&
              appContext.hasUserAccessRight('role-view', 'album'),
          },
          {
            label: `${_.capitalize(tran('common.access'))} ${_.capitalize(
              tran('rights')
            )} (${_.capitalize(tran('album'))})`,
            link: `/entities/${appContext.serviceConfig.entityId}/albums/${appContext.activeAlbum?.id}/role-access-right`,
            show: () =>
              appContext.activeAlbum &&
              appContext.hasUserAccessRight('access-right-view', 'album'),
          },
        ].map(
          (route, index) =>
            route.show() && (
              <NavLink to={route.link} title={route.label} key={index}>
                <ListItem button>
                  <ListItemText primary={route.label} />
                </ListItem>
              </NavLink>
            )
        )}
      </List>
    </div>
  );

  return (
    <>
      <AppNavBar />
      <div className={styles.appLayout}>
        <AppSidebar toggleDrawer={setManageDrawer} />
        <main>{children}</main>
      </div>
      <div className="leftDrawer">
        <React.Fragment>
          <Drawer
            className={classes.drawer}
            elevation={0}
            style={{zIndex: 500}}
            anchor={'left'}
            open={manageDrawer}
            onClose={() => setManageDrawer(false)}
            BackdropProps={{
              invisible: true,
            }}
          >
            {list('left')}
          </Drawer>
        </React.Fragment>
      </div>
    </>
  );
};

export default AppLayout;

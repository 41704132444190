import React, {HTMLAttributes, ReactNode} from 'react';
import clsx from 'clsx';
import {makeStyles} from '@material-ui/core';

import AppCardListCreateItem from './AppCardListCreateItem';
import AppCardListItem, {AppCardListItemProps} from './AppCardListItem';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    marginTop: theme.spacing(2),
    flexWrap: 'wrap',
  },
}));

interface AppCardListProps extends HTMLAttributes<HTMLDivElement> {
  cards: AppCardListItemProps[];
  createText?: ReactNode;
  onCreateClick?: () => void;
}

const AppCardList = React.forwardRef<HTMLDivElement, AppCardListProps>(
  function ({cards, className, createText, onCreateClick, ...restProps}, ref) {
    const classes = useStyles();
    return (
      <div className={clsx(classes.root, className)} {...restProps} ref={ref}>
        {cards.map((card) => (
          <AppCardListItem {...card} />
        ))}
        {onCreateClick && (
          <AppCardListCreateItem
            onCreateClick={onCreateClick}
            createText={createText}
          />
        )}
      </div>
    );
  }
);

export default AppCardList;

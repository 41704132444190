import React from 'react';
import {
  Card,
  Checkbox,
  Chip,
  ClickAwayListener,
  Collapse,
  Grow,
  IconButton,
  makeStyles,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  TextField,
} from '@material-ui/core';
import {ModuleRole} from '../../../services/moduleRoleAssignment';
import {MoreHoriz} from '@material-ui/icons';
import {Autocomplete} from '@material-ui/lab';
import useAppContext from '../../../hooks/useAppContext';
import {User} from '../../../services/app';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    margin: 6,
  },
  divContent: {width: '100%', marginLeft: 16, marginRight: 16},
  div1: {display: 'inline-block', width: '5%'},
  div2: {display: 'inline-block', width: '20%'},
  div3: {
    display: 'inline-block',
    width: '69%',
    textAlign: 'center',
    marginBottom: 16,
  },
  div4: {display: 'inline-block', width: '6%', textAlign: 'center'},
  tagNew: {backgroundColor: '#cae4b4'},
}));

interface ModuleRoleItemProps {
  moduleRole: ModuleRole;
  selected: boolean;
  modified: boolean;
  deleted: boolean;
  assigneeIds: string[];
  onSelect: (moduleRoleId: number, selected: boolean) => void;
  onAssignmentChange: (moduleRoleId: number, assigneeIds: string[]) => void;
  onRoleEdit: (moduleRoleId: number) => void;
  onRoleDelete: (moduleRoleId: number) => void;
}

const ModuleRoleItem: React.FC<ModuleRoleItemProps> = ({
  moduleRole,
  selected,
  modified,
  deleted,
  assigneeIds,
  onSelect,
  onAssignmentChange,
  onRoleEdit,
  onRoleDelete,
}: ModuleRoleItemProps) => {
  const classes = useStyles();
  const appContext = useAppContext();
  const [open, setOpen] = React.useState(false);

  const anchorRef = React.useRef(null);

  const originalAssignees = moduleRole.users.map((user) => user.id);

  const values = [];
  for (const assigneeId of assigneeIds) {
    const user = appContext.userList.find((user) => user.id === assigneeId);
    if (user) {
      values.push(user);
    } else {
      values.push({
        id: assigneeId,
        name: `New User : ${assigneeId}`,
        createdOn: new Date(),
        updatedOn: new Date(),
      });
    }
  }

  const options = appContext.userList;

  const getOptionDisabled = (option: User) => {
    if (option.name.startsWith('Invite By Email: ')) {
      const email = option.name.replace('Invite By Email: ', '');
      return !/^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/.test(
        email
      );
    }

    return false;
  };

  const handleChipInputChange = (event: any, newValue: any) => {
    const valueIds = [];
    if (Array.isArray(newValue)) {
      for (const value of newValue) {
        valueIds.push(value.id);
      }
    } else {
      valueIds.push(newValue.id);
    }
    onAssignmentChange(moduleRole.id, valueIds);
  };

  return (
    <Collapse in={!deleted}>
      <Card className={classes.root}>
        <div className={classes.divContent}>
          <div className={classes.div1}>
            {appContext.hasUserAccessRight('role-assign') && (
              <Checkbox
                color="primary"
                onChange={(e) => onSelect(moduleRole.id, !selected)}
              ></Checkbox>
            )}
          </div>
          <div className={classes.div2}>{moduleRole.name}</div>
          <div className={classes.div3}>
            <Autocomplete
              disabled={!appContext.hasUserAccessRight('role-assign')}
              multiple
              filterSelectedOptions
              id="tags-outlined"
              options={options}
              getOptionLabel={(option: User) => option.name}
              getOptionDisabled={getOptionDisabled}
              value={values}
              onChange={handleChipInputChange}
              renderTags={(value, getTagProps) =>
                value.map((user, index) => (
                  <Chip
                    style={{marginRight: 6}}
                    variant="default"
                    label={`${user.name}`}
                    {...getTagProps({index})}
                    className={
                      originalAssignees.includes(user.id) ? '' : classes.tagNew
                    }
                  />
                ))
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label=""
                  placeholder={'Please enter'}
                />
              )}
            />
          </div>
          <div className={classes.div4}>
            <IconButton
              disabled={
                !appContext.hasUserAccessRight('role-edit') &&
                !appContext.hasUserAccessRight('role-delete')
              }
              aria-label="more"
              ref={anchorRef}
              aria-controls={open ? 'menu-list-grow' : undefined}
              aria-haspopup="true"
              onClick={() => setOpen(!open)}
            >
              <MoreHoriz />
            </IconButton>
          </div>
        </div>
      </Card>

      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
      >
        {({TransitionProps, placement}) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={() => setOpen(false)}>
                <MenuList
                  autoFocusItem={open}
                  id="menu-list-grow"
                  onKeyDown={() => setOpen(false)}
                >
                  {appContext.hasUserAccessRight('role-edit') && (
                    <MenuItem
                      onClick={() => {
                        setOpen(false);
                        onRoleEdit(moduleRole.id);
                      }}
                    >
                      Edit
                    </MenuItem>
                  )}
                  {appContext.hasUserAccessRight('role-delete') && (
                    <MenuItem
                      onClick={() => {
                        setOpen(false);
                        onRoleDelete(moduleRole.id);
                      }}
                    >
                      Delete
                    </MenuItem>
                  )}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Collapse>
  );
};

export default ModuleRoleItem;

import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';

import {
  Avatar,
  createStyles,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  makeStyles,
  Paper,
  Theme,
  Typography,
} from '@material-ui/core';
import _ from 'lodash';

import {Photo} from '../../../../services/photo';
import formatDate from '../../../../utils/date/formatDate';
import {NxpButton} from '../../../NxpButton';

interface AppPhotoCommentProps {
  comments: Pick<Photo, 'comments'>;
  handlePhotosAddComment: (comment: string) => void;
}
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: 0,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '100%',
      '& input': {
        width: 180,
        height: 32,
        border: '0.75px solid #8A9999',
        borderRadius: 3,
        padding: '0 8px',
      },
      '& .MuiButtonBase-root': {
        minHeight: 32,
        marginLeft: 8,
        '& .MuiButton-label': {
          fontSize: theme.typography.pxToRem(10),
          fontWeight: theme.typography.fontWeightBold as number,
        },
      },
    },
    list: {
      width: '100%',
      maxWidth: '36ch',
      backgroundColor: theme.palette.background.paper,
    },
    commentAvatar: {
      color: theme.palette.background.paper,
      backgroundColor: theme.palette.primary.main,
      fontSize: theme.typography.pxToRem(12),
    },
    inline: {
      display: 'inline',
    },
  })
);

const AppPhotoComment: React.FC<AppPhotoCommentProps> = ({
  comments,
  handlePhotosAddComment,
}) => {
  const {t: tran} = useTranslation();
  const classes = useStyles();
  // const commentInput = useRef<HTMLInputElement>(null);
  const [commentInput, setCommentInput] = useState<string>('');

  const submited = () => {
    handlePhotosAddComment(commentInput);
  };
  return (
    <>
      <Grid container alignItems="center" className="photo-comment">
        <Grid item xs={4}>
          <Typography variant="h1" component="h2">
            {_.capitalize(tran('photo.comment'))}
          </Typography>
        </Grid>
      </Grid>
      <Divider />
      <List className={classes.list}>
        {_.isArray(comments) ? (
          comments.map((comment: any, index: number) => (
            <ListItem alignItems="center" disableGutters key={index}>
              <ListItemAvatar>
                <Avatar
                  alt={_.get(comment, 'user.name', 'Anonymous')}
                  className={classes.commentAvatar}
                >
                  {_.words(_.get(comment, 'user.name', 'Anonymous')).map(
                    (user) => user.charAt(0)
                  )}
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={_.get(comment, 'content')}
                secondary={
                  <Typography
                    component="span"
                    variant="subtitle2"
                    className={classes.inline}
                    color="textSecondary"
                  >
                    {formatDate(_.get(comment, 'createdOn'))}
                  </Typography>
                }
              />
            </ListItem>
          ))
        ) : (
          <></>
        )}
      </List>
      <Paper
        component="form"
        className={classes.root}
        elevation={0}
        onSubmit={(e) => {
          e.preventDefault();
          submited();
        }}
      >
        <input
          type="text"
          placeholder={`${_.capitalize(tran('common.please'))} ${_.capitalize(
            tran('common.enter')
          )}`}
          onChange={(e) => setCommentInput(e.target.value)}
        />
        <NxpButton type="submit" size="small" disabled={commentInput === ''}>
          {tran('common.add')}
        </NxpButton>
      </Paper>
    </>
  );
};
export default AppPhotoComment;

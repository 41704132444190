import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';

import {
  Box,
  createStyles,
  Divider,
  Grid,
  List,
  ListItem,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import _ from 'lodash';

import {Photo} from '../../../../services/photo';
import formatDate from '../../../../utils/date/formatDate';
import {NxpButton} from '../../../NxpButton';

export interface exitList {
  fieldName: string;
  value: any;
}
interface AppPhotoExifProps {
  exif: Pick<Photo, 'exif'>;
}
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      fontSize: theme.typography.pxToRem(12),
      backgroundColor: theme.palette.background.paper,
      paddingTop: 0,
      '& .MuiListItem-root': {
        paddingRight: theme.spacing(1),
        paddingLeft: 0,
        '&.exif-row': {
          color: '#3E3F42',
          '& .label': {
            fontWeight: theme.typography.fontWeightBold as number,
          },
          '& .detail_value': {
            fontWeight: theme.typography.fontWeightRegular,
          },
        },
      },
      '& .MuiFormControl-root': {
        width: '100%',
      },
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular as number,
    },
  })
);
const AppPhotoExif: React.FC<AppPhotoExifProps> = ({exif}) => {
  const classes = useStyles();
  const [expand, setExpand] = useState<boolean>(false);
  const [exifState, setexifState] = useState<exitList[]>([]);
  const {t: tran} = useTranslation();
  useEffect(() => {
    const reFormat: exitList[] =
      exif !== undefined
        ? _.map(exif, (val, key) => ({
            fieldName: key,
            value: _.isDate(val) ? formatDate(val) : val,
          })).filter(
            (exifValue) => !_.isObject(exifValue.value || !_.isArray())
          )
        : [];
    setexifState(reFormat);

    return () => {
      setexifState([]);
    };
  }, [exif]);

  function Text(props: {source: exitList[]; display: number}) {
    return (
      <Box component="div" className="AppPhotoExif">
        <List className={classes.root}>
          {props.source.slice(0, props.display).map((item, i) => {
            return (
              <ListItem
                key={i}
                role={undefined}
                disableGutters
                alignItems="center"
                className="exif-row"
              >
                <Grid
                  container
                  alignItems="center"
                  justify="flex-start"
                  direction="row"
                  spacing={1}
                >
                  <Grid item className="label" xs={8}>
                    {_.capitalize(item.fieldName)}
                  </Grid>
                  <Grid item className="detail_value">
                    {item.value}
                  </Grid>
                </Grid>
              </ListItem>
            );
          })}
        </List>
      </Box>
    );
  }
  return (
    <>
      <Grid
        container
        alignItems="center"
        className="photo-exif"
        justify="space-between"
      >
        <Grid item xs={4}>
          <Typography variant="h1" component="h2">
            {tran('photo.exif')}
          </Typography>
        </Grid>
        {exifState.length > 5 ? (
          <Grid item>
            <Typography component="div">
              <NxpButton
                color="primary"
                onClick={() => setExpand(!expand)}
                variant="text"
              >
                {expand
                  ? tran('photo.exif.minimize')
                  : tran('photo.exif.expand')}
              </NxpButton>
            </Typography>
          </Grid>
        ) : (
          <></>
        )}
      </Grid>
      <Divider />
      <Text source={exifState} display={expand ? exifState.length : 6} />
    </>
  );
};
export default AppPhotoExif;

import {format} from 'date-fns';

export enum DateFnsFormatPattern {
  date = 'y-LL-dd',
  dateMonthYear = 'dd-LL-y',
  dateWithWeekday = 'E y-LL-dd',
  dateWithoutYear = 'dd-LLL',
  monthAndYear = 'LLL y',
  dateWithTime = 'y-LL-dd kk:mm:ss',
  time = 'kk:mm',
  timeToSecond = 'kk:mm:ss',
}

export default function formatDate(
  date: Date | null | undefined,
  pattern: string = DateFnsFormatPattern.date, // set type as string for flexibility
  upperCase = true
): string {
  return date
    ? upperCase
      ? format(date, pattern).toUpperCase()
      : format(date, pattern)
    : '';
}

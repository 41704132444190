import React, {ReactNode} from 'react';
import {MaterialUiPickersDate} from '@material-ui/pickers/typings/date';
import clsx from 'clsx';
import {makeStyles} from '@material-ui/core';

import {NxpDatePickerProps} from '../NxpDatePicker';
import {
  NxpFormControl,
  NxpFormControlProps,
  NxpFormControlPropsForExtends,
} from '../NxpFormControl';
import {NxpSelectProps} from '../NxpSelect';
import {NxpCheckboxProps} from '../NxpCheckbox';
import {NxpNumericInputProps, NxpTextInputProps} from '../NxpTextInput';
import {getFormFieldContent} from './getFieldContent';
import {NxpTreeSelectItem, NxpTreeSelectProps} from '../NxpTreeSelect';

const useStyles = makeStyles({
  root: {
    '&.read-only': {
      margin: 0,
      '& .NxpFormField-content': {
        paddingTop: 3,
      },
    },
  },
});

export interface NxpFormFieldBaseProps extends NxpFormControlPropsForExtends {
  fieldName: string;
  editing?: boolean;
  readModeContent?: ReactNode;
  onFormStateChange?: (fieldName: string, value?: unknown) => void;
}

export type NxpFormFieldType =  // type guards
  | 'text'
  | 'numeric'
  //   | 'money'
  //   | 'percent'
  | 'date'
  // | 'time'
  | 'select'
  | 'tree-select'
  //   | 'auto-complete'
  //   | 'radio-group'
  //   | 'radio-yes-no'
  | 'checkbox'
  //   | 'checkbox-group'
  //   | 'user'
  //   | 'location'
  //   | 'file'
  | 'custom'
  | 'read-only';

interface NxpFieldTextProps extends NxpTextInputProps, NxpFormFieldBaseProps {
  fieldType: 'text';
}

interface NxpFieldNumericProps
  extends Omit<NxpNumericInputProps, 'children'>, // only allow using selectItems to set options
    NxpFormFieldBaseProps {
  fieldType: 'numeric';
}

interface NxpFieldDateProps
  extends Omit<NxpDatePickerProps, 'onChange' | 'value'>,
    NxpFormFieldBaseProps {
  fieldType: 'date';
  value?: MaterialUiPickersDate;
  onChange?: (
    date: MaterialUiPickersDate,
    value?: string | null | undefined
  ) => void;
  readModeDatePattern?: string;
  onFormatReadModeDate?: (date: Date) => void;
}

interface NxpFieldSelectProps
  extends Omit<NxpSelectProps, 'children'>, // only allow using selectItems to set options
    NxpFormFieldBaseProps {
  fieldType: 'select';
}

interface NxpFieldMultiChipProps
  extends Omit<NxpSelectProps, 'children'>, // only allow using selectItems to set options
    NxpFormFieldBaseProps {
  fieldType: 'multi-chip';
}

interface NxpFieldTreeSelectProps
  extends Omit<NxpTreeSelectProps, 'children'>, // only allow using selectItems to set options
    NxpFormFieldBaseProps {
  fieldType: 'tree-select';
  treeSelectItems?: NxpTreeSelectItem[];
  onValueChange?: (value: string) => void;
}

interface NxpFieldCustomProps
  extends NxpFormFieldBaseProps,
    NxpFormControlProps {
  fieldType: 'custom';
  value?: unknown;
}

interface NxpFieldReadOnlyProps
  extends NxpFormFieldBaseProps,
    NxpFormControlProps {
  fieldType: 'read-only';
  value?: unknown;
}
interface NxpFieldCheckboxProps
  extends Omit<NxpCheckboxProps, 'children'>, // only allow using selectItems to set options
    NxpFormFieldBaseProps {
  fieldType: 'checkbox';
}

export type NxpFormFieldProps =
  | NxpFieldTextProps
  | NxpFieldNumericProps
  | NxpFieldDateProps
  | NxpFieldSelectProps
  | NxpFieldMultiChipProps
  | NxpFieldTreeSelectProps
  | NxpFieldCustomProps
  | NxpFieldReadOnlyProps
  | NxpFieldCheckboxProps;

const NxpFormField: React.FC<NxpFormFieldProps> = (props) => {
  const classes = useStyles();
  let defaultClassName = 'NxpFormField';

  if (props.editing) {
    return (
      <>
        {getFormFieldContent(
          true,
          props,
          clsx(defaultClassName, classes.root, props.className)
        )}
      </>
    );
  }

  defaultClassName = 'NxpFormField read-only';

  // if field set readModeContent, render it here when editing is false
  if (!props.editing && props.readModeContent) {
    return (
      <NxpFormControl
        className={clsx(defaultClassName, classes.root, props.className)}
        {...props.formControlProps}
      >
        <div className="NxpFormField-content MuiInput-formControl">
          {props.readModeContent}
        </div>
      </NxpFormControl>
    );
  }

  // default rendering when editing is false and the field specified no readModeContent
  else if (!props.editing && props.readModeContent === undefined) {
    return (
      <NxpFormControl
        className={clsx(defaultClassName, classes.root, props.className)}
        {...props.formControlProps}
        label={props.label}
        errorContent={undefined}
        id={props.id}
        required={false}
        fullWidth={props.fullWidth}
      >
        <div className="NxpFormField-content MuiInput-formControl">
          {getFormFieldContent(false, props)}
        </div>
      </NxpFormControl>
    );
  }

  return null;
};

export default NxpFormField;

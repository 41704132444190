import React from 'react';
import clsx from 'clsx';
import {Grid, GridProps, makeStyles} from '@material-ui/core';

export type GridColumnCount = 1 | 2 | 3 | 4 | 6 | 12;

export interface NxpFormGridBaseProps
  extends Omit<GridProps, 'container' | 'item'> {}

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(2, 1),
    // overflowX: 'hidden', // get around overflow issue resulted from grid negative margin
  },
}));

const NxpFormGridBase = React.forwardRef<HTMLDivElement, NxpFormGridBaseProps>(
  function NxpFormGridBase({className, spacing = 3, ...restProps}, ref) {
    const classes = useStyles();

    return (
      <div ref={ref} className={clsx('NxpFormGrid', className, classes.root)}>
        <Grid container spacing={spacing} {...restProps} />
      </div>
    );
  }
);

export default NxpFormGridBase;

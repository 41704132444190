import React from 'react';
import {Pie} from 'react-chartjs-2';

import {Card, CardContent, makeStyles} from '@material-ui/core';

import ChartTitle from '../ChartTitle';

interface PieChartCardLayoutProps {
  title?: string;
  entries: {name: string; value: number}[];
}

const BACKGROUND_COLOR = [
  '#1F5CC1',
  '#4DC53B',
  '#CE2D5C',
  '#FFC52E',
  '#FF5B20',
  '#2D2D2D',
];

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: 'center',
  },
  pie: {
    width: '40%',
    margin: 24,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  colorBox: {
    height: 10,
    width: 10,
    marginRight: 8,
  },
  legendItem: {
    display: 'inline-block',
    '&::after': {
      marginRight: 16,
      content: '""',
    },
  },
}));

const PieChartCardLayout: React.FC<PieChartCardLayoutProps> = ({
  title,
  entries,
}) => {
  const classes = useStyles();

  let labels: string[] = [];
  let values: number[] = [];

  for (let entry of entries) {
    labels.push(entry.name);
    values.push(entry.value);
  }

  const data = {
    labels: labels,
    datasets: [
      {
        backgroundColor: BACKGROUND_COLOR,
        data: values,
      },
    ],
  };

  const options = {
    plugins: {
      title: {
        display: false,
        text: title,
        font: {weight: '700', size: 20},
        color: '#3E3F42',
      },
      legend: {display: false, position: 'bottom' as const},
    },
    elements: {
      arc: {
        borderWidth: 0,
      },
    },
  };

  return (
    <Card>
      <CardContent>
        <div className={classes.root}>
          <ChartTitle>{title}</ChartTitle>
          <div className={classes.pie}>
            <Pie data={data} options={options} />
          </div>

          <div>
            {labels.map((label, index) => (
              <span className={classes.legendItem} key={index}>
                <img
                  alt="background color"
                  className={classes.colorBox}
                  style={{backgroundColor: BACKGROUND_COLOR[index]}}
                ></img>
                {label}
              </span>
            ))}
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

export default PieChartCardLayout;

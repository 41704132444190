import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';

import {capitalize} from '@material-ui/core';
import {useSnackbar} from 'notistack';
import * as yup from 'yup';

import useAppContext from '../../../hooks/useAppContext';
import {useAsync} from '../../../hooks/useAsync';
import useServiceConfig from '../../../hooks/useServiceConfig';
import {useValidate} from '../../../hooks/useValidate';
import {addToLightbox, AddToLightboxForm} from '../../../services/lightbox';
import AddToLightboxModal from './AddToLightboxModal';

interface AddToLightboxContainerProps {
  modalOpen: boolean;
  selectedPhotos: number[];
  onModalClose: () => void;
  onAddToLightboxCompleted?: () => void;
}

const AddToLightboxContainer: React.FC<AddToLightboxContainerProps> = ({
  modalOpen,
  selectedPhotos,
  onModalClose,
  onAddToLightboxCompleted,
}) => {
  const {enqueueSnackbar} = useSnackbar();
  const appContext = useAppContext();
  const serviceConfig = useServiceConfig();
  const {t: tran} = useTranslation();
  const getFormSchema = (addToExisting: boolean) => {
    const commonSchema = {
      addToExisting: yup.boolean().nullable().required(),
      album: yup.object().shape({id: yup.number().nullable().required()}),
      photos: yup
        .array()
        .of(yup.object().shape({id: yup.number().nullable().required()}))
        .required(),
    };

    if (addToExisting) {
      return yup.object().shape({
        ...commonSchema,
        lightboxId: yup.number().nullable().required('Lightbox required.'),
        name: yup.string(),
      });
    }

    return yup.object().shape({
      ...commonSchema,
      lightboxId: yup.number().nullable(),
      name: yup.string().required(tran('app.common.validation.name.required')),
    });
  };

  const [form, setForm] = useState<AddToLightboxForm>({
    lightboxId: null,
    addToExisting: true,
    name: '',
    album: {id: appContext.activeAlbum!.id},
    photos: selectedPhotos.map((id) => ({
      id,
    })),
  });

  useEffect(() => {
    setForm((prevForm) => ({
      ...prevForm,
      photos: selectedPhotos.map((id) => ({id})),
    }));
  }, [selectedPhotos]);

  const [addToLightboxAsyncResult, setAddToLightboxRequest] = useAsync(
    undefined,
    {
      onSuccess: (data) => {
        enqueueSnackbar(
          `${capitalize(tran('common.photo'))} ${capitalize(
            tran('common.add')
          )} ${capitalize(tran('common.success'))}.`,
          {
            variant: 'success',
          }
        );
        if (!form.lightboxId) {
          appContext.onAppContextCacheItemUpdate('activeAlbumLightboxes', [
            data,
            ...(appContext.activeAlbumLightboxes || []),
          ]);
        }
        if (onAddToLightboxCompleted) {
          onAddToLightboxCompleted();
        }
        onModalClose();
      },
      onError: (ex) =>
        appContext.errorHandler(ex as Error, 'adding photo to lightbox', form),
    }
  );

  const handleFormStateChange = (
    fieldName: keyof AddToLightboxForm,
    value: unknown
  ) => {
    setForm((prevState) => {
      const changeForm = {...prevState, [fieldName]: value};

      if (fieldName === 'addToExisting') {
        clearError();
      }

      return changeForm;
    });
  };

  const handleSaveValidated = () => {
    setAddToLightboxRequest(() => addToLightbox(form, serviceConfig));
  };

  const [validationError, , clearError, saveWithValidate] =
    useValidate<AddToLightboxForm>(
      form,
      getFormSchema(form.addToExisting),
      handleSaveValidated
    );

  const handleSaveClick = () => {
    saveWithValidate(undefined);
  };

  let lightboxOptions = appContext.activeAlbumLightboxes || [];
  if (!appContext.hasUserAccessRight('lightbox-edit', 'album')) {
    // cannot edit all lightboxes, need to filter out some
    lightboxOptions = lightboxOptions.filter((lightbox) =>
      appContext.hasUserAccessRight('lightbox-edit', 'lightbox', lightbox.id)
    );
  }

  return (
    <AddToLightboxModal
      form={form}
      lightboxOptions={lightboxOptions}
      canCreateLightbox={appContext.hasUserAccessRight(
        'lightbox-create',
        'album'
      )}
      formError={validationError}
      onFormStateChange={handleFormStateChange}
      submitLoading={addToLightboxAsyncResult.loading}
      modalOpen={modalOpen}
      onSaveClick={handleSaveClick}
      onModalClose={onModalClose}
    />
  );
};

export default AddToLightboxContainer;

import React, {useState} from 'react';
import * as yup from 'yup';
import useAppContext from '../../../hooks/useAppContext';

import {useAsync} from '../../../hooks/useAsync';
import {useValidate} from '../../../hooks/useValidate';
import {
  Album,
  AlbumForm,
  createAlbum,
  updateAlbum,
} from '../../../services/album';
import AlbumFormModal from './AlbumFormModal';
import {useTranslation} from 'react-i18next';
import {lowerCase} from 'lodash';
interface AlbumFormContainerProps {
  album?: Album;
  modalOpen: boolean;
  onAlbumSaved: (album: Album) => void;
  onModalClose: () => void;
}

const AlbumFormContainer: React.FC<AlbumFormContainerProps> = ({
  album,
  modalOpen,
  onModalClose,
  onAlbumSaved,
}) => {
  const appContext = useAppContext();
  const {t: tran} = useTranslation();
  const getFormSchema = (isCreate: boolean) =>
    isCreate
      ? yup.object().shape({
          id: yup.number(),
          name: yup
            .string()
            .nullable()
            .required(tran('app.common.validation.name.required')),
        })
      : yup.object().shape({
          id: yup.number().nullable().required('Album id required.'),
          name: yup
            .string()
            .nullable()
            .required(tran('app.common.validation.name.required')),
        });
  const [form, setForm] = useState<AlbumForm>({
    id: album?.id, // undefined for album creation
    name: album?.name || '',
  });

  const [albumFormAsynResult, setAlbumFormRequest] = useAsync(undefined, {
    onSuccess: (data) => {
      if (data.id) {
        onAlbumSaved(data);
      } else if (album) {
        // response data cannot be used as update api is not returning the album object
        onAlbumSaved({...album, name: form.name});
      }

      appContext.enqueueSnackbar(
        `${lowerCase(tran('common.albums'))} ${lowerCase(
          tran('common.add')
        )} ${tran('common.saved')} ${tran('common.success')}.`,
        {
          variant: 'success',
        }
      );
      onModalClose();
    },
    onError: (ex) =>
      appContext.errorHandler(
        ex.message.endsWith('already exist')
          ? {...ex, message: tran('album.warning.existed')}
          : ex,
        tran('album.saving'),
        form
      ),
  });

  const handleFormStateChange = (fieldName: string, value: unknown) => {
    setForm((prevState) => ({
      ...prevState,
      [fieldName]: value,
    }));
  };

  const handleSaveValidated = async () => {
    if (!album) {
      setAlbumFormRequest(() => createAlbum(form, appContext.serviceConfig));
    } else {
      setAlbumFormRequest(() => updateAlbum(form, appContext.serviceConfig));
    }
  };

  const [validationError, , , saveWithValidate] = useValidate<AlbumForm>(
    form,
    getFormSchema(!album),
    handleSaveValidated
  );

  const handleSave = () => {
    saveWithValidate(undefined);
  };

  return (
    <AlbumFormModal
      modalOpen={modalOpen}
      form={form}
      formError={validationError}
      submitLoading={albumFormAsynResult.loading}
      onFormStateChange={handleFormStateChange}
      onSave={handleSave}
      onModalClose={onModalClose}
    />
  );
};

export default AlbumFormContainer;

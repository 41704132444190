import {Create as EditIcon} from '@material-ui/icons';
import React, {useState} from 'react';
import {NxpButton} from '../../components/NxpButton';

import {Lightbox} from '../../services/lightbox';
import LightboxForm from './LightboxForm';
import {useTranslation} from 'react-i18next';
import {capitalize} from 'lodash';
interface LightboxEditButtonProps {
  lightbox: Lightbox;
  onLightboxUpdated: (lightbox: Lightbox) => void;
}

const LightboxEditButton: React.FC<LightboxEditButtonProps> = ({
  lightbox,
  onLightboxUpdated,
}) => {
  const {t: tran} = useTranslation();
  const [modalOpen, setModalOpen] = useState(false);

  const handleEditClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  return (
    <>
      <NxpButton
        title={`${tran('common.edit')} ${capitalize(tran('common.lightbox'))}`}
        onClick={handleEditClick}
        variant="text"
        size="small"
      >
        <EditIcon color="primary" />
      </NxpButton>
      <LightboxForm
        modalOpen={modalOpen}
        lightbox={lightbox}
        onModalClose={handleModalClose}
        onLightboxSaved={onLightboxUpdated}
      />
    </>
  );
};

export default LightboxEditButton;

import {useKeycloak} from '@react-keycloak/web';
import {useMemo} from 'react';

const useCurrentUser = () => {
  const {keycloak} = useKeycloak();
  return useMemo(() => {
    if (keycloak) {
      return {
        id: keycloak.tokenParsed?.sub,
        // below properties down exist in keycloak type at the moment
        email: (keycloak.tokenParsed as any)?.email,
        username: (keycloak.tokenParsed as any)?.name,
      };
    }
  }, [keycloak]);
};

export default useCurrentUser;

import {ModuleRole} from '../../services/moduleRoleAccessRight';

export interface AccessRightList {
  'photo-view': boolean;
  'photo-upload': boolean;
  'photo-edit': boolean;
  'photo-delete': boolean;
  'photo-download': boolean;
  'lightbox-create': boolean;
  'lightbox-view': boolean;
  'lightbox-edit': boolean;
  'album-create': boolean;
  'album-view': boolean;
  'album-edit': boolean;
  'album-delete': boolean;
  'dashboard-view': boolean;
  'role-view': boolean;
  'role-create': boolean;
  'role-edit': boolean;
  'role-assign': boolean;
  'role-delete': boolean;
  'access-right-view': boolean;
  'access-right-assign': boolean;
  'tag-add': boolean;
  'tag-edit': boolean;
  'tag-delete': boolean;
}

interface ModuleRoleAccessRightSettingsState {
  moduleRoles: ModuleRole[];
  selectedModuleRole: null | ModuleRole;
  accessRightList: AccessRightList;
  accessRightListTouched: boolean;
}

type ModuleRoleAccessRightSettingsAction =
  | {
      type: 'moduleRolesLoading';
    }
  | {
      type: 'moduleRoleResultError';
      error: Error;
    }
  | {
      type: 'moduleRolesLoaded';
      moduleRoles: ModuleRole[];
    }
  | {
      type: 'accessRightChecked';
      value: string;
      checked: boolean;
    }
  | {
      type: 'moduleRoleSelected';
      moduleRoleId: number;
    }
  | {
      type: 'moduleRoleSaved';
      moduleRole: ModuleRole;
    };

export const moduleRoleAccessRightSettingsReducer = (
  state: ModuleRoleAccessRightSettingsState,
  action: ModuleRoleAccessRightSettingsAction
): ModuleRoleAccessRightSettingsState => {
  switch (action.type) {
    case 'moduleRolesLoaded':
      return {
        ...state,
        moduleRoles: action.moduleRoles,
      };
    case 'accessRightChecked':
      return {
        ...state,
        accessRightList: {
          ...state.accessRightList,
          [action.value]: action.checked,
        },
        accessRightListTouched: true,
      };
    case 'moduleRoleSelected':
      const accessRightList = {} as AccessRightList;
      const selectedModuleRole = state.moduleRoles.find(
        (role) => role.id === action.moduleRoleId
      );
      if (!selectedModuleRole) return state;

      for (const accessRight of selectedModuleRole.accessRights) {
        accessRightList[accessRight.accessRightType as keyof AccessRightList] =
          true;
      }

      return {
        ...state,
        selectedModuleRole,
        accessRightList,
        accessRightListTouched: false,
      };
    case 'moduleRoleSaved':
      const moduleRole = action.moduleRole;

      const moduleRoles = [...state.moduleRoles];
      const index = moduleRoles.findIndex((role) => role.id === moduleRole.id);
      moduleRoles[index] = moduleRole;

      return {
        ...state,
        moduleRoles,
        accessRightListTouched: false,
      };
    default:
      return state;
  }
};

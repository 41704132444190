import React, {useEffect, useState} from 'react';
import Lightbox from 'react-image-lightbox';

import {Photo} from '../../../services/photo';
import PanoramaZoom from './PanoramaZoom';

interface AppPhotoZoomProps {
  photoId?: number;
  photoListData: Photo[];
  onClose: () => void;
}

const AppPhotoZoom: React.FC<AppPhotoZoomProps> = ({
  photoId,
  photoListData,
  onClose,
}) => {
  const [activePhotoId, setActivePhotoId] = useState(photoId);

  useEffect(() => {
    setActivePhotoId(photoId);
  }, [photoId]);

  const idx = photoListData.findIndex((photo) => photo.id === activePhotoId);

  const handleMovePrev = (idx: number) => {
    if (idx > 0) {
      setActivePhotoId(photoListData[idx - 1].id);
    }
  };

  const handleMoveNext = (idx: number) => {
    if (idx < photoListData.length - 1) {
      setActivePhotoId(photoListData[idx + 1].id);
    }
  };

  return activePhotoId && idx > -1 ? (
    photoListData[idx].tags.find((tag) => tag.name === '360') ? (
      <PanoramaZoom
        imageSrc={photoListData[idx].fileUrl}
        onClose={onClose}
        onMovePrevRequest={() => handleMovePrev(idx)}
        onMoveNextRequest={() => handleMoveNext(idx)}
      />
    ) : (
      <Lightbox
        mainSrc={photoListData[idx].fileUrl}
        nextSrc={
          idx < photoListData.length - 1
            ? photoListData[idx + 1].fileUrl
            : undefined
        }
        prevSrc={idx > 0 ? photoListData[idx - 1].fileUrl : undefined}
        onCloseRequest={onClose}
        onMovePrevRequest={() => handleMovePrev(idx)}
        onMoveNextRequest={() => handleMoveNext(idx)}
      />
    )
  ) : null;
};

export default AppPhotoZoom;

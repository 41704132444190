import React, {HTMLAttributes} from 'react';

import {makeStyles} from '@material-ui/core';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.label,
    fontWeight: theme.typography.fontWeightBold as number,
    fontSize: theme.typography.caption.fontSize,
  },
}));

interface NxpLabelProps extends HTMLAttributes<HTMLLabelElement> {}

const NxpLabel = React.forwardRef<HTMLLabelElement, NxpLabelProps>(
  function NxpLabel({className, ...restProps}, ref) {
    const classes = useStyles();

    return (
      <label
        ref={ref}
        className={clsx('NxpLabel', className, classes.root)}
        {...restProps}
      />
    );
  }
);

export default NxpLabel;

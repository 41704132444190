import React, {HTMLAttributes, ReactNode, useState} from 'react';
import {makeStyles, Collapse} from '@material-ui/core';
import {ArrowDropDown, ArrowDropUp} from '@material-ui/icons';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    border: `1px solid ${theme.palette.border.main}`,
    borderRadius: theme.shape.borderRadius,
    marginBottom: theme.spacing(3),
    '& .NxpPanel-bar': {
      borderTopLeftRadius: theme.shape.borderRadius,
      borderTopRightRadius: theme.shape.borderRadius,
      display: 'flex',
      padding: theme.spacing(1, 2),
      minHeight: 51,
      alignItems: 'center',
      transition: theme.transitions.create(
        ['background-color', 'transform'], // 'background-color' can be set to '' for convenience
        {
          duration: theme.transitions.duration.standard,
        }
      ),
      '&.allow-collapse': {
        cursor: 'pointer',
      },
      '&:not(.collapsed)': {
        backgroundColor: theme.palette.background.dark,
      },

      '& .NxpPanel-bar-arrow': {
        paddingTop: 4,
        marginLeft: theme.spacing(-1),
        color: theme.palette.primary.main,
      },
      '& .NxpPanel-bar-title': {
        flexGrow: 1,
        '& .NxpPanel-bar-title-header': {
          fontSize: theme.typography.h4.fontSize,
          margin: 0,
          fontWeight: 'normal',
        },
      },
      '& .NxpPanel-bar-action': {},
    },
    '& .NxpPanel-content': {
      borderTop: `1px solid ${theme.palette.border.main}`,
      padding: theme.spacing(2),
    },
  },
}));

export interface NxpPanelProps extends HTMLAttributes<HTMLDivElement> {
  titleContent: ReactNode;
  actionContent?: ReactNode;
  disableCollapse?: boolean;
  defaultCollapsed?: boolean;
  collapsed?: boolean; // panel will handle collapsed state if this prop is undefined
  onCollapseClick?: (collapsed: boolean) => void;
}

const NxpPanel = React.forwardRef<
  HTMLDivElement,
  React.PropsWithChildren<NxpPanelProps>
>(function NxpPanel(
  {
    titleContent,
    actionContent,
    children,
    className,
    disableCollapse = false,
    defaultCollapsed = false,
    collapsed: collapsedProp,
    onCollapseClick,
    ...restProps
  },
  ref
) {
  const classes = useStyles();

  const [collapsedState, setCollapsedState] = useState(defaultCollapsed);

  const collapsedComputed =
    collapsedProp === undefined ? collapsedState : collapsedProp;

  const handleCollapseClick = () => {
    if (!disableCollapse) {
      if (onCollapseClick) {
        onCollapseClick(!collapsedComputed);
      }
      if (collapsedProp === undefined) {
        setCollapsedState(!collapsedComputed);
      }
    }
  };

  return (
    <div
      ref={ref}
      className={clsx('NxpPanel', classes.root, className)}
      {...restProps}
    >
      <div
        className={clsx(
          'NxpPanel-bar',
          disableCollapse || 'allow-collapse',
          collapsedComputed && 'collapsed'
        )}
        onClick={handleCollapseClick}
      >
        {!disableCollapse && (
          <div className="NxpPanel-bar-arrow">
            {collapsedComputed ? <ArrowDropDown /> : <ArrowDropUp />}
          </div>
        )}
        <div className="NxpPanel-bar-title">
          {typeof titleContent === 'string' ||
          typeof titleContent === 'number' ? (
            <h3 className="NxpPanel-bar-title-header">{titleContent}</h3>
          ) : (
            titleContent
          )}
        </div>
        {actionContent ? (
          <div className="NxpPanel-bar-action">{actionContent}</div>
        ) : null}
      </div>
      <Collapse in={!collapsedComputed}>
        <div className="NxpPanel-content">{children}</div>
      </Collapse>
    </div>
  );
});

export default NxpPanel;

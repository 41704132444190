import {Grid, GridProps, GridSize} from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';

import {GridColumnCount} from './NxpFormGridBase';

export interface NxpFormGridItemProps extends Omit<GridProps, 'item'> {
  gridColumnCount: GridColumnCount;
  itemColumnSpan: number;
}

const NxpFormGridItem = React.forwardRef<HTMLDivElement, NxpFormGridItemProps>(
  function NxpFormGridItem(
    {gridColumnCount, itemColumnSpan, className, ...restProps},
    ref
  ) {
    const size =
      (12 / gridColumnCount) *
      (itemColumnSpan > gridColumnCount ? gridColumnCount : itemColumnSpan);

    return (
      <Grid
        ref={ref}
        item
        xs={size as GridSize}
        className={clsx('NxpFormGridItem', className)}
        {...restProps}
      />
    );
  }
);

export default NxpFormGridItem;

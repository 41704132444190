import React, {useState} from 'react';

import {NxpButton} from '../../../components/NxpButton';
import {Lightbox} from '../../../services/lightbox';
import ShareLightbox from '.';

interface ShareLightboxButtonProps {
  lightbox: Lightbox;
}

const ShareLightboxButton: React.FC<ShareLightboxButtonProps> = ({
  lightbox,
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const handleModalClose = () => {
    setModalOpen(false);
  };
  const handleClick = () => {
    setModalOpen(true);
  };
  return (
    <>
      <NxpButton onClick={handleClick}>Share</NxpButton>
      {modalOpen && (
        <ShareLightbox
          lightbox={lightbox}
          modalOpen={modalOpen}
          onModalClose={handleModalClose}
        />
      )}
    </>
  );
};

export default ShareLightboxButton;

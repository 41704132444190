import React from 'react';
import {Bar} from 'react-chartjs-2';
import {useTranslation} from 'react-i18next';

import {Card, CardContent, makeStyles} from '@material-ui/core';

import ChartTitle from '../ChartTitle';

const BACKGROUND_COLOR = ['#1F78B4'];

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: 'center',
  },
  chart: {
    padding: 16,
    height: 360,
  },
}));

interface ContributorCardLayoutProps {
  entries?: {name: string; value: number}[];
}

const options = {
  plugins: {
    title: {
      display: false,
    },
    legend: {display: false},
  },
  scales: {
    y: {
      ticks: {
        stepSize: 1,
      },
    },
  },
  maintainAspectRatio: false,
};

const ContributorCardLayout: React.FC<ContributorCardLayoutProps> = ({
  entries = [],
}) => {
  const classes = useStyles();
  const {t: tran} = useTranslation();
  const labels = entries.map((x: {name: string; value: number}) => x.name);
  const data = {
    labels: labels,
    datasets: [
      {
        backgroundColor: BACKGROUND_COLOR,
        data: entries.map((x: {name: string; value: number}) => x.value),
      },
    ],
  };
  return (
    <Card>
      <CardContent>
        <div className={classes.root}>
          <ChartTitle>{tran('album.contributors')}</ChartTitle>
        </div>
        <div className={classes.chart}>
          <Bar data={data} options={options} width={10} height={5} />
        </div>
      </CardContent>
    </Card>
  );
};

export default ContributorCardLayout;

import React, {ReactNode} from 'react';
import {makeStyles} from '@material-ui/core';

import {NxpButtonProps} from './NxpButton';
import NxpButtonWithConfirm from './NxpButtonWithConfirm';
import {useTranslation} from 'react-i18next';
import {DeleteOutlined} from '@material-ui/icons';
const useStyles = makeStyles((theme) => ({
  root: {},
  deleteHighlight: {
    color: theme.palette.error.main,
    textTransform: 'uppercase',
  },
}));

interface NxpButtonDeleteConfirmProps extends NxpButtonProps {
  deleteButtonContent?: ReactNode;
  deleteItemName?: string;
  onConfirm: () => void;
}

const NxpButtonDeleteConfirm: React.FC<NxpButtonDeleteConfirmProps> = ({
  deleteButtonContent,
  deleteItemName,
  onConfirm,
  ...restProps
}) => {
  const classes = useStyles();
  const {t: tran} = useTranslation();
  return (
    <NxpButtonWithConfirm
      color="secondary"
      title={`${tran('common.delete')} ${tran('album')}`}
      dialogContent={
        <p>
          {tran('common.proceed')} {tran('common.to')}{' '}
          <strong className={classes.deleteHighlight}>
            {tran('common.delete')}
          </strong>{' '}
          {tran('common.this')} {deleteItemName || tran('record')}?
        </p>
      }
      onConfirm={onConfirm}
      {...restProps}
    >
      {deleteButtonContent || <DeleteOutlined />}
    </NxpButtonWithConfirm>
  );
};

export default NxpButtonDeleteConfirm;

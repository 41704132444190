import React from 'react';
import {makeStyles, Theme} from '@material-ui/core';

export interface StandaloneNumberProps {
  caption: string;
  value: number;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    textAlign: 'center',
  },
  caption: {
    display: 'block',
    textTransform: 'uppercase',
    fontSize: theme.typography.pxToRem(10),
    lineHeight: '24px',
    fontWeight: 'bold',
    color: '#8A9999',
  },
  value: {
    display: 'block',
    color: theme.palette.primary.main,
    fontSize: theme.typography.pxToRem(28),
    lineHeight: '38px',
    fontWeight: 'bold',
  },
}));

const StandaloneNumber: React.FC<StandaloneNumberProps> = ({
  caption,
  value,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <span className={classes.caption}>{caption}</span>
      <span className={classes.value}>{value.toLocaleString()}</span>
    </div>
  );
};
export default StandaloneNumber;

import React from 'react';
import {makeStyles, SelectProps} from '@material-ui/core';

import DropdownTreeSelect from 'react-dropdown-tree-select';
import 'react-dropdown-tree-select/dist/styles.css';
import _ from 'lodash';

export interface NxpTreeSelectItem {
  value: string;
  name: string;
  children: NxpTreeSelectItem[];
}

export interface NxpTreeSelectBaseProps extends SelectProps {
  treeSelectItems?: NxpTreeSelectItem[];
  onValueChange?: (value: string) => void;
}

interface TreeNode {
  label: string;
  value: string;
}

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: '16px',
    border: `1px solid ${theme.palette.border.main}`,
    borderRadius: theme.shape.borderRadius,
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    '& .dropdown': {
      width: '100%',
      border: 'none',
      lineHeight: 1,
      '& .dropdown-content': {
        position: 'fixed',
        width: (props) => _.get(props, 'width'),
        '& .node.tree': {
          display: 'flex',
          alignItems: 'center',
        },
      },
    },
    '& .dropdown .dropdown-trigger': {
      border: 'none',
      overflow: 'hidden',
      lineHeight: 1,
      padding: 0,
      paddingTop: 4,
    },
    '& a.dropdown-trigger': {
      border: 'none',
      lineHeight: 1,
      width: '100%',
      padding: 0,
    },
    '& .dropdown .dropdown-trigger.arrow.bottom::after': {
      content: `url('data:image/svg+xml;utf8,<?xml version="1.0" encoding="UTF-8"?><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" id="Layer_1" x="0px" y="0px" viewBox="0 0 24 24" xml:space="preserve" height="24px" width="24px"><g><path d="M7 10l5 5 5-5z"></path></g></svg>')`,
      width: 24,
      height: 24,
      margin: 0,
      color: 'rgba(0, 0, 0, 0.54)',
    },
    '& .dropdown .dropdown-trigger.arrow.top::after': {
      content: `url('data:image/svg+xml;utf8,<?xml version="1.0" encoding="UTF-8"?><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" id="Layer_1" x="0px" y="0px" viewBox="0 0 24 24" xml:space="preserve" height="24px" width="24px"><g><path d="M7 14l5-5 5 5z"></path></g></svg>')`,
      width: 24,
      height: 24,
      margin: 0,
      color: 'rgba(0, 0, 0, 0.54)',
    },
    '& .tag-list': {
      display: 'inline-block',
      width: 'calc(100% - 24px)',
      padding: 0,
      border: 'none',
    },
    '& .tag-list .search': {
      borderBottom: 'none',
      background: 'transparent',
      '&::placeholder': {
        color: theme.palette.grey[800],
      },
    },
    '& .radio-item': {
      visibility: 'hidden',
      width: 0,
    },
    '& i.toggle.collapsed::after': {
      content: `url('data:image/svg+xml;utf8,<?xml version="1.0" encoding="UTF-8"?><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" id="Layer_1" x="0px" y="0px" viewBox="0 0 24 24" xml:space="preserve" height="24px" width="24px"><g><path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"></path></g></svg>')`,
      width: 16,
      height: 16,
      margin: 0,
    },
    '& i.toggle.expanded::after': {
      content: `url('data:image/svg+xml;utf8,<?xml version="1.0" encoding="UTF-8"?><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" id="Layer_1" x="0px" y="0px" viewBox="0 0 24 24" xml:space="preserve" height="24px" width="24px"><g><path d="M7.41 7.84L12 12.42l4.59-4.58L18 9.25l-6 6-6-6z"></path></g></svg>')`,
      width: 16,
      height: 16,
      margin: 0,
    },
    '& span.node-label': {
      fontSize: theme.typography.pxToRem(14),
    },
  },
}));

const onAction = (node: any, action: any) => {
  console.log('onAction::', action, node);
};
const onNodeToggle = (currentNode: any) => {
  console.log('onNodeToggle::', currentNode);
};

const convertSelectItemsToData = (
  treeSelectItems: undefined | NxpTreeSelectItem | NxpTreeSelectItem[]
): any => {
  if (!treeSelectItems) {
    return {
      label: 'All',
      value: '',
      children: [],
      expanded: true,
    };
  }
  if (Array.isArray(treeSelectItems)) {
    return {
      label: 'All',
      value: '',
      expanded: true,
      children: treeSelectItems.map((item) => convertSelectItemsToData(item)),
    };
  } else {
    return {
      label: treeSelectItems.name,
      value: treeSelectItems.value,
      children: treeSelectItems.children.map((child) =>
        convertSelectItemsToData(child)
      ),
    };
  }
};

const findName = (
  data: {label: string; value: string; children: any},
  value: string
): null | string => {
  if (data.value === value) {
    return data.label;
  } else if (data.children.length === 0) {
    return null;
  } else {
    for (const child of data.children) {
      const childResult: null | string = findName(child, value);
      if (childResult) return childResult;
    }
    return null;
  }
};

//onFormStateChange: (fieldName: keyof T, value: unknown) => void;
const NxpTreeSelectBase = React.forwardRef<
  HTMLDivElement,
  NxpTreeSelectBaseProps
>(function NxpTreeSelectBase(
  {
    className,
    id,
    treeSelectItems,
    children,
    value,
    onValueChange,
    ...restProps
  },
  ref
) {
  const classes = useStyles({width: _.get(ref, 'current.clientWidth')});
  const data = convertSelectItemsToData(treeSelectItems);

  return (
    <div className={classes.root} ref={ref}>
      <DropdownTreeSelect
        data={data}
        className={value ? 'placeholder' : 'tree-value'}
        texts={{
          placeholder: value
            ? findName(data, '' + value) || ''
            : _.get(restProps, 'placeholder', ''),
        }}
        mode="radioSelect"
        onChange={(currentNode: any, selectedNodes: TreeNode[]) => {
          if (onValueChange)
            onValueChange(selectedNodes.map((node) => node.value).join(','));
        }}
        onAction={onAction}
        onNodeToggle={onNodeToggle}
      />
    </div>
  );
});

export default NxpTreeSelectBase;

import React from 'react';
import {Card, CardContent, makeStyles, Button} from '@material-ui/core';
import {ExpandMore} from '@material-ui/icons';
import {Collapse} from '@material-ui/core';
import {NxpDatePicker} from '../../../components/NxpDatePicker';
import {
  NxpFormGridBase,
  NxpFormGridItem,
} from '../../../components/NxpFormGrid';
import StandaloneNumber from '../StandaloneNumber';
import Table from '../Table';
import {useTranslation} from 'react-i18next';

interface ControlCardLayoutProps {
  dateRangeStart: null | Date;
  dateRangeEnd: null | Date;
  expanded: boolean;
  countInDateRange: number;
  countsByLocation: {name: string; value: number}[];
  onDateRangeStartChange: (date: null | Date) => void;
  onDateRangeEndChange: (date: null | Date) => void;
  onExpand: () => void;
  onCollapse: () => void;
}

const useStyles = makeStyles((theme) => ({
  to: {
    lineHeight: 4.5,
    textAlign: 'center',
  },
  expandButton: {
    fontWeight: 'bold',
  },
  expandIcon: {
    transform: 'rotate(0deg)',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandIconOpen: {
    transform: 'rotate(180deg)',
  },
}));

const DashboardLayout: React.FC<ControlCardLayoutProps> = ({
  dateRangeStart,
  dateRangeEnd,
  expanded,
  countInDateRange,
  countsByLocation,
  onDateRangeStartChange,
  onDateRangeEndChange,
  onExpand,
  onCollapse,
}) => {
  const classes = useStyles();
  const {t: tran} = useTranslation();

  return (
    <Card>
      <CardContent>
        <NxpFormGridBase>
          <NxpFormGridItem gridColumnCount={12} itemColumnSpan={4}>
            <NxpDatePicker
              id=""
              value={dateRangeStart}
              onChange={onDateRangeStartChange}
              label={tran('dashboard.dateRange')}
            />
          </NxpFormGridItem>
          <NxpFormGridItem gridColumnCount={12} itemColumnSpan={1}>
            <div className={classes.to}>To</div>
          </NxpFormGridItem>
          <NxpFormGridItem gridColumnCount={12} itemColumnSpan={4}>
            <NxpDatePicker
              id=""
              value={dateRangeEnd}
              onChange={onDateRangeEndChange}
              label=" "
            />
          </NxpFormGridItem>
          <NxpFormGridItem gridColumnCount={12} itemColumnSpan={3}>
            <StandaloneNumber
              caption={tran('dashboard.totalUploaded')}
              value={countInDateRange}
            />
          </NxpFormGridItem>
        </NxpFormGridBase>
        <hr />
        <Button
          className={classes.expandButton}
          fullWidth
          color="primary"
          onClick={expanded ? onCollapse : onExpand}
        >
          <ExpandMore
            className={
              expanded
                ? [classes.expandIcon, classes.expandIconOpen].join(' ')
                : classes.expandIcon
            }
          />
          {expanded
            ? tran('photo.exif.minimize')
            : tran('dashboard.viewDetailedUpload')}
        </Button>
      </CardContent>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <Table>
            <thead>
              <tr>
                <th>{tran('photo.location')}</th>
                <th>{tran('photo.uploaded')}</th>
              </tr>
            </thead>
            <tbody>
              {countsByLocation.map((x) => (
                <tr>
                  <td>{x.name}</td>
                  <td>{x.value}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </CardContent>
      </Collapse>
    </Card>
  );
};

export default DashboardLayout;

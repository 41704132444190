import {MapSettingsLocation} from '../../../utils/const';

export const getDefaultMapLocation: () => MapSettingsLocation = () => {
  if (window.location.hostname.startsWith('fotolio.weur')) {
    return 'eu';
  } else if (
    window.location.hostname.startsWith('fotolio.qualitycontrol.cpb') // site not yet exist
  ) {
    return 'au';
  } else if (window.location.hostname.startsWith('fotolio.cus')) {
    return 'us';
  } else if (
    window.location.hostname.startsWith('fotolio.qualitycontrol.apac')
  ) {
    return 'apac';
  } else if (window.location.hostname.startsWith('localhost')) {
    return 'hk';
  }
  return 'default';
};

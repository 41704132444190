import React, {ReactNode} from 'react';
import clsx from 'clsx';
import {
  Checkbox,
  CheckboxProps,
  FormControlLabel,
  makeStyles,
} from '@material-ui/core';

export interface NxpCheckdoxBaseProps extends CheckboxProps {
  label?: ReactNode;
}

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 100,
    borderRadius: theme.shape.borderRadius,
    '& .MuiFormControlLabel-label': {
      fontSize: theme.typography.pxToRem(14),
      color: '#3E3F42',
    },
    '& .Mui-checked': {
      '& .MuiSvgIcon-root': {
        color: theme.palette.primary.main,
      },
    },
    '& .MuiSvgIcon-root': {
      color: 'rgba(188, 203, 203)',
    },
    '&.Mui-error': {
      borderColor: theme.palette.error.main,
    },
    '& .MuiSelect-root': {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
  },
}));

const NxpCheckboxBase = React.forwardRef<unknown, NxpCheckdoxBaseProps>(
  function NxpCheckboxBase({className, label, ...restProps}, ref) {
    const classes = useStyles();
    return (
      <FormControlLabel
        ref={ref}
        className={clsx('NxpCheckbox', classes.root, className)}
        control={<Checkbox color="primary" {...restProps} />}
        label={label}
      />
    );
  }
);

export default NxpCheckboxBase;

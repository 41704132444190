import React, {ReactNode, useState} from 'react';
import {NxpDialog} from '../NxpDialog';
import NxpButton, {NxpButtonProps} from './NxpButton';
import {makeStyles} from '@material-ui/core';
import {useTranslation} from 'react-i18next';
const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiDialogContent-root': {
      margin: theme.spacing(5, 3),
      textAlign: 'center',
    },
  },
}));

export interface NxpButtonWithConfirmProps extends NxpButtonProps {
  dialogTitle?: ReactNode;
  dialogContent: ReactNode;
  dialogButtonContent?: ReactNode;
  onConfirm: () => void;
}

const NxpButtonWithConfirm: React.FC<NxpButtonWithConfirmProps> = ({
  dialogTitle,
  dialogContent,
  dialogButtonContent,
  onConfirm,
  onClick,
  ...restProps
}) => {
  const {t: tran} = useTranslation();
  const [dialogOpen, setDialogOpen] = useState(false);

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    setDialogOpen(true);
    if (onClick) {
      onClick(e);
    }
  };

  const handleClose = () => {
    setDialogOpen(false);
  };

  const classes = useStyles();

  return (
    <>
      <NxpButton onClick={handleClick} {...restProps} />
      <NxpDialog
        maxWidth="xs"
        className={classes.root}
        titleContent={dialogTitle || tran('common.confirmation')}
        open={dialogOpen}
        actionContent={
          <NxpButton onClick={onConfirm}>
            {dialogButtonContent || tran('common.ok')}
          </NxpButton>
        }
        onClose={handleClose}
      >
        {typeof dialogContent === 'string' ? (
          <p>{dialogContent}</p>
        ) : (
          dialogContent
        )}
      </NxpDialog>
    </>
  );
};

export default NxpButtonWithConfirm;

import {useTheme} from '@material-ui/core';
import {useSnackbar} from 'notistack';
import {useContext, useMemo} from 'react';
import AppContext from '../components/AppContext';
import useCurrentUser from './useCurrentUser';
import useErrorHandler from './useErrorHandler';
import useServiceConfig from './useServiceConfig';

const useAppContext = () => {
  const appContext = useContext(AppContext);
  const serviceConfig = useServiceConfig();
  const currentUser = useCurrentUser();
  const theme = useTheme();
  const {enqueueSnackbar} = useSnackbar();
  const errorHandler = useErrorHandler();

  return useMemo(
    () => ({
      ...appContext,
      serviceConfig,
      currentUser,
      theme,
      enqueueSnackbar,
      errorHandler,
    }),
    [
      appContext,
      serviceConfig,
      currentUser,
      theme,
      enqueueSnackbar,
      errorHandler,
    ]
  );
};

export default useAppContext;

import {makeStyles} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'inline-block',
    backgroundColor: 'rgba(234, 237, 243, 1)',
    color: theme.palette.primary.main,
    width: 50,
    height: 50,
    borderRadius: theme.shape.borderRadius,
    fontSize: theme.typography.h1.fontSize,
    lineHeight: '50px',
  },
}));

const AppCreateIcon = () => {
  const classes = useStyles();
  return <span className={classes.root}>+</span>;
};

export default AppCreateIcon;

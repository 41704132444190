import React from 'react';

import {Lock as LockIcon} from '@material-ui/icons';

import styles from './AppProtectedRouteLoader.module.scss';

const AppProtectedRouteLoader: React.FC = () => {
  return (
    <div className={styles['route-authenticating']}>
      <LockIcon color="primary" />
      <div className={styles['route-authenticating-spinner']}>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
};

export default AppProtectedRouteLoader;

import {Photo} from '../../services/photo';
import {Search} from '../../services/search';
import {AsyncLazyLoadResult} from '../../utils/typing/AsyncLazyLoadResult';
import {ExplorerFilterViewBy} from '../Explorer/ExplorerFilter/ExplorerFilterContainer';
// import {SearchFilterViewBy} from './SearchFilter/SearchFilterContainer';

interface SearchState {
  photoList: AsyncLazyLoadResult<Photo[]>;
  filter: {};
}

type SearchAction =
  | {
      type: 'photoCreated';
      photos: Photo[];
    }
  | {
      type: 'photoUpdated';
      photo: Photo;
    }
  | {
      type: 'photoDeleted';
      photoId: number;
    }
  | {
      type: 'filterChange';
      filterState: Search;
    }
  | {
      type: 'photoResultLoading';
    }
  | {
      type: 'photoResultError';
      viewBy: ExplorerFilterViewBy;
      filterValue: string;
      error: Error;
    }
  | {
      type: 'photoResultLoaded';
      photoListData: Photo[];
      noMoreItems: boolean;
    }
  | {type: 'refresh'};

export default function searchReducer(
  state: SearchState,
  action: SearchAction
): SearchState {
  switch (action.type) {
    case 'photoCreated': {
      return {
        ...state,
        photoList: {
          ...state.photoList,
          data: [...action.photos, ...(state.photoList.data || [])],
        },
        filter: {
          ...state.filter,
          filterRefreshTracker: new Date(),
        },
      };
    }
    case 'photoUpdated': {
      return {
        ...state,
        photoList: {
          ...state.photoList,
          data: state.photoList.data?.map((item) =>
            item.id === action.photo.id ? action.photo : item
          ),
        },
      };
    }
    case 'photoDeleted': {
      return {
        ...state,
        photoList: {
          ...state.photoList,
          data: state.photoList.data?.filter(
            (item) => item.id !== action.photoId
          ),
        },
      };
    }
    case 'filterChange':
      return {
        ...state,
        filter: {
          ...state.filter,
          ...action.filterState,
        },
        // photoList: {
        //   noMoreItems: false,
        //   loading: true,
        //   error: undefined,
        //   data: [],
        // },
      };
    case 'refresh':
      return {
        filter: {},
        photoList: {
          noMoreItems: false,
          loading: false,
          data: [],
        },
      };
    case 'photoResultLoading':
      return {
        ...state,
        photoList: {
          ...state.photoList,
          loading: true,
        },
      };
    case 'photoResultError':
      return {
        ...state,
        photoList: {
          ...state.photoList,
          loading: false,
          error: action.error,
        },
      };
    case 'photoResultLoaded':
      // if (
      //   action.viewBy === state.filter?.viewBy &&
      //   action.filterValue === state.filter.filterValue
      // ) {
      return {
        ...state,
        photoList: {
          noMoreItems: action.noMoreItems,
          loading: false,
          error: undefined,
          data: [
            ...(state.photoList.data ? state.photoList.data : []),
            ...action.photoListData,
          ],
        },
      };
    // }
    // return state;
    default:
      return state;
  }
}

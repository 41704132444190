import React, {useCallback, useEffect} from 'react';
import NumberFormat, {NumberFormatProps} from 'react-number-format';

import NxpTextInput, {NxpTextInputProps} from './NxpTextInput';

interface NumberFormatCustomProps {
  inputRef: (instance: NumberFormat<any> | null) => void;
  onChange: (event: {target: {value: string}}) => void;
}

export interface NxpNumericInputProps
  extends Omit<NxpTextInputProps, 'value' | 'onChange'>,
    Pick<
      NumberFormatProps,
      'prefix' | 'allowEmptyFormatting' | 'decimalScale' | 'suffix'
    > {
  value?: number | null;
  fixDecimalScale?: boolean;
  onChange?: (
    e: React.ChangeEvent<HTMLInputElement>,
    value: number | null
  ) => void;
}

const NxpNumericInput: React.FC<NxpNumericInputProps> = ({
  prefix,
  allowEmptyFormatting = true,
  fixDecimalScale = true,
  decimalScale = 2,
  suffix,
  value,
  onChange,
  ...restProps
}) => {
  const [stringValue, setStringValue] = React.useState(
    // use object type here intentionally, using a number as state caused the controlled behavior of the NumberFormatCustom to fail for unknown reason
    {
      value: Number.isFinite(value) ? value?.toString() : '',
    }
  );

  useEffect(() => {
    setStringValue((prevState) => {
      if (
        value?.toString() === prevState.value ||
        `${value?.toString()}.` === prevState.value
      ) {
        return prevState;
      }
      return {value: value?.toString()};
    });
  }, [value, stringValue]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setStringValue({
      value: event.target.value,
    });

    if (onChange) {
      const changeValue = parseFloat(event.target.value);
      onChange(event, Number.isFinite(changeValue) ? changeValue : null);
    }
  };

  const NumberFormatCustom = useCallback(
    (props: NumberFormatCustomProps) => {
      const {
        inputRef,
        onChange: onNumberFormatCustomChange,
        ...restPropsNumberFormatCustom
      } = props;

      return (
        <NumberFormat
          {...restPropsNumberFormatCustom}
          getInputRef={inputRef}
          onValueChange={(values) => {
            onNumberFormatCustomChange({
              target: {
                value: values.value,
              },
            });
          }}
          thousandSeparator
          isNumericString
          prefix={prefix}
          allowEmptyFormatting={allowEmptyFormatting}
          fixedDecimalScale={fixDecimalScale}
          decimalScale={decimalScale}
          suffix={suffix}
        />
      );
    },
    [prefix, allowEmptyFormatting, fixDecimalScale, decimalScale, suffix]
  );

  return (
    <div>
      <NxpTextInput
        onChange={handleChange}
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        inputComponent={NumberFormatCustom as any}
        {...restProps}
        value={stringValue.value}
      />
    </div>
  );
};

export default NxpNumericInput;

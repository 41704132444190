import React from 'react';
import clsx from 'clsx';
import {Button, ButtonProps, makeStyles, Theme} from '@material-ui/core';

export interface NxpButtonProps extends ButtonProps {
  withTopLabelMargin?: boolean;
  clearInlineLeftMargin?: boolean;
}

const useStyles = makeStyles<Theme, NxpButtonProps>((theme) => ({
  root: {
    '&.MuiButtonBase-root.NxpButton:not(.MuiButton-text)': {
      paddingTop: 5,
      paddingBottom: 5,
      boxShadow:
        '0px 3px 1px -2px rgb(0 0 0 / 12%), 0px 2px 2px 0px rgb(0 0 0 / 8%), 0px 1px 5px 0px rgb(0 0 0 / 6%);',
    },
    marginTop: (props) => (props.withTopLabelMargin ? 16 : undefined),
    '&:not(:first-child)': {
      marginLeft: (props) =>
        props.clearInlineLeftMargin ? 0 : theme.spacing(2),
    },
  },
}));

const NxpButton = React.forwardRef<HTMLButtonElement, NxpButtonProps>(
  function NxpButton({className, ...restProps}, ref) {
    const classes = useStyles(restProps);

    // remove props incompatible with mui button control
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const propsForChild = {...restProps};
    delete propsForChild.withTopLabelMargin;
    delete propsForChild.clearInlineLeftMargin;

    return (
      <Button
        className={clsx('NxpButton', classes.root, className)}
        ref={ref}
        color="primary"
        variant="contained"
        {...propsForChild}
      />
    );
  }
);

export default NxpButton;

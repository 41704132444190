import React, {useReducer} from 'react';

import useAppContext from '../../hooks/useAppContext';
import {getPhotoList} from '../../services/explorer';
import {Photo} from '../../services/photo';
import {LAZY_LOAD_PAGE_SIZE} from '../../utils/const';
import {ExplorerFilterViewBy} from './ExplorerFilter/ExplorerFilterContainer';
import ExplorerLayout from './ExplorerLayout';
import explorerReducer from './explorerReducer';

const initExplorerState = {
  filter: {
    viewBy: ExplorerFilterViewBy.dateTaken,
    filterRefreshTracker: undefined,
  },
  photoList: {
    noMoreItems: false,
    loading: false,
    data: [],
  },
};

interface ExplorerContainerProps {}

const ExplorerContainer: React.FC<ExplorerContainerProps> = () => {
  const appContext = useAppContext();

  const [explorerState, dispatch] = useReducer(
    explorerReducer,
    initExplorerState
  );

  const handleFilterChange = async (
    viewBy: ExplorerFilterViewBy,
    filterValue: string
  ) => {
    dispatch({
      type: 'filterChange',
      viewBy,
      filterValue,
    });
    try {
      const photos = await getPhotoList(
        appContext?.activeAlbum?.id,
        {viewBy, filterValue},
        {
          limit: LAZY_LOAD_PAGE_SIZE,
          offset: 0,
        },
        appContext.serviceConfig
      );

      dispatch({
        type: 'photoResultLoaded',
        viewBy: viewBy,
        filterValue: filterValue!,
        noMoreItems: photos.length < LAZY_LOAD_PAGE_SIZE,
        photoListData: photos,
      });
    } catch (ex) {
      appContext.errorHandler(ex as Error, `getting photo list`);
      dispatch({
        type: 'photoResultError',
        viewBy: viewBy,
        filterValue: filterValue!,
        error: ex as Error,
      });
    }
  };

  const handlePhotoUpdated = (photo: Photo) => {
    dispatch({
      type: 'photoUpdated',
      photo,
    });
  };

  const handlePhotoCreated = (photos: Photo[]) => {
    dispatch({
      type: 'photoCreated',
      photos,
    });
  };

  const handlePhotoDeleted = (photoId: number) => {
    dispatch({
      type: 'photoDeleted',
      photoId,
    });
  };

  const handlePhotoListLazyLoad = async () => {
    const offset = explorerState.photoList.data?.length || 0;
    dispatch({
      type: 'photoResultLoading',
    });

    try {
      const photos = await getPhotoList(
        appContext?.activeAlbum?.id,
        explorerState.filter,
        {
          limit: LAZY_LOAD_PAGE_SIZE,
          offset,
        },
        appContext.serviceConfig
      );
      dispatch({
        type: 'photoResultLoaded',
        viewBy: explorerState.filter.viewBy,
        filterValue: explorerState.filter.filterValue!,
        noMoreItems: photos.length < LAZY_LOAD_PAGE_SIZE,
        photoListData: photos,
      });
    } catch (ex) {
      appContext.errorHandler(ex as Error, `getting photo list`);
    }
  };

  return (
    <ExplorerLayout
      entityId={appContext?.activeAlbum?.entityId}
      albumId={appContext?.activeAlbum?.id}
      photoList={explorerState.photoList}
      filterRefreshTracker={explorerState.filter.filterRefreshTracker}
      onFilterChange={handleFilterChange}
      onPhotoListLazyLoad={handlePhotoListLazyLoad}
      onPhotoUpdated={handlePhotoUpdated}
      onPhotoCreated={handlePhotoCreated}
      onPhotoDeleted={handlePhotoDeleted}
    />
  );
};

export default ExplorerContainer;

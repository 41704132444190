import React, {useState} from 'react';
import {
  Collapse,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
} from '@material-ui/core';
import {ModuleRole} from '../../services/moduleRoleAccessRight';
import {ObjectRole} from '../../services/objectRoleAccessRight';
import {ExpandLess, ExpandMore} from '@material-ui/icons';
import {useTranslation} from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: 'center',
  },
  nested: {
    paddingLeft: theme.spacing(12),
  },
}));

interface AppRoleMenuProps {
  roles: ModuleRole[] | ObjectRole[];
  selectedRole: null | ModuleRole | ObjectRole;
  onItemClick: (roleId: number) => void;
}

const AppRoleMenu: React.FC<AppRoleMenuProps> = ({
  roles,
  selectedRole,
  onItemClick,
}: AppRoleMenuProps) => {
  const classes = useStyles();
  const {t: tran} = useTranslation();
  const [localOpen, setLocalOpen] = useState(true);
  const [inheritedOpen, setInheritedOpen] = useState(false);

  const selectedRoleId = selectedRole?.id || -1;

  return (
    <List aria-labelledby="nested-list-subheader" className={classes.root}>
      <ListItem
        button
        onClick={() => {
          setLocalOpen(!localOpen);
        }}
      >
        <ListItemIcon>
          {localOpen ? <ExpandLess /> : <ExpandMore />}
        </ListItemIcon>
        <ListItemText primary={tran('settings.localRole')} />
      </ListItem>
      <Collapse in={localOpen} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {roles.map((role, index: number) => (
            <ListItem
              onClick={() => onItemClick(role.id)}
              button
              selected={selectedRoleId === role.id}
              key={index}
              className={classes.nested}
            >
              <ListItemText primary={role.name} />
            </ListItem>
          ))}
        </List>
      </Collapse>
      <ListItem
        button
        onClick={() => {
          setInheritedOpen(!inheritedOpen);
        }}
      >
        <ListItemIcon>
          {inheritedOpen ? <ExpandLess /> : <ExpandMore />}
        </ListItemIcon>
        <ListItemText primary={tran('settings.inheritedRoles')} />
      </ListItem>
      <Collapse in={localOpen} timeout="auto" unmountOnExit>
        <List component="div" disablePadding></List>
      </Collapse>
    </List>
  );
};

export default AppRoleMenu;

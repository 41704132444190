import {UserDateMatrix} from '../../services/dashboard';

interface DashboardState {
  dateRangeStart: null | Date;
  dateRangeEnd: null | Date;
  data: DashboardData;
}

interface DashboardData {
  count: number;
  countInDateRange: number;
  countsByLocation: {name: string; value: number}[];
  countsByType: {name: string; value: number}[];
  countsByTag: {name: string; value: number}[];
  userDateMatrix: null | UserDateMatrix;
  countsByContributor: {name: string; value: number}[];
}

type DashboardAction =
  | {
      type: 'dateRangeStartChange';
      date: null | Date;
    }
  | {
      type: 'dateRangeEndChange';
      date: null | Date;
    }
  | {
      type: 'dashboardDataLoading';
    }
  | {
      type: 'dashboardResultError';
      error: Error;
    }
  | {
      type: 'dashboardResultLoaded';
      data: DashboardData;
    };

export const dashboardReducer = (
  state: DashboardState,
  action: DashboardAction
): DashboardState => {
  switch (action.type) {
    case 'dateRangeStartChange':
      return {
        ...state,
        dateRangeStart: action.date,
      };
    case 'dateRangeEndChange':
      return {
        ...state,
        dateRangeEnd: action.date,
      };
    case 'dashboardResultLoaded':
      return {
        ...state,
        data: action.data,
      };
    default:
      return state;
  }
};

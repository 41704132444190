import {useSnackbar} from 'notistack';
import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import AddRoleModalLayout from './AppAddRoleModalLayout';

interface AddRoleModalContainerProps {
  open: boolean;
  onSave: (formGridState: AddRoleFormGridState) => void;
  onDismiss: () => void;
}

export interface AddRoleFormGridState {
  name: string;
  code: string;
  description: string;
  userIds: string[];
}

const AddRoleModalContainer: React.FC<AddRoleModalContainerProps> = (props) => {
  const {enqueueSnackbar} = useSnackbar();
  const {t: tran} = useTranslation();

  const [formGridState, setFormGridState] = useState({
    name: '',
    code: '',
    description: '',
    userIds: [],
  } as AddRoleFormGridState);

  useEffect(() => {
    if (props.open) {
      setFormGridState({
        name: '',
        code: '',
        description: '',
        userIds: [],
      });
    }
  }, [props.open]);

  const handleFormGridStateChange = (fieldName: string, value: unknown) => {
    setFormGridState({
      ...formGridState,
      [fieldName]: value,
    });
  };

  const handleSave = () => {
    if (props.open) {
      // check props.open to prevent multiple clicks
      if (formGridState.name === '' || formGridState.code === '') {
        enqueueSnackbar(tran('role.validation.warning'), {
          variant: 'error',
        });
        return;
      }
      props.onSave(formGridState);
    }
  };

  return (
    <AddRoleModalLayout
      open={props.open}
      formGridState={formGridState}
      onFormGridStateChange={handleFormGridStateChange}
      onSave={handleSave}
      onDismiss={props.onDismiss}
    />
  );
};

export default AddRoleModalContainer;

import {apiRequest} from '../utils/backend';
import {ServiceConfig} from '../utils/backend/apiRequest';
import {User} from './app';

export interface ObjectRole {
  id: number;
  name: string;
  code: string;
  description: string;
  users: User[];
}

export const getObjectRolesWithAssignees = async (
  objectType: string,
  objectId: number,
  serviceConfig: ServiceConfig
): Promise<ObjectRole[]> => {
  const getObjectRolesApiResponse = await apiRequest<ObjectRole[]>(
    `/object-role-assignees?objectType=${objectType}&objectId=${objectId}`,
    serviceConfig
  );
  return getObjectRolesApiResponse;
};

export const createObjectRole = async (
  objectType: string,
  objectId: number,
  data: {
    name: string;
    code: string;
    description: string;
    userIds: string[];
  },
  serviceConfig: ServiceConfig
): Promise<ObjectRole> => {
  return await apiRequest<ObjectRole>(`/object-roles`, serviceConfig, {
    method: 'POST',
    body: JSON.stringify({objectType, objectId, ...data}),
  });
};

export const editObjectRole = async (
  ObjectRoleId: number,
  name: string,
  description: string,
  serviceConfig: ServiceConfig
): Promise<ObjectRole> => {
  return await apiRequest<ObjectRole>(
    `/object-roles/${ObjectRoleId}`,
    serviceConfig,
    {
      method: 'PATCH',
      body: JSON.stringify({
        name,
        description,
      }),
    }
  );
};

export const deleteObjectRole = async (
  ObjectRoleId: number,
  serviceConfig: ServiceConfig
): Promise<Boolean> => {
  await apiRequest<Record<string, never>>(
    `/object-roles/${ObjectRoleId}`,
    serviceConfig,
    {
      method: 'DELETE',
    }
  );
  return true;
};

export const applyAssigneeList = async (
  ObjectRoleId: number,
  userIds: string[],
  serviceConfig: ServiceConfig
): Promise<ObjectRole> => {
  return await apiRequest<ObjectRole>(
    `/object-roles/${ObjectRoleId}/assignees`,
    serviceConfig,
    {
      method: 'PATCH',
      body: JSON.stringify({
        userIds,
      }),
    }
  );
};

import React, {useReducer, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useRouteMatch} from 'react-router-dom';

import {capitalize, lowerCase} from 'lodash';
import {useSnackbar} from 'notistack';

import {AddRoleFormGridState} from '../../components/AppAddRoleModal/AppAddRoleModalContainer';
import useAppContext from '../../hooks/useAppContext';
import {useAsync} from '../../hooks/useAsync';
import {
  applyAssigneeList,
  createObjectRole,
  deleteObjectRole,
  editObjectRole,
  getObjectRolesWithAssignees,
  ObjectRole,
} from '../../services/objectRoleAssignment';
import ObjectRoleAssignmentLayout from './ObjectRoleAssignmentLayout';
import {objectRoleAssignmenReducer} from './objectRoleAssignmentReducer';

interface ObjectRoleAssignmentSettingsContainerProps {}

const ObjectRoleAssignmentContainer: React.FC<ObjectRoleAssignmentSettingsContainerProps> =
  () => {
    const appContext = useAppContext();
    const {t: tran} = useTranslation();
    const {enqueueSnackbar} = useSnackbar();

    let objectType = '';
    let objectId = 0;

    {
      const route = useRouteMatch<{entityId: string; albumId: string}>(
        '/entities/:entityId/albums/:albumId'
      );
      if (route) {
        objectType = 'album';
        objectId = parseInt(route.params.albumId);
      }
    }

    const [reducerState, dispatch] = useReducer(objectRoleAssignmenReducer, {
      objectRoles: [],
      assigneeIdsByRoleId: {},
      modifiedObjectRoleIds: [],
      deletedObjectRoleIds: [],
    });

    const [keyword, setKeyword] = useState('');
    const [addRoleModalOpen, setAddRoleModalOpen] = useState(false);
    const [batchAssignModalOpen, setBatchAssignModalOpen] = useState(false);

    const [selectedObjectRoleIds, setSelectedObjectRoleIds] = useState(
      [] as number[]
    );

    const [pendingRoleEdit, setPendingRoleEdit] = useState(
      null as unknown as ObjectRole
    );
    const [pendingRoleDeletion, setPendingRoleDeletion] = useState(0);

    const handleBatchAssignModalOpen = (open: boolean) => {
      if (open && selectedObjectRoleIds.length === 0) {
        enqueueSnackbar(tran('warning.role'), {variant: 'error'});
        return;
      }
      setBatchAssignModalOpen(open);
    };

    const handleAssignmentChange = (
      objectRoleId: number,
      assigneeIds: string[]
    ) => {
      dispatch({
        type: 'assigneesChanged',
        objectRoleId,
        assigneeIds,
      });
    };

    const handleDiscard = () => {
      dispatch({
        type: 'assigneesDiscarded',
      });
    };

    const handleAssignmentSave = async () => {
      const objectRoleIds = reducerState.modifiedObjectRoleIds.filter(
        (id) => !reducerState.deletedObjectRoleIds.includes(id)
      );

      if (objectRoleIds.length === 0) {
        enqueueSnackbar(`${tran('warning.noChange')}`, {variant: 'error'});
        return;
      }

      let successCount = 0;
      let failureCount = 0;

      for (const objectRoleId of objectRoleIds) {
        try {
          const objectRole = await applyAssigneeList(
            objectRoleId,
            reducerState.assigneeIdsByRoleId[objectRoleId],
            appContext.serviceConfig
          );

          dispatch({
            type: 'assigneesSaved',
            objectRole,
          });
        } catch (ex) {
          failureCount++;
        }
        successCount++;
      }

      if (successCount) {
        enqueueSnackbar(
          `${capitalize(tran('common.user'))} ${lowerCase(
            tran('common.list')
          )}  ${lowerCase(tran('common.hasBeen'))} ${lowerCase(
            tran('common.updated')
          )} ${lowerCase(tran('common.success'))}`,
          {
            variant: 'success',
          }
        );
      }

      if (failureCount) {
        enqueueSnackbar(
          `Failed to apply assignees to ${failureCount} Object Role(s)`,
          {
            variant: 'error',
          }
        );
      }
    };

    const handleObjectRoleSelect = (
      objectRoleId: number,
      selected: boolean
    ) => {
      const ids = [...selectedObjectRoleIds] as number[];
      const index = selectedObjectRoleIds.indexOf(objectRoleId);

      if (selected) {
        if (index === -1) {
          ids.push(objectRoleId);
        }
      } else {
        if (index !== -1) {
          ids.splice(index, 1);
        }
      }
      setSelectedObjectRoleIds(ids);
    };

    const handleBatchAssign = (userIds: string[]) => {
      for (const roleId of selectedObjectRoleIds) {
        const assigneeIds = [...reducerState.assigneeIdsByRoleId[roleId]];
        for (const userId of userIds) {
          if (!assigneeIds.includes(userId)) assigneeIds.push(userId);
        }
        dispatch({
          type: 'assigneesChanged',
          objectRoleId: roleId,
          assigneeIds: assigneeIds,
        });
      }

      enqueueSnackbar(
        `${capitalize(tran('common.added'))} ${userIds.length} ${tran(
          'common.person'
        )} ${lowerCase(tran('common.to'))} ${lowerCase(
          tran('common.list')
        )}${tran('common.warning.notYetSave')}`
      );

      setBatchAssignModalOpen(false);
    };

    const handleRoleCreate = async (formGridState: AddRoleFormGridState) => {
      try {
        const newObjectRole = await createObjectRole(
          objectType,
          objectId,
          formGridState,
          appContext.serviceConfig
        );
        enqueueSnackbar(
          `${capitalize(tran('common.success'))} ${capitalize(
            tran('common.added')
          )}  ${capitalize(tran('role'))}`,
          {
            variant: 'success',
          }
        );
        dispatch({
          type: 'objectRoleAdded',
          objectRole: newObjectRole,
        });
        setAddRoleModalOpen(false);
      } catch (ex) {
        appContext.errorHandler(ex as Error, 'creating object role');
      }
    };

    const handleRoleEditRequest = async (objectRoleId: number) => {
      const objectRole = reducerState.objectRoles.find(
        (role) => role.id === objectRoleId
      );
      if (!objectRole) return;
      setPendingRoleEdit(objectRole);
    };

    const handleRoleEditCancel = async () => {
      setPendingRoleEdit(null as unknown as ObjectRole);
    };

    const handleRoleEditConfirm = async (
      objectRoleId: number,
      name: string,
      description: string
    ) => {
      try {
        const objectRole = await editObjectRole(
          objectRoleId,
          name,
          description,
          appContext.serviceConfig
        );
        enqueueSnackbar(
          `${capitalize(tran('role'))} ${capitalize(
            tran('common.name')
          )} ${capitalize(tran('common.edited'))}  ${capitalize(
            tran('common.success')
          )}`,
          {
            variant: 'success',
          }
        );
        dispatch({
          type: 'objectRoleEdited',
          objectRole,
        });
        setPendingRoleEdit(null as unknown as ObjectRole);
      } catch (ex) {
        appContext.errorHandler(ex as Error, 'updating object role');
      }
    };

    const handleRoleDeletionRequest = async (objectRoleId: number) => {
      setPendingRoleDeletion(objectRoleId);
    };

    const handleRoleDeletionCancel = async () => {
      setPendingRoleDeletion(0);
    };

    const handleRoleDeletionConfirm = async () => {
      try {
        await deleteObjectRole(pendingRoleDeletion, appContext.serviceConfig);
        enqueueSnackbar(
          `${capitalize(tran('role'))} ${lowerCase(
            tran('common.hasBeen')
          )} ${lowerCase(tran('common.deleted'))} `,
          {
            variant: 'success',
          }
        );
        dispatch({
          type: 'objectRoleDeleted',
          objectRoleId: pendingRoleDeletion,
        });
        setPendingRoleDeletion(0);
      } catch (ex) {
        appContext.errorHandler(
          ex as Error,
          'deleting object role',
          pendingRoleDeletion
        );
      }
    };

    const [,] = useAsync<ObjectRole[]>(
      () =>
        getObjectRolesWithAssignees(
          objectType,
          objectId,
          appContext.serviceConfig
        ),
      {
        onSuccess: (objectRoles: ObjectRole[]) => {
          if (objectRoles) {
            dispatch({
              type: 'objectRolesLoaded',
              objectRoles: objectRoles,
            });
          }
        },
        onError: (ex) =>
          appContext.errorHandler(ex as Error, 'loading object roles'),
      }
    );

    return (
      <ObjectRoleAssignmentLayout
        objectRoles={reducerState.objectRoles}
        assigneeIdsByRoleId={reducerState.assigneeIdsByRoleId}
        selectedObjectRoleIds={selectedObjectRoleIds}
        modifiedObjectRoleIds={reducerState.modifiedObjectRoleIds}
        deletedObjectRoleIds={reducerState.deletedObjectRoleIds}
        keyword={keyword}
        onKeywordChange={(keyword) => setKeyword(keyword)}
        addRoleModalOpen={addRoleModalOpen}
        onAddRoleModalOpen={() => setAddRoleModalOpen(true)}
        onAddRoleModalClose={() => setAddRoleModalOpen(false)}
        batchAssignModalOpen={batchAssignModalOpen}
        onBatchAssignModalOpen={() => handleBatchAssignModalOpen(true)}
        onBatchAssignModalClose={() => handleBatchAssignModalOpen(false)}
        onBatchAssign={handleBatchAssign}
        onRoleCreate={handleRoleCreate}
        pendingRoleEdit={pendingRoleEdit}
        onRoleEditRequest={handleRoleEditRequest}
        onRoleEditCancel={handleRoleEditCancel}
        onRoleEditConfirm={handleRoleEditConfirm}
        pendingRoleDeletion={pendingRoleDeletion}
        onRoleDeletionRequest={handleRoleDeletionRequest}
        onRoleDeletionCancel={handleRoleDeletionCancel}
        onRoleDeletionConfirm={handleRoleDeletionConfirm}
        onObjectRoleSelect={handleObjectRoleSelect}
        onAssignmentChange={handleAssignmentChange}
        onAssignmentsDiscard={handleDiscard}
        onAssignmentsSave={handleAssignmentSave}
      />
    );
  };

export default ObjectRoleAssignmentContainer;

import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';

import 'leaflet';
import 'leaflet.markercluster';
import './lib/Leaflet.Photo';

import {
  Box,
  createStyles,
  Grid,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import _ from 'lodash';

import useAppContext from '../../hooks/useAppContext';
import {getPhotoMap, Photo} from '../../services/photo';
import {MAP_SETTINGS} from '../../utils/const';
import {getDefaultMapLocation} from './lib/getDefaultMapLocation';

import 'leaflet.markercluster/dist/MarkerCluster.Default.css';
import 'leaflet/dist/leaflet.css';
import './lib/Leaflet.Photo.css';

interface AppPhotoLocationProps {
  // @ts-ignore
  location?: LatLngExpression;
}
const L = (window as any)['L'];
// @ts-ignore
export const MAP_CENTER_COORDINATES: LatLngExpression = [51.0, 19.0];
export const MAP_ZOOM = 4;
export const MAP_MAX_ZOOM = 18;
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      maxWidth: 345,
    },
    media: {
      height: 400,
    },
    map: {
      height: 'calc(100vh - 200px)',
      width: 'calc(100wh - 200px)',
      margin: theme.spacing(1, 0),
      '& .leaflet-top, .leaflet-bottom': {
        zIndex: 500,
      },
    },
    title: {
      fontSize: theme.typography.pxToRem(24),
      fontWeight: theme.typography.fontWeightBold as number,
      color: '#3E3F42',
      padding: theme.spacing(2, 0),
    },
  })
);
export const POLSKA_SZER_GEOGR = 51.9874;
export const POLSKA_DL_GEOGR = 19.0162;
export const POLSKA_ZOOM = 5;
const PhotoMap: React.FC<AppPhotoLocationProps> = ({
  location = [22.42449166666667, 114.21437777777778],
}) => {
  const classes = useStyles();
  const [photoList, setPhotoList] = useState<any[]>([]);
  const appContext = useAppContext();
  const {t: tran} = useTranslation();
  const fetchDate = async (): Promise<Photo[]> => {
    if (
      appContext?.serviceConfig?.albumId !== undefined &&
      appContext?.entityId !== undefined
    ) {
      return await getPhotoMap(
        Number(appContext?.entityId),
        Number(appContext?.serviceConfig?.albumId),
        appContext.serviceConfig
      );
    } else {
      return new Promise((resolve) => resolve([]));
    }
  };
  useEffect(() => {
    var tiles = L.tileLayer(
      'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      {
        maxZoom: 19,
        attribution:
          '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
      }
    );

    const initMapSettings = MAP_SETTINGS[getDefaultMapLocation()];

    const map = L.map('googleMapsPlaner', {
      center: initMapSettings.center,
      zoom: initMapSettings.zoom,
      zoomControl: true,
      layers: [tiles],
    });

    const fetchPhotos = async () => await fetchDate();
    fetchPhotos().then((res: Photo[]) => {
      if (res.length > 0) {
        setPhotoList(
          res.map((photo) => ({
            lat: _.get(photo, 'exif.latitude'),
            lng: _.get(photo, 'exif.longitude'),
            url: _.get(photo, 'mediumUrl'),
            thumbnail: _.get(photo, 'thumbnailUrl'),
            caption: _.get(photo, 'caption', '')
              ? _.get(photo, 'caption', '')
              : `${tran('common.no')} ${tran('photo.caption')}`,
          }))
        );
        const photos = res.map((photo) => ({
          lat: _.get(photo, 'exif.latitude'),
          lng: _.get(photo, 'exif.longitude'),
          url: _.get(photo, 'mediumUrl'),
          thumbnail: _.get(photo, 'thumbnailUrl'),
          caption: _.get(photo, 'caption', '')
            ? _.get(photo, 'caption', '')
            : `${tran('common.no')} ${tran('photo.caption')}`,
        }));
        const photoLayer = L.photo
          .cluster({
            showCoverageOnHover: true,
            icon: {
              iconSize: [100, 100],
            },
          })
          .on('click', function (evt: any) {
            var photo = evt.layer.photo,
              template =
                '<img src="{url}" class="popup-photo" /></a><p>{caption}</p>';
            if (
              photo.video &&
              !!document
                .createElement('video')
                .canPlayType('video/mp4; codecs=avc1.42E01E,mp4a.40.2')
            ) {
              template =
                '<video autoplay controls poster="{url}"><source src="{video}" type="video/mp4"/></video>';
            }
            evt.layer
              .bindPopup(L.Util.template(template, photo), {
                className: 'leaflet-popup-photo',
                minWidth: 400,
              })
              .openPopup();
          });
        photoLayer.add(photos).addTo(map);
        map.fitBounds(photoLayer.getBounds());
      }
    });

    return () => {
      setPhotoList([]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box component="div" m={4} borderRadius={5} bgcolor="grey[700">
      <Grid container alignItems="center" className="photo-map">
        <Grid item xs={4}>
          <Typography variant="h1" component="div" className={classes.title}>
            {`${_.capitalize(tran('photo'))} ${_.capitalize(tran('map'))} ( ${
              photoList.length
            } )`}
          </Typography>
        </Grid>
      </Grid>
      <div className={classes.map} id="googleMapsPlaner"></div>
    </Box>
  );
};
export default PhotoMap;

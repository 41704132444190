import React, {ReactNode} from 'react';
import {CircularProgress, makeStyles, Theme, useTheme} from '@material-ui/core';
import {Cancel as CancelIcon} from '@material-ui/icons';
import LazyLoad from 'react-lazy-load';

import {Photo} from '../../services/photo';
import AppPhotoListItem from './AppPhotoListItem';
import AppPhotoZoom from './AppPhotoZoom';
import AppPhotoDetail from './AppPhotoDetail';
import AppSelectedPhotoActions from '../AppSelectedPhotoActions';

import photoSvg from '../../images/icons/photo.svg';
import {IconButton} from '@material-ui/core';
import clsx from 'clsx';
import _ from 'lodash';
import {useTranslation} from 'react-i18next';

const useStyles = makeStyles<Theme, {loading?: boolean}>((theme) => ({
  root: {
    display: 'flex',
    flexGrow: 1,
  },
  list: {
    position: 'relative',
    flexGrow: 1,
    padding: theme.spacing(3, 5),
    '& > ol': {
      margin: theme.spacing(2, -2),
      padding: 0,
      listStyle: 'none',
      display: 'flex',
      justifyContent: 'flex-start',
      flexWrap: 'wrap',
      '& li': {
        margin: theme.spacing(2),
      },
    },
  },
  headerAction: {
    position: 'fixed',
    top: 118,
    right: 40,
    zIndex: 100,
    '&.detail-on': {
      right: 300,
    },
  },
  circularProgress: {
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  notFound: {
    width: '100%',
    height: 'calc(80vh - 180px)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    '& img': {
      width: 100,
      filter: 'grayscale(100%) brightness(3)',
    },
    '& p': {
      textAlign: 'center',
    },
  },
  mediumPreview: {
    display: 'none', // hide preview for 360 integration
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    '& .MuiButtonBase-root': {
      transform: 'translate(-50%, -50%)',
      position: 'absolute',
      '& .MuiIconButton-label': {
        background: '#fff',
        borderRadius: '50%',
      },
    },
  },
}));

interface AppPhotoListProps {
  headerTitleContent: ReactNode;
  headerActionContent?: ReactNode;
  photoListData: Photo[];
  loading?: boolean;
  noMoreItems?: boolean;
  activePhotoId?: number;
  selectedPhotos: number[];
  photoZoomId?: number;
  hideAddToLightbox?: boolean;
  showMediumPreview: boolean;
  onPhotoZoom: (photoId?: number) => void;
  onPhotoDetail: (photoId?: number) => void;
  onLazyLoad?: () => void;
  onPhotoSelectionChange: (photoId: number) => void;
  onPhotoSelectAll: () => void;
  onPhotoUpdated: (photo: Photo) => void;
  onPhotoDeleted: (photoId: number) => void;
  onHideMediumPreview: () => void;
}

const AppPhotoListLayout: React.FC<AppPhotoListProps> = ({
  headerTitleContent,
  headerActionContent,
  photoListData,
  loading,
  noMoreItems,
  activePhotoId,
  selectedPhotos,
  photoZoomId,
  hideAddToLightbox,
  showMediumPreview,
  onPhotoDetail,
  onPhotoZoom,
  onLazyLoad,
  onPhotoSelectionChange,
  onPhotoSelectAll,
  onPhotoUpdated,
  onPhotoDeleted,
  onHideMediumPreview,
}) => {
  const classes = useStyles({loading});
  const theme = useTheme();
  const {t: tran} = useTranslation();
  const activePhoto = photoListData.find((photo) => photo.id === activePhotoId);

  return (
    <div className={classes.root}>
      <div className={classes.list}>
        <div
          className={clsx(classes.headerAction, activePhotoId && 'detail-on')}
        >
          {headerActionContent}
          <AppSelectedPhotoActions
            photoList={photoListData}
            selectedPhotos={selectedPhotos}
            onPhotoSelectAll={onPhotoSelectAll}
            hideAddToLightbox={hideAddToLightbox}
          />
        </div>
        {headerTitleContent}
        {noMoreItems && photoListData.length === 0 ? (
          <div className={classes.notFound}>
            <img src={photoSvg} alt="Not found icon" />
            <p>
              {`${_.capitalize(tran('common.no'))} 
              ${_.capitalize(tran('common.image'))} 
              ${_.capitalize(tran('common.found'))}`}
            </p>
          </div>
        ) : (
          <ol>
            {photoListData.map((item) => (
              <AppPhotoListItem
                key={item.id}
                active={item.id === activePhotoId}
                photo={item}
                selected={selectedPhotos.includes(item.id)}
                onPhotoDetail={onPhotoDetail}
                onPhotoZoom={onPhotoZoom}
                onPhotoSelectionChange={onPhotoSelectionChange}
              />
            ))}
          </ol>
        )}
        {loading && (
          <CircularProgress
            className={classes.circularProgress}
            size={theme.dimension.circularProgressSize.small}
          />
        )}
        {!loading && !noMoreItems && (
          <LazyLoad offsetTop={250} onContentVisible={onLazyLoad}>
            <div />
          </LazyLoad>
        )}
        {activePhoto && showMediumPreview && (
          <div className={classes.mediumPreview}>
            <img src={activePhoto.mediumUrl} alt="medium preview" />
            <IconButton aria-label="Hide Preview" onClick={onHideMediumPreview}>
              <CancelIcon color="primary" />
            </IconButton>
          </div>
        )}
      </div>
      <AppPhotoZoom
        photoId={photoZoomId}
        photoListData={photoListData ? photoListData : []}
        onClose={onPhotoZoom}
      />
      {activePhoto && (
        <AppPhotoDetail
          photoListData={photoListData ? photoListData : []}
          photo={activePhoto}
          onPhotoDetailUpdate={onPhotoUpdated}
          onPhotoDeleted={onPhotoDeleted}
        />
      )}
    </div>
  );
};

export default AppPhotoListLayout;

import {createStyles, makeStyles, Theme, Typography} from '@material-ui/core';
import React from 'react';
import {NxpButton} from '../NxpButton';
import {NxpDialog} from '../NxpDialog';
import {useTranslation} from 'react-i18next';

interface AppConfirmDialogProps {
  show: boolean;
  title: string;
  message: string;
  onCancel: () => void;
  onConfirm: () => void;
}
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },
  })
);

const AppConfirmDialog: React.FC<AppConfirmDialogProps> = ({
  show,
  title,
  message,
  onCancel,
  onConfirm,
}) => {
  const classes = useStyles();
  const {t: tran} = useTranslation();
  return (
    <NxpDialog
      maxWidth="md"
      fullWidth
      open={show}
      titleContent={title}
      actionContent={
        <>
          <NxpButton variant="text" onClick={onCancel}>
            {tran('common.cancel')}
          </NxpButton>
          <NxpButton onClick={onConfirm}>{tran('common.confirm')}</NxpButton>
        </>
      }
      onClose={onCancel}
      closeByClickOutside
    >
      <Typography
        variant="subtitle1"
        component="h1"
        display="block"
        className={classes.root}
      >
        {message}
      </Typography>
    </NxpDialog>
  );
};

export default AppConfirmDialog;

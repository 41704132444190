import React, {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import InnerImageZoom from 'react-inner-image-zoom';

import {
  AppBar,
  Box,
  Card,
  CardActions,
  CardContent,
  IconButton,
  makeStyles,
  Tab,
  Tabs,
  Theme,
  Toolbar,
  Typography,
} from '@material-ui/core';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import Skeleton from '@material-ui/lab/Skeleton';
import _ from 'lodash';

import useAppContext from '../../../hooks/useAppContext';
import {ReactComponent as Bin} from '../../../images/icons/bin.svg';
import {ReactComponent as LightRoom} from '../../../images/LightRoom.svg';
import {Photo, UploadPhoto} from '../../../services/photo';
import {Tag} from '../../../services/tag';
import {PhotoDetailTab} from '../../../utils/typing/PhotoDetailsTab';
import AddToLightboxContainer from '../../AppSelectedPhotoActions/AddToLightbox/AddToLightboxContainer';
import AppPhotoDelete from '../AppPhotoDelete/AppDeletePhoto';
import AppPhotoZoom from '../AppPhotoZoom';
import AppPhotoComment from './AppPhotoComment';
import AppPhotoExif from './AppPhotoExif';
import AppPhotoGeneralDetailContainer from './AppPhotoGeneralDetail';
import AppPhotoLocation from './AppPhotoLocation';
import AppPhotoTags from './AppPhotoTags';

import 'react-inner-image-zoom/lib/InnerImageZoom/styles.min.css';

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}
function TabPanel(props: TabPanelProps) {
  const {children, value, index, ...other} = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Typography variant="inherit" component="div">
          {children}
        </Typography>
      )}
    </div>
  );
}

function a11yProps(index: any) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}

const useStyles = makeStyles<
  Theme,
  Pick<AppPhotoDetailLayoutProps, 'generalDetail'>
>((theme) => ({
  root: {
    borderLeft: `1px solid ${theme.palette.border.light}`,
    backgroundColor: theme.palette.background.paper,
    minWidth: 270,
    '& > div': {
      position: 'fixed',
      height: 'calc(100vh - 100px)',
      width: (props) => (props?.generalDetail ? 270 : 0),
      overflow: 'auto',
      boxSizing: 'border-box',
      borderColor: theme.palette.background.default,
      backgroundColor: theme.palette.background.paper,
      transition: theme.transitions.create('all', {
        duration: theme.transitions.duration.shorter,
      }),
    },
    '& .MuiPaper-rounded': {
      borderRadius: 'inherit',
    },
  },
  zooming: {
    '& .iiz': {
      '& >div': {
        '& img': {
          maxHeight: 175,
          maxWidth: 250,
        },
      },
    },
  },
  appBar: {
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary,
    fontSize: theme.typography.pxToRem(14),
    '& > .MuiToolbar-root': {
      padding: '0 8px',
    },
  },
  media: {
    '& >img': {
      maxWidth: 270,
      maxHeight: 180,
      margin: '0 auto',
      width: 'auto !important',
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    fontSize: theme.typography.h2.fontSize,
    padding: 0,
    fontWeight: theme.typography.fontWeightBold as number,
    flexGrow: 1,
    margin: theme.spacing(3, 0, 0, 1),
  },
  '& .MuiCardActions-root': {
    padding: 0,
  },
  iconWrapper: {
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(0, 1),
    '& .MuiButtonBase-root': {
      padding: 4,
      fontSize: theme.typography.pxToRem(14),
    },
  },
  cardContent: {
    padding: '0 8px',
    '& .photo-general, & .photo-exif, & .photo-tags, & .photo-location, & .photo-comment,':
      {
        minHeight: 32,
      },
    '& .MuiButton-label': {
      fontWeight: theme.typography.fontWeightBold as number,
    },
    '& .MuiTypography-h1': {
      fontWeight: theme.typography.fontWeightBold as number,
      fontSize: theme.typography.pxToRem(14),
    },
    '& .MuiButton-text': {
      fontSize: theme.typography.pxToRem(10),
      '&.cancel': {
        color: '#8A9999',
      },
    },
    '& .container': {
      marginBottom: theme.spacing(2),
    },
  },
  tab: {
    flexGrow: 1,
    width: '100%',
    minHeight: 30,
    backgroundColor: theme.palette.background.paper,
    '& .MuiButtonBase-root MuiTab-root': {
      padding: theme.spacing(6, 0),
    },
    '& .MuiBadge-badge': {
      height: 15,
      width: 15,
      minHeight: 15,
      minWidth: 15,
      fontSize: theme.typography.pxToRem(8),
      '&.MuiBadge-anchorOriginTopRightRectangle': {
        top: -5,
        right: -2,
      },
    },

    '& .MuiTabs-root': {
      minHeight: 'fit-content',

      '& .MuiTab-wrapper': {
        fontWeight: theme.typography.fontWeightBold as number,
        alignItems: 'flex-start',
        flexDirection: 'row',
        fontSize: theme.typography.pxToRem(8),
      },
      '& .MuiButtonBase-root': {
        padding: '4px',
        letterSpacing: theme.typography.pxToRem(0),
      },
    },
    '& .MuiTab-fullWidth': {
      minWidth: 'fit-content',
      minHeight: 'inherit',
      '& .MuiTab-wrapper': {
        minWidth: 'fit-content',
      },
    },
    '& .MuiTabs-flexContainer': {
      minHeight: 30,
    },
    '& .MuiAppBar-root': {
      minHeight: 36,
    },
  },
}));

interface AppPhotoDetailLayoutProps {
  photoId?: number;
  photoListData: Photo[];
  generalDetail: Partial<Photo>;
  photoZoomId?: number;
  onPhotoZoom: (photoId?: number) => void;
  handlePhotoDelete: (cd: Function) => void;
  handlePhotoUserTagsUpdate: (
    userTags: Pick<UploadPhoto, 'userTags'>,
    actionType: string
  ) => void;
  handlePhotoDetailUpdate: (detail: Partial<Photo>) => void;
  dialogOpen: boolean;
  setDialogOpen: (toggole: boolean) => void;
  handlePhotosAddComment: (comment: string) => void;
  addToLightboxModalOpen: boolean;
  onAddToLightboxModalToggle?: (modalOpen: boolean) => void;
}

const AppPhotoDetailLayout: React.FC<AppPhotoDetailLayoutProps> = ({
  photoId,
  photoZoomId,
  photoListData,
  generalDetail,
  onPhotoZoom,
  handlePhotoDelete,
  handlePhotoDetailUpdate,
  setDialogOpen,
  dialogOpen,
  handlePhotoUserTagsUpdate,
  handlePhotosAddComment,
  addToLightboxModalOpen,
  onAddToLightboxModalToggle,
}) => {
  const appContext = useAppContext();
  const [value, setValue] = React.useState<number>(0);
  const [displayTabs, setDisplayTabs] = React.useState<PhotoDetailTab[]>([]);
  const {t: tran} = useTranslation();
  useEffect(() => {
    let displayTabsList: PhotoDetailTab[] = [
      PhotoDetailTab.generate,
      PhotoDetailTab.tags,
      PhotoDetailTab.comment,
    ];
    if (_.get(generalDetail, 'exif', false)) {
      displayTabsList.splice(2, 0, PhotoDetailTab.exif);
    } else {
      displayTabsList = displayTabsList.filter(
        (tab) => tab !== PhotoDetailTab.exif
      );
    }
    if (
      _.get(generalDetail, 'exif.latitude', false) &&
      _.get(generalDetail, 'exif.longitude', false)
    ) {
      displayTabsList.splice(3, 0, PhotoDetailTab.location);
    } else {
      displayTabsList = displayTabsList.filter(
        (tab) => tab !== PhotoDetailTab.location
      );
    }
    setDisplayTabs(displayTabsList);
    return () => {
      setDisplayTabs([]);
      setValue(0);
    };
  }, [generalDetail]);
  // tab section

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };
  const classes = useStyles({
    generalDetail,
  });
  const handleTagsUpdate = (tags: Tag[], actionType: string) => {
    switch (actionType) {
      case 'update':
        handlePhotoUserTagsUpdate(
          tags
            .filter((tag) => !_.has(tag, 'id'))
            .map((tag) => tag.name) as Pick<UploadPhoto, 'userTags'>,
          actionType
        );
        break;

      case 'delete':
        handlePhotoUserTagsUpdate(
          tags.map((tag) => tag.id) as Pick<UploadPhoto, 'userTags'>,
          actionType
        );
        break;
    }
  };
  return (
    <div className={classes.root}>
      <div>
        <AppBar position="static" className={classes.appBar} elevation={0}>
          <Toolbar>
            <Typography variant="h2" className={classes.title}>
              {`${_.capitalize(tran('common.image'))} ${_.capitalize(
                tran('photo.properties')
              )}`}
            </Typography>
          </Toolbar>
        </AppBar>
        <Card elevation={0}>
          {generalDetail?.fileUrl ? (
            <>
              <Box
                component="div"
                height={180}
                width={270}
                display="flex"
                alignItems="center"
                justifyContent="center"
                className={classes.zooming}
              >
                <InnerImageZoom
                  className="InnerImageZoom"
                  src={generalDetail?.mediumUrl}
                  zoomType="hover"
                  zoomScale={2}
                  hideCloseButton={true}
                  hideHint={true}
                />
              </Box>
            </>
          ) : (
            <Skeleton
              variant="rect"
              width={'100%'}
              height={180}
              component="div"
            />
          )}

          <CardActions className={classes.iconWrapper}>
            <div className="font">
              {onAddToLightboxModalToggle && (
                <IconButton
                  aria-label="upload picture"
                  component="div"
                  onClick={() => onAddToLightboxModalToggle(true)}
                >
                  <LightRoom />
                </IconButton>
              )}
              {photoId !== undefined && (
                <AddToLightboxContainer
                  modalOpen={addToLightboxModalOpen}
                  onModalClose={() => {
                    if (onAddToLightboxModalToggle) {
                      onAddToLightboxModalToggle(false);
                    }
                  }}
                  selectedPhotos={[photoId]}
                />
              )}
              <IconButton
                aria-label="zoom picture"
                component="div"
                onClick={() => onPhotoZoom(photoId)}
              >
                <ZoomInIcon />
              </IconButton>
            </div>
            <div className="back">
              {appContext.hasUserAccessRight('photo-delete', 'album') && (
                <>
                  <IconButton
                    aria-label="delete photo"
                    component="div"
                    onClick={() => setDialogOpen(true)}
                  >
                    <Bin />
                  </IconButton>
                  <AppPhotoDelete
                    handleDialogClose={handleDialogClose}
                    handlePhotoDelete={handlePhotoDelete}
                    showModal={dialogOpen}
                  />
                </>
              )}
            </div>
          </CardActions>
          <CardContent className={classes.cardContent}>
            <div className={classes.tab}>
              <AppBar position="static" color="inherit" elevation={0}>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  indicatorColor="primary"
                  textColor="primary"
                  variant="fullWidth"
                  aria-label="scrollable auto tabs example"
                >
                  {displayTabs.map((tab, i) => (
                    <Tab
                      key={i}
                      className={`tag-${tab}`}
                      label={`${_.capitalize(tran('photo.' + tab))}${
                        tab === PhotoDetailTab.tags
                          ? '(' + _.get(generalDetail, 'tags', []).length + ')'
                          : tab === PhotoDetailTab.comment
                          ? '(' +
                            _.get(generalDetail, 'comments', []).length +
                            ')'
                          : ''
                      }`}
                      {...a11yProps(i)}
                    />
                  ))}
                </Tabs>
              </AppBar>
              {displayTabs.map((tab, i) => {
                switch (tab) {
                  case PhotoDetailTab.generate:
                    return (
                      <TabPanel value={value} index={i} key={i}>
                        <div className="general container" key={i}>
                          <AppPhotoGeneralDetailContainer
                            photo={generalDetail}
                            handlePhotoDetailUpdate={handlePhotoDetailUpdate}
                          />
                        </div>
                      </TabPanel>
                    );
                  case PhotoDetailTab.tags:
                    return (
                      <TabPanel value={value} index={1} key={i}>
                        <div className="tags container" key={i}>
                          <AppPhotoTags
                            tags={
                              generalDetail?.tags ? generalDetail?.tags : []
                            }
                            handleTagsUpdate={handleTagsUpdate}
                          />
                        </div>
                      </TabPanel>
                    );
                  case PhotoDetailTab.exif:
                    return (
                      <TabPanel value={value} index={i} key={i}>
                        {_.get(generalDetail, 'exif', false) ? (
                          <div className="exif container" key={i}>
                            <AppPhotoExif
                              exif={
                                generalDetail?.exif ? generalDetail?.exif : {}
                              }
                            />
                          </div>
                        ) : (
                          <></>
                        )}
                      </TabPanel>
                    );
                  case PhotoDetailTab.location:
                    return (
                      <TabPanel value={value} index={i} key={i}>
                        {_.get(generalDetail, 'exif.latitude', false) &&
                        _.get(generalDetail, 'exif.longitude', false) ? (
                          <div className="location container" key={i}>
                            <AppPhotoLocation
                              location={[
                                _.get(generalDetail, 'exif.latitude'),
                                _.get(generalDetail, 'exif.longitude'),
                              ]}
                            />
                          </div>
                        ) : (
                          <></>
                        )}
                      </TabPanel>
                    );
                  case PhotoDetailTab.comment:
                    return (
                      <TabPanel value={value} index={i} key={i}>
                        <div className="comment container" key={i}>
                          <AppPhotoComment
                            comments={
                              _.get(generalDetail, 'comments', []) as Pick<
                                Photo,
                                'comments'
                              >
                            }
                            handlePhotosAddComment={handlePhotosAddComment}
                          />
                        </div>
                      </TabPanel>
                    );
                  default:
                    return null;
                }
              })}
            </div>
          </CardContent>
        </Card>
      </div>
      <AppPhotoZoom
        photoId={photoZoomId}
        photoListData={photoListData}
        onClose={() => onPhotoZoom()}
      />
    </div>
  );
};

export default AppPhotoDetailLayout;

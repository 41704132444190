// import {useSnackbar} from 'notistack';
import _ from 'lodash';
import {useSnackbar} from 'notistack';
import React, {useEffect} from 'react';
import {useState} from 'react';
import useAppContext from '../../../hooks/useAppContext';
import useServiceConfig from '../../../hooks/useServiceConfig';
import {
  deletePhotos,
  deletePhotosTags,
  Photo,
  updatePhotos,
  updatePhotosComment,
  updatePhotosTags,
  UploadPhoto,
} from '../../../services/photo';
import AppPhotoDetailLayout from './AppPhotoDetailLayout';

interface AppPhotoDetailProps {
  photoListData: Photo[];
  photo?: Photo;
  onPhotoDetailUpdate: (photo: Photo) => void;
  onPhotoDeleted: (photoId: number) => void;
}

const AppPhotoDetail: React.FC<AppPhotoDetailProps> = ({
  photo,
  photoListData,
  onPhotoDetailUpdate,
  onPhotoDeleted,
}) => {
  const appContext = useAppContext();

  const [dialogOpen, setDialogOpen] = useState(false);
  const [generalDetail, setGeneralDetail] = useState<Partial<Photo>>({});
  const {enqueueSnackbar} = useSnackbar();
  const serviceConfig = useServiceConfig();

  const [photoZoomId, setPhotoZoomId] = useState<number>();

  const [addToLightboxModalOpen, setAddToLightboxModalOpen] = useState(false);

  const handlePhotoZoom = (id?: number) => {
    setPhotoZoomId(id);
  };
  const handlePhotoDetailUpdate = (detail: Partial<Photo>) => {
    if (photo)
      updatePhotos(
        photo?.id,
        {
          location: detail?.location,
          caption: detail?.caption,
          photoType: detail?.photoType,
          keyPhoto: detail?.keyPhoto,
        },
        serviceConfig
      ).then((photo: Photo) => {
        setDialogOpen(false);
        enqueueSnackbar(`Photo ${photo?.filename} update successful`, {
          variant: 'success',
        });
        if (photo?.id) {
          onPhotoDetailUpdate(photo);
        }
      });
  };

  const handlePhotoDelete = () => {
    deletePhotos(photo !== undefined ? photo?.id : 1, serviceConfig).then(
      (res: any) => {
        setDialogOpen(false);
        enqueueSnackbar(`Photo ${photo?.filename} delete successful`, {
          variant: 'success',
        });
        onPhotoDeleted(photo !== undefined ? photo?.id : 1);
      }
    );
  };
  const handlePhotoUserTagsUpdate = (
    userTags: Pick<UploadPhoto, 'userTags'>,
    actionType: string
  ) => {
    switch (actionType) {
      case 'update':
        updatePhotosTags(
          photo !== undefined ? photo?.id : 1,
          userTags,
          serviceConfig
        ).then((tags: any) => {
          enqueueSnackbar(`Photo ${photo?.filename} update tags successful`, {
            variant: 'success',
          });
          if (photo?.id) {
            onPhotoDetailUpdate({...photo, tags});
          }
        });
        break;

      case 'delete':
        let postData = _.get(userTags, [0]);
        deletePhotosTags(
          photo !== undefined ? photo?.id : 1,
          postData as number,
          serviceConfig
        ).then((tags: any) => {
          enqueueSnackbar(`Photo ${photo?.filename} delete tags successful`, {
            variant: 'success',
          });
          if (photo?.id) {
            onPhotoDetailUpdate({...photo, tags});
          }
        });
        break;
    }
  };

  const handlePhotosAddComment = (comment: string) => {
    updatePhotosComment(
      photo !== undefined ? photo?.id : 1,
      comment,
      serviceConfig
    ).then((comments: any) => {
      enqueueSnackbar(`Photo ${photo?.filename} add comment successful`, {
        variant: 'success',
      });
      if (photo?.id) {
        onPhotoDetailUpdate({
          ...photo,
          ...{comments: [..._.get(photo, 'comments', []), comments]},
        });
      }
    });
  };

  const canUseLightbox = () => {
    if (appContext.hasUserAccessRight('lightbox-create', 'album')) return true;
    if (appContext.hasUserAccessRight('lightbox-edit', 'album')) return true;

    const lightboxOptions = (appContext.activeAlbumLightboxes || []).filter(
      (lightbox) =>
        appContext.hasUserAccessRight('lightbox-edit', 'lightbox', lightbox.id)
    );

    if (lightboxOptions.length > 0) return true;
    return false;
  };

  useEffect(() => {
    if (photo) setGeneralDetail(photo);
    return () => {
      setGeneralDetail({});
    };
  }, [photo]);
  return (
    <AppPhotoDetailLayout
      photoId={photo?.id}
      photoZoomId={photoZoomId}
      photoListData={photoListData}
      generalDetail={generalDetail}
      handlePhotoUserTagsUpdate={handlePhotoUserTagsUpdate}
      onPhotoZoom={handlePhotoZoom}
      handlePhotoDelete={handlePhotoDelete}
      handlePhotoDetailUpdate={handlePhotoDetailUpdate}
      handlePhotosAddComment={handlePhotosAddComment}
      setDialogOpen={setDialogOpen}
      dialogOpen={dialogOpen}
      addToLightboxModalOpen={addToLightboxModalOpen}
      onAddToLightboxModalToggle={
        canUseLightbox() ? setAddToLightboxModalOpen : undefined
      }
    />
  );
};

export default AppPhotoDetail;

import {createStyles, makeStyles} from '@material-ui/core';
import React from 'react';

import NxpHeader from '../NxpHeader';
import {useTranslation} from 'react-i18next';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      margin: theme.spacing(3),
    },
  })
);

interface Nxp404Props {
  systemSupportEmail?: string;
}

const Nxp404: React.FC<Nxp404Props> = ({systemSupportEmail}) => {
  const classes = useStyles();
  const {t: tran} = useTranslation();
  return (
    <div className={classes.root}>
      <NxpHeader titleContent="404" />
      <p>
        {tran('error.pageNotFound')}{' '}
        {systemSupportEmail ? (
          <a
            href={`mailto:${systemSupportEmail}`}
            target="_blank"
            rel="noreferrer"
          >
            {tran('systemSupport')}
          </a>
        ) : (
          'system support'
        )}{' '}
        {tran('lookingFor')}
      </p>
    </div>
  );
};

export default Nxp404;

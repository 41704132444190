import React from 'react';
import {NxpButton} from '../NxpButton';
import {NxpDialog} from '../NxpDialog';
import {NxpFormGrid} from '../NxpFormGrid';
import {AddRoleFormGridState} from './AppAddRoleModalContainer';
import useAppContext from '../../hooks/useAppContext';
import {useTranslation} from 'react-i18next';
interface AddRoleModalLayoutProps {
  open: boolean;
  formGridState: AddRoleFormGridState;
  onFormGridStateChange: (fieldName: string, value: unknown) => void;
  onSave: () => void;
  onDismiss: () => void;
}

const AddRoleModalLayout: React.FC<AddRoleModalLayoutProps> = ({
  open,
  formGridState,
  onFormGridStateChange,
  onSave,
  onDismiss,
}) => {
  const appContext = useAppContext();
  const {t: tran} = useTranslation();
  let userItems = [] as {name: string; value: string}[];

  if (appContext.userList.length) {
    userItems = appContext.userList.map((user) => ({
      name: user.name,
      value: user.id,
    }));
  }

  return (
    <NxpDialog
      maxWidth="sm"
      fullWidth
      open={open}
      titleContent={`${tran('common.add')} ${tran('role')}`}
      actionContent={
        <>
          <NxpButton variant="text" onClick={onDismiss}>
            {tran('common.cancel')}
          </NxpButton>
          <NxpButton onClick={onSave}>{tran('common.save')}</NxpButton>
        </>
      }
      onClose={onDismiss}
      closeByClickOutside
    >
      <NxpFormGrid
        editing={true}
        formState={formGridState}
        onFormStateChange={onFormGridStateChange}
        fields={[
          {
            itemColumnSpan: 1,
            fieldName: 'name',
            fieldType: 'text',
            onFormStateChange: onFormGridStateChange,
            label: `${tran('role')} ${tran('common.name')}`,
            fullWidth: true,
            required: true,
          },
          {
            itemColumnSpan: 1,
            fieldName: 'code',
            fieldType: 'text',
            onFormStateChange: onFormGridStateChange,
            label: `${tran('role')} ${tran('code')}`,
            fullWidth: true,
            required: true,
          },
          {
            itemColumnSpan: 2,
            fieldName: 'description',
            fieldType: 'text',
            onFormStateChange: onFormGridStateChange,
            label: tran('description'),
            fullWidth: true,
          },
          {
            itemColumnSpan: 2,
            fieldName: 'userIds',
            fieldType: 'multi-chip',
            multiple: true,
            onFormStateChange: onFormGridStateChange,
            label: tran('common.user'),
            fullWidth: true,
            selectItems: userItems,
          },
        ]}
        gridColumnCount={2}
      />
    </NxpDialog>
  );
};

export default AddRoleModalLayout;

import {AccessRightList} from '../pages/ModuleRoleAccessRightSettings/moduleRoleAccessRightSettingsReducer';
import {apiRequest} from '../utils/backend';
import {ServiceConfig} from '../utils/backend/apiRequest';

export interface ModuleRole {
  id: number;
  name: string;
  code: string;
  accessRights: {accessRightType: string}[];
}

export const getModuleRoles = async (
  serviceConfig: ServiceConfig
): Promise<ModuleRole[]> => {
  const getModuleRolesApiResponse = await apiRequest<ModuleRole[]>(
    `/module-roles`,
    serviceConfig
  );
  return getModuleRolesApiResponse;
};

export const saveModuleRoleAccessRights = async (
  moduleRoleId: number,
  accessRightList: AccessRightList,
  serviceConfig: ServiceConfig
): Promise<ModuleRole> => {
  return await apiRequest<ModuleRole>(
    `/module-roles/${moduleRoleId}/access-rights`,
    serviceConfig,
    {
      method: 'PATCH',
      body: JSON.stringify({
        accessRights: (
          Object.keys(accessRightList) as (keyof AccessRightList)[]
        ).filter((key) => {
          return accessRightList[key];
        }),
      }),
    }
  );
};

import _ from 'lodash';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {NxpButton} from '../NxpButton';
import {NxpDialog} from '../NxpDialog';
import {NxpFormGrid} from '../NxpFormGrid';
import {EditRoleFormGridState} from './AppEditRoleModalContainer';

interface EditRoleModalLayoutProps {
  open: boolean;
  formGridState: EditRoleFormGridState;
  onFormGridStateChange: (fieldName: string, value: unknown) => void;
  onSave: () => void;
  onDismiss: () => void;
}

const EditRoleModalLayout: React.FC<EditRoleModalLayoutProps> = ({
  open,
  formGridState,
  onFormGridStateChange,
  onSave,
  onDismiss,
}) => {
  const {t: tran} = useTranslation();

  return (
    <NxpDialog
      maxWidth="sm"
      fullWidth
      open={open}
      titleContent={`${_.capitalize(tran('common.edit'))} ${_.capitalize(
        tran('role')
      )}`}
      actionContent={
        <>
          <NxpButton variant="text" onClick={onDismiss}>
            {tran('common.cancel')}
          </NxpButton>
          <NxpButton onClick={onSave}> {tran('common.save')}</NxpButton>
        </>
      }
      onClose={onDismiss}
      closeByClickOutside
    >
      <NxpFormGrid
        editing={true}
        formState={formGridState}
        onFormStateChange={onFormGridStateChange}
        fields={[
          {
            itemColumnSpan: 1,
            fieldName: 'name',
            fieldType: 'text',
            onFormStateChange: onFormGridStateChange,
            label: `${tran('role')} ${tran('common.name')}`,
            fullWidth: true,
            required: true,
          },
          {
            itemColumnSpan: 1,
            fieldName: 'code',
            fieldType: 'text',
            onFormStateChange: onFormGridStateChange,
            label: `${tran('role')} ${tran('code')}`,
            fullWidth: true,
            required: true,
            editing: false,
          },
          {
            itemColumnSpan: 2,
            fieldName: 'description',
            fieldType: 'text',
            onFormStateChange: onFormGridStateChange,
            label: `${tran('description')}`,
            fullWidth: true,
          },
        ]}
        gridColumnCount={2}
      />
    </NxpDialog>
  );
};

export default EditRoleModalLayout;

import {
  ExplorerFilterTreeItem,
  ExplorerFilterViewBy,
} from '../pages/Explorer/ExplorerFilter/ExplorerFilterContainer';
import {apiRequest, ServiceConfig} from '../utils/backend';
import {searchPhotos, SearchQuery} from './photo';
import querystring from 'querystring';
import {PaginationQuery} from '../utils/typing/PaginationQuery';

export const getFilterTreeData = async (
  albumId: undefined | number,
  viewBy: ExplorerFilterViewBy,
  serviceConfig: ServiceConfig
) => {
  let query = {
    albumId,
    viewBy,
  };

  const apiResponse = await apiRequest<ExplorerFilterTreeItem[]>(
    `/explorer-filter-tree?${querystring.encode(query)}`,
    serviceConfig
  );

  return {viewBy, filterTreeItems: apiResponse};
};

export const getPhotoList = async (
  albumId: undefined | number,
  filter: {viewBy: ExplorerFilterViewBy; filterValue?: string},
  pagination: PaginationQuery,
  serviceConfig: ServiceConfig
) => {
  const query: SearchQuery = {albumId, ...pagination};
  const {viewBy, filterValue} = filter;
  if (filterValue) {
    if (viewBy === ExplorerFilterViewBy.dateTaken) {
      if (/^\d{4}$/.test(filterValue)) {
        const takenFrom = new Date();
        takenFrom.setFullYear(parseInt(filterValue), 0, 1);
        takenFrom.setHours(0, 0, 0, 0);
        query.takenFrom = takenFrom;
        const takenTo = new Date();
        takenTo.setFullYear(parseInt(filterValue) + 1, 0, 1);
        takenTo.setHours(0, 0, 0, -1);
        query.takenTo = takenTo;
      } else if (/^\d{4}-\d{2}$/.test(filterValue)) {
        const [year, month] = filterValue
          .split('-')
          .map((value) => parseInt(value));

        const takenFrom = new Date();
        takenFrom.setFullYear(year, month - 1, 1);
        takenFrom.setHours(0, 0, 0, 0);
        query.takenFrom = takenFrom;

        const takenTo = new Date();
        takenTo.setFullYear(year, month, 1);
        takenTo.setHours(0, 0, 0, -1);
        query.takenTo = takenTo;
      }
    } else if (viewBy === ExplorerFilterViewBy.uploadDate) {
      if (/^\d{4}$/.test(filterValue)) {
        const createdFrom = new Date();
        createdFrom.setFullYear(parseInt(filterValue), 0, 1);
        createdFrom.setHours(0, 0, 0, 0);
        query.createdFrom = createdFrom;
        const createdTo = new Date();
        createdTo.setFullYear(parseInt(filterValue) + 1, 0, 1);
        createdTo.setHours(0, 0, 0, -1);
        query.createdTo = createdTo;
      } else if (/^\d{4}-\d{2}$/.test(filterValue)) {
        const [year, month] = filterValue
          .split('-')
          .map((value) => parseInt(value));

        const createdFrom = new Date();
        createdFrom.setFullYear(year, month - 1, 1);
        createdFrom.setHours(0, 0, 0, 0);
        query.createdFrom = createdFrom;

        const createdTo = new Date();
        createdTo.setFullYear(year, month, 1);
        createdTo.setHours(0, 0, 0, -1);
        query.createdTo = createdTo;
      }
    } else if (viewBy === ExplorerFilterViewBy.location) {
      query.locationCode = filterValue;
      query.includeSubLocation = true;
    } else if (viewBy === ExplorerFilterViewBy.photoType) {
      query.photoTypeCode = filterValue;
    } else if (viewBy === ExplorerFilterViewBy.uploader) {
      query.uploadedBy = filterValue;
    } else if (viewBy === ExplorerFilterViewBy.tag) {
      query.tag = filterValue;
    }
  }

  return searchPhotos(query, serviceConfig);
};

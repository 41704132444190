import React, {useRef} from 'react';
import {useTranslation} from 'react-i18next';

import {
  Checkbox,
  createStyles,
  FormControl,
  Grid,
  List,
  ListItem,
  makeStyles,
  MenuItem,
  Select,
  Theme,
  Typography,
} from '@material-ui/core';
import clsx from 'clsx';
import _, {capitalize, lowerCase} from 'lodash';

import useAppContext from '../../../../hooks/useAppContext';
import {NxpSelectItem, Photo, UploadPhoto} from '../../../../services/photo';
import formatDate, {
  DateFnsFormatPattern,
} from '../../../../utils/date/formatDate';
import {generalDetailField} from '../../../../utils/typing/PhotoGeneralDetailField';
import {NxpFormFieldType} from '../../../NxpFormField';
import {NxpFormGrid} from '../../../NxpFormGrid';

interface AppPhotoGeneralDetailLayoutProps {
  form: UploadPhoto;
  isEditing: boolean;
  updateGeneralDetail: (detail: UploadPhoto) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& .Mui-selected': {
        fontSize: theme.typography.pxToRem(12),
      },
      '& .infinite-scroll-component': {
        height: ['auto', '!important'],
        maxHeight: ['200px', '!important'],
        overflow: ['auto', '!important'],
      },
      width: '100%',
      backgroundColor: theme.palette.background.paper,
      paddingTop: 0,
      paddingBottom: 0,
      '& .MuiListItem-root': {
        paddingRight: 0,
        paddingLeft: 0,
        paddingTop: 0,
        paddingBottom: theme.spacing(1),
        '& .detail_label': {
          fontWeight: theme.typography.fontWeightBold as number,
          fontSize: theme.typography.pxToRem(12),
        },
        '& .MuiGrid-item': {
          fontSize: theme.typography.pxToRem(12),
          '& .detail_value': {
            fontWeight: theme.typography.fontWeightRegular,
            fontSize: theme.typography.pxToRem(12),
          },
          color: '#3E3F42',
        },
        '&:first-child': {
          paddingTop: theme.spacing(1),
        },
      },
      '& .MuiFormControl-root': {
        width: '100%',
      },
    },
    checkbox: {
      padding: 0,
      color: 'rgba(188, 203, 203)',
      '& .MuiIconButton-root': {
        padding: 0,
      },
    },
    select: {
      minWidth: 160,
      border: `1px solid ${theme.palette.border.main}`,
      borderRadius: theme.shape.borderRadius,
      '&.Mui-error': {
        borderColor: theme.palette.error.main,
      },
      '& .MuiSelect-root': {
        height: 23,
        padding: '0 20px 0px 8px',
        fontSize: theme.typography.pxToRem(12),
        display: 'flex',
        alignItems: 'center',
      },
    },
    generalMenu: {
      fontSize: theme.typography.pxToRem(12),
    },

    treeSeclet: {
      '& .tag-list .search': {
        width: '100%',
        '&::placeholder': {
          fontSize: theme.typography.pxToRem(12),
          paddingInlineStart: theme.spacing(0.5),
          color: theme.palette.grey[800],
        },
      },
      '& .dropdown .dropdown-content': {
        width: 250,
        right: 0,
        '& .node-label': {
          fontSize: theme.typography.pxToRem(12),
        },
      },
      '&.NxpFormGrid': {
        margin: theme.spacing(0, 0),
        maxWidth: 160,
      },
      '& .MuiFormControl-root>div': {
        marginTop: 0,
      },
      '& .MuiGrid-spacing-xs-3': {
        margin: 0,
        width: '100%',

        '& .MuiGrid-item': {
          '& .dropdown-trigger': {
            paddingTop: 0,
            display: 'flex',
            alignItems: 'center',
          },
          '& .tag-item': {
            margin: theme.spacing(0, 0.4),
          },
          padding: theme.spacing(0, 0),
          '& .node-labal': {},
        },
      },
    },
    input: {
      flex: 1,
      width: 160,
      border: '0.75px solid #8A9999',
      height: 23,
      padding: '0 8px',
      borderRadius: 3,
      fontSize: theme.typography.pxToRem(12),
    },
    placeholder: {
      color: theme.palette.grey[700],
    },
  })
);

const AppPhotoGeneralDetailLayout: React.FC<AppPhotoGeneralDetailLayoutProps> =
  ({form, isEditing, updateGeneralDetail}) => {
    const appContext = useAppContext();
    const {t: tran} = useTranslation();
    const classes = useStyles();
    const captionInput = useRef<HTMLInputElement>(null);
    const phototypeinput = useRef<HTMLInputElement>(null);
    const formField: {
      key: generalDetailField | string;
      value: any;
      type: NxpFormFieldType;
      raw?: string;
      option?: NxpSelectItem[];
      fieldName: keyof Photo;
    }[] = [
      {
        key: capitalize(tran('photo.resolution')),
        value: `${form?.width} x ${form?.height}`,
        type: 'text',
        fieldName: 'resolution',
      },
      {
        key: capitalize(tran('photo.caption')),
        value: form?.caption,
        type: 'text',
        fieldName: 'caption',
      },
      {
        key: `${capitalize(tran('common.taken'))} ${lowerCase(
          tran('common.on')
        )}`,
        value: _.isDate(_.get(form, 'dateTaken'))
          ? formatDate(_.get(form, 'dateTaken'), DateFnsFormatPattern.date)
          : formatDate(
              new Date(_.get(form, 'dateTaken') || new Date().getTime()),
              DateFnsFormatPattern.date
            ),
        type: 'text',
        fieldName: 'dateTaken',
      },
      {
        key: `${capitalize(tran('common.upload'))} ${lowerCase(
          tran('common.on')
        )}`,
        value: _.isDate(_.get(form, 'createdOn'))
          ? formatDate(_.get(form, 'createdOn'), DateFnsFormatPattern.date)
          : formatDate(
              new Date(_.get(form, 'createdOn') || new Date().getTime()),
              DateFnsFormatPattern.date
            ),
        type: 'text',
        fieldName: 'dateTaken',
      },
      {
        key: `${capitalize(tran('common.upload'))} ${lowerCase(
          tran('common.by')
        )}`,
        value: _.get(form, 'uploadedBy.name'),
        type: 'text',
        fieldName: 'uploadBy',
      },
      {
        key: capitalize(tran('photo.location')),
        raw: form.location,
        value: _.get(
          _.filter(
            _.get(appContext, 'locationDictionary'),
            (v, k) => k === _.get(form, 'location', form.location)
          ),
          [0],
          ''
        ),
        type: 'select',
        option: appContext.locationOptions,
        fieldName: 'location',
      },
      {
        key: tran('photo.photoType'),
        raw: form.photoType,
        value: _.get(
          _.filter(
            _.get(appContext, 'photoTypeOptions'),
            (v, k) => v.value === _.get(form, 'photoType.code', form.photoType)
          )[0],
          'name',
          ''
        ),
        type: 'select',
        option: appContext.photoTypeOptions,
        fieldName: 'photoType',
      },
      {
        key: tran('photo.keyPhoto'),
        value: form.keyPhoto,
        type: 'checkbox',
        fieldName: 'keyPhoto',
      },
    ];
    const renderSwitch = (param: any) => {
      switch (param.fieldName) {
        case 'caption':
          return (
            <input
              className={classes.input}
              type="text"
              ref={captionInput}
              placeholder={`${_.capitalize(
                tran('common.please')
              )} ${_.capitalize(tran('common.enter'))}`}
              defaultValue={param.value}
              onChange={(event) => {
                updateGeneralDetail({
                  ...form,
                  ...{caption: _.toString(event.target.value)},
                });
              }}
            />
          );
        case 'location':
          return (
            <NxpFormGrid
              className={classes.treeSeclet}
              editing={true}
              formState={{
                location: _.get(param, 'raw'),
              }}
              onFormStateChange={(change) => change}
              fields={[
                {
                  itemColumnSpan: 1,
                  fieldName: 'location',
                  fieldType: 'tree-select',
                  onFormStateChange: (fieldName: string, value: any) => {
                    updateGeneralDetail({
                      ...form,
                      ...{location: value},
                    });
                  },
                  placeholder: `${_.capitalize(
                    tran('common.please')
                  )} ${_.capitalize(tran('common.select'))} `,
                  fullWidth: true,
                  treeSelectItems: appContext.locationOptions,
                },
              ]}
              gridColumnCount={1}
            />
          );
        case 'photoType':
          return (
            <Select
              placeholder={`${_.capitalize(
                tran('common.please')
              )} ${_.capitalize(tran('common.select'))} `}
              onChange={(event) => {
                updateGeneralDetail({
                  ...form,
                  ...{photoType: _.toString(event.target.value)},
                });
              }}
              ref={phototypeinput}
              className={clsx('NxpSelect', classes.select)}
              labelId={`${1}-label`}
              disableUnderline={true}
              value={param?.raw === null ? '' : param?.raw}
              displayEmpty
              MenuProps={{
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left',
                },
                getContentAnchorEl: null,
              }}
            >
              <MenuItem value="" disabled className={classes.placeholder}>
                {`${_.capitalize(tran('common.please'))} ${_.capitalize(
                  tran('common.select')
                )} `}
              </MenuItem>
              {param.option
                ? param.option.map((item: {value: any; name: string}) => {
                    return (
                      <MenuItem
                        key={item.value === undefined ? '' : item.value}
                        value={item.value}
                        className={classes.generalMenu}
                      >
                        {item.name}
                      </MenuItem>
                    );
                  })
                : []}
            </Select>
          );
        case 'keyPhoto':
          return (
            <Checkbox
              className={classes.checkbox}
              checked={param.value}
              color="primary"
              onChange={(event) => {
                updateGeneralDetail({
                  ...form,
                  ...{keyPhoto: event.target.checked},
                });
              }}
            />
          );
        default:
          return <Grid item>{param.value}</Grid>;
      }
    };
    return (
      <>
        <List className={classes.root}>
          <FormControl>
            {formField.map((item, i) => {
              return (
                <ListItem
                  key={i}
                  role={undefined}
                  disableGutters
                  alignItems="center"
                >
                  <Grid
                    container
                    alignItems="center"
                    justify="flex-start"
                    direction="row"
                  >
                    <Grid item xs={4} className="detail_label">
                      {_.capitalize(item.key)}
                    </Grid>
                    <Grid item>
                      {isEditing ? (
                        renderSwitch(item)
                      ) : (
                        <Typography
                          variant="inherit"
                          component="span"
                          className="detail_value"
                        >
                          {item.fieldName === 'keyPhoto'
                            ? item.value
                              ? tran('common.yes')
                              : tran('common.no')
                            : item.value?.toString()}
                        </Typography>
                      )}
                    </Grid>
                  </Grid>
                </ListItem>
              );
            })}
          </FormControl>
        </List>
      </>
    );
  };

export default AppPhotoGeneralDetailLayout;

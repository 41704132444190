import {useRef, useState} from 'react';
import {useRouteMatch} from 'react-router';

import useAppContext from '../../hooks/useAppContext';
import {getAlbum} from '../../services/album';
import {
  createMyUserPreference,
  getEntityInfo,
  getLocationTree,
  getMyUserPreference,
  getPhotoTypeList,
  getPredefinedTagList,
  getUserAccessRightList,
  getUserList,
  Location,
} from '../../services/app';
import {getLightboxList} from '../../services/lightbox';

// for setting album in app context based on route param
const useRouteAppContextUpdate = () => {
  const appContext = useAppContext();
  const loadingLanguage = useRef(false);

  let entityId: string | undefined = undefined;
  {
    const route = useRouteMatch<{entityId: string}>('/entities/:entityId');
    entityId = route?.params.entityId;
  }

  const route = useRouteMatch<{entityId: string; albumId: string}>(
    '/entities/:entityId/albums/:albumId'
  );

  // for avoiding duplicated api call
  const [activeAlbumLoading, setActiveAlbumLoading] = useState(false);

  if (route?.params.albumId && appContext.serviceConfig.token) {
    const albumId = Number(route?.params.albumId);
    if (Number.isFinite(albumId)) {
      // get only when album id is not the same as active album such as if the page is first loaded
      if (!activeAlbumLoading && albumId !== appContext.activeAlbum?.id) {
        setActiveAlbumLoading(true);
        getAlbum(albumId, appContext.serviceConfig)
          .then((data) => {
            appContext.onAppContextCacheItemUpdate('activeAlbum', data);
            return getLightboxList(albumId, appContext.serviceConfig);
          })
          .then((data) => {
            setActiveAlbumLoading(false);
            appContext.onAppContextCacheItemUpdate(
              'activeAlbumLightboxes',
              data
            );
          })
          .catch((ex) => {
            appContext.errorHandler(ex as Error, `getting album[#${albumId}]`);
          });
      }
    } else {
      appContext.enqueueSnackbar('Album ID not in correct format.', {
        variant: 'error',
      });
    }
  }

  if (entityId && appContext.serviceConfig.token) {
    if (appContext.currentUser && !loadingLanguage.current) {
      loadingLanguage.current = true;
      getMyUserPreference(appContext.serviceConfig).then((userPreference) => {
        const languagePreference = userPreference.find(
          (u) => u.application === 'entity' && u.parameter === 'language'
        );
        if (languagePreference) {
          appContext.onAppContextCacheItemUpdate(
            'language',
            languagePreference.value
          );
        } else {
          createMyUserPreference(appContext.serviceConfig, {
            application: 'entity',
            parameter: 'language',
            value: 'en',
          }).then((newUserPreference) => {
            appContext.onAppContextCacheItemUpdate(
              'language',
              newUserPreference.value
            );
          });
        }
      });
    }

    if (appContext.entityId !== entityId) {
      appContext.onAppContextCacheItemUpdate('entityId', entityId);
      getEntityInfo(appContext.serviceConfig)
        .then((entity) =>
          appContext.onAppContextCacheItemUpdate('activeEntity', entity)
        )
        .catch((ex) => {
          appContext.errorHandler(
            ex as Error,
            `getting entity info[#${entityId}]`
          );
        });

      getUserList(appContext.serviceConfig)
        .then((users) =>
          appContext.onAppContextCacheItemUpdate('userList', users)
        )
        .catch((ex) => {
          appContext.errorHandler(
            ex as Error,
            `getting user list[#${entityId}]`
          );
        });

      getUserAccessRightList(appContext.serviceConfig)
        .then((userAccessRightList) =>
          appContext.onUserAccessRightListUpdate(userAccessRightList)
        )
        .catch((ex) => {
          appContext.errorHandler(
            ex,
            `getting user accessRightList [#${entityId}]`
          );
        });

      getPhotoTypeList(entityId, appContext.serviceConfig)
        .then((photoTypes) =>
          appContext.onAppContextCacheItemUpdate(
            'photoTypeOptions',
            photoTypes.map((photoType) => ({
              value: photoType.code,
              name: photoType.name,
            }))
          )
        )
        .catch((ex) => {
          appContext.errorHandler(
            ex as Error,
            `getting photo type list[#${entityId}]`
          );
        });

      getPredefinedTagList(entityId, appContext.serviceConfig)
        .then((tags) =>
          appContext.onAppContextCacheItemUpdate(
            'predefinedTagOptions',
            tags.map((tag) => ({
              value: tag.id,
              name: tag.name,
            }))
          )
        )
        .catch((ex) => {
          appContext.errorHandler(
            ex,
            `getting predefined tag list[#${entityId}]`
          );
        });

      getLocationTree(entityId, appContext.serviceConfig)
        .then((locations) => {
          const {options, dictionary} = analyseLocationTree(locations);
          appContext.onAppContextCacheItemUpdate('locationOptions', options);
          appContext.onAppContextCacheItemUpdate(
            'locationDictionary',
            dictionary
          );
        })
        .catch((ex) => {
          appContext.errorHandler(
            ex as Error,
            `getting location list[#${entityId}]`
          );
        });
    }
  }
};

const analyseLocationTree: (locations: Location[]) => {
  options: any;
  dictionary: any;
} = (locations: Location[]) => {
  if (!locations) {
    return {
      options: [],
      dictionary: {},
    };
  }
  let options = [];
  let dictionary = {};
  for (const location of locations) {
    const childrenResult = analyseLocationTree(location.children);
    options.push({
      value: location.code,
      name: location.name,
      children: childrenResult.options,
    });
    dictionary = {
      ...dictionary,
      [location.code]: location.name,
      ...childrenResult.dictionary,
    };
  }

  return {
    options,
    dictionary,
  };
};

export default useRouteAppContextUpdate;

import React from 'react';
import {CircularProgress, makeStyles, Theme, useTheme} from '@material-ui/core';
import AppCardList from '../../components/AppCardList';
import {NxpButton} from '../../components/NxpButton';
import NxpButtonDeleteConfirm from '../../components/NxpButton/NxpButtonDeleteConfirm';
import NxpHeader from '../../components/NxpHeader';
import {Album} from '../../services/album';
import formatDate, {DateFnsFormatPattern} from '../../utils/date/formatDate';
import {AsyncResult} from '../../utils/typing/AsyncResult';
import AlbumEditButton from './AlbumFormModal/AlbumEditButton';
import useAppContext from '../../hooks/useAppContext';
import clsx from 'clsx';
import {useTranslation} from 'react-i18next';
import {capitalize} from 'lodash';
const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    padding: theme.spacing(3, 5),
    '& .MuiCircularProgress-root': {
      marginTop: theme.spacing(5),
      marginLeft: 'calc(50vw - 100px)',
    },
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  cardFooter: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'space-between',
    '& button.MuiButtonBase-root.MuiButton-root': {
      minWidth: 38,
      '&:not(:first-child)': {
        marginLeft: theme.spacing(1),
      },
    },

    '& .rightbuttons': {
      display: 'inline-block',
    },
  },
}));

interface AlbumsLayoutProps {
  albumsAsyncResult: AsyncResult<Album[]>;
  activeAlbumId?: number;
  onBackClick?: () => void;
  onCreateClick: () => void;
  onSelectClick: (alrbrum: Album) => void;
  onAlbumUpdated: (album: Album) => void;
  onDeleteConfirm: (albumId: number) => void;
}

const AlbumsLayout: React.FC<AlbumsLayoutProps> = ({
  albumsAsyncResult,
  activeAlbumId,
  onBackClick,
  onCreateClick,
  onSelectClick,
  onAlbumUpdated,
  onDeleteConfirm,
}) => {
  const appContext = useAppContext();
  const classes = useStyles();
  const theme = useTheme();
  const {t: tran} = useTranslation();
  return (
    <div className={classes.root}>
      <NxpHeader
        titleContent={capitalize(tran('album'))}
        onBackClick={onBackClick}
      />
      {albumsAsyncResult.loading || !albumsAsyncResult.data ? (
        <CircularProgress size={theme.dimension.circularProgressSize.main} />
      ) : (
        <AppCardList
          onCreateClick={
            appContext.hasUserAccessRight('album-create')
              ? onCreateClick
              : undefined
          }
          createText={`${tran('common.create')} ${tran('album')}`}
          cards={albumsAsyncResult.data.map((album) => ({
            className: classes.card,
            cardTitle: `${tran('album')} ${tran('card')}`,
            key: album.id.toString(),
            imageUrl: album.thumbnailUrl,
            descriptionContent: `${
              album.photoCount ? album.photoCount : 0
            } ${capitalize(tran('photo'))} / ${capitalize(
              tran('common.updated')
            )}: ${formatDate(album.updatedOn, DateFnsFormatPattern.date)}`,
            titleContent: <>{album.name}</>,
            onCardClick: () => {
              onSelectClick(album);
            },
            footerActionContent: (
              <div className={classes.cardFooter}>
                <NxpButton
                  variant="text"
                  onClick={() => {
                    onSelectClick(album);
                  }}
                  size="small"
                  className={clsx(activeAlbumId === album.id && 'current')}
                >
                  {activeAlbumId === album.id ? (
                    <strong>{`${tran('common.current')} ${tran(
                      'album'
                    )}`}</strong>
                  ) : (
                    tran('common.select')
                  )}
                </NxpButton>
                <div className="right-buttons">
                  {appContext.hasUserAccessRight(
                    'album-edit',
                    'album',
                    album.id
                  ) && (
                    <AlbumEditButton
                      album={album}
                      onAlbumUpdated={onAlbumUpdated}
                    />
                  )}
                  {appContext.hasUserAccessRight(
                    'album-delete',
                    'album',
                    album.id
                  ) &&
                    album.id !== activeAlbumId && (
                      <NxpButtonDeleteConfirm
                        onConfirm={() => onDeleteConfirm(album.id)}
                        size="small"
                        variant="text"
                      />
                    )}
                </div>
              </div>
            ),
          }))}
        />
      )}
    </div>
  );
};

export default AlbumsLayout;

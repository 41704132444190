import React, {useEffect, useState} from 'react';

import {Fade, makeStyles} from '@material-ui/core';
import {NxpButton} from '../NxpButton';
import {GetApp as GetAppIcon, Print as PrintIcon} from '@material-ui/icons';
import {Photo} from '../../services/photo';
import AddToLightboxButton from './AddToLightbox/AddToLightboxButton';
import useAppContext from '../../hooks/useAppContext';
import {useTranslation} from 'react-i18next';
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'inline-block',
    marginLeft: theme.spacing(2),
  },
}));

interface AppSelectedPhotoActionsLayoutProps {
  hideAddToLightbox?: boolean;
  photoList: Photo[];
  selectedPhotos: number[];
  onDownloadClick: () => void;
  onPrintClick: () => void;
  onPhotoSelectAll: () => void;
}

const AppSelectedPhotoActionsLayout: React.FC<AppSelectedPhotoActionsLayoutProps> =
  ({
    hideAddToLightbox,
    photoList,
    selectedPhotos,
    onDownloadClick,
    onPrintClick,
    onPhotoSelectAll,
  }) => {
    const classes = useStyles();
    const appContext = useAppContext();
    const {t: tran} = useTranslation();

    const [hideSelectAll, setHideSelectAll] = useState<boolean | undefined>(
      true
    );

    // added to stop the select all button from blinking when user select filter tree item, difference can be seen by replacing {!hideSelectAll && (... with {photoList.length >0 && (...
    useEffect(() => {
      if (photoList.length) {
        setHideSelectAll(false);
      } else if (!hideSelectAll && photoList.length === 0) {
        setHideSelectAll(undefined);
        setTimeout(
          () =>
            setHideSelectAll((prevState) => {
              if (prevState === undefined) {
                return true;
              }
              return prevState;
            }),
          1500
        );
      }
    }, [photoList.length, hideSelectAll]);

    return (
      <div className={classes.root}>
        {selectedPhotos.length > 0 && (
          <Fade in={selectedPhotos.length > 0}>
            {/*  fade can only work with real element (eg span) and not react fragment   */}
            <span>
              {selectedPhotos.length > 0 && (
                <>
                  {hideAddToLightbox || (
                    <AddToLightboxButton
                      selectedPhotos={selectedPhotos}
                      onAddToLightboxCompleted={onPhotoSelectAll}
                    />
                  )}
                  {appContext.hasUserAccessRight('photo-download', 'album') && (
                    <NxpButton
                      onClick={onDownloadClick}
                      title={tran('common.download')}
                    >
                      <GetAppIcon />
                    </NxpButton>
                  )}
                  <NxpButton
                    onClick={onPrintClick}
                    title={tran('common.print')}
                  >
                    <PrintIcon />{' '}
                  </NxpButton>
                </>
              )}
            </span>
          </Fade>
        )}
        {!hideSelectAll && (
          <NxpButton
            onClick={photoList.length ? onPhotoSelectAll : undefined}
          >{`${
            selectedPhotos.length > 0
              ? tran('common.deselect')
              : tran('common.select')
          } ${tran('common.all')}`}</NxpButton>
        )}
      </div>
    );
  };

export default AppSelectedPhotoActionsLayout;

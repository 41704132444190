import {apiRequest} from '../utils/backend';
import {ServiceConfig} from '../utils/backend/apiRequest';
import {Photo} from './photo';

export interface Album {
  id: number;
  entityId: string;
  name: string;
  photos: Photo[];
  createdOn: Date;
  updatedOn: Date;
  thumbnailUrl?: string;
  photoCount?: number;
}

export const getAlbumList = async (serviceConfig: ServiceConfig) => {
  return await apiRequest<Album[]>('/albums', serviceConfig);
};

export const getAlbum = async (id: number, serviceConfig: ServiceConfig) => {
  return await apiRequest<Album>(`/albums/${id}`, serviceConfig);
};

export interface AlbumForm extends Pick<Album, 'name'> {
  id?: number;
}

export const createAlbum = async (
  album: AlbumForm,
  serviceConfig: ServiceConfig
) => {
  return await apiRequest<Album>('/albums', serviceConfig, {
    method: 'POST',
    body: JSON.stringify({name: album.name, entityId: serviceConfig.entityId}),
  });
};

export const deleteAlbum = async (
  albumId: number,
  serviceConfig: ServiceConfig
) => {
  // backend is not returning Album at the moment
  // return await apiRequest<Album>(`/albums/${albumId}`, serviceConfig, {
  return await apiRequest(`/albums/${albumId}`, serviceConfig, {
    method: 'DELETE',
  });
};

export const updateAlbum = async (
  album: AlbumForm,
  serviceConfig: ServiceConfig
) => {
  // backend is not returning Album at the moment
  //return await apiRequest<Album>(`/albums/${album.id}`, serviceConfig, {
  return await apiRequest(`/albums/${album.id}`, serviceConfig, {
    method: 'PATCH',
    headers: {
      album: '' + album.id,
    },
    body: JSON.stringify({name: album.name}),
  });
};

import _ from 'lodash';
import {
  apiRequest,
  CustomApiErrorServerResponseData,
  ServiceConfig,
} from '../utils/backend';
import {Tag} from './tag';
import buildQueryString from '../utils/backend/buildQueryString';
import {PaginationQuery} from '../utils/typing/PaginationQuery';
import {User} from './app';
import {RecordBase} from './recordBase';
import {Album} from './album';
import {reviveJsonDateTime} from '../utils/date/jsonDateTime';
import CustomApiError from '../utils/backend/customApiError';
import fetchWithTimeout from '../utils/backend/extendFetchApiTime';
import moment from 'moment';

export interface UploadPhotoFile {
  lastModified: number;
  lastModifiedDate: Date;
  name: string;
  path: string;
  size: number;
  type: string;
  webkitRelativePath?: string;
}

interface PhotoExif {
  [key: string]: any;
}

interface PhotoComment extends Partial<RecordBase> {
  user: User;
  content: string;
}
export interface Photo {
  resolution: string;
  id: number;
  tags: Tag[];
  album: Album;
  location: string;
  fileUrl: string;
  thumbnailUrl: string;
  mediumUrl: string;
  originalUrl: string;
  filename: string;
  dateTaken: Date;
  caption: string;
  photoType: string;
  keyPhoto: boolean;
  uploadBy?: string;
  exif?: PhotoExif;
  comments?: PhotoComment[];
  width: number;
  height: number;
}
export type UploadPhoto = {
  files?: any;
  userTags?: string[];
  tagIds?: NxpSelectItem[];
  otherTags?: string;
  uploadedBy?: string;
  album?: Album;
} & Partial<
  Pick<
    Photo,
    | 'tags'
    | 'location'
    | 'caption'
    | 'dateTaken'
    | 'keyPhoto'
    | 'photoType'
    | 'width'
    | 'height'
  >
>;
export interface NxpSelectItem {
  value: string | number | undefined;
  name: string;
}
export const photoOrientationOptions: NxpSelectItem[] = [
  {value: '1', name: 'Landscape'},
  {value: '2', name: 'Portrait'},
  {value: '3', name: 'Square'},
  {value: '4', name: 'Wide / Pano'},
];

export const uploadPhotos = async (
  photo: UploadPhoto,
  serviceConfig: ServiceConfig
): Promise<Photo[]> => {
  const data = new FormData();
  let file = photo?.files !== undefined ? photo?.files : '';
  file?.forEach((file: any) => {
    data.append('files', file);
  });
  data.append('album', _.get(photo, 'album', '').toString());
  if (_.get(photo, 'caption', false)) {
    data.append('caption', _.get(photo, 'caption', false).toString());
  }
  if (_.get(photo, 'keyPhoto')) {
    data.append('keyPhoto', _.get(photo, 'keyPhoto', false).toString());
  }

  if (photo.dateTaken) {
    data.append('dateTaken', photo.dateTaken.toISOString());
  }

  if (_.get(photo, 'location', false)) {
    data.append('location', _.get(photo, 'location', '').toString());
  }
  if (_.get(photo, 'photoType', false)) {
    data.append('photoType', _.get(photo, 'photoType', '').toString());
  }
  photo?.tagIds?.forEach((tag, i) =>
    data.append(`tagIds[${i}]`, _.get(tag, 'value', false).toString())
  );
  photo?.userTags?.forEach((tag, i) =>
    data.append(`userTags[${i}]`, tag.toString())
  );

  const upload_data = {
    headers: {
      Authorization: `Bearer ${serviceConfig.token}` || '',
      entity: '' + serviceConfig.entityId,
      album: '' + photo.album,
      timezone: moment().format('Z'),
    },
    method: 'POST',
    body: data,
  };

  const url = '/photos';
  const path =
    url.startsWith('http://') || url.startsWith('https://')
      ? url
      : serviceConfig.apiBaseUrl + url;
  const response = await fetchWithTimeout(path, {
    ...upload_data,
    timeout: 3600000, // one hour
  });

  if (!response.ok) {
    let errorJson: CustomApiErrorServerResponseData | undefined = undefined;
    try {
      errorJson = await response.json();
    } catch {
      // nothing to do
    }
    throw new CustomApiError(
      url,
      upload_data,
      response.status,
      response.statusText,
      errorJson,
      undefined,
      0,
      undefined,
      true // stop retry for defined api server error
    );
  } else {
    const respText = await response.text();
    if (respText.trim() === '') {
      return null as any;
    }
    const photos = JSON.parse(respText, reviveJsonDateTime) as Photo[];
    return photos;
  }
};

export const deletePhotos = async (
  id: number,
  serviceConfig: ServiceConfig
) => {
  return await apiRequest<any>(`/photos/${id}`, serviceConfig, {
    method: 'DELETE',
  });
};

export const updatePhotos = async (
  id: number,
  photo: Partial<Photo>,
  serviceConfig: ServiceConfig
) => {
  return await apiRequest<any>(`/photos/${id}`, serviceConfig, {
    method: 'PATCH',
    body: JSON.stringify(photo),
  }).then((response) => response);
};

export const updatePhotosTags = async (
  photoId: number,
  userTags: Pick<UploadPhoto, 'userTags'>,
  serviceConfig: ServiceConfig
) => {
  return await apiRequest<any>(`/photos/${photoId}/tags`, serviceConfig, {
    method: 'PUT',
    body: JSON.stringify({
      userTags,
    }),
  }).then((response) => response);
};

export const deletePhotosTags = async (
  photoId: number,
  tagId: number,
  serviceConfig: ServiceConfig
) => {
  return await apiRequest<any>(
    `/photos/${photoId}/tags/${tagId}`,
    serviceConfig,
    {
      method: 'DELETE',
    }
  );
};
export const updatePhotosComment = async (
  id: number,
  comment: string,
  serviceConfig: ServiceConfig
) => {
  return await apiRequest<any>(`/photos/${id}/comments`, serviceConfig, {
    method: 'POST',
    body: JSON.stringify({
      content: comment,
    }),
  }).then((response) => response);
};

export interface SearchQuery
  extends Partial<{
      albumId: number;
      lightboxId: number;
      takenFrom: Date;
      takenTo: Date;
      createdFrom: Date;
      createdTo: Date;
      locationCode: string;
      includeSubLocation: boolean;
      photoTypeCode: string;
      uploadedBy: string;
      keyword: string;
      keyPhoto: boolean;
      tag: string;
      orientation: string;
      keyPhotoOnly: boolean;
      top: number;
    }>,
    PaginationQuery {}

export const searchPhotos = async (
  searchQuery: SearchQuery,
  serviceConfig: ServiceConfig
) => {
  return await apiRequest<Photo[]>(
    `/photo-search?${buildQueryString(searchQuery)}`,
    serviceConfig
  );
};

export const getPhotoMap = async (
  entity: number,
  albumId: number,
  serviceConfig: ServiceConfig
): Promise<Photo[]> => {
  return await apiRequest<any>(
    `/photo-location-map?${buildQueryString({
      entity,
      albumId,
    })}`,
    serviceConfig
  );
};

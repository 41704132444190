import React from 'react';
import {IconButton, IconButtonProps, makeStyles} from '@material-ui/core';
import {
  Favorite as FavoriteIcon,
  FavoriteBorder as FavoriteBorderIcon,
} from '@material-ui/icons';
import clsx from 'clsx';

import {Lightbox} from '../../../services/lightbox';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: 'rgba(0,0,0,.15)',
    color: theme.palette.common.white,
    '& .on': {
      color: '#ff0000',
    },
  },
}));

interface LightboxFavoriteButtonLayoutProps extends IconButtonProps {
  lightbox: Lightbox;
  onFavoriteClick: () => void;
}

const LightboxFavoriteButtonLayout: React.FC<LightboxFavoriteButtonLayoutProps> =
  ({lightbox, onFavoriteClick, className, ...restProps}) => {
    const classes = useStyles();

    return (
      <IconButton
        aria-label="Toggle Favorite"
        onClick={(e) => {
          e.stopPropagation();
          onFavoriteClick();
        }}
        className={clsx(classes.root, className)}
        {...restProps}
      >
        {lightbox.isFavorite ? (
          <FavoriteIcon className="on" />
        ) : (
          <FavoriteBorderIcon />
        )}
      </IconButton>
    );
  };

export default LightboxFavoriteButtonLayout;

import React from 'react';
import {IconButton, makeStyles} from '@material-ui/core';
import {
  CheckCircle,
  CheckCircleOutline,
  ThreeSixty,
  ZoomIn,
} from '@material-ui/icons';
import clsx from 'clsx';

import {Photo} from '../../services/photo';

const useStyles = makeStyles((theme) => ({
  root: {
    cursor: 'pointer',
    borderBottom: `1px solid transparent`,
    '&.active': {
      borderBottom: `1px solid ${theme.palette.primary.main}`,
    },
    '& > p': {
      margin: theme.spacing(0.5, 0),
      width: 180,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    position: 'relative',
    '& .button-overlay': {
      display: 'none',
      position: 'absolute',
      top: 0,
      width: '100%',
      height: '50%',
      '&.selected': {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        '& .photo-zoom-button': {
          display: 'none',
        },
      },
      '& .select-button-icon': {
        background: theme.palette.background.paper,
        borderRadius: '50%',
      },
    },
    '&:hover .button-overlay': {
      PointerEvent: 'none',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
      backgroundImage: 'linear-gradient(#fff, transparent)',
      '& button.photo-zoom-button': {
        display: 'inline-block',
      },
    },
  },
  photo: {
    width: 180,
    height: 150,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& > img': {
      maxHeight: 150,
      maxWidth: 180,
    },
  },
}));

interface AppPhotoListItemProps {
  photo: Photo;
  active: boolean;
  selected: boolean;
  onPhotoZoom: (photoId?: number) => void;
  onPhotoDetail: (photoId?: number) => void;
  onPhotoSelectionChange: (photoId: number) => void;
}

const AppPhotoListItem: React.FC<AppPhotoListItemProps> = ({
  photo,
  active,
  selected,
  onPhotoZoom,
  onPhotoDetail,
  onPhotoSelectionChange,
}) => {
  const classes = useStyles();

  const handlePhotoSelection = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    onPhotoSelectionChange(photo.id);
  };

  const handlePhotoZoom = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    onPhotoZoom(photo.id);
  };

  return (
    <li
      title="Photo List Item"
      className={clsx(classes.root, active && 'active')}
      onClick={() => onPhotoDetail(photo.id)}
    >
      <div className={classes.photo}>
        <img src={photo.thumbnailUrl} alt={`${photo.caption}`} />
      </div>
      <p>{photo.caption || photo.filename || `#${photo.id}`}</p>
      <div
        onClick={() => onPhotoDetail(photo.id)}
        className={clsx('button-overlay', selected && 'selected')}
      >
        <IconButton
          aria-label={
            selected ? 'Select Photo Active' : 'Select Photo Inactive'
          }
          color="primary"
          onClick={handlePhotoSelection}
        >
          {selected ? (
            <CheckCircle color="primary" className="select-button-icon" />
          ) : (
            <CheckCircleOutline
              color="disabled"
              className="select-button-icon"
            />
          )}
        </IconButton>
        <IconButton
          aria-label="Zoom-in Photo"
          color="primary"
          onClick={handlePhotoZoom}
          className="photo-zoom-button"
        >
          {photo.tags.find((tag) => tag.name === '360') ? (
            <ThreeSixty color="primary" />
          ) : (
            <ZoomIn color="primary" />
          )}
        </IconButton>
      </div>
    </li>
  );
};

export default AppPhotoListItem;

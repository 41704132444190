import React, {useEffect, useReducer, useState} from 'react';

import useAppContext from '../../hooks/useAppContext';
import {useAsync} from '../../hooks/useAsync';
import {AlbumStatistic, getAlbumStatistic} from '../../services/dashboard';
import DashboardLayout from './DashboardLayout';
import {dashboardReducer} from './dashboardReducer';

interface DashboardContainerProps {}

const DashboardContainer: React.FC<DashboardContainerProps> = () => {
  const appContext = useAppContext();

  const [dashboardState, dispatch] = useReducer(dashboardReducer, {
    dateRangeStart: null,
    dateRangeEnd: null,
    data: {
      count: 0,
      countInDateRange: 0,
      countsByLocation: [],
      countsByType: [],
      countsByTag: [],
      userDateMatrix: null,
      countsByContributor: [],
    },
  });

  const [detailedLocationOpened, setDetailedLocationOpened] = useState(false);
  const [albumId, setAlbumId] = useState(0);

  const handleDetailedLocationOpen = () => setDetailedLocationOpened(true);

  const handleDetailedLocationClose = () => setDetailedLocationOpened(false);

  const [, setGetAlbumStatisticRequest] = useAsync<AlbumStatistic>(
    () =>
      appContext?.activeAlbum?.id
        ? getAlbumStatistic(
            appContext?.activeAlbum?.id,
            dashboardState.dateRangeStart,
            dashboardState.dateRangeEnd,
            appContext.serviceConfig
          )
        : new Promise<AlbumStatistic>(() => {}),
    {
      onSuccess: (statistic: AlbumStatistic) => {
        if (statistic) {
          dispatch({
            type: 'dashboardResultLoaded',
            data: {...statistic},
          });
        }
      },
      onError: (ex) =>
        appContext.errorHandler(ex as Error, 'getting dashboard data'),
    }
  );

  useEffect(() => {
    if (
      appContext?.activeAlbum?.id &&
      appContext?.activeAlbum?.id !== albumId
    ) {
      const newRequest = () =>
        getAlbumStatistic(
          appContext?.activeAlbum?.id,
          dashboardState.dateRangeStart,
          dashboardState.dateRangeEnd,
          appContext.serviceConfig
        );
      setGetAlbumStatisticRequest(newRequest);
      setAlbumId(appContext.activeAlbum.id);
    }
  }, [
    albumId,
    appContext?.activeAlbum?.id,
    appContext.serviceConfig,
    dashboardState.dateRangeEnd,
    dashboardState.dateRangeStart,
    setGetAlbumStatisticRequest,
  ]);

  const handleDateRangeStartChange = (date: null | Date) => {
    dispatch({
      type: 'dateRangeStartChange',
      date,
    });

    const newRequest = () =>
      getAlbumStatistic(
        appContext?.activeAlbum?.id,
        date,
        dashboardState.dateRangeEnd,
        appContext.serviceConfig
      );
    setGetAlbumStatisticRequest(newRequest);
  };

  const handleDateRangeEndChange = (date: null | Date) => {
    dispatch({
      type: 'dateRangeEndChange',
      date,
    });

    const newRequest = () =>
      getAlbumStatistic(
        appContext?.activeAlbum?.id,
        dashboardState.dateRangeStart,
        date,
        appContext.serviceConfig
      );
    setGetAlbumStatisticRequest(newRequest);
  };

  return (
    <DashboardLayout
      count={dashboardState.data.count}
      countInDateRange={dashboardState.data.countInDateRange}
      countsByLocation={dashboardState.data.countsByLocation}
      countsByType={dashboardState.data.countsByType}
      countsByTag={dashboardState.data.countsByTag}
      userDateMatrix={dashboardState.data.userDateMatrix}
      countsByContributor={dashboardState.data.countsByContributor}
      dateRangeStart={dashboardState.dateRangeStart}
      dateRangeEnd={dashboardState.dateRangeEnd}
      detailedLocationOpened={detailedLocationOpened}
      onDateRangeStartChange={handleDateRangeStartChange}
      onDateRangeEndChange={handleDateRangeEndChange}
      onDetailedLocationOpen={handleDetailedLocationOpen}
      onDetailedLocationClose={handleDetailedLocationClose}
    />
  );
};

export default DashboardContainer;

import React from 'react';

import {makeStyles} from '@material-ui/core';
import {Tag} from '../../../services/tag';
import NxpHeader from '../../../components/NxpHeader';
import {NxpButton} from '../../../components/NxpButton';
import {SortOrder} from '../../../utils/typing/SortOrder';
import TagListItem from './TagListItem';
import {useTranslation} from 'react-i18next';
import {capitalize} from 'lodash';
const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 5),
    '& .tag-list': {
      marginTop: theme.spacing(3),
    },
  },
}));

interface TagsLayoutProps {
  tags: Tag[];
  form?: Tag;
  onCreateClick: () => void;
  onChange: (tag: Tag) => void;
  onEdit: (tagId: number) => void;
  onDelete: () => void;
  onCancel: () => void;
  onSave: () => void;
  onSort: (order: SortOrder) => void;
}

const TagsLayout: React.FC<TagsLayoutProps> = ({
  tags,
  form,
  onCreateClick,
  onEdit,
  onCancel,
  onChange,
  onDelete,
  onSave,
  onSort,
}) => {
  const classes = useStyles();
  const {t: tran} = useTranslation();
  return (
    <div className={classes.root}>
      <NxpHeader
        titleContent="Pre-defined Tags"
        actionContent={
          <NxpButton onClick={() => onCreateClick()}>{`${capitalize(
            tran('common.tag')
          )} ${capitalize(tran('common.add'))}`}</NxpButton>
        }
      />
      <div className="tag-list">
        {tags.map((tag) => (
          <TagListItem
            tag={tag}
            form={form}
            onEdit={onEdit}
            onCancel={onCancel}
            onDelete={onDelete}
            onSave={onSave}
            onChange={onChange}
          />
        ))}
      </div>
    </div>
  );
};

export default TagsLayout;

import React from 'react';
import clsx from 'clsx';
import {Input, InputProps, makeStyles} from '@material-ui/core';

export interface NxpTextInputBaseProps extends InputProps {}

const useStyles = makeStyles((theme) => ({
  root: {
    border: `1px solid ${theme.palette.border.main}`,
    borderRadius: theme.shape.borderRadius,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    '&.Mui-error': {
      borderColor: theme.palette.error.main,
    },
  },
}));

const NxpTextInputBase = React.forwardRef<unknown, NxpTextInputBaseProps>(
  function NxpTextInputBase({className, ...restProps}, ref) {
    const classes = useStyles();
    return (
      <Input
        className={clsx('NxpTextInput', classes.root, className)}
        ref={ref}
        disableUnderline={true}
        {...restProps}
      />
    );
  }
);

export default NxpTextInputBase;

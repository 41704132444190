import React from 'react';

import ContributorCardLayout from './ContributorCardLayout';

interface ContributorCardContainerProps {
  entries?: {name: string; value: number}[];
}

const ContributorCardContainer: React.FC<ContributorCardContainerProps> = (
  props
) => {
  return <ContributorCardLayout {...props} />;
};

export default ContributorCardContainer;

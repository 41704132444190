import React from 'react';
import {Photo} from '../../services/photo';
import AppSelectedPhotoActionsLayout from './AppSelectedPhotoActionsLayout';
import JSZipUtils from 'jszip-utils';
import JSZip from 'jszip';
import {saveAs} from 'file-saver';
import formatDate, {DateFnsFormatPattern} from '../../utils/date/formatDate';
import saveAsUrl from '../../utils/saveAsUrl';

interface AppSelectedPhotoActionsContainerProps {
  photoList: Photo[];
  selectedPhotos: number[];
  hideAddToLightbox?: boolean;
  onPhotoSelectAll: () => void;
}

const getUnduplicatedFilenames = (
  photoFilename: string,
  unduplicateFilenames: string[]
) => {
  let filename = photoFilename;
  let renameSeed = 2;
  while (unduplicateFilenames.includes(filename)) {
    filename = photoFilename;
    if (filename.includes('.')) {
      const nameArray = filename.split('.');
      const renameArray = [
        ...nameArray.slice(0, nameArray.length - 2),
        `${nameArray.slice(-2, -1)}-${renameSeed}`,
        nameArray.slice(-1),
      ];
      filename = renameArray.join('.');
    } else {
      filename += `-${renameSeed}`;
    }
    renameSeed++;
  }
  unduplicateFilenames.push(filename);
  return filename;
};

const AppSelectedPhotoActionsContainer: React.FC<AppSelectedPhotoActionsContainerProps> =
  ({photoList, selectedPhotos, hideAddToLightbox, onPhotoSelectAll}) => {
    const handleDownloadClick = () => {
      if (selectedPhotos.length === 1) {
        const photo = photoList.find((item) => item.id === selectedPhotos[0])!;
        saveAsUrl(photo.fileUrl, photo.filename);
      } else {
        const zip = new JSZip();
        let count = 0;
        const unduplicatedFilenames: string[] = [];
        selectedPhotos.forEach((photoId, idx) => {
          const photo = photoList.find((item) => item.id === photoId)!;
          JSZipUtils.getBinaryContent(
            photo.originalUrl,
            function (err: Error, data: any) {
              if (err) {
                throw err; // or handle the error
              }
              zip.file(
                getUnduplicatedFilenames(
                  photo.filename,
                  unduplicatedFilenames
                ) || `${photoId}`,
                data,
                {binary: true}
              );
              count++;
              if (count === selectedPhotos.length) {
                zip.generateAsync({type: 'blob'}).then(function (content) {
                  saveAs(
                    content,
                    `Nexplore-Fotolio-${formatDate(
                      new Date(),
                      DateFnsFormatPattern.date
                    )}.zip`
                  );
                });
              }
            }
          );
        });
      }
    };

    const handlePrintClick = () => {
      // https://stackoverflow.com/a/48510614/8252769
      const w = window.open('', '')!;
      w.document.write('<html><head>');
      w.document.write(
        '<style>table {font-family: "Roboto", "Helvetica", "Arial", sans-serif; width: 1000px; margin:0 auto;} '
      );
      w.document.write(
        'th {width: 300px; text-align: left; padding-bottom: 30px;} th:last-child {text-align: right; color: rgb(0, 87, 148)} '
      );
      w.document.write(
        'td {text-align: center; width: 300px; height: 250px; vertical-align: bottom; padding-bottom: 30px;} '
      );
      w.document.write('p {margin: 5px 0 0; font-size: 10px} ');
      w.document.write('img { max-width: 300px; max-height: 250px');
      // w.document.write('page-break-before: auto;');
      // w.document.write('page-break-after: auto;');
      // w.document.write('page-break-inside: avoid;');
      w.document.write('}</style>');

      // print only when all loaded
      w.document.write(
        `<script> var imgCount = ${selectedPhotos.length}, imgLoaded = 0; `
      );
      w.document.write('function onImgLoaded(){');
      w.document.write('imgLoaded += 1;');
      w.document.write('if (imgLoaded === imgCount){');
      w.document.write(
        'window.focus(); window.print(); window.document.close(); window.close();'
      );
      w.document.write('}');
      w.document.write('} </script>');
      w.document.write(
        '</head><body><table><tr><th>Print Output</th><th></th><th>Fotolio</th></tr>'
      );

      // add the images
      selectedPhotos.forEach((photoId, idx) => {
        const photo = photoList.find((item) => item.id === photoId);
        if (photo) {
          w.document.write(
            `${
              idx % 3 === 0 ? '<tr>' : ''
            }<td><img id="print-image-element" src="${
              photo.fileUrl
            }"/><p>Photo ID: ${photo.id
              .toString()
              .padStart(4, '0')}</p><p>Taken on: ${formatDate(
              photo.dateTaken,
              DateFnsFormatPattern.date
            )}</p></td>${idx % 3 === 2 ? '</tr>' : ''}`
          );
        }
      });

      // add the image onLoad handlers
      w.document.write(
        '</table> <script>var imgs = document.getElementsByTagName("img");'
      );
      w.document.write('for (i = 0; i < imgs.length; i++) { ');
      w.document.write(
        'imgs[i].addEventListener("load",function(){ onImgLoaded(); });'
      );
      w.document.write('};');

      w.document.write('</script>');
      w.document.write('</body></html>');
    };

    return (
      <AppSelectedPhotoActionsLayout
        hideAddToLightbox={hideAddToLightbox}
        photoList={photoList}
        selectedPhotos={selectedPhotos}
        onDownloadClick={handleDownloadClick}
        onPrintClick={handlePrintClick}
        onPhotoSelectAll={onPhotoSelectAll}
      />
    );
  };

export default AppSelectedPhotoActionsContainer;

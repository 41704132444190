import React from 'react';
import {ArrowRight, ArrowDropDown} from '@material-ui/icons';
import {TreeItem, TreeView} from '@material-ui/lab';
import {
  makeStyles,
  MenuItem,
  CircularProgress,
  useTheme,
} from '@material-ui/core';
import NxpLabel from '../../../components/NxpLabel';
import {NxpSelectBase} from '../../../components/NxpSelect';
import {
  ExplorerFilterTree,
  ExplorerFilterTreeItem,
  ExplorerFilterViewBy,
} from './ExplorerFilterContainer';
import {useTranslation} from 'react-i18next';
import {capitalize} from 'lodash';
const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 2, 0, 2),
    borderRight: `1px solid ${theme.palette.border.light}`,
    backgroundColor: theme.palette.background.paper,
    minWidth: 275,
    '& > div': {
      position: 'sticky',
      top: 116,
      '& > ul': {
        height: 'calc(100vh - 168px)',
        overflow: 'auto',
      },
    },
  },
  viewBy: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
    '& label': {
      whiteSpace: 'nowrap',
    },
    '& .NxpSelect ': {
      width: 180,
      marginLeft: theme.spacing(1),
    },
  },
  tree: {
    '& .MuiSvgIcon-root': {
      color: theme.palette.primary.main,
    },
  },
}));

const getTreeViewItems = (items: ExplorerFilterTreeItem[]) => {
  return items.map((item) => (
    <TreeItem key={item.nodeId} nodeId={item.nodeId} label={item.label}>
      {item.items?.length && getTreeViewItems(item.items)}
    </TreeItem>
  ));
};

interface ExplorerFilterLayoutProps {
  loadingTreeData: boolean;
  viewBy: ExplorerFilterViewBy;
  filterTree?: ExplorerFilterTree;
  onViewByChange: (value: ExplorerFilterViewBy) => void;
  onFilterTreeSelect: (event: React.ChangeEvent<{}>, nodeIds: string[]) => void;
  onFilterTreeToggle: (event: React.ChangeEvent<{}>, nodeIds: string[]) => void;
}

const ExplorerFilterLayout: React.FC<ExplorerFilterLayoutProps> = ({
  loadingTreeData,
  viewBy,
  filterTree = {
    viewBy: '',
    items: [],
    expandedNodeIds: [],
    selectedNodeIds: [],
  },
  onViewByChange,
  onFilterTreeSelect,
  onFilterTreeToggle,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const {t: tran} = useTranslation();
  const filterByDictionary = {
    dateTaken: `${capitalize(tran('common.date'))} (${capitalize(
      tran('common.taken')
    )} ${capitalize(tran('common.on'))})`,
    uploadDate: `${tran('common.date')} (${capitalize(
      tran('common.upload')
    )} ${capitalize(tran('common.on'))})`,
    location: capitalize(tran('photo.location')),
    uploader: capitalize(tran('photo.uploader')),
    type: tran('photo.photoType'),
    tag: capitalize(tran('photo.tags')),
  };
  return (
    <div className={classes.root}>
      <div>
        <div className={classes.viewBy}>
          <NxpLabel id="views-select-label">{tran('label.viewBy')}</NxpLabel>{' '}
          <NxpSelectBase
            labelId="views-select-label"
            onChange={(event) =>
              onViewByChange(event.target.value as ExplorerFilterViewBy)
            }
            value={viewBy}
          >
            {Object.entries(ExplorerFilterViewBy).map((entry) => (
              <MenuItem key={entry[1]} value={entry[1]}>
                {filterByDictionary[entry[1]]}
              </MenuItem>
            ))}
          </NxpSelectBase>
        </div>
        {loadingTreeData ? (
          <CircularProgress size={theme.dimension.circularProgressSize.small} />
        ) : (
          <TreeView
            className={classes.tree}
            defaultCollapseIcon={<ArrowDropDown />}
            defaultExpandIcon={<ArrowRight />}
            expanded={filterTree.expandedNodeIds}
            selected={filterTree.selectedNodeIds}
            onNodeSelect={onFilterTreeSelect}
            onNodeToggle={onFilterTreeToggle}
            multiSelect
          >
            {getTreeViewItems(filterTree.items)}
          </TreeView>
        )}
      </div>
    </div>
  );
};

export default ExplorerFilterLayout;

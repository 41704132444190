import React from 'react';
import {renderToStaticMarkup as toHtmlMarkup} from 'react-dom/server';
import {useTranslation} from 'react-i18next';
import {MapContainer, Marker, TileLayer} from 'react-leaflet';

import {
  createStyles,
  Divider,
  Grid,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import RoomIcon from '@material-ui/icons/Room';
import {divIcon, LatLngExpression} from 'leaflet';
import _ from 'lodash';

import 'leaflet/dist/leaflet.css';

interface AppPhotoLocationProps {
  location: LatLngExpression;
}
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    map: {
      height: 180,
      width: 250,
      margin: theme.spacing(1, 0),
    },
  })
);
const AppPhotoLocation: React.FC<AppPhotoLocationProps> = ({location}) => {
  const classes = useStyles();
  const {t: tran} = useTranslation();
  const iconMarkup = toHtmlMarkup(<RoomIcon color="primary" />);
  const customMarkerIcon = divIcon({
    html: iconMarkup,
    className: 'marker',
    iconSize: [40, 40],
  });

  return (
    <>
      <Grid container alignItems="center" className="photo-location">
        <Grid item xs={4}>
          <Typography variant="h1" component="h2">
            {_.capitalize(tran('photo.location'))}
          </Typography>
        </Grid>
      </Grid>
      <Divider />
      <MapContainer
        className={classes.map}
        center={location}
        zoom={13}
        scrollWheelZoom={false}
        attributionControl={false}
      >
        <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
        <Marker position={location} icon={customMarkerIcon}></Marker>
      </MapContainer>
    </>
  );
};
export default AppPhotoLocation;

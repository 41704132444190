import React from 'react';
import {useTranslation} from 'react-i18next';

import {Box, Grid, makeStyles} from '@material-ui/core';
import _ from 'lodash';

import AccessRightGroupPanel from '../../components/AppAccessRightGroupPanel';
import AppRoleMenu from '../../components/AppRoleMenu';
import {NxpButton} from '../../components/NxpButton';
import NxpHeader from '../../components/NxpHeader';
import useAppContext from '../../hooks/useAppContext';
import {ModuleRole} from '../../services/moduleRoleAccessRight';
import {ModuleRoleAccessRightItems} from './ModuleRoleAccessRightSettingsContainer';
import {AccessRightList} from './moduleRoleAccessRightSettingsReducer';

// import ManageInheritedRolesButton from './ManageInheritedRoles/ManageInheritedRolesButton';

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: 'calc(100vh - 85px)',
  },
  result: {
    flexGrow: 1,
    padding: theme.spacing(3, 5),
  },
  header: {
    marginLeft: 50,
    fontSize: theme.typography.pxToRem(10),
    fontWeight: theme.typography.fontWeightBold as number,
  },
}));

interface ModuleRoleAccessRightSettingsLayoutProps {
  moduleRoles: ModuleRole[];
  selectedModuleRole: null | ModuleRole;
  accessRightList: AccessRightList;
  onAccessRightCheck: (value: string, checked: boolean) => void;
  onModuleRoleSelect: (moduleRoleId: number) => void;
  onSaveButton: () => void;
  moduleRoleAccessRightItems: ModuleRoleAccessRightItems;
}

const ModuleRoleAccessRightSettingsLayout: React.FC<ModuleRoleAccessRightSettingsLayoutProps> =
  ({
    moduleRoles,
    selectedModuleRole,
    accessRightList,
    onAccessRightCheck,
    onModuleRoleSelect,
    onSaveButton,
    moduleRoleAccessRightItems,
  }) => {
    const classes = useStyles();
    const appContext = useAppContext();
    const {t: tran} = useTranslation();
    return (
      <div className={classes.root}>
        <div className={classes.result}>
          <NxpHeader
            titleContent="Access Right (Module)"
            actionContent={
              appContext.hasUserAccessRight('access-right-assign') && (
                <NxpButton color="primary" onClick={onSaveButton}>
                  {tran('common.save')}
                </NxpButton>
              )
            }
          />
          <Grid container spacing={3} justify="flex-end">
            <Grid item xs={9}>
              <Box
                flexDirection="row"
                className={classes.header}
                display="flex"
              >
                <Box p={1} flexGrow={1} color="grey.600">
                  {_.capitalize(tran('common.right'))}
                </Box>
                <Box p={1} color="grey.600">
                  {_.capitalize(tran('common.allow'))}
                </Box>
              </Box>
            </Grid>
          </Grid>
          <Grid item container spacing={2} direction="row">
            <Grid item xs={3}>
              <AppRoleMenu
                roles={moduleRoles}
                selectedRole={selectedModuleRole}
                onItemClick={onModuleRoleSelect}
              />
            </Grid>
            <Grid item xs={9}>
              {selectedModuleRole && (
                <>
                  <AccessRightGroupPanel
                    title={`${_.capitalize(tran('manage'))} ${_.capitalize(
                      tran('photo')
                    )}`}
                    accessRightList={accessRightList}
                    items={moduleRoleAccessRightItems?.managePhotos}
                    onItemCheck={onAccessRightCheck}
                  />
                  <AccessRightGroupPanel
                    title={_.capitalize(tran('lightbox'))}
                    accessRightList={accessRightList}
                    items={moduleRoleAccessRightItems?.lightbox}
                    onItemCheck={onAccessRightCheck}
                  />
                  <AccessRightGroupPanel
                    title={_.capitalize(tran('album'))}
                    accessRightList={accessRightList}
                    items={moduleRoleAccessRightItems?.album}
                    onItemCheck={onAccessRightCheck}
                  />
                  <AccessRightGroupPanel
                    title={_.capitalize(tran('common.dashboard'))}
                    accessRightList={accessRightList}
                    items={moduleRoleAccessRightItems?.dashboard}
                    onItemCheck={onAccessRightCheck}
                  />
                  <AccessRightGroupPanel
                    title={`${_.capitalize(tran('manage'))} ${_.capitalize(
                      tran('role')
                    )}`}
                    accessRightList={accessRightList}
                    items={moduleRoleAccessRightItems?.manageRoles}
                    onItemCheck={onAccessRightCheck}
                  />
                  <AccessRightGroupPanel
                    title={`${_.capitalize(
                      tran('common.access')
                    )} ${_.capitalize(tran('common.right'))}`}
                    accessRightList={accessRightList}
                    items={moduleRoleAccessRightItems?.accessRights}
                    onItemCheck={onAccessRightCheck}
                  />
                  <AccessRightGroupPanel
                    title={_.capitalize(tran('setup'))}
                    accessRightList={accessRightList}
                    items={moduleRoleAccessRightItems?.setup}
                    onItemCheck={onAccessRightCheck}
                  />
                </>
              )}
            </Grid>
          </Grid>
        </div>
      </div>
    );
  };

export default ModuleRoleAccessRightSettingsLayout;

import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useHistory} from 'react-router-dom';

import {capitalize} from 'lodash';
import * as yup from 'yup';

import useAppContext from '../../../hooks/useAppContext';
import {useAsync} from '../../../hooks/useAsync';
import {useValidate} from '../../../hooks/useValidate';
import {
  createLightbox,
  deleteLightbox,
  Lightbox,
  LightboxForm,
  updateLightbox,
} from '../../../services/lightbox';
import LightboxFormModal from './LightboxFormModal';

interface LightboxFormContainerProps {
  modalOpen: boolean;
  lightbox?: Lightbox; // undefined for record creation
  onLightboxSaved: (lightbox: Lightbox) => void;
  onLightboxDeleted?: (lightboxId: number) => void;
  onModalClose: () => void;
}

const LightboxFormContainer: React.FC<LightboxFormContainerProps> = ({
  lightbox,
  modalOpen,
  onModalClose,
  onLightboxSaved,
  onLightboxDeleted,
}) => {
  const appContext = useAppContext();
  const {t: tran} = useTranslation();
  const formSchema = yup.object().shape({
    id: yup.number(),
    name: yup
      .string()
      .nullable()
      .required(tran('app.common.validation.name.required')),
    album: yup.object().shape({id: yup.number().nullable().required()}),
  });
  const [form, setForm] = useState<LightboxForm>(
    lightbox
      ? {
          ...lightbox,
          album: {id: appContext.activeAlbum!.id},
        }
      : {
          name: '',
          album: {id: appContext.activeAlbum!.id},
        }
  );

  const [lightboxFormAsyncResult, setLightboxFormRequest] = useAsync(
    undefined,
    {
      onSuccess: (data) => {
        if (data.id) {
          onLightboxSaved(data);
          appContext.enqueueSnackbar(
            `${tran('common.lightbox')} ${tran('common.update')} ${tran(
              'common.success'
            )}`,
            {
              variant: 'success',
            }
          );
          onModalClose();
        }
      },
      onError: (ex) =>
        appContext.errorHandler(ex as Error, 'saving lightbox', form),
    }
  );

  const handleFormStateChange = (fieldName: keyof Lightbox, value: unknown) => {
    setForm((prevState) => {
      const changeForm = {...prevState, [fieldName]: value};
      return changeForm;
    });
  };

  const handleSaveValidated = async () => {
    if (form.id) {
      setLightboxFormRequest(() =>
        updateLightbox(form, appContext.serviceConfig)
      );
    } else {
      setLightboxFormRequest(() =>
        createLightbox(form, appContext.serviceConfig)
      );
    }
  };

  const history = useHistory();

  const [lightboxDeleteAsyncResult, setLightboxDeleteRequest] = useAsync(
    undefined,
    {
      onSuccess: (data) => {
        if (onLightboxDeleted) {
          onLightboxDeleted(lightbox!.id);
        }
        appContext.enqueueSnackbar(
          `${capitalize(tran('common.lightbox'))} ${capitalize(
            tran('common.delete')
          )} ${capitalize(tran('common.success'))}`,
          {
            variant: 'success',
          }
        );
        onModalClose();
        history.push('../lightboxes');
      },
      onError: (ex) =>
        appContext.errorHandler(ex as Error, 'deleting lightbox', lightbox!.id),
    }
  );

  const handleLightboxDeleted = async () => {
    setLightboxDeleteRequest(() =>
      deleteLightbox(lightbox!.id, appContext.serviceConfig)
    );
  };

  const [validationError, , , saveWithValidate] = useValidate<LightboxForm>(
    form,
    formSchema,
    handleSaveValidated
  );

  const handleSave = () => {
    saveWithValidate(undefined);
  };

  return (
    <LightboxFormModal
      modalOpen={modalOpen}
      form={form}
      formError={validationError}
      submitLoading={
        lightboxDeleteAsyncResult.loading || lightboxFormAsyncResult.loading
      }
      onFormStateChange={handleFormStateChange}
      onSave={handleSave}
      onModalClose={onModalClose}
      onDelete={handleLightboxDeleted}
    />
  );
};

export default LightboxFormContainer;

import React, {useCallback, useEffect, useMemo, useState} from 'react';

import useAppContext from '../../../hooks/useAppContext';
import {useAsync} from '../../../hooks/useAsync';
import useDebounce from '../../../hooks/useDebounce';
import {getEligibleLightboxShareeList, User} from '../../../services/app';
import {
  getLightboxShareAccess,
  Lightbox,
  LightboxShareAccess,
  updateLightboxShareAccess,
} from '../../../services/lightbox';
import ShareLightboxModal from './ShareLightboxModal';

interface ShareLightboxContainerProps {
  lightbox: Lightbox;
  modalOpen: boolean;
  onModalClose: () => void;
}

const ShareLightboxContainer: React.FC<ShareLightboxContainerProps> = ({
  lightbox,
  modalOpen,
  onModalClose,
}) => {
  const appContext = useAppContext();

  const [userOptionsAsyncResult, , setUserOptionsAsyncResult] = useAsync<
    User[]
  >(
    () =>
      getEligibleLightboxShareeList(appContext.serviceConfig).then((users) =>
        users.filter(
          (user) =>
            user.id !==
            appContext.userList.find(
              (user) => user.email === appContext.currentUser?.email
            )?.id
        )
      ),
    undefined,
    []
  );

  const [form, setForm] = useState<{userId: string; allowEdit: boolean}[]>();

  useAsync<LightboxShareAccess[]>(
    () => getLightboxShareAccess(lightbox.id, appContext.serviceConfig),
    {
      onSuccess: (data) =>
        setForm(
          data.map((access) => ({
            userId: access.user.id,
            allowEdit: access.allowEdit,
          }))
        ),
    }
  );

  const [userSearchText, setUserSearchText] = useState('');
  const debouncedUserSearchText = useDebounce(userSearchText);
  const handleUserSearch = useCallback((searchText: string) => {
    setUserSearchText(searchText);
  }, []);

  useEffect(() => {
    // may call getUserList with filter here
    // console.log('ffffffffffffffff');
    // console.log(debouncedUserSearchText);
    // below cause input search text to disappear ...
    // setUserOptionsAsyncResult((prevState) => ({
    //   ...prevState,
    //   data: [...(prevState.data || [])],
    // }));
  }, [debouncedUserSearchText, setUserOptionsAsyncResult]);

  const lightboxUpdateShareAsyncHandler = useMemo(
    () => ({
      onSuccess: (data: LightboxShareAccess[]) => {
        if (data) {
          appContext.enqueueSnackbar('Lightbox saved successfully.', {
            variant: 'success',
          });
          onModalClose();
        }
      },
      onError: (ex: Error) =>
        appContext.errorHandler(ex as Error, 'sharing lightbox', form),
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const [lightboxUpdateShareAsyncResult, setLightboxUpdateShareAsyncRequest] =
    useAsync<LightboxShareAccess[]>(undefined, lightboxUpdateShareAsyncHandler);

  const handleShareAccessChange = useCallback(
    (userIds: string[], allowEdit: boolean) => {
      setForm((prevState) => {
        const newState = (prevState || []).filter(
          (access) => access.allowEdit !== allowEdit
        );
        newState.push(...userIds.map((userId) => ({userId, allowEdit})));
        return newState;
      });
    },
    []
  );

  const handleSave = useCallback(() => {
    if (form) {
      setLightboxUpdateShareAsyncRequest(() =>
        updateLightboxShareAccess(lightbox.id, form, appContext.serviceConfig)
      );
    }
  }, [
    form,
    lightbox.id,
    appContext.serviceConfig,
    setLightboxUpdateShareAsyncRequest,
  ]);

  return (
    <ShareLightboxModal
      shareAccess={userOptionsAsyncResult.loading ? undefined : form}
      modalOpen={modalOpen}
      userOptions={userOptionsAsyncResult.data || []}
      submitLoading={lightboxUpdateShareAsyncResult.loading}
      onSave={handleSave}
      onShareAccessChange={handleShareAccessChange}
      onModalClose={onModalClose}
      onUserSearch={handleUserSearch}
    />
  );
};

export default ShareLightboxContainer;

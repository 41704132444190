import React from 'react';
import {
  Box,
  Button,
  InputAdornment,
  makeStyles,
  TextField,
} from '@material-ui/core';

import NxpHeader from '../../components/NxpHeader';
import ModuleRoleItem from './ModuleRoleItem/ModuleRoleItem';
import {ModuleRole} from '../../services/moduleRoleAssignment';
import AddRoleModal from '../../components/AppAddRoleModal';
import {AddRoleFormGridState} from '../../components/AppAddRoleModal/AppAddRoleModalContainer';
import BatchAssignModal from '../../components/AppBatchAssignModal';
import AppConfirmDialog from '../../components/AppConfirmDialog';
import EditRoleModal from '../../components/AppEditRoleModal';
import TableHead from './TableHead';
import useAppContext from '../../hooks/useAppContext';
import {Search} from '@material-ui/icons';
import {useTranslation} from 'react-i18next';
import _ from 'lodash';
const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: 'calc(100vh - 85px)',
  },
  result: {
    flexGrow: 1,
    padding: theme.spacing(3, 5),
  },
  searchBar: {
    '& .MuiOutlinedInput-input': {
      padding: '9px 14px',
    },
  },
}));

interface ModuleRoleAssignmentLayoutProps {
  moduleRoles: ModuleRole[];
  assigneeIdsByRoleId: Record<string, string[]>;
  selectedModuleRoleIds: number[];
  modifiedModuleRoleIds: number[];
  deletedModuleRoleIds: number[];
  keyword: string;
  onKeywordChange: (keyword: string) => void;
  addRoleModalOpen: boolean;
  onAddRoleModalOpen: () => void;
  onAddRoleModalClose: () => void;
  batchAssignModalOpen: boolean;
  onBatchAssignModalOpen: () => void;
  onBatchAssignModalClose: () => void;
  onBatchAssign: (userIds: string[]) => void;
  onRoleCreate: (formGridState: AddRoleFormGridState) => void;
  pendingRoleEdit: null | ModuleRole;
  onRoleEditRequest: (moduleRoleId: number) => void;
  onRoleEditCancel: () => void;
  onRoleEditConfirm: (
    moduleRoleId: number,
    name: string,
    description: string
  ) => void;
  pendingRoleDeletion: number;
  onRoleDeletionRequest: (moduleRoleId: number) => void;
  onRoleDeletionCancel: () => void;
  onRoleDeletionConfirm: () => void;
  onModuleRoleSelect: (moduleRoleId: number, selected: boolean) => void;
  onAssignmentChange: (moduleRoleId: number, assigneeIds: string[]) => void;
  onAssignmentsDiscard: () => void;
  onAssignmentsSave: () => void;
}

const ModuleRoleAssignmentLayout: React.FC<ModuleRoleAssignmentLayoutProps> = ({
  moduleRoles,
  assigneeIdsByRoleId,
  selectedModuleRoleIds,
  modifiedModuleRoleIds,
  deletedModuleRoleIds,
  keyword,
  onKeywordChange,
  addRoleModalOpen,
  onAddRoleModalOpen,
  onAddRoleModalClose,
  batchAssignModalOpen,
  onBatchAssignModalOpen,
  onBatchAssignModalClose,
  onBatchAssign,
  onRoleCreate,
  pendingRoleEdit,
  onRoleEditRequest,
  onRoleEditCancel,
  onRoleEditConfirm,
  pendingRoleDeletion,
  onRoleDeletionRequest,
  onRoleDeletionCancel,
  onRoleDeletionConfirm,
  onModuleRoleSelect,
  onAssignmentChange,
  onAssignmentsDiscard,
  onAssignmentsSave,
}) => {
  const classes = useStyles();
  const appContext = useAppContext();
  const {t: tran} = useTranslation();
  return (
    <>
      <div className={classes.root}>
        <div className={classes.result}>
          <NxpHeader
            titleContent={`${_.capitalize(tran('module'))} ${_.capitalize(
              tran('role')
            )} (Module)`}
            actionContent={
              <>
                <TextField
                  className={classes.searchBar}
                  id="input-with-icon-textfield"
                  variant="outlined"
                  placeholder="Search"
                  value={keyword}
                  onChange={(ev) => onKeywordChange(ev.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Search />
                      </InputAdornment>
                    ),
                  }}
                />{' '}
                {appContext.hasUserAccessRight('role-create') && (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={onAddRoleModalOpen}
                  >
                    {_.capitalize(tran('common.add'))}{' '}
                    {_.capitalize(tran('role'))}
                  </Button>
                )}{' '}
                {appContext.hasUserAccessRight('role-assign') && (
                  <Button variant="contained" onClick={onBatchAssignModalOpen}>
                    {_.capitalize(tran('batch'))} {_.capitalize(tran('assign'))}
                  </Button>
                )}
              </>
            }
          />

          <TableHead />

          {moduleRoles.map((moduleRole) =>
            moduleRole.name.toLowerCase().indexOf(keyword.toLowerCase()) !==
            -1 ? (
              <ModuleRoleItem
                key={`ModuleRoleItem_${moduleRole.id}`}
                moduleRole={moduleRole}
                selected={selectedModuleRoleIds.includes(moduleRole.id)}
                modified={modifiedModuleRoleIds.includes(moduleRole.id)}
                deleted={deletedModuleRoleIds.includes(moduleRole.id)}
                assigneeIds={assigneeIdsByRoleId[moduleRole.id]}
                onSelect={onModuleRoleSelect}
                onRoleEdit={onRoleEditRequest}
                onRoleDelete={onRoleDeletionRequest}
                onAssignmentChange={onAssignmentChange}
              />
            ) : null
          )}

          {appContext.hasUserAccessRight('role-assign') && (
            <Box display="flex" justifyContent="flex-end">
              <Box>
                <Button onClick={onAssignmentsDiscard} variant="contained">
                  {_.capitalize(tran('discard'))}{' '}
                  {_.capitalize(tran('common.change'))}
                </Button>{' '}
                <Button
                  onClick={onAssignmentsSave}
                  variant="contained"
                  color="primary"
                >
                  {_.capitalize(tran('common.save'))}{' '}
                  {_.capitalize(tran('common.change'))}
                </Button>
              </Box>
            </Box>
          )}
        </div>
      </div>
      <AddRoleModal
        open={addRoleModalOpen}
        onSave={onRoleCreate}
        onDismiss={onAddRoleModalClose}
      />
      <EditRoleModal
        editingRole={pendingRoleEdit}
        onSave={onRoleEditConfirm}
        onDismiss={onRoleEditCancel}
      />
      <BatchAssignModal
        open={batchAssignModalOpen}
        onSave={onBatchAssign}
        onDismiss={onBatchAssignModalClose}
      />
      <AppConfirmDialog
        show={pendingRoleDeletion !== 0}
        title={`${_.capitalize(tran('common.delete'))} ${_.capitalize(
          tran('role')
        )}?`}
        message={tran('warning.role.delete')}
        onConfirm={onRoleDeletionConfirm}
        onCancel={onRoleDeletionCancel}
      />
    </>
  );
};

export default ModuleRoleAssignmentLayout;

import React from 'react';
import {CircularProgress, makeStyles} from '@material-ui/core';

import AppPhotoList from '../../components/AppPhotoList';
import NxpHeader from '../../components/NxpHeader';
import {Lightbox} from '../../services/lightbox';
import {Photo} from '../../services/photo';
import {AsyncLazyLoadResult} from '../../utils/typing/AsyncLazyLoadResult';
import LightboxFavoriteButton from '../Lightboxes/LightboxFavoriteButton';
//import EditLightboxButton from '../Lightboxes/LightboxForm/EditLightboxButton';
import ShareLightboxButton from './ShareLightbox/ShareLightboxButton';
import {NxpButton} from '../../components/NxpButton';
import useAppContext from '../../hooks/useAppContext';
import {useTranslation} from 'react-i18next';
import {capitalize} from 'lodash';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'stretch',
    minHeight: 'calc(100vh - 85px)',
    '& .MuiCircularProgress-root': {
      margin: '80px auto',
    },
  },
  favorite: {
    backgroundColor: theme.palette.action.hover,
    margin: theme.spacing(-1, 0),
  },
}));

interface LightboxDetailLayoutProps {
  lightbox: Lightbox;
  photoList: AsyncLazyLoadResult<Photo[]>;
  canShare: boolean;
  onPhotoListLazyLoad: () => void;
  onPhotoUpdated: (photo: Photo) => void;
  onPhotoDeleted: (photoId: number) => void;
  onLightboxUpdated: (lightbox: Lightbox) => void;
  onRemoveFromLightbox: (selectedPhotos: number[]) => void;
}

const LightboxDetailLayout: React.FC<LightboxDetailLayoutProps> = ({
  lightbox,
  photoList,
  canShare,
  onPhotoListLazyLoad,
  onPhotoUpdated,
  onPhotoDeleted,
  onLightboxUpdated,
  onRemoveFromLightbox,
}) => {
  const classes = useStyles();
  const appContext = useAppContext();
  const {t: tran} = useTranslation();

  return (
    <div className={classes.root}>
      {lightbox ? (
        <AppPhotoList
          headerTitleContent={
            <NxpHeader titleContent={`Lightbox - ${lightbox.name}`} />
          }
          headerActionContent={(selectedPhotos) =>
            selectedPhotos.length === 0 ? (
              <>
                <LightboxFavoriteButton
                  className={classes.favorite}
                  lightbox={lightbox}
                  onLightboxUpdated={onLightboxUpdated}
                />
                {/* {appContext.hasUserAccessRight(
                  'lightbox-edit',
                  'lightbox',
                  lightbox.id
                ) && (
                  <EditLightboxButton
                    onLightboxSaved={onLightboxUpdated}
                    lightbox={lightbox}
                  />
                )} */}
                {canShare && <ShareLightboxButton lightbox={lightbox} />}
              </>
            ) : (
              appContext.hasUserAccessRight(
                'lightbox-edit',
                'lightbox',
                lightbox.id
              ) && (
                <NxpButton onClick={() => onRemoveFromLightbox(selectedPhotos)}>
                  {capitalize(tran('common.remove'))}{' '}
                  {capitalize(tran('common.from'))}{' '}
                  {capitalize(tran('common.lightbox'))}
                </NxpButton>
              )
            )
          }
          noMoreItems={photoList.noMoreItems}
          loading={photoList.loading}
          photoListData={photoList.data ? photoList.data : []}
          hideAddToLightbox={true}
          onLazyLoad={onPhotoListLazyLoad}
          onPhotoDeleted={onPhotoDeleted}
          onPhotoUpdated={onPhotoUpdated}
        />
      ) : (
        <CircularProgress
          size={appContext.theme.dimension.circularProgressSize.main}
        />
      )}
    </div>
  );
};

export default LightboxDetailLayout;

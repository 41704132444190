import React from 'react';
import {CircularProgress, makeStyles, useTheme} from '@material-ui/core';

import AppCardList from '../../components/AppCardList';
import {NxpButton} from '../../components/NxpButton';
import NxpButtonDeleteConfirm from '../../components/NxpButton/NxpButtonDeleteConfirm';
import NxpHeader from '../../components/NxpHeader';
import {Lightbox} from '../../services/lightbox';
import formatDate, {DateFnsFormatPattern} from '../../utils/date/formatDate';
import LightboxFavoriteButton from './LightboxFavoriteButton';
import LightboxEditButton from './LightboxEditButton';
import useAppContext from '../../hooks/useAppContext';
import {useTranslation} from 'react-i18next';
import _ from 'lodash';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 5),
    '& .MuiCircularProgress-root': {
      marginTop: theme.spacing(5),
      marginLeft: 'calc(50vw - 100px)',
    },
  },
  favorite: {
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(1),
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  cardFooter: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'space-between',
    '& button.MuiButtonBase-root.MuiButton-root:not(:first-child)': {
      marginLeft: theme.spacing(1),
    },
    '& .rightbuttons': {
      display: 'inline-block',
    },
  },
}));

interface LightboxesLayoutProps {
  lightboxes: Lightbox[];
  loading: boolean;
  onCreateClick: () => void;
  onSelectClick: (lightbox: Lightbox) => void;
  onLightboxUpdated: (lightbox: Lightbox) => void;
  onDeleteConfirm: (lightboxId: number) => void;
}

const LightboxesLayout: React.FC<LightboxesLayoutProps> = ({
  lightboxes,
  loading,
  onCreateClick,
  onSelectClick,
  onLightboxUpdated,
  onDeleteConfirm,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const appContext = useAppContext();
  const {t: tran} = useTranslation();
  return (
    <div className={classes.root}>
      <NxpHeader titleContent={_.capitalize(tran('lightbox'))} />
      {loading ? (
        <CircularProgress size={theme.dimension.circularProgressSize.main} />
      ) : (
        <AppCardList
          onCreateClick={
            appContext.hasUserAccessRight('lightbox-create', 'album')
              ? onCreateClick
              : undefined
          }
          createText={`${tran('common.create')} ${tran('lightbox')}`}
          cards={lightboxes.map((lightbox) => ({
            className: classes.card,
            cardTitle: `${tran('lightbox')} ${tran('card')}`,
            key: lightbox.id.toString(),
            imageUrl: lightbox.thumbnailUrl,
            imageActionContent: (
              <LightboxFavoriteButton
                className={classes.favorite}
                lightbox={lightbox}
                onLightboxUpdated={onLightboxUpdated}
              />
            ),
            descriptionContent: `${lightbox.photoCount} ${_.capitalize(
              tran('photos')
            )} / ${_.capitalize(tran('common.updated'))}: ${formatDate(
              lightbox.updatedOn,
              DateFnsFormatPattern.date
            )}`,
            titleContent: <>{lightbox.name} </>,
            onCardClick: () => {
              onSelectClick(lightbox);
            },
            footerActionContent: (
              <div className={classes.cardFooter}>
                <NxpButton
                  variant="text"
                  onClick={() => {
                    onSelectClick(lightbox);
                  }}
                  size="small"
                >
                  {tran('common.select')}
                </NxpButton>
                <div className="right-buttons">
                  {appContext.hasUserAccessRight(
                    'lightbox-edit',
                    'lightbox',
                    lightbox.id
                  ) && (
                    <LightboxEditButton
                      lightbox={lightbox}
                      onLightboxUpdated={onLightboxUpdated}
                    />
                  )}
                  {lightbox?.owner?.id ===
                    appContext.userList.find(
                      (user) => user.email === appContext.currentUser?.email
                    )?.id && (
                    <NxpButtonDeleteConfirm
                      onConfirm={() => onDeleteConfirm(lightbox.id)}
                      size="small"
                      variant="text"
                    />
                  )}
                </div>
              </div>
            ),
          }))}
        />
      )}
    </div>
  );
};

export default LightboxesLayout;

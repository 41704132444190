import React from 'react';

import {makeStyles} from '@material-ui/core';
import {Tag} from '../../../services/tag';
import {NxpButton} from '../../../components/NxpButton';
import {NxpTextInput} from '../../../components/NxpTextInput';
import NxpButtonDeleteConfirm from '../../../components/NxpButton/NxpButtonDeleteConfirm';
import {useTranslation} from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    borderRadius: theme.shape.borderRadius,
    border: `1px solid ${theme.palette.border.light}`,
    alignItems: 'center',
    marginBottom: theme.spacing(1),
    padding: theme.spacing(1),
    '& .field': {
      flexGrow: 1,
      paddingRight: theme.spacing(2),
    },
    '& .action': {
      margin: theme.spacing(-0.5, 0),
    },
    '& button.cancel': {
      color: theme.palette.text.hint,
    },
    '& button.delete': {
      color: theme.palette.error.main,
    },
  },
}));

interface TagListItemProps {
  tag: Tag;
  form?: Tag;
  onEdit: (tagId: number) => void;
  onChange: (tag: Tag) => void;
  onCancel: () => void;
  onDelete: () => void;
  onSave: () => void;
}

const TagListItem: React.FC<TagListItemProps> = ({
  tag,
  form,
  onEdit,
  onChange,
  onCancel,
  onDelete,
  onSave,
}) => {
  const classes = useStyles();
  const {t: tran} = useTranslation();
  return (
    <div className={classes.root}>
      {tag.id === form?.id ? (
        <>
          <div className="field">
            <NxpTextInput
              value={tag.name}
              onChange={(e) =>
                onChange({
                  ...tag,
                  name: e.target.value,
                })
              }
            />
          </div>
          <div className="action">
            <NxpButton className="cancel" onClick={onCancel} variant="text">
              {tran('common.cancel')}
            </NxpButton>
            <NxpButtonDeleteConfirm
              className="delete"
              onConfirm={onDelete}
              variant="text"
              deleteItemName="Tag"
            />
            <NxpButton onClick={onSave} variant="text">
              {tran('common.save')}
            </NxpButton>
          </div>
        </>
      ) : (
        <>
          <div className="field">{tag.name}</div>
          <div className="action">
            <NxpButton
              disabled={!!form?.id}
              onClick={() => onEdit(tag?.id !== undefined ? tag?.id : 1)}
              variant="text"
            >
              {tran('common.edit')}
            </NxpButton>
          </div>
        </>
      )}
    </div>
  );
};

export default TagListItem;

import {apiRequest} from '../utils/backend';
import {ServiceConfig} from '../utils/backend/apiRequest';
import {PaginationQuery} from '../utils/typing/PaginationQuery';
import {Album} from './album';
import {User} from './app';
import {Photo, searchPhotos, SearchQuery} from './photo';

export interface Lightbox {
  id: number;
  isFavorite: boolean;
  album: Album;
  name: string;
  photos: Photo[];
  createdOn: Date;
  updatedOn: Date;
  owner?: User;
  shares?: [
    {
      userId: string;
      allowEdit: boolean;
    }
  ];
  thumbnailUrl?: string;
  photoCount?: number;
}

export const getLightboxList = async (
  albumId: number,
  serviceConfig: ServiceConfig
) => {
  return await apiRequest<Lightbox[]>(
    `/lightboxes?albumId=${albumId}`,
    serviceConfig,
    {headers: {album: '' + albumId}}
  );
};

export const getLightbox = async (id: number, serviceConfig: ServiceConfig) => {
  return await apiRequest<Lightbox>(`/lightboxes/${id}`, serviceConfig);
};

export interface LightboxForm
  extends Omit<
    Lightbox,
    | 'photos'
    | 'createdOn'
    | 'updatedOn'
    | 'id'
    | 'album'
    | 'isFavorite'
    | 'owner'
    | 'shares'
    | 'thumbnailUrl'
    | 'photoCount'
  > {
  id?: number;
  album: {id: number};
}

export const createLightbox = async (
  lightbox: LightboxForm,
  serviceConfig: ServiceConfig
) => {
  return await apiRequest<Lightbox>('/lightboxes', serviceConfig, {
    method: 'POST',
    body: JSON.stringify({
      ...lightbox,
    }),
  });
};

export const updateLightbox = async (
  lightbox: LightboxForm, // lightboxFormContainer has to pass in the whole Lightbox instead because backend not return lightbox object
  serviceConfig: ServiceConfig
) => {
  // return await apiRequest<Lightbox>(lightbox is not returned)
  return await apiRequest(`/lightboxes/${lightbox.id}`, serviceConfig, {
    method: 'PATCH',
    body: JSON.stringify({
      name: lightbox.name,
    }),
  }).then(() => ({...lightbox}));
};

export const toggleLightboxFavorite = async (
  lightbox: Lightbox,
  serviceConfig: ServiceConfig
) => {
  // return await apiRequest<Lightbox>(lightbox is not returned)
  return await apiRequest(`/lightboxes/${lightbox.id}`, serviceConfig, {
    method: 'PATCH',
    body: JSON.stringify({isFavorite: !lightbox.isFavorite}),
  }).then(() => ({...lightbox, isFavorite: !lightbox.isFavorite}));
};

export const deleteLightbox = async (
  lightboxId: number,
  serviceConfig: ServiceConfig
) => {
  return await apiRequest<Lightbox>(
    `/lightboxes/${lightboxId}`,
    serviceConfig,
    {
      method: 'DELETE',
    }
  );
};

export interface AddToLightboxForm
  extends Partial<
    Omit<
      Lightbox,
      | 'id'
      | 'photos'
      | 'createdOn'
      | 'updatedOn'
      | 'album'
      | 'isFavorite'
      | 'owner'
      | 'shares'
      | 'thumbnailUrl'
      | 'photoCount'
    >
  > {
  addToExisting: boolean;
  album: {id: number};
  lightboxId?: number | null;
  photos: {id: number}[];
}

export const addToLightbox = async (
  form: AddToLightboxForm,
  serviceConfig: ServiceConfig
) => {
  if (form.addToExisting) {
    return await apiRequest(`/lightboxes/add-photos`, serviceConfig, {
      method: 'PATCH',
      body: JSON.stringify({
        lightboxId: form.lightboxId!,
        photos: [...form.photos],
      }),
    });
  } else {
    const lightbox = await createLightbox(
      {
        name: form.name!,
        album: form.album as Album,
      },
      serviceConfig
    );
    await apiRequest(`/lightboxes/add-photos`, serviceConfig, {
      method: 'PATCH',
      body: JSON.stringify({
        lightboxId: lightbox.id,
        photos: [...form.photos],
      }),
    });

    return lightbox;
  }
};

export const removeFromLightbox = async (
  lightboxId: number,
  photos: {id: number}[],
  serviceConfig: ServiceConfig
) => {
  return await apiRequest(`/lightboxes/remove-photos`, serviceConfig, {
    method: 'PATCH',
    body: JSON.stringify({
      lightboxId,
      photos,
    }),
  });
};

export const getLightboxPhotoList = async (
  lightboxId: number,
  pagination: PaginationQuery,
  serviceConfig: ServiceConfig
) => {
  const query: SearchQuery = {lightboxId, ...pagination};
  return searchPhotos(query, serviceConfig);
};

export interface LightboxShareAccess {
  user: User;
  allowEdit: boolean;
}

export const getLightboxShareAccess = async (
  lightboxId: number,
  serviceConfig: ServiceConfig
) => {
  return await apiRequest<LightboxShareAccess[]>(
    `/lightboxes/${lightboxId}/shares`,
    serviceConfig
  );
};

export const updateLightboxShareAccess = async (
  lightboxId: number,
  sharedUsers: {userId: string; allowEdit: boolean}[],
  serviceConfig: ServiceConfig
) => {
  return await apiRequest<LightboxShareAccess[]>(
    `/lightboxes/${lightboxId}/shares`,
    serviceConfig,
    {
      method: 'PATCH',
      body: JSON.stringify({
        sharedUsers,
      }),
    }
  );
};

import {apiRequest} from '../utils/backend';
import {ServiceConfig} from '../utils/backend/apiRequest';
import {RecordBase} from './recordBase';

export interface Entity {
  id: string;
  name: string;
}
export interface User extends Partial<RecordBase> {
  id: string;
  name: string;
  email?: string;
  createdOn: Date;
  updatedOn: Date;
}

export interface UserAccessRightList {
  'photo-view': UserAccessRightListItem[];
  'photo-upload': UserAccessRightListItem[];
  'photo-edit': UserAccessRightListItem[];
  'photo-delete': UserAccessRightListItem[];
  'photo-download': UserAccessRightListItem[];
  'lightbox-create': UserAccessRightListItem[];
  'lightbox-view': UserAccessRightListItem[];
  'lightbox-edit': UserAccessRightListItem[];
  'lightbox-delete': UserAccessRightListItem[];
  'album-create': UserAccessRightListItem[];
  'album-view': UserAccessRightListItem[];
  'album-edit': UserAccessRightListItem[];
  'album-delete': UserAccessRightListItem[];
  'dashboard-view': UserAccessRightListItem[];
  'role-view': UserAccessRightListItem[];
  'role-create': UserAccessRightListItem[];
  'role-edit': UserAccessRightListItem[];
  'role-assign': UserAccessRightListItem[];
  'role-delete': UserAccessRightListItem[];
  'access-right-view': UserAccessRightListItem[];
  'access-right-assign': UserAccessRightListItem[];
  'tag-add': UserAccessRightListItem[];
  'tag-edit': UserAccessRightListItem[];
  'tag-delete': UserAccessRightListItem[];
}

export interface UserAccessRightListItem {
  source: 'hardcode' | 'module' | 'album' | 'lightbox';
  album?: number;
  lightbox?: number;
}

export interface PhotoType {
  code: string;
  name: string;
  createdOn: Date;
  updatedOn: Date;
}

export interface PredefinedTag {
  predefinedTagCode: string;
  name: string;
  type: string;
  createdOn: Date;
  updatedOn: Date;
  id: number;
}

export interface Location {
  code: string;
  name: string;
  children: Location[];
}

export interface UserPreference {
  id: string;
  userId: string;
  application: string;
  parameter: string;
  value: string;
}
export type AddUserPreference = Omit<UserPreference, 'id' | 'userId'>;

export const getEntityInfo = async (serviceConfig: ServiceConfig) => {
  return await apiRequest<Entity>(
    `/entities/${serviceConfig.entityId}`,
    serviceConfig
  );
};
export const getCurrentUser = async (serviceConfig: ServiceConfig) => {
  return await apiRequest<User>(
    `/entities/${serviceConfig?.entityId}/users/me`,
    serviceConfig
  );
};
export const getUserList = async (serviceConfig: ServiceConfig) => {
  return await apiRequest<User[]>(`/users`, serviceConfig);
};

export const getEligibleLightboxShareeList = async (
  serviceConfig: ServiceConfig
) => {
  return await apiRequest<User[]>(`/eligible-lightbox-sharees`, serviceConfig);
};

export const getUserAccessRightList = async (serviceConfig: ServiceConfig) => {
  return await apiRequest<UserAccessRightList>(
    `/user-access-right-list`,
    serviceConfig
  );
};

export const getPhotoTypeList = async (
  entityId: string,
  serviceConfig: ServiceConfig
) => {
  return await apiRequest<PhotoType[]>(
    `/photo-types?entityId=${entityId}`,
    serviceConfig
  );
};

export const getPredefinedTagList = async (
  entityId: string,
  serviceConfig: ServiceConfig
) => {
  return await apiRequest<PredefinedTag[]>(
    `/tags/predefined?entityId=${entityId}`,
    serviceConfig
  );
};

export const getLocationTree = async (
  entityId: string,
  serviceConfig: ServiceConfig
) => {
  return await apiRequest<Location[]>(
    `/locations?entityId=${entityId}`,
    serviceConfig
  );
};

export const getMyUserPreference = async (serviceConfig: ServiceConfig) => {
  return apiRequest<UserPreference[]>(
    `${serviceConfig.userDirectoryServiceApiBaseUrl}/user-preference/me`,
    serviceConfig
  );
};
export const createMyUserPreference = async (
  serviceConfig: ServiceConfig,
  userPreference: AddUserPreference
) => {
  return apiRequest<UserPreference>(
    `${serviceConfig.userDirectoryServiceApiBaseUrl}/user-preference/me`,
    serviceConfig,
    {
      method: 'POST',
      body: JSON.stringify(userPreference),
    }
  );
};
export const patchMyUserPreference = async (
  serviceConfig: ServiceConfig,
  userPreference: AddUserPreference
) => {
  return apiRequest<any>(
    `${serviceConfig.userDirectoryServiceApiBaseUrl}/user-preference/me`,
    serviceConfig,
    {
      method: 'PATCH',
      body: JSON.stringify(userPreference),
    }
  );
};

import React from 'react';
import {useTranslation} from 'react-i18next';
import {NxpButton} from '../../../components/NxpButton';
import {NxpDialog} from '../../../components/NxpDialog';
import {NxpFormGrid} from '../../../components/NxpFormGrid';
import {ValidationResult} from '../../../hooks/useValidate';
import {AlbumForm} from '../../../services/album';

interface AlbumFormModalProps {
  modalOpen: boolean;
  form: AlbumForm;
  formError: ValidationResult<AlbumForm>;
  submitLoading: boolean;
  onFormStateChange: (fieldName: string, value: unknown) => void;
  onSave: () => void;
  onModalClose: () => void;
}

const AlbumFormModal: React.FC<AlbumFormModalProps> = ({
  modalOpen,
  form,
  formError,
  submitLoading,
  onFormStateChange,
  onSave,
  onModalClose,
}) => {
  const {t: tran} = useTranslation();
  return (
    <NxpDialog
      maxWidth="xs"
      onClose={onModalClose}
      open={modalOpen}
      titleContent={`${
        form.id ? tran('common.update') : tran('common.create')
      } ${tran('album')}`}
      actionContent={
        <NxpButton disabled={submitLoading} onClick={onSave}>
          {tran('common.save')}
        </NxpButton>
      }
      onClick={(e) => e.stopPropagation()}
    >
      <NxpFormGrid
        editing={true}
        gridColumnCount={1}
        formState={form}
        onFormStateChange={onFormStateChange}
        fields={[
          {
            itemColumnSpan: 1,
            fieldName: 'name',
            fieldType: 'text',
            // onFormStateChange,
            label: `${tran('album')} ${tran('common.name')}`,
            required: true,
            errorContent: formError.name,
          },
        ]}
      />
    </NxpDialog>
  );
};

export default AlbumFormModal;

import React, {ReactNode, useEffect, useReducer, useRef} from 'react';
import {Photo} from '../../services/photo';
import AppPhotoListLayout from './AppPhotoListLayout';
import {photoListReducer} from './photoListReducer';

interface AppPhotoListContainerProps {
  headerTitleContent: ReactNode;
  headerActionContent?: (selectedPhotos: number[]) => ReactNode;
  photoListData: Photo[];
  loading?: boolean;
  noMoreItems?: boolean;
  hideAddToLightbox?: boolean;
  onLazyLoad?: () => void;
  onPhotoUpdated: (photo: Photo) => void;
  onPhotoDeleted: (photoId: number) => void;
}

const AppPhotoListContainer: React.FC<AppPhotoListContainerProps> = ({
  headerTitleContent,
  headerActionContent,
  photoListData,
  loading,
  noMoreItems,
  hideAddToLightbox,
  onLazyLoad,
  onPhotoUpdated,
  onPhotoDeleted,
}) => {
  console.log(photoListData);
  const [photoListState, dispatch] = useReducer(photoListReducer, {
    activePhotoId: undefined,
    selectedPhotos: [],
    showMediumPreview: false,
  });

  const photoListDataCountRef = useRef(0);
  useEffect(() => {
    // auto select first photo when the list is first loaded
    if (photoListDataCountRef.current === 0 && photoListData.length > 0) {
      dispatch({
        type: 'activePhotoChange',
        photoId: photoListData[0].id,
      });
    }
    photoListDataCountRef.current = photoListData.length;
    dispatch({
      type: 'photoSelectionCheck', // remove selected ids when the photos no longer exist
      photoListData,
    });
  }, [photoListData]);

  const handlePhotoDetail = (photoId?: number) => {
    dispatch({
      type: 'activePhotoChange',
      photoId,
    });
    dispatch({
      type: 'toggleMediumPreview',
      isShown: true,
    });
  };

  const handlePhotoZoom = (photoId?: number) => {
    dispatch({
      type: 'photoZoomChange',
      photoId,
    });
  };

  const handlePhotoSelectionChange = (photoId: number) => {
    dispatch({
      type: 'photoSelectionChange',
      photoId,
    });
  };

  const handlePhotoSelectAll = () => {
    dispatch({
      type: 'photoSelectAll',
      photoListData,
    });
  };

  const handlePhotoDeleted = (photoId: number) => {
    let nextPhoto =
      photoListData[photoListData.findIndex((item) => item.id === photoId) + 1];

    if (!nextPhoto) {
      // if the last photo is deleted, set nextPhoto to the photo before the deleted photo
      nextPhoto = photoListData[photoListData.length - 2];
    }
    if (nextPhoto) {
      dispatch({
        type: 'activePhotoChange',
        photoId: nextPhoto.id,
      });
    }
    onPhotoDeleted(photoId);
  };

  const handleHideMediumPreview = () => {
    dispatch({
      type: 'toggleMediumPreview',
      isShown: false,
    });
  };

  return (
    <AppPhotoListLayout
      headerTitleContent={headerTitleContent}
      headerActionContent={
        headerActionContent &&
        headerActionContent(photoListState.selectedPhotos)
      }
      photoListData={photoListData}
      loading={loading}
      activePhotoId={photoListState.activePhotoId}
      selectedPhotos={photoListState.selectedPhotos}
      photoZoomId={photoListState.photoZoomId}
      hideAddToLightbox={hideAddToLightbox}
      showMediumPreview={photoListState.showMediumPreview}
      noMoreItems={noMoreItems}
      onLazyLoad={onLazyLoad}
      onPhotoUpdated={onPhotoUpdated}
      onPhotoDeleted={handlePhotoDeleted}
      onPhotoDetail={handlePhotoDetail}
      onPhotoZoom={handlePhotoZoom}
      onPhotoSelectionChange={handlePhotoSelectionChange}
      onPhotoSelectAll={handlePhotoSelectAll}
      onHideMediumPreview={handleHideMediumPreview}
    />
  );
};

export default AppPhotoListContainer;

import React from 'react';
import clsx from 'clsx';
import {Chip, makeStyles, SelectProps, TextField} from '@material-ui/core';
import {Autocomplete} from '@material-ui/lab';

export interface NxpMultiChipItem {
  value: string | number | undefined;
  name: string;
}

export interface NxpMultiChipBaseProps extends SelectProps {
  selectItems?: NxpMultiChipItem[];
  onValueChange: (values: unknown[]) => void;
}

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 100,
    marginTop: 16,
    '&.Mui-error': {
      borderColor: theme.palette.error.main,
    },
    '& .MuiSelect-root': {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
    '& .MuiInputBase-root.MuiOutlinedInput-root.MuiAutocomplete-inputRoot.MuiInputBase-fullWidth.MuiInputBase-formControl.MuiInputBase-adornedEnd.MuiOutlinedInput-adornedEnd.MuiInputBase-marginDense.MuiOutlinedInput-marginDense':
      {
        padding: 3,
      },
  },
}));

const NxpMultiChipBase = React.forwardRef<unknown, NxpMultiChipBaseProps>(
  function NxpMultiChipBase(
    {className, id, selectItems, children, onValueChange, ...restProps},
    ref
  ) {
    const classes = useStyles();

    const values = selectItems?.filter((option) =>
      (restProps.value as any[]).includes(option.value)
    );

    return (
      <>
        <Autocomplete
          multiple
          ref={ref}
          className={clsx(classes.root, className)}
          size="small"
          filterSelectedOptions
          options={selectItems || []}
          getOptionLabel={(option) => option.name}
          value={values}
          renderTags={(values, getTagProps) =>
            values.map((value, index) => (
              <Chip
                size="small"
                variant="default"
                label={`${value.name}`}
                {...getTagProps({index})}
              />
            ))
          }
          onChange={(event, value) => {
            onValueChange(value.map((item) => item.value));
          }}
          renderInput={(params) => <TextField {...params} variant="outlined" />}
        />
      </>
    );
  }
);

export default NxpMultiChipBase;

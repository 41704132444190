import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useHistory} from 'react-router';

import {capitalize} from 'lodash';

import useAppContext from '../../hooks/useAppContext';
import {useAsync} from '../../hooks/useAsync';
import {
  deleteLightbox,
  getLightboxList,
  Lightbox,
} from '../../services/lightbox';
import LightboxesLayout from './LightboxesLayout';
import LightboxForm from './LightboxForm';

interface LightboxesContainerProps {}

const LightboxesContainer: React.FC<LightboxesContainerProps> = () => {
  const appContext = useAppContext();
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const {t: tran} = useTranslation();
  const history = useHistory();

  const handleCreateClick = () => {
    setCreateModalOpen(true);
  };

  const handleCreateModalClose = () => setCreateModalOpen(false);

  const [lightboxesAsyncResult] = useAsync(
    // fetch list every time this page opens to ensure photo count data is up-to-date
    () => getLightboxList(appContext.activeAlbum!.id, appContext.serviceConfig),
    {
      onSuccess: (data) =>
        appContext.onAppContextCacheItemUpdate('activeAlbumLightboxes', data),
      onError: (ex) =>
        appContext.enqueueSnackbar(
          `Error retrieving lightbox list - ${ex.message}`
        ),
    }
  );

  const handleLightboxCreated = (lightbox: Lightbox) => {
    appContext.onAppContextCacheItemUpdate('activeAlbumLightboxes', [
      {...lightbox, photoCount: 0},
      ...(appContext.activeAlbumLightboxes || []),
    ]);
  };

  const handleSelectClick = (lightbox: Lightbox) => {
    history.push(`./lightboxes/${lightbox.id}`);
  };

  const handleLightboxUpdated = (lightbox: Lightbox) => {
    appContext.onAppContextCacheItemUpdate(
      'activeAlbumLightboxes',
      appContext.activeAlbumLightboxes!.map((item) =>
        item.id === lightbox.id ? lightbox : item
      )
    );
  };

  const handleDeleteConfirm = async (lightboxId: number) => {
    try {
      await deleteLightbox(lightboxId, appContext.serviceConfig);
      appContext.onAppContextCacheItemUpdate(
        'activeAlbumLightboxes',
        appContext.activeAlbumLightboxes?.filter(
          (lightbox) => lightbox.id !== lightboxId
        )
      );
      appContext.enqueueSnackbar(
        `${capitalize(tran('common.lightbox'))} ${capitalize(
          tran('common.delete')
        )} ${capitalize(tran('common.success'))}`,
        {
          variant: 'success',
        }
      );
    } catch (ex) {
      appContext.errorHandler(ex as Error, 'lightbox deletion', lightboxId);
    }
  };

  return (
    <>
      {appContext.activeAlbum && (
        <LightboxForm
          modalOpen={createModalOpen}
          onModalClose={handleCreateModalClose}
          onLightboxSaved={handleLightboxCreated}
        />
      )}
      <LightboxesLayout
        lightboxes={appContext.activeAlbumLightboxes || []}
        loading={lightboxesAsyncResult.loading}
        onCreateClick={handleCreateClick}
        onSelectClick={handleSelectClick}
        onLightboxUpdated={handleLightboxUpdated}
        onDeleteConfirm={handleDeleteConfirm}
      />
    </>
  );
};

export default LightboxesContainer;

import React, {ReactNode} from 'react';
import {
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Typography,
  makeStyles,
} from '@material-ui/core';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  root: {
    width: 300,
    margin: theme.spacing(0, 3, 3, 0),
    boxShadow:
      '0px 3px 1px -2px rgb(0 0 0 / 12%), 0px 2px 2px 0px rgb(0 0 0 / 8%), 0px 1px 5px 0px rgb(0 0 0 / 6%);',
  },
  cardMedia: {
    height: 200,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.text.disabled,
    paddingTop: theme.spacing(2),
  },
  actionContent: {
    borderTop: `1px solid ${theme.palette.border.light}`,
  },
  cardDescription: {
    minHeight: 30,
  },
}));

export interface AppCardListItemProps {
  key: string;
  cardTitle?: string;
  imageUrl?: string;
  imageTitle?: string;
  imageActionContent?: ReactNode;
  titleContent?: ReactNode;
  descriptionContent?: ReactNode;
  footerActionContent?: ReactNode;
  className?: string;
  onCardClick?: () => void;
}

const AppCardListItem: React.FC<AppCardListItemProps> = ({
  cardTitle,
  imageUrl,
  imageTitle,
  imageActionContent,
  titleContent,
  descriptionContent,
  footerActionContent,
  className,
  onCardClick,
}) => {
  const classes = useStyles();

  const cardMainContent = (
    <>
      <CardMedia
        className={classes.cardMedia}
        title={imageTitle}
        image={imageUrl}
      >
        {imageUrl ? null : 'Preview Unavailable'}
        {imageActionContent}
      </CardMedia>
      <CardContent>
        {titleContent && (
          <Typography gutterBottom variant="h5" component="h2">
            {titleContent}
          </Typography>
        )}
        {descriptionContent && (
          <Typography
            className={classes.cardDescription}
            variant="body2"
            color="textSecondary"
            component="p"
          >
            {descriptionContent}
          </Typography>
        )}
      </CardContent>
    </>
  );

  return (
    <Card className={clsx(classes.root, className)} title={cardTitle}>
      {onCardClick ? (
        <CardActionArea onClick={onCardClick}>{cardMainContent}</CardActionArea>
      ) : (
        cardMainContent
      )}
      {footerActionContent && (
        <CardActions className={classes.actionContent}>
          {footerActionContent}
        </CardActions>
      )}
    </Card>
  );
};

export default AppCardListItem;

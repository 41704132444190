import {ObjectRole} from '../../services/objectRoleAccessRight';

export interface AccessRightList {
  'photo-view': boolean;
  'photo-upload': boolean;
  'photo-edit': boolean;
  'photo-delete': boolean;
  'photo-download': boolean;
  'lightbox-create': boolean;
  'lightbox-view': boolean;
  'lightbox-edit': boolean;
  'album-create': boolean;
  'album-view': boolean;
  'album-edit': boolean;
  'album-delete': boolean;
  'dashboard-view': boolean;
  'role-view': boolean;
  'role-create': boolean;
  'role-edit': boolean;
  'role-assign': boolean;
  'role-delete': boolean;
  'access-right-view': boolean;
  'access-right-assign': boolean;
  'tag-add': boolean;
  'tag-edit': boolean;
  'tag-delete': boolean;
}

interface ObjectRoleAccessRightSettingsState {
  objectRoles: ObjectRole[];
  selectedObjectRole: null | ObjectRole;
  accessRightList: AccessRightList;
  accessRightListTouched: boolean;
}

type ObjectRoleAccessRightSettingsAction =
  | {
      type: 'objectRolesLoading';
    }
  | {
      type: 'objectRoleResultError';
      error: Error;
    }
  | {
      type: 'objectRolesLoaded';
      objectRoles: ObjectRole[];
    }
  | {
      type: 'accessRightChecked';
      value: string;
      checked: boolean;
    }
  | {
      type: 'objectRoleSelected';
      objectRoleId: number;
    }
  | {
      type: 'objectRoleSaved';
      objectRole: ObjectRole;
    };

export const objectRoleAccessRightSettingsReducer = (
  state: ObjectRoleAccessRightSettingsState,
  action: ObjectRoleAccessRightSettingsAction
): ObjectRoleAccessRightSettingsState => {
  switch (action.type) {
    case 'objectRolesLoaded':
      return {
        ...state,
        objectRoles: action.objectRoles,
      };
    case 'accessRightChecked':
      return {
        ...state,
        accessRightList: {
          ...state.accessRightList,
          [action.value]: action.checked,
        },
        accessRightListTouched: true,
      };
    case 'objectRoleSelected':
      console.log(action.objectRoleId);
      const accessRightList = {} as AccessRightList;
      const selectedObjectRole = state.objectRoles.find(
        (role) => role.id === action.objectRoleId
      );
      if (!selectedObjectRole) return state;

      for (const accessRight of selectedObjectRole.accessRights) {
        accessRightList[accessRight.accessRightType as keyof AccessRightList] =
          true;
      }

      return {
        ...state,
        selectedObjectRole,
        accessRightList,
        accessRightListTouched: false,
      };
    case 'objectRoleSaved':
      const objectRole = action.objectRole;

      const objectRoles = [...state.objectRoles];
      const index = objectRoles.findIndex((role) => role.id === objectRole.id);
      objectRoles[index] = objectRole;

      return {
        ...state,
        objectRoles,
        accessRightListTouched: false,
      };
    default:
      return state;
  }
};

import _ from 'lodash';
import {useSnackbar} from 'notistack';
import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Tag} from '../../../../services/tag';
import CreateTagModal from './CreateTagModal';

interface CreateTagContainerProps {
  modalOpen: boolean;
  onTagCreated: (tag: Tag) => void;
  onModalClose: () => void;
}

export interface CreateTagForm extends Pick<Tag, 'name'> {}

const CreateTagContainer: React.FC<CreateTagContainerProps> = ({
  modalOpen,
  onModalClose,
}) => {
  const {t: tran} = useTranslation();
  const [form, setForm] = useState<CreateTagForm>({
    name: '',
  });
  const handleFormStateChange = (fieldName: string, value: unknown) => {
    setForm((prevState) => ({
      ...prevState,
      [fieldName]: value,
    }));
  };
  const {enqueueSnackbar} = useSnackbar();
  const handleSave = () => {
    enqueueSnackbar(
      `${_.capitalize(tran('photo.tags'))} ${_.lowerCase(
        tran('common.add')
      )} ${_.lowerCase(tran('common.success'))}.`,
      {variant: 'success'}
    );
    onModalClose();
  };
  return (
    <CreateTagModal
      modalOpen={modalOpen}
      form={form}
      onFormStateChange={handleFormStateChange}
      onSave={handleSave}
      onModalClose={onModalClose}
    />
  );
};

export default CreateTagContainer;

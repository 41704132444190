import React from 'react';
import {makeStyles, Theme} from '@material-ui/core';

export interface ChartTitleProps {
  children?: string | JSX.Element | JSX.Element[];
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'block',
    color: '#3E3F42',
    fontSize: theme.typography.pxToRem(20),
    lineHeight: '28px',
    fontWeight: 'bold',
    alignItems: 'center',
    textAlign: 'center',
    margin: 8,
  },
}));

const ChartTitle: React.FC<ChartTitleProps> = ({children}) => {
  const classes = useStyles();
  return <span className={classes.root}>{children}</span>;
};
export default ChartTitle;

import {initReactI18next} from 'react-i18next';

import i18n from 'i18next';
import PhraseInContextEditorPostProcessor from 'i18next-phrase-in-context-editor-post-processor';

import de from '../../i18n/de/translation.json';
import en from '../../i18n/en/translation.json';
import es from '../../i18n/es/translation.json';
import cn from '../../i18n/zh-hk/translation.json';

const resources = {
  en: {
    translation: en,
  },
  'zh-HK': {
    translation: cn,
  },
  de: {
    translation: de,
  },
  es: {
    translation: es,
  },
};
export const languageSet: {name: string; key: string}[] = [
  {
    name: 'English',
    key: 'en',
  },
  {
    name: '中文',
    key: 'zh-HK',
  },
  {
    name: 'Deutsch',
    key: 'de',
  },
  {
    name: 'Español',
    key: 'es',
  },
];

const enableInContextProcessor = window.location.search.includes('phrase=true');
if (enableInContextProcessor) {
  i18n.use(
    new PhraseInContextEditorPostProcessor({
      phraseEnabled: true,
      fullReparse: true,
      autoLowercase: false,
      projectId: 'ecb6d06d67f2199e310cd53bc89da14e',
    })
  );
}

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  // .use(LanguageDetector)

  .init({
    resources,
    fallbackLng: 'en', // default language
    keySeparator: false, // we do not use keys in form messages.welcome
    interpolation: {
      escapeValue: false, // react already safes from xss
      format: function (value, format, lng) {
        return value;
      },
    },
    postProcess: enableInContextProcessor ? ['phraseInContextEditor'] : [],
  });

export default i18n;

import {Photo} from '../../services/photo';
import {AsyncLazyLoadResult} from '../../utils/typing/AsyncLazyLoadResult';

interface LightboxState {
  photoList: AsyncLazyLoadResult<Photo[]>;
}

type LightboxAction =
  | {
      type: 'photoUpdated';
      photo: Photo;
    }
  | {
      type: 'photosRemoved';
      photoIds: number[];
    }
  | {
      type: 'photoResultLoading';
    }
  | {
      type: 'photoResultError';
      error: Error;
    }
  | {
      type: 'photoResultLoaded';
      photoListData: Photo[];
      noMoreItems: boolean;
    };

export default function lightboxDetailReducer(
  state: LightboxState,
  action: LightboxAction
): LightboxState {
  switch (action.type) {
    case 'photoUpdated': {
      return {
        ...state,
        photoList: {
          ...state.photoList,
          data: state.photoList.data?.map((item) =>
            item.id === action.photo.id ? action.photo : item
          ),
        },
      };
    }
    case 'photosRemoved': {
      return {
        ...state,
        photoList: {
          ...state.photoList,
          data: state.photoList.data?.filter(
            (item) => !action.photoIds.includes(item.id)
          ),
        },
      };
    }
    case 'photoResultLoading':
      return {
        ...state,
        photoList: {
          ...state.photoList,
          loading: true,
        },
      };
    case 'photoResultError':
      return {
        ...state,
        photoList: {
          ...state.photoList,
          loading: false,
          error: action.error,
        },
      };
    case 'photoResultLoaded':
      return {
        ...state,
        photoList: {
          noMoreItems: action.noMoreItems,
          loading: false,
          error: undefined,
          data: [
            ...(state.photoList.data ? state.photoList.data : []),
            ...action.photoListData,
          ],
        },
      };

    default:
      return state;
  }
}

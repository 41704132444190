import {Photo} from '../../services/photo';

interface ExplorerState {
  activePhotoId?: number;
  photoZoomId?: number;
  selectedPhotos: number[];
  showMediumPreview: boolean;
}

type ExplorerAction =
  | {
      type: 'activePhotoChange';
      photoId?: number;
    }
  | {
      type: 'photoZoomChange';
      photoId?: number;
    }
  | {
      type: 'photoSelectionChange';
      photoId: number;
    }
  | {
      type: 'photoSelectionCheck';
      photoListData: Photo[];
    }
  | {
      type: 'photoSelectAll';
      photoListData: Photo[];
    }
  | {
      type: 'toggleMediumPreview';
      isShown: boolean;
    };

export const photoListReducer = (
  state: ExplorerState,
  action: ExplorerAction
): ExplorerState => {
  switch (action.type) {
    case 'activePhotoChange':
      return {
        ...state,
        activePhotoId: action.photoId,
      };
    case 'photoZoomChange':
      return {
        ...state,
        photoZoomId: action.photoId,
      };
    case 'photoSelectionChange':
      if (state.selectedPhotos.includes(action.photoId)) {
        return {
          ...state,
          selectedPhotos: state.selectedPhotos.filter(
            (id) => id !== action.photoId
          ),
        };
      }
      return {
        ...state,
        selectedPhotos: [...state.selectedPhotos, action.photoId],
      };
    case 'photoSelectionCheck':
      return {
        ...state,
        selectedPhotos: state.selectedPhotos.filter((id) =>
          action.photoListData.find((photo) => photo.id === id)
        ),
      };
    case 'photoSelectAll':
      return {
        ...state,
        selectedPhotos:
          state.selectedPhotos.length > 0
            ? []
            : action.photoListData?.map((photo) => photo.id) || [],
      };
    case 'toggleMediumPreview':
      return {
        ...state,
        showMediumPreview: action.isShown,
      };
    default:
      return state;
  }
};

export const LAZY_LOAD_PAGE_SIZE = 30;

export type MapSettingsLocation =
  | 'eu'
  | 'au'
  | 'us'
  | 'apac'
  | 'hk'
  | 'default';

// implement with Record instead of Map to ensure all keys are present
export const MAP_SETTINGS: Record<
  MapSettingsLocation,
  {center: [number, number]; zoom: number}
> = {
  eu: {
    center: [50, 25],
    zoom: 4,
  },
  au: {
    center: [-26, 136],
    zoom: 4,
  },
  us: {
    center: [38.116386, -101.299591],
    zoom: 4,
  },
  apac: {center: [0, 95], zoom: 3},
  hk: {
    center: [22.28, 114.173],
    zoom: 16,
  },
  default: {
    center: [38, 20],
    zoom: 2,
  },
};

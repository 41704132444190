import React, {useEffect, useMemo, useReducer, useState} from 'react';
import {useTranslation} from 'react-i18next';

import _, {capitalize} from 'lodash';
import moment from 'moment';

import useAppContext from '../../hooks/useAppContext';
import {getCurrentUser} from '../../services/app';
import {Photo} from '../../services/photo';
import {
  getLocalStorage,
  getPhotoSeachList,
  Search,
} from '../../services/search';
import {LAZY_LOAD_PAGE_SIZE} from '../../utils/const';
import SearchLayout from './SearchLayout';
import searchReducer from './SearchReducer';

interface SearchContainerProps {}

const SearchContainer: React.FC<SearchContainerProps> = () => {
  const appContext = useAppContext();
  const initQueryState = useMemo(
    () => ({
      keyword: '',
      subLocation: false,
      keyPhoto: false,
      myPhoto: false,
    }),
    []
  );
  const [queryState, setQueryState] = useState<Search>(initQueryState);

  const initSearchState = useMemo(
    () => ({
      filter: {},
      photoList: {
        noMoreItems: false,
        loading: false,
        data: [],
      },
    }),
    []
  );
  const [searchState, dispatch] = useReducer(searchReducer, initSearchState);
  const [searchKeyword, setSearchKeyword] = useState<string>('');
  const [expandFilter, setExpandFilter] = useState<boolean>(true);

  const resetState = () => {
    setSearchKeyword('');
    setQueryState(initQueryState);
    setExpandFilter(true);
    dispatch({
      type: 'refresh',
    });
  };
  const handleQueryStateStateChange = (fieldName: string, value: unknown) => {
    setQueryState(
      (prevState: Search) =>
        ({
          ...prevState,
          [fieldName]: value,
        } as Search)
    );
  };
  useEffect(() => {
    handleQueryStateStateChange('keyword', searchKeyword);
  }, [searchKeyword]);

  useEffect(() => {
    dispatch({
      type: 'filterChange',
      filterState: queryState,
    });
  }, [queryState]);
  const {t: tran} = useTranslation();

  const mockShortCutList: {label: string; value: any}[] = [
    {
      label: `${capitalize(tran('search.last'))} 100`,
      value: {
        top: 100,
      },
    },
    {
      label: `${capitalize(tran('search.today'))}`,
      value: {
        dateFrom: moment().startOf('day'),
        dateTo: moment().endOf('day'),
      },
    },
    {
      label: `${capitalize(tran('search.yesterday'))}`,
      value: {
        dateFrom: moment().startOf('day').add(-1, 'days'),
        dateTo: moment().endOf('day').add(-1, 'days'),
      },
    },
    {
      label: `${capitalize(tran('common.this'))} ${capitalize(
        tran('search.week')
      )}`,
      value: {
        dateFrom: moment().startOf('week').add(1, 'days'),
        dateTo: moment().endOf('week').add(1, 'days'),
      },
    },
    {
      label: `${capitalize(tran('search.last'))} ${capitalize(
        tran('search.week')
      )}`,
      value: {
        dateFrom: moment().subtract(1, 'weeks').startOf('week').add(1, 'days'),
        dateTo: moment().subtract(1, 'weeks').endOf('week').add(1, 'days'),
      },
    },
    {
      label: `${capitalize(tran('common.this'))} ${capitalize(
        tran('search.month')
      )}`,
      value: {
        dateFrom: moment().startOf('months'),
        dateTo: moment().endOf('months'),
      },
    },
    {
      label: `${capitalize(tran('search.last'))} ${capitalize(
        tran('search.month')
      )}`,
      value: {
        dateFrom: moment().subtract(1, 'month').startOf('months'),
        dateTo: moment().subtract(1, 'month').endOf('months'),
      },
    },
    {
      label: `${capitalize(tran('common.my'))} ${capitalize(
        tran('search.last')
      )} 100`,
      value: {
        top: 100,
        myPhoto: true,
      },
    },
  ];
  const fretchDate = async (offset: number, serchObj: Search) => {
    dispatch({
      type: 'photoResultLoading',
    });
    const currentUserObj = await getCurrentUser(appContext?.serviceConfig);
    const photos = await getPhotoSeachList(
      _.get(appContext, 'activeAlbum.id') || 0,
      currentUserObj?.id,
      serchObj,
      {
        limit: LAZY_LOAD_PAGE_SIZE,
        offset,
      },
      appContext.serviceConfig
    );
    dispatch({
      type: 'photoResultLoaded',
      noMoreItems: photos.length < LAZY_LOAD_PAGE_SIZE,
      photoListData: photos,
    });
    return photos;
  };
  const handlePhotoListLazyLoad = async () => {
    if (_.isEqual(queryState, initQueryState)) return;
    setExpandFilter(false);
    const offset = searchState.photoList.data?.length || 0;
    dispatch({
      type: 'photoResultLoading',
    });
    fretchDate(offset, searchState.filter);
  };
  const handlePhotoUpdated = (photo: Photo) => {
    dispatch({
      type: 'photoUpdated',
      photo,
    });
  };

  const handlePhotoDeleted = (photoId: number) => {
    dispatch({
      type: 'photoDeleted',
      photoId,
    });
  };
  const handlePhotoSearch = (query: string) => {
    searchState.photoList.data = []; // clear search data for research
    handlePhotoListLazyLoad();
  };
  const shortCutSearch = async (shotKeyValue: Search) => {
    setExpandFilter(false);
    searchState.photoList.data = [];
    setQueryState({
      ...shotKeyValue,
    });
    dispatch({
      type: 'filterChange',
      filterState: queryState,
    });
    fretchDate(0, {
      ...shotKeyValue,
    });
  };
  return (
    <>
      <SearchLayout
        handleFormGridStateChange={handleQueryStateStateChange}
        formGridState={queryState}
        shortCutList={
          getLocalStorage()
            ? [
                ...mockShortCutList,
                {
                  label: `${capitalize(tran('search.last'))} ${capitalize(
                    tran('search')
                  )}`,
                  value:
                    getLocalStorage() !== null
                      ? JSON.parse(getLocalStorage().toString())
                      : {},
                },
              ]
            : mockShortCutList
        }
        toggleShortCut={_.isEqual(queryState, initQueryState)}
        shortCutSearch={shortCutSearch}
        expandFilter={expandFilter}
        setExpandFilter={setExpandFilter}
        handlePhotoSearch={handlePhotoSearch}
        photoList={searchState.photoList}
        onPhotoListLazyLoad={handlePhotoListLazyLoad}
        onPhotoUpdated={handlePhotoUpdated}
        onPhotoDeleted={handlePhotoDeleted}
        filter={searchState.filter}
        resetState={resetState}
        query={searchKeyword}
        setQuery={setSearchKeyword}
      />
    </>
  );
};

export default SearchContainer;

import {makeStyles} from '@material-ui/core';
import {ChevronLeft} from '@material-ui/icons';
import React, {ReactNode} from 'react';
import {useTranslation} from 'react-i18next';
import {NxpButton} from '../NxpButton';

interface NxpHeaderProps {
  titleContent: ReactNode;
  onBackClick?: () => void;
  actionContent?: ReactNode;
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(1, 0),
    '& > h1': {
      fontSize: theme.typography.h1.fontSize,
      margin: 0,
      display: 'inline-block',
      flexGrow: 1,
    },
  },
  backButton: {
    marginRight: 16,
  },
  actionContent: {
    display: 'inline-block',
    whiteSpace: 'nowrap',
  },
}));

const NxpHeader: React.FC<NxpHeaderProps> = ({
  titleContent,
  onBackClick,
  actionContent,
}) => {
  const classes = useStyles();
  const {t: tran} = useTranslation();
  return (
    <header className={classes.root}>
      {onBackClick && (
        <NxpButton
          className={classes.backButton}
          variant="outlined"
          onClick={onBackClick}
          size="small"
          startIcon={<ChevronLeft />}
        >
          {tran('common.back')}
        </NxpButton>
      )}
      <h1>{titleContent}</h1>
      {actionContent && (
        <div className={classes.actionContent}>{actionContent}</div>
      )}
    </header>
  );
};

export default NxpHeader;

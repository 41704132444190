import {PaginationQuery} from '../utils/typing/PaginationQuery';
import {
  Photo,
  photoOrientationOptions,
  searchPhotos,
  SearchQuery,
} from './photo';
import {ServiceConfig} from '../utils/backend';
import _ from 'lodash';
import moment from 'moment';
export type Search = {
  keyword?: string;
  dateFrom?: Date;
  dateTo?: Date;
  orientation?: string;
  myPhoto?: boolean;
  subLocation?: boolean;
  top?: number;
} & Partial<Pick<Photo, 'location' | 'keyPhoto' | 'photoType'>>;

export const getPhotoSeachList = async (
  albumId: number,
  currentId: string,
  filter: Search,
  pagination: PaginationQuery,
  serviceConfig: ServiceConfig
): Promise<Photo[]> => {
  setLocalStorage(filter);
  const query: SearchQuery = {albumId, ...pagination};

  _.forEach(filter, (filterField, field) => {
    switch (field) {
      case 'keyword':
        query.keyword = filter.keyword;
        break;
      case 'dateFrom':
        query.createdFrom =
          filter?.dateFrom !== undefined
            ? moment(new Date(filter?.dateFrom)).startOf('days').toDate()
            : undefined;
        break;
      case 'subLocation':
        query.includeSubLocation = filter.subLocation;
        break;
      case 'dateTo':
        query.createdTo =
          filter?.dateTo !== undefined
            ? moment(new Date(filter?.dateTo)).endOf('days').toDate()
            : undefined;
        break;
      case 'orientation':
        query.orientation = photoOrientationOptions.find(
          (option) => option.value === filter.orientation
        )?.name;
        break;
      case 'myPhoto':
        if (filter.myPhoto) {
          query.uploadedBy = currentId;
        }
        break;
      case 'location':
        query.locationCode = filter.location;
        break;
      case 'keyPhoto':
        query.keyPhotoOnly = filter.keyPhoto;
        break;
      case 'photoType':
        query.photoTypeCode = filter.photoType;
        break;
      case 'top':
        query.top = filter.top;
        break;
    }
  });
  return searchPhotos(query, serviceConfig);
};
export const setLocalStorage = (filter: Search) => {
  localStorage.setItem('lastSearchRecord', JSON.stringify(filter));
};
export const getLocalStorage = () => {
  return localStorage.getItem('lastSearchRecord') || false;
};

import React, {useRef} from 'react';
import {useTranslation} from 'react-i18next';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Chip,
  Fade,
  Grid,
  makeStyles,
  Typography,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import clsx from 'clsx';
import _ from 'lodash';

import AppPhotoList from '../../components/AppPhotoList';
import AppSearchBar from '../../components/AppSearchBar';
import {NxpButton} from '../../components/NxpButton';
import {NxpFormGrid} from '../../components/NxpFormGrid';
import NxpHeader from '../../components/NxpHeader';
import useAppContext from '../../hooks/useAppContext';
import {Photo, photoOrientationOptions} from '../../services/photo';
import {Search} from '../../services/search';
import {DateFnsFormatPattern} from '../../utils/date/formatDate';
import {AsyncLazyLoadResult} from '../../utils/typing/AsyncLazyLoadResult';

const useStyles = makeStyles((theme) => ({
  section: {
    '&.search-container': {
      display: 'flex',
    },
    '& .MuiAccordion-root': {
      background: 'transparent',
      '& .MuiAccordionSummary-root, & .MuiAccordionDetails-root': {
        padding: 0,
        justifyContent: 'flex-start',
        '& .MuiAccordionSummary-content': {
          flexGrow: 'inherit',
          '&.Mui-expanded': {
            margin: 0,
          },
        },
      },
    },
    '&~ .MuiCircularProgress-root': {
      display: 'none',
    },
  },
  header: {
    color: '#3E3F42',
    fontSize: theme.typography.pxToRem(28),
    fontWeight: theme.typography.fontWeightBold as number,
  },
  title: {
    fontWeight: theme.typography.fontWeightBold as number,
    fontSize: theme.typography.pxToRem(14),
    textTransform: 'uppercase',
  },
  shortCut: {
    margin: theme.spacing(2, 0),
    '& .MuiGrid-item': {
      margin: theme.spacing(0.5),
      '& .MuiChip-root': {
        cursor: 'pointer',
        backgroundColor: 'rgba(138, 153, 153, 0.2)',
        '& .MuiChip-label': {
          margin: theme.spacing(0, 1),
          fontSize: theme.typography.pxToRem(16),
        },
      },
    },
  },
  filter: {
    '& .react-dropdown-tree-select': {
      '& .tag-item ': {
        '& .search': {
          '&::placeholder': {
            color: theme.palette.grey[700],
            fontSize: theme.typography.pxToRem(16),
          },
        },
      },
    },
    '& .NxpFormGridItem': {
      padding: theme.spacing(0, 0.5),
      marginRight: theme.spacing(2),
    },
    margin: theme.spacing(1, 0),
    display: 'flex',
    justifyContent: 'stretch',
    '& .NxpDatePicker': {
      maxWidth: 150,
      minWidth: 150,
    },
    '& .MuiSelect-root': {
      '&.MuiSelect-select': {
        minWidth: 150,
      },
    },
  },
  rowITem: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'left',
    alignItems: 'center',
  },
  seperator: {
    width: 26,
  },
}));

interface SearchLayoutProps {
  handleFormGridStateChange: (fieldName: string, value: unknown) => void;
  formGridState: Search;
  shortCutList: {label: string; value: any}[];
  shortCutSearch: (shortKeyValue: Search) => void;
  expandFilter: boolean;
  query: string;
  setQuery: (keyword: string) => void;
  setExpandFilter: (expanded: boolean) => void;
  handlePhotoSearch: (query: string) => void;
  photoList: AsyncLazyLoadResult<Photo[]>;
  onPhotoListLazyLoad: () => void;
  onPhotoUpdated: (photo: Photo) => void;
  onPhotoDeleted: (photoId: number) => void;
  filter: Search;
  resetState: () => void;
  toggleShortCut: boolean;
}

const SearchLayout: React.FC<SearchLayoutProps> = ({
  handleFormGridStateChange,
  formGridState,
  shortCutList,
  expandFilter,
  setExpandFilter,
  handlePhotoSearch,
  onPhotoListLazyLoad,
  onPhotoDeleted,
  onPhotoUpdated,
  photoList,
  filter,
  resetState,
  query,
  setQuery,
  shortCutSearch,
  toggleShortCut,
}) => {
  const appContext = useAppContext();
  const classes = useStyles();
  const locationSelectRef = useRef<HTMLDivElement>(undefined!);
  const {t: tran} = useTranslation();
  return (
    <div className="result-container">
      <AppPhotoList
        headerTitleContent={
          <>
            <NxpHeader
              titleContent={`${_.capitalize(tran('search'))} ${
                filter?.keyword && _.get(photoList, 'data', []).length > 0
                  ? `${_.capitalize(tran('common.result'))} ${_.capitalize(
                      tran('common.for')
                    )} ${filter?.keyword}`
                  : ''
              }`}
            />
            <div className={clsx('search-container', classes.section)}>
              <AppSearchBar
                size={12}
                query={query}
                setQuery={setQuery}
                handleOnSearch={handlePhotoSearch}
              />
              <NxpButton
                color="primary"
                variant="contained"
                onClick={resetState}
              >
                {_.capitalize(tran('common.reset'))}
              </NxpButton>
            </div>

            <div className={clsx('filter-container', classes.section)}>
              <Accordion
                square
                expanded={expandFilter}
                onChange={() => setExpandFilter(!expandFilter)}
                elevation={0}
                aria-controls="search-filtering"
              >
                <AccordionSummary
                  aria-controls="panel1d-content"
                  id="panel1d-header"
                  expandIcon={
                    <ExpandMoreIcon
                      color="primary"
                      aria-label="expanded filter"
                    />
                  }
                >
                  <Typography
                    component="div"
                    color="primary"
                    className={classes.title}
                  >
                    {_.capitalize(tran('common.filter'))}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <div className={classes.filter}>
                    <Grid container direction="column">
                      <Grid item className={classes.rowITem}>
                        <NxpFormGrid
                          editing={true}
                          gridColumnCount={1}
                          formState={formGridState}
                          onFormStateChange={handleFormGridStateChange}
                          fields={[
                            {
                              itemColumnSpan: 1,
                              fieldName: 'photoType',
                              fieldType: 'select',
                              onFormStateChange: handleFormGridStateChange,
                              label: tran('photo.photoType'),
                              selectItems: appContext.photoTypeOptions,
                              value: _.get(formGridState, 'photoType', ''),
                              placeholder: `${_.capitalize(
                                tran('common.please')
                              )} ${_.capitalize(tran('common.select'))} `,
                            },
                          ]}
                        />
                        <NxpFormGrid
                          editing={true}
                          gridColumnCount={1}
                          formState={formGridState}
                          onFormStateChange={handleFormGridStateChange}
                          fields={[
                            {
                              itemColumnSpan: 1,
                              fieldName: 'location',
                              fieldType: 'tree-select',
                              onFormStateChange: handleFormGridStateChange,
                              label: tran('photo.location'),
                              treeSelectItems: appContext.locationOptions,
                              placeholder: `${_.capitalize(
                                tran('common.please')
                              )} ${_.capitalize(tran('common.select'))} `,
                              selectRef: locationSelectRef,
                            },
                          ]}
                        />
                        <NxpFormGrid
                          editing={true}
                          gridColumnCount={1}
                          formState={formGridState}
                          onFormStateChange={handleFormGridStateChange}
                          fields={[
                            {
                              itemColumnSpan: 1,
                              fieldName: 'dateFrom',
                              fieldType: 'date',
                              onFormStateChange: handleFormGridStateChange,
                              label: `${_.capitalize(
                                tran('common.date')
                              )} ${_.capitalize(tran('common.from'))}`,
                              readModeDatePattern: DateFnsFormatPattern.date,
                              value: formGridState?.dateFrom || null,
                            },
                          ]}
                        />
                        <Box
                          display="flex"
                          pr={2}
                          justifyContent="center"
                          alignItems="center"
                        >
                          <Box component="div" mt={2}>
                            {_.capitalize(tran('common.to'))}
                          </Box>
                        </Box>
                        <NxpFormGrid
                          editing={true}
                          gridColumnCount={1}
                          formState={formGridState}
                          onFormStateChange={handleFormGridStateChange}
                          fields={[
                            {
                              itemColumnSpan: 1,
                              fieldName: 'dateTo',
                              fieldType: 'date',
                              onFormStateChange: handleFormGridStateChange,
                              label: `${_.capitalize(
                                tran('common.date')
                              )} ${_.capitalize(tran('common.to'))}`,
                              readModeDatePattern: DateFnsFormatPattern.date,
                              value: formGridState?.dateTo || null,
                            },
                          ]}
                        />
                        <NxpFormGrid
                          editing={true}
                          gridColumnCount={1}
                          formState={formGridState}
                          onFormStateChange={handleFormGridStateChange}
                          fields={[
                            {
                              itemColumnSpan: 1,
                              fieldName: 'orientation',
                              fieldType: 'select',
                              onFormStateChange: handleFormGridStateChange,
                              label: _.capitalize(tran('photo.orientation')),
                              selectItems: photoOrientationOptions,
                              value: _.get(formGridState, 'orientation', ''),
                              placeholder: `${_.capitalize(
                                tran('common.please')
                              )} ${_.capitalize(tran('common.select'))} `,
                            },
                          ]}
                        />
                      </Grid>

                      <Grid item className={classes.rowITem}>
                        <NxpFormGrid
                          editing={true}
                          gridColumnCount={1}
                          formState={formGridState}
                          onFormStateChange={handleFormGridStateChange}
                          fields={[
                            {
                              itemColumnSpan: 1,
                              fieldName: 'myPhoto',
                              fieldType: 'checkbox',
                              onFormStateChange: handleFormGridStateChange,
                              label: `${_.capitalize(
                                tran('common.my')
                              )} ${_.capitalize(tran('common.photo'))} `,
                              fullWidth: true,
                            },
                          ]}
                        />
                        <NxpFormGrid
                          editing={true}
                          gridColumnCount={1}
                          formState={formGridState}
                          onFormStateChange={handleFormGridStateChange}
                          fields={[
                            {
                              itemColumnSpan: 1,
                              fieldName: 'subLocation',
                              fieldType: 'checkbox',
                              onFormStateChange: handleFormGridStateChange,
                              label: `${_.capitalize(
                                tran('photo.sublocation')
                              )} ${_.capitalize(tran('photo.location'))} `,
                              fullWidth: true,
                            },
                          ]}
                        />
                        <NxpFormGrid
                          editing={true}
                          gridColumnCount={1}
                          formState={formGridState}
                          onFormStateChange={handleFormGridStateChange}
                          fields={[
                            {
                              itemColumnSpan: 1,
                              fieldName: 'keyPhoto',
                              fieldType: 'checkbox',
                              onFormStateChange: handleFormGridStateChange,
                              label: tran('photo.keyPhoto'),
                              fullWidth: true,
                            },
                          ]}
                        />
                      </Grid>
                    </Grid>
                  </div>
                </AccordionDetails>
              </Accordion>
            </div>
            {toggleShortCut && _.get(photoList, 'data', []).length === 0 && (
              <Fade
                in={toggleShortCut && _.get(photoList, 'data', []).length === 0}
              >
                <div className={clsx('shortCut-container', classes.section)}>
                  <Typography
                    component="div"
                    color="primary"
                    className={classes.title}
                  >
                    {tran('search.shortcut')}
                  </Typography>
                  <Box component="div" className={classes.shortCut}>
                    <Grid container>
                      {shortCutList.map((shortCut, i) => (
                        <Grid item key={i}>
                          <Box
                            component="span"
                            onClick={() => shortCutSearch(shortCut.value)}
                          >
                            <Chip label={shortCut?.label} clickable />
                          </Box>
                        </Grid>
                      ))}
                    </Grid>
                  </Box>
                </div>
              </Fade>
            )}
          </>
        }
        headerActionContent={(selectedPhotos: number[]) => {
          // if (
          //   selectedPhotos.length === 0 &&
          //   appContext.hasUserAccessRight('photo-upload')
          // ) {
          return <></>;
          // }
        }}
        noMoreItems={photoList.noMoreItems}
        loading={photoList.loading}
        photoListData={photoList.data ? photoList.data : []}
        onLazyLoad={onPhotoListLazyLoad}
        onPhotoDeleted={onPhotoDeleted}
        onPhotoUpdated={onPhotoUpdated}
      />
    </div>
  );
};

export default SearchLayout;

import React from 'react';
import {
  KeyboardDatePicker,
  KeyboardDatePickerProps,
} from '@material-ui/pickers';
import {makeStyles} from '@material-ui/core';
import clsx from 'clsx';
import {useTranslation} from 'react-i18next';

export interface NxpDatePickerBaseProps extends KeyboardDatePickerProps {}

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiInputBase-root': {
      border: `1px solid ${theme.palette.border.main}`,
      borderRadius: theme.shape.borderRadius,
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      '& input::placeholder': {
        fontSize: theme.typography.pxToRem(12),
        color: '#8A9999',
      },
      '&.Mui-error': {
        borderColor: theme.palette.error.main,
      },
    },
    '& .MuiInputAdornment-root button': {marginRight: -10},
  },
}));

const NxpDatePickerBase = React.forwardRef<
  HTMLDivElement,
  NxpDatePickerBaseProps
>(function NxpDatePickerBase(
  {className, InputLabelProps, fullWidth = true, ...restProps},
  ref
) {
  const classes = useStyles();
  const {t} = useTranslation();
  return (
    <KeyboardDatePicker
      ref={ref} // ref of mui DatePicker is not being set properly at the moment, try use the another property - TextFieldComponent - https://stackoverflow.com/a/60441524/8252769
      className={clsx('NxpDatePicker', classes.root, className)}
      autoOk
      clearable
      views={['year', 'month', 'date']}
      variant="inline"
      format="yyyy-MM-dd"
      margin="none"
      KeyboardButtonProps={{
        'aria-label': t('nui.datePicker.changeDate'),
      }}
      placeholder={t('datePicker.placeholder')}
      fullWidth={fullWidth}
      InputLabelProps={{shrink: true, ...InputLabelProps}}
      InputProps={{
        disableUnderline: true,
      }}
      {...restProps}
    />
  );
});

export default NxpDatePickerBase;

import {ObjectRole} from '../../services/objectRoleAssignment';

interface ObjectRoleAssignmentState {
  objectRoles: ObjectRole[];
  assigneeIdsByRoleId: Record<string, string[]>;
  modifiedObjectRoleIds: number[];
  deletedObjectRoleIds: number[];
}

type ObjectRoleAssignmentAction =
  | {
      type: 'objectRolesLoaded';
      objectRoles: ObjectRole[];
    }
  | {
      type: 'objectRoleAdded';
      objectRole: ObjectRole;
    }
  | {
      type: 'objectRoleEdited';
      objectRole: ObjectRole;
    }
  | {
      type: 'objectRoleDeleted';
      objectRoleId: number;
    }
  | {
      type: 'assigneesChanged';
      objectRoleId: number;
      assigneeIds: string[];
    }
  | {
      type: 'assigneesSaved';
      objectRole: ObjectRole;
    }
  | {
      type: 'assigneesDiscarded';
    };

export const objectRoleAssignmenReducer = (
  state: ObjectRoleAssignmentState,
  action: ObjectRoleAssignmentAction
): ObjectRoleAssignmentState => {
  switch (action.type) {
    case 'objectRolesLoaded': {
      const assigneeIdsByRoleId = {} as Record<string, string[]>;
      for (const role of action.objectRoles) {
        assigneeIdsByRoleId[role.id] = role.users.map((user) => user.id);
      }
      return {
        ...state,
        objectRoles: action.objectRoles,
        assigneeIdsByRoleId: assigneeIdsByRoleId,
        modifiedObjectRoleIds: [],
        deletedObjectRoleIds: [],
      };
    }
    case 'objectRoleAdded': {
      const objectRole = action.objectRole;
      if (!objectRole.users) {
        objectRole.users = [];
      }
      return {
        ...state,
        objectRoles: [...state.objectRoles, action.objectRole],
        assigneeIdsByRoleId: {
          ...state.assigneeIdsByRoleId,
          [objectRole.id]: objectRole.users.map((user) => user.id),
        },
      };
    }
    case 'objectRoleEdited': {
      const objectRoles = [...state.objectRoles];
      const objectRole = action.objectRole;

      const index = objectRoles.findIndex((role) => role.id === objectRole.id);
      if (index !== -1) {
        objectRoles[index] = objectRole;
      }

      return {
        ...state,
        objectRoles,
      };
    }
    case 'objectRoleDeleted': {
      return {
        ...state,
        deletedObjectRoleIds: [
          ...state.deletedObjectRoleIds,
          action.objectRoleId,
        ],
      };
    }
    case 'assigneesChanged': {
      const modifiedObjectRoleIds = [...state.modifiedObjectRoleIds];
      if (!modifiedObjectRoleIds.includes(action.objectRoleId)) {
        modifiedObjectRoleIds.push(action.objectRoleId);
      }

      return {
        ...state,
        assigneeIdsByRoleId: {
          ...state.assigneeIdsByRoleId,
          [action.objectRoleId]: action.assigneeIds,
        },
        modifiedObjectRoleIds,
      };
    }
    case 'assigneesSaved': {
      const objectRole = action.objectRole;

      const objectRoles = state.objectRoles;
      const index = objectRoles.findIndex((role) => role.id === objectRole.id);
      objectRoles[index] = objectRole;

      const modifiedObjectRoleIds = state.modifiedObjectRoleIds.filter(
        (id) => id !== objectRole.id
      );

      return {
        ...state,
        objectRoles,
        assigneeIdsByRoleId: {
          ...state.assigneeIdsByRoleId,
          [objectRole.id]: objectRole.users.map((user) => user.id),
        },
        modifiedObjectRoleIds,
      };
    }
    case 'assigneesDiscarded': {
      const assigneeIdsByRoleId = {} as Record<string, string[]>;
      for (const role of state.objectRoles) {
        assigneeIdsByRoleId[role.id] = role.users.map((user) => user.id);
      }
      return {
        ...state,
        assigneeIdsByRoleId: assigneeIdsByRoleId,
        modifiedObjectRoleIds: [],
      };
    }
    default:
      return state;
  }
};

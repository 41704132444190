import React from 'react';

import NxpFormControl, {
  NxpFormControlPropsForExtends,
} from '../NxpFormControl/NxpFormControl';
import buildRandomId from '../../utils/buildRandomId';
import {useConst} from '../../hooks/useConst';
import NxpMultiChipBase, {NxpMultiChipBaseProps} from './NxpMultiChipBase';

export interface NxpMultiChipProps
  extends Omit<NxpMultiChipBaseProps, 'ref'>,
    Omit<NxpFormControlPropsForExtends, 'ref'> {
  selectRef?: React.Ref<unknown>;
}

const NxpMultiChip = React.forwardRef<HTMLDivElement, NxpMultiChipProps>(
  function NxpMultiChip(
    {
      label,
      errorContent,
      id,
      required,
      formControlProps,
      fullWidth,
      selectRef,
      ...restProps
    },
    ref
  ) {
    const idConst = useConst(() => id || buildRandomId('select'));

    return (
      <NxpFormControl
        label={label}
        errorContent={errorContent}
        required={required}
        fullWidth={fullWidth}
        {...formControlProps}
        inputLabelProps={{id: `${idConst}-label`}}
        // inputControlId={id} don't set inputControlId as the <label> element's for attribute does not work with mui select control
        ref={ref}
      >
        <NxpMultiChipBase ref={selectRef} id={idConst} {...restProps} />
      </NxpFormControl>
    );
  }
);

export default NxpMultiChip;

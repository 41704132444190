// import {useTranslation} from 'react-i18next';
import './i18n';

import React from 'react';
import DateFnsUtils from '@date-io/date-fns';
import {CssBaseline, ThemeProvider} from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';
import {MuiPickersUtilsProvider} from '@material-ui/pickers';
import {SnackbarProvider} from 'notistack';
import {ReactKeycloakProvider} from '@react-keycloak/web';

import AppRouter from '../AppRouter';
import getKeycloakInstance from './keycloak';

import 'react-image-lightbox/style.css';
import styles from './App.module.scss';
import {theme} from './theme';
import NxpBackToTop from '../NxpBackToTop';
import useAppTimer from './useAppTimer';
import AppContextProvider from '../AppContext/AppContextProvider';

export interface UserInfo {
  username: string;
  email: string;
  token: string;
}

function App() {
  // const {t} = useTranslation();

  // const eventLogger = (event: unknown, error: unknown) => {
  //   console.log('onKeycloakEvent', event, error);
  // };

  const tokenLogger = (tokens: unknown) => {
    console.log('onKeycloakTokens', tokens);
  };

  // ref for handling closing snackbar by user action - https://iamhosseindhv.com/notistack/demos#persist-snackbar
  const notistackRef = React.useRef<SnackbarProvider>(null);

  // should be handled as ReactKeycloakProvider's autoRefresh token is set to true by default
  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     if (keycloakInstance && keycloakInstance.authenticated) {
  //       keycloakInstance
  //         .updateToken(30)
  //         .then((res) => {
  //           console.log('sssssssssssssss');
  //           console.log(res);
  //         })
  //         .catch((ex) => {
  //           console.log('eeeeeeeeeeeee');
  //           console.log(ex);
  //         });
  //     }
  //   }, 1000 * 6); // 1 min
  //   return () => {
  //     clearInterval(interval);
  //   };
  // }, []);

  // for tracking idle time and token expiry
  useAppTimer(getKeycloakInstance());

  return (
    <ReactKeycloakProvider
      authClient={getKeycloakInstance()}
      // onEvent={eventLogger}
      onTokens={tokenLogger}
      initOptions={{
        onLoad: 'login-required',
        flow: 'implicit',
      }}
    >
      <ThemeProvider theme={theme}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <CssBaseline />
          <SnackbarProvider
            ref={notistackRef}
            className="appSnackBar"
            maxSnack={4}
            // autoHideDuration={100000} // for easier debugging in browser
            classes={{
              anchorOriginBottomCenter: 'snackBarAnchorOrigin',
              variantSuccess: 'snackBarSuccess',
            }}
            iconVariant={{
              error: <ErrorIcon className={styles.snackbarErrorIcon} />,
            }}
            anchorOrigin={{
              horizontal: 'center',
              vertical: 'bottom',
            }}
          >
            <AppContextProvider notistackRef={notistackRef}>
              <div className={styles.app}>
                <AppRouter />
              </div>
              <NxpBackToTop />
            </AppContextProvider>
          </SnackbarProvider>
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    </ReactKeycloakProvider>
  );
}

export default App;

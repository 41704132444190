import React from 'react';
import {
  CircularProgress,
  makeStyles,
  TextField,
  useTheme,
} from '@material-ui/core';
import {Autocomplete} from '@material-ui/lab';

import {NxpButton} from '../../../components/NxpButton';
import {NxpDialog} from '../../../components/NxpDialog';
import {User} from '../../../services/app';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(3, 1, 0),
    '& .MuiCircularProgress-root': {
      display: 'block',
      margin: '0 auto',
      marginBottom: theme.spacing(2),
    },
  },
  textInput: {
    margin: theme.spacing(2, 0),
    border: `1px solid ${theme.palette.border.main}`,
    borderRadius: theme.shape.borderRadius,
    '& .MuiInputLabel-shrink': {
      marginTop: -10,
      marginLeft: -15,
    },
    '& fieldset': {
      border: 'none',
      outline: 'none',
    },
  },
}));

interface ShareLightboxModalProps {
  shareAccess?: {userId: string; allowEdit: boolean}[];
  modalOpen: boolean;
  submitLoading: boolean;
  userOptions: User[];
  onUserSearch: (searchText: string) => void;
  onShareAccessChange: (userIds: string[], allowEdit: boolean) => void;
  onSave: () => void;
  onModalClose: () => void;
}

const ShareLightboxModal: React.FC<ShareLightboxModalProps> = ({
  shareAccess,
  modalOpen,
  submitLoading,
  userOptions,
  onUserSearch,
  onShareAccessChange,
  onSave,
  onModalClose,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const handleUserFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onUserSearch(e.target.value);
  };

  // const [testOptions, setTestOptions] = useState([
  //   {id: 1, name: 'test 1'},
  //   {id: 2, name: 'test 2'},
  //   {id: 3, name: 'test 3'},
  //   {id: 4, name: 'test 4'},
  //   {id: 5, name: 'test 5'},
  // ]);

  // const [testValues, setTestValues] = useState<{id: number; name: string}[]>(
  //   []
  // );

  // const handleTestAutocompleteChange = (
  //   newValues: {
  //     id: number;
  //     name: string;
  //   }[]
  // ) => {
  //   setTestValues((prevState) => [...newValues]);
  // };

  return (
    <NxpDialog
      open={modalOpen}
      onClose={onModalClose}
      titleContent="Share Lightbox"
      actionContent={
        <>
          <NxpButton disabled={submitLoading} onClick={onSave}>
            Save
          </NxpButton>
        </>
      }
    >
      <div className={classes.root}>
        {/* for testing dynamic options search with api only <Autocomplete
          multiple
          options={testOptions}
          value={testValues}
          getOptionLabel={(item) => item.name}
          onChange={(_, newValue) => handleTestAutocompleteChange(newValue)}
          onInputChange={(e) => {
            setTimeout(
              () =>
                setTestOptions([
                  {
                    id: 1,
                    name: (e.target as any).value + ' 1',
                  },
                  {
                    id: 2,
                    name: (e.target as any).value + ' 2',
                  },
                  {
                    id: 3,
                    name: (e.target as any).value + ' 3',
                  },
                  {
                    id: 4,
                    name: (e.target as any).value + ' 4',
                  },
                  {
                    id: 5,
                    name: (e.target as any).value + ' 5',
                  },
                ]),
              500
            );
          }}
          renderInput={(params) => (
            <TextField
              className={classes.textInput}
              {...params}
              InputLabelProps={{shrink: true}}
              variant="outlined"
              label="Read-only"
              placeholder="Please enter"
            />
          )}
        /> */}
        {!shareAccess ? (
          <CircularProgress size={theme.dimension.circularProgressSize.main} />
        ) : (
          <>
            <Autocomplete
              multiple
              size="small"
              options={userOptions.filter(
                (user) =>
                  !shareAccess.find((access) => access.userId === user.id)
              )}
              getOptionLabel={(option) => option.name || ''}
              value={shareAccess
                .filter((access) => !access.allowEdit)
                .map((access) => ({
                  id: access.userId,
                  name: userOptions.find((user) => user.id === access.userId)
                    ?.name,
                }))}
              onChange={(_, newValue) =>
                onShareAccessChange(
                  newValue.map((user) => user.id),
                  false
                )
              }
              renderInput={(params) => (
                <TextField
                  className={classes.textInput}
                  {...params}
                  InputLabelProps={{shrink: true}}
                  variant="outlined"
                  label="Read-only"
                  placeholder="Please enter"
                  onChange={handleUserFilterChange}
                />
              )}
            />
            <Autocomplete
              multiple
              size="small"
              options={userOptions.filter(
                (user) =>
                  !shareAccess.find((access) => access.userId === user.id)
              )}
              getOptionLabel={(option) => option.name || ''}
              filterSelectedOptions
              value={shareAccess
                .filter((access) => access.allowEdit)
                .map((access) => ({
                  id: access.userId,
                  name: userOptions.find((user) => user.id === access.userId)
                    ?.name,
                }))}
              onChange={(_, newValue) =>
                onShareAccessChange(
                  newValue.map((user) => user.id),
                  true
                )
              }
              renderInput={(params) => (
                <TextField
                  className={classes.textInput}
                  {...params}
                  InputLabelProps={{shrink: true}}
                  variant="outlined"
                  label="Allow Edit"
                  placeholder="Please enter"
                  onChange={handleUserFilterChange}
                />
              )}
            />
          </>
        )}
      </div>
    </NxpDialog>
  );
};

// memorize to avoid autocomplete text input filtering issue
export default React.memo(ShareLightboxModal);

import {useState} from 'react';

import {NxpButton} from '../../NxpButton';
import AddToLightboxContainer from './AddToLightboxContainer';
import {useTranslation} from 'react-i18next';

interface AddToLightboxButtonProps {
  selectedPhotos: number[];
  onAddToLightboxCompleted: () => void; // for deselecting selected photos after completing add to lightbox
}

const AddToLightboxButton: React.FC<AddToLightboxButtonProps> = ({
  selectedPhotos,
  onAddToLightboxCompleted,
}) => {
  const {t: tran} = useTranslation();
  const [modalOpen, setModalOpen] = useState(false);

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const handleClick = () => {
    setModalOpen(true);
  };

  return (
    <>
      <NxpButton onClick={handleClick}>
        {tran('common.add')} {tran('common.to')} {tran('photo.lightbox')}
      </NxpButton>
      {modalOpen && (
        <AddToLightboxContainer
          onAddToLightboxCompleted={onAddToLightboxCompleted}
          modalOpen={modalOpen}
          selectedPhotos={selectedPhotos}
          onModalClose={handleModalClose}
        />
      )}
    </>
  );
};

export default AddToLightboxButton;

import _ from 'lodash';
import React from 'react';
import {useTranslation} from 'react-i18next';

import {NxpButton} from '../../../components/NxpButton';
import {NxpDialog} from '../../../components/NxpDialog';
import {NxpFormGrid} from '../../../components/NxpFormGrid';
import {ValidationResult} from '../../../hooks/useValidate';
import {LightboxForm} from '../../../services/lightbox';

interface LightboxFormModalProps {
  modalOpen: boolean;
  form: LightboxForm;
  formError: ValidationResult<LightboxForm>;
  submitLoading: boolean;
  onFormStateChange: (fieldName: keyof LightboxForm, value: unknown) => void;
  onSave: () => void;
  onModalClose: () => void;
  onDelete: () => void;
}

const LightboxFormModal: React.FC<LightboxFormModalProps> = ({
  modalOpen,
  form,
  formError,
  submitLoading,
  onFormStateChange,
  onSave,
  onModalClose,
}) => {
  const {t: tran} = useTranslation();
  return (
    <NxpDialog
      onClick={(e) => e.stopPropagation()} // added to avoid triggering onClick of lightbox card in /lightboxes
      maxWidth="xs"
      onClose={onModalClose}
      open={modalOpen}
      titleContent={`${
        form.id ? tran('common.update') : tran('common.create')
      } ${tran('common.lightbox')}`}
      actionContent={
        <>
          <NxpButton disabled={submitLoading} onClick={onSave}>
            {tran('common.save')}
          </NxpButton>
        </>
      }
    >
      <NxpFormGrid
        editing={true}
        gridColumnCount={4}
        formState={form}
        onFormStateChange={onFormStateChange}
        fields={[
          {
            itemColumnSpan: 4,
            fieldName: 'name',
            fieldType: 'text',
            label: `${_.capitalize(tran('common.lightbox'))} ${_.capitalize(
              tran('common.name')
            )}`,
            required: true,
            errorContent: formError.name,
          },
        ]}
      />
    </NxpDialog>
  );
};

export default LightboxFormModal;

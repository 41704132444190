import React, {useEffect, useState} from 'react';
import {Photo, UploadPhoto} from '../../../../services/photo';
import AppPhotoGeneralDetailLayout from './AppPhotoGeneralDetailLayout';
import {Divider, Grid, Typography, Box, Paper} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import {NxpButton} from '../../../NxpButton';
import _ from 'lodash';
import useAppContext from '../../../../hooks/useAppContext';
import {useTranslation} from 'react-i18next';
interface PhotoGeneralDetailFormProps {
  photo: UploadPhoto;
  handlePhotoDetailUpdate: (detail: Partial<Photo>) => void;
}

const AppPhotoGeneralDetailContainer: React.FC<PhotoGeneralDetailFormProps> = ({
  photo,
  handlePhotoDetailUpdate,
}) => {
  const appContext = useAppContext();
  const {t: tran} = useTranslation();
  useEffect(() => {
    setFormGridState({
      ...photo,
      location: _.get(photo, 'location.code', photo?.location),
      photoType: _.get(photo, 'photoType.code', photo?.photoType),
    });
    return () => {
      setFormGridState({});
      setEditing(false);
    };
  }, [photo]);
  const [formGridState, setFormGridState] = useState<UploadPhoto>({});
  const [editing, setEditing] = useState<boolean>(false);
  const cancelChange = () => {
    setFormGridState({
      ...photo,
      location: _.get(photo, 'location.code', photo?.location),
      photoType: _.get(photo, 'photoType.code', photo?.photoType),
    });
    setEditing(false);
  };
  const saveChange = () => {
    handlePhotoDetailUpdate(formGridState);
    setEditing(false);
  };
  return (
    <>
      <Paper
        component="form"
        elevation={0}
        onSubmit={(e) => {
          e.preventDefault();
          saveChange();
        }}
      >
        <Grid
          container
          justify="space-between"
          alignItems="center"
          className="photo-general"
        >
          <Grid item xs={4}>
            <Typography variant="h1" component="h2">
              {_.capitalize(tran('photo.general'))}
            </Typography>
          </Grid>
          <Grid item>
            {!editing ? (
              appContext.hasUserAccessRight('photo-edit', 'album') && (
                <NxpButton
                  color="primary"
                  variant="text"
                  startIcon={<EditIcon />}
                  onClick={() => setEditing(true)}
                >
                  {tran('common.edit')}
                </NxpButton>
              )
            ) : (
              <>
                <NxpButton
                  color="default"
                  onClick={() => cancelChange()}
                  className="cancel"
                  variant="text"
                >
                  {tran('common.cancel')}
                </NxpButton>
                <NxpButton color="primary" type="submit" variant="text">
                  {tran('common.save')}
                </NxpButton>
              </>
            )}
          </Grid>
        </Grid>
        <Divider />
        <Box component="div" className="AppPhotoGeneralDetail">
          <AppPhotoGeneralDetailLayout
            form={formGridState}
            isEditing={editing}
            updateGeneralDetail={setFormGridState}
          />
        </Box>
      </Paper>
    </>
  );
};

export default AppPhotoGeneralDetailContainer;

import React from 'react';
import {makeStyles} from '@material-ui/core';

import AppPhotoList from '../../components/AppPhotoList';
import NxpHeader from '../../components/NxpHeader';
import {Photo} from '../../services/photo';
import {AsyncLazyLoadResult} from '../../utils/typing/AsyncLazyLoadResult';
import ExplorerFilter from './ExplorerFilter';
import {ExplorerFilterViewBy} from './ExplorerFilter/ExplorerFilterContainer';
import UploadingFilesButtonButton from './ExplorerUploadingFiles/UploadingFilesButton';
import useAppContext from '../../hooks/useAppContext';
import _ from 'lodash';
import {useTranslation} from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'stretch',
    minHeight: 'calc(100vh - 85px)',
  },
}));

interface ExplorerLayoutProps {
  entityId?: string;
  albumId?: number;
  photoList: AsyncLazyLoadResult<Photo[]>;
  filterRefreshTracker?: Date;
  onFilterChange: (viewBy: ExplorerFilterViewBy, filterValue: string) => void;
  onPhotoListLazyLoad: () => void;
  onPhotoUpdated: (photo: Photo) => void;
  onPhotoCreated: (photos: Photo[]) => void;
  onPhotoDeleted: (photoId: number) => void;
}

const ExplorerLayout: React.FC<ExplorerLayoutProps> = ({
  entityId,
  albumId,
  photoList,
  filterRefreshTracker,
  onFilterChange,
  onPhotoListLazyLoad,
  onPhotoUpdated,
  onPhotoCreated,
  onPhotoDeleted,
}) => {
  const classes = useStyles();
  const appContext = useAppContext();
  const {t: tran} = useTranslation();

  const canAddToLightbox =
    appContext.hasUserAccessRight('lightbox-edit', 'album') ||
    appContext.hasUserAccessRight('lightbox-create', 'album');

  return (
    <div className={classes.root}>
      <ExplorerFilter
        entityId={entityId}
        albumId={albumId}
        refreshTracker={filterRefreshTracker}
        onFilterChange={onFilterChange}
      />
      <AppPhotoList
        headerTitleContent={
          <NxpHeader titleContent={_.capitalize(tran('explorer'))} />
        }
        headerActionContent={(selectedPhotos: number[]) => {
          if (
            selectedPhotos.length === 0 &&
            appContext.hasUserAccessRight('photo-upload', 'album')
          ) {
            return (
              <UploadingFilesButtonButton onPhotoCreated={onPhotoCreated} />
            );
          }
        }}
        hideAddToLightbox={!canAddToLightbox}
        noMoreItems={photoList.noMoreItems}
        loading={photoList.loading}
        photoListData={photoList.data ? photoList.data : []}
        onLazyLoad={onPhotoListLazyLoad}
        onPhotoDeleted={onPhotoDeleted}
        onPhotoUpdated={onPhotoUpdated}
      />
    </div>
  );
};

export default ExplorerLayout;

import _ from 'lodash';

export default function getCurrentLink(
  postlocation: Partial<Location>,
  equalLinkList: string[],
  equalClass: string,
  elseClass: string
) {
  const accessRoute = _.last(_.get(postlocation, 'pathname', '').split('/'));
  return _.includes(equalLinkList, _.toString(accessRoute))
    ? equalClass
    : elseClass;
}

import React, {useReducer} from 'react';
import {useTranslation} from 'react-i18next';
import {useRouteMatch} from 'react-router-dom';

import {capitalize} from 'lodash';

import useAppContext from '../../hooks/useAppContext';
import {
  getLightboxPhotoList,
  Lightbox,
  removeFromLightbox,
} from '../../services/lightbox';
import {Photo} from '../../services/photo';
import {LAZY_LOAD_PAGE_SIZE} from '../../utils/const';
import LightboxDetailLayout from './LightboxDetailLayout';
import lightboxDetailReducer from './lightboxDetailReducer';

const initLightboxDetailState = {
  photoList: {
    noMoreItems: false,
    loading: false,
    data: [],
  },
};

interface LightboxDetailContainerProps {}

const LightboxDetailContainer: React.FC<LightboxDetailContainerProps> = () => {
  const appContext = useAppContext();
  const {t: tran} = useTranslation();
  const route = useRouteMatch<{lightboxId: string}>(
    '/entities/:entityId/albums/:albumId/lightboxes/:lightboxId'
  );

  const lightbox = appContext.activeAlbumLightboxes?.find(
    (item) => item.id === Number(route!.params.lightboxId)
  )!;

  const [lightboxDetailState, dispatch] = useReducer(
    lightboxDetailReducer,
    initLightboxDetailState
  );

  const handlePhotoUpdated = (photo: Photo) => {
    dispatch({
      type: 'photoUpdated',
      photo,
    });
  };

  const handlePhotoDeleted = (photoId: number) => {
    dispatch({
      type: 'photosRemoved',
      photoIds: [photoId],
    });
  };

  const handlePhotoListLazyLoad = async () => {
    const offset = lightboxDetailState.photoList.data?.length || 0;
    dispatch({
      type: 'photoResultLoading',
    });

    try {
      const photos = await getLightboxPhotoList(
        lightbox.id,
        {
          limit: LAZY_LOAD_PAGE_SIZE,
          offset,
        },
        appContext.serviceConfig
      );
      dispatch({
        type: 'photoResultLoaded',
        noMoreItems: photos.length < LAZY_LOAD_PAGE_SIZE,
        photoListData: photos,
      });
    } catch (e) {
      appContext.errorHandler(e as Error, 'loading photo');
      dispatch({
        type: 'photoResultError',
        error: e as Error,
      });
    }
  };

  const handleLightboxUpdated = (lightbox: Lightbox) => {
    appContext.onAppContextCacheItemUpdate(
      'activeAlbumLightboxes',
      appContext.activeAlbumLightboxes!.map((item) =>
        item.id === lightbox.id ? lightbox : item
      )
    );
  };

  const handleRemoveFromLightbox = async (selectedPhotos: number[]) => {
    await removeFromLightbox(
      lightbox.id,
      selectedPhotos.map((id) => ({id})),
      appContext.serviceConfig
    );
    dispatch({
      type: 'photosRemoved',
      photoIds: selectedPhotos,
    });
    appContext.enqueueSnackbar(
      `${capitalize(tran('common.lightbox'))} ${capitalize(
        tran('common.remove')
      )} ${capitalize(tran('common.success'))}`,
      {
        variant: 'success',
      }
    );
  };

  return (
    <LightboxDetailLayout
      lightbox={lightbox}
      canShare={
        lightbox?.owner?.id ===
        appContext.userList.find(
          (user) => user.email === appContext.currentUser?.email
        )?.id
      }
      photoList={lightboxDetailState.photoList}
      onPhotoListLazyLoad={handlePhotoListLazyLoad}
      onPhotoUpdated={handlePhotoUpdated}
      onPhotoDeleted={handlePhotoDeleted}
      onRemoveFromLightbox={handleRemoveFromLightbox}
      onLightboxUpdated={handleLightboxUpdated}
    />
  );
};

export default LightboxDetailContainer;

import {SnackbarProvider} from 'notistack';
import React, {RefObject} from 'react';

import {Album} from '../../services/album';
import {Entity, User, UserAccessRightList} from '../../services/app';
import {Lightbox} from '../../services/lightbox';
import {NxpSelectItem} from '../NxpSelect';
import {NxpTreeSelectItem} from '../NxpTreeSelect';

export interface AppContextCache {
  entityId: string;
  userList: User[];
  photoTypeOptions?: NxpSelectItem[];
  predefinedTagOptions?: NxpSelectItem[];
  locationOptions?: NxpTreeSelectItem[];
  locationDictionary?: {};
  activeEntity?: Entity;
  activeAlbum?: Album;
  activeAlbumLightboxes?: Lightbox[];
  language?: string;
}

type AppContextType = AppContextCache & {
  userAccessRightList: UserAccessRightList;
  onUserAccessRightListUpdate: (
    userAccessRightList: UserAccessRightList
  ) => void;
  hasUserAccessRight: (
    accessRight: keyof UserAccessRightList,
    objectType?: string,
    objectId?: number
  ) => boolean;
  onAppContextCacheUpdate: React.Dispatch<
    React.SetStateAction<AppContextCache>
  >;
  onAppContextCacheItemUpdate: <K extends keyof AppContextCache>(
    stateItem: K,
    itemValue: AppContextCache[K]
  ) => void;
  // needed for snackbar to persist: https://iamhosseindhv.com/notistack/demos#persist-snackbar
  notistackRef: RefObject<SnackbarProvider>;
};

const AppContext = React.createContext<AppContextType>({} as AppContextType);

export default AppContext;

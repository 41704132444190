import {useIdleTimer} from 'react-idle-timer';

const useAppTimer = (keycloakInstance: Keycloak.KeycloakInstance) => {
  const handleIdleTimerAction = (event: Event) => {
    // console.log('user did something', event);
  };

  const handleIdleTimerActive = (event: Event) => {
    // console.log('user is active', event);
    // console.log('time remaining', getRemainingTime());
  };

  const handleIdleTimerIdle = (event: Event) => {
    console.log('user is idle', event);
    keycloakInstance.logout();
  };
  useIdleTimer({
    timeout: 1000 * 60 * 30, // 30 mins
    onIdle: handleIdleTimerIdle,
    onActive: handleIdleTimerActive,
    onAction: handleIdleTimerAction,
    debounce: 2000,
  });
};

export default useAppTimer;

import React, {useReducer, useState} from 'react';
import {useTranslation} from 'react-i18next';

import {useSnackbar} from 'notistack';

import useAppContext from '../../hooks/useAppContext';
import {useAsync} from '../../hooks/useAsync';
import {
  getModuleRoles,
  ModuleRole,
  saveModuleRoleAccessRights,
} from '../../services/moduleRoleAccessRight';
import ModuleRoleAccessRightSettingsLayout from './ModuleRoleAccessRightSettingsLayout';
import {
  AccessRightList,
  moduleRoleAccessRightSettingsReducer,
} from './moduleRoleAccessRightSettingsReducer';

interface ModuleRoleAccessRightItem {
  title: string;
  value: keyof AccessRightList;
}

export type ModuleRoleAccessRightSettingsType =
  | 'managePhotos'
  | 'lightbox'
  | 'dashboard'
  | 'manageRoles'
  | 'AccessRights'
  | 'setup';
export interface ModuleRoleAccessRightItems {
  [key: string]: ModuleRoleAccessRightItem[];
}
interface ModuleRoleAccessRightSettingsContainerProps {}

const ModuleRoleAccessRightSettingsContainer: React.FC<ModuleRoleAccessRightSettingsContainerProps> =
  () => {
    const appContext = useAppContext();
    const {enqueueSnackbar} = useSnackbar();
    const {t: tran} = useTranslation();
    const [moduleRoleAccessRightItems, ,] =
      useState<ModuleRoleAccessRightItems>({
        managePhotos: [
          {
            title: `${tran('right.view.photo')}`,
            value: 'photo-view',
          },
          {
            title: `${tran('right.upload.photo')}`,
            value: 'photo-upload',
          },
          {
            title: `${tran('right.edit.photo')}`,
            value: 'photo-edit',
          },
          {
            title: `${tran('right.delete.photo')}`,
            value: 'photo-delete',
          },
          {
            title: `${tran('right.download.photo')}`,
            value: 'photo-download',
          },
        ],
        lightbox: [
          {
            title: `${tran('right.create.lightbox')}`,
            value: 'lightbox-create',
          },
          {
            title: `${tran('right.view.lightbox')}`,
            value: 'lightbox-view',
          },
          {
            title: `${tran('right.edit.lightbox')}`,
            value: 'lightbox-edit',
          },
        ],
        album: [
          {
            title: `${tran('right.create.album')}`,
            value: 'album-create',
          },
          {
            title: `${tran('right.view.album')}`,
            value: 'album-view',
          },
          {
            title: `${tran('right.edit.album')}`,
            value: 'album-edit',
          },
          {
            title: `${tran('right.delete.album')}`,
            value: 'album-delete',
          },
        ],
        manageRoles: [
          {
            title: `${tran('right.view.role')}`,
            value: 'role-view',
          },
          {
            title: `${tran('right.create.role')}`,
            value: 'role-create',
          },
          {
            title: `${tran('right.edit.role')}`,
            value: 'role-edit',
          },
          {
            title: `${tran('right.assign.role')}`,
            value: 'role-assign',
          },
          {
            title: `${tran('right.delete.role')}`,
            value: 'role-delete',
          },
        ],
        dashboard: [
          {
            title: `${tran('right.view.dashboard')}`,
            value: 'dashboard-view',
          },
        ],
        accessRights: [
          {
            title: `${tran('right.view.access')}`,
            value: 'access-right-view',
          },
          {
            title: `${tran('right.assign.access')}`,
            value: 'access-right-assign',
          },
        ],
        setup: [
          {
            title: `${tran('right.add.item')}`,
            value: 'tag-add',
          },
          {
            title: `${tran('right.edit.item')}`,
            value: 'tag-edit',
          },
          {
            title: `${tran('right.delete.item')}`,
            value: 'tag-delete',
          },
        ],
      });

    const [moduleRoleAccessRightSettingsState, dispatch] = useReducer(
      moduleRoleAccessRightSettingsReducer,
      {
        moduleRoles: [],
        selectedModuleRole: null,
        accessRightList: {} as AccessRightList,
        accessRightListTouched: false,
      }
    );

    const [,] = useAsync<ModuleRole[]>(
      () => getModuleRoles(appContext.serviceConfig),
      {
        onSuccess: (moduleRoles: ModuleRole[]) => {
          console.log('data: ', moduleRoles);
          if (moduleRoles) {
            dispatch({
              type: 'moduleRolesLoaded',
              moduleRoles: moduleRoles,
            });
          }
        },
        onError: (ex) =>
          appContext.errorHandler(ex as Error, 'getting module roles'),
      }
    );

    const handleAccessRightCheck = (value: string, checked: boolean) => {
      dispatch({type: 'accessRightChecked', value, checked});
      return;
    };

    const handleModuleRoleSelect = (moduleRoleId: number) => {
      dispatch({type: 'moduleRoleSelected', moduleRoleId});
    };

    const handleSaveButton = async () => {
      if (!moduleRoleAccessRightSettingsState.selectedModuleRole) return;
      try {
        const moduleRole = await saveModuleRoleAccessRights(
          moduleRoleAccessRightSettingsState.selectedModuleRole.id,
          moduleRoleAccessRightSettingsState.accessRightList,
          appContext.serviceConfig
        );
        enqueueSnackbar('Access Right has been applied');
        dispatch({
          type: 'moduleRoleSaved',
          moduleRole,
        });
      } catch (ex: any) {
        appContext.errorHandler(ex as Error, 'applying module access right');
      }
    };

    return (
      <ModuleRoleAccessRightSettingsLayout
        moduleRoles={moduleRoleAccessRightSettingsState.moduleRoles}
        selectedModuleRole={
          moduleRoleAccessRightSettingsState.selectedModuleRole
        }
        accessRightList={moduleRoleAccessRightSettingsState.accessRightList}
        onAccessRightCheck={handleAccessRightCheck}
        onModuleRoleSelect={handleModuleRoleSelect}
        onSaveButton={handleSaveButton}
        moduleRoleAccessRightItems={moduleRoleAccessRightItems}
      />
    );
  };

export default ModuleRoleAccessRightSettingsContainer;

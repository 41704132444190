import {apiRequest} from '../utils/backend';
import {ServiceConfig} from '../utils/backend/apiRequest';

const querystring = require('querystring');

export interface PhotoCountResponse {
  total: number;
  groups: {name: string; count: number}[];
}

export interface UserDateMatrix {
  dates: string[];
  groups: {user: string; date: string; count: number}[];
}

export interface AlbumStatistic {
  count: number;
  countInDateRange: number;
  countsByLocation: {name: string; value: number}[];
  countsByType: {name: string; value: number}[];
  countsByTag: {name: string; value: number}[];
  userDateMatrix: UserDateMatrix;
  countsByContributor: {name: string; value: number}[];
}

export const getAlbumStatistic = async (
  albumId: undefined | number,
  startDate: null | Date,
  endDate: null | Date,
  serviceConfig: ServiceConfig
): Promise<AlbumStatistic> => {
  const query: any = {
    albumId,
  };
  if (startDate) {
    const createdFrom = new Date(startDate);
    createdFrom.setHours(0, 0, 0, 0);
    query['createdFrom'] = createdFrom.toISOString();
  }
  if (endDate) {
    const createdTo = new Date(endDate);
    createdTo.setHours(23, 59, 59, 999);
    query['createdTo'] = createdTo.toISOString();
  }

  const [
    totalCountApiResponse,
    dateRangeApiResponse,
    locationResponse,
    typeResponse,
    tagResponse,
    dateResponse,
    contributorResponse,
  ] = await Promise.all([
    apiRequest<PhotoCountResponse>(
      `/dashboard/count?${querystring.encode({albumId})}`,
      serviceConfig
    ),
    apiRequest<PhotoCountResponse>(
      `/dashboard/count?${querystring.encode(query)}`,
      serviceConfig
    ),
    apiRequest<PhotoCountResponse>(
      `/dashboard/count?${querystring.encode({
        ...query,
        groupBy: 'locationCode',
      })}`,
      serviceConfig
    ),
    apiRequest<PhotoCountResponse>(
      `/dashboard/count?${querystring.encode({
        ...query,
        groupBy: 'photoTypeCode',
      })}`,
      serviceConfig
    ),
    apiRequest<PhotoCountResponse>(
      `/dashboard/count?${querystring.encode({...query, groupBy: 'tag'})}`,
      serviceConfig
    ),
    apiRequest<UserDateMatrix>(
      `/dashboard/user-date-matrix?${querystring.encode({
        albumId,
      })}`,
      serviceConfig
    ),
    apiRequest<PhotoCountResponse>(
      `/dashboard/count?${querystring.encode({
        ...query,
        groupBy: 'uploadedBy',
      })}`,
      serviceConfig
    ),
  ]);

  return {
    count: totalCountApiResponse.total,
    countInDateRange: dateRangeApiResponse.total,
    countsByLocation: locationResponse.groups.map((group) => ({
      name: group.name,
      value: group.count,
    })),
    countsByType: typeResponse.groups.map((group) => ({
      name: group.name,
      value: group.count,
    })),
    countsByTag: tagResponse.groups.map((group) => ({
      name: group.name,
      value: group.count,
    })),
    userDateMatrix: dateResponse,
    countsByContributor: contributorResponse.groups.map((group) => ({
      name: group.name,
      value: group.count,
    })),
  };
};

import {
  Grid,
  IconButton,
  makeStyles,
  TextField,
  Theme,
} from '@material-ui/core';
import React from 'react';
import {NxpButton} from '../NxpButton';
import CancelRoundedIcon from '@material-ui/icons/CancelRounded';
import SearchIcon from '@material-ui/icons/Search';
import {useTranslation} from 'react-i18next';
type GridSize = 'auto' | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
export interface AppSearchBarProps {
  query: string;
  setQuery: (query: string) => void;
  handleOnSearch: (query: string) => void;
  size?: GridSize;
}
const useStyles = makeStyles<Theme>((theme) => ({
  search: {
    minWidth: '50vw',
    '& .MuiIconButton-root': {
      padding: 8,
    },
    '& .MuiGrid-item': {
      display: 'flex',
    },
    '& .MuiButtonBase-root.NxpButton': {
      boxShadow: 'none',
      borderTopLeftRadius: 0,
      borderTopRightRadius: 4,
      borderBottomRightRadius: 4,
      borderBottomLeftRadius: 0,
      maxHeight: 40,
      maxWidth: 50,
      marginLeft: 0,
    },
    '& .MuiOutlinedInput-root': {
      paddingRight: 0,
      minHeight: 30,
      borderTopLeftRadius: 4,
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
      borderBottomLeftRadius: 4,
      '& .MuiOutlinedInput-adornedEnd': {
        padding: 0,
      },
      '& .MuiOutlinedInput-notchedOutline': {
        border: '1px solid rgba(188, 203, 203)',
      },
    },
  },
}));

const AppSearchBar: React.FC<AppSearchBarProps> = ({
  query,
  setQuery,
  handleOnSearch,
  size,
}) => {
  const {t: tran} = useTranslation();
  const classes = useStyles();
  return (
    <div className={classes.search}>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleOnSearch(query);
        }}
      >
        <Grid container>
          <Grid item xs={size}>
            <TextField
              placeholder={tran('common.search')}
              type="text"
              variant="outlined"
              fullWidth
              size="small"
              onChange={(e) => setQuery(e.target.value)}
              value={query}
              InputProps={{
                endAdornment: query && (
                  <IconButton
                    color="primary"
                    aria-label="toggle visibility"
                    onClick={() => setQuery('')}
                  >
                    <CancelRoundedIcon />
                  </IconButton>
                ),
              }}
            />
            <NxpButton
              aria-label="search"
              variant="contained"
              color="primary"
              type="submit"
            >
              <SearchIcon fontSize="large" />
            </NxpButton>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

export default AppSearchBar;

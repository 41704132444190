import React from 'react';
import {Card, CardContent, Grid, makeStyles} from '@material-ui/core';
import {TagCloud} from 'react-tagcloud';

import NxpHeader from '../../components/NxpHeader';
import PieChartCard from './PieChartCard';
import ControlCard from './ControlCard';
import StandaloneNumber from './StandaloneNumber';
import DailyUploadCard from './DailyUploadCard';
import ContributorCard from './ContributorCard';

import appstoreBadge from '../../images/appstore-badge.png';
import playstoreBadge from '../../images/playstore-badge.png';
import {UserDateMatrix} from '../../services/dashboard';
import {capitalize} from 'lodash';
import {useTranslation} from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: 'calc(100vh - 85px)',
  },
  result: {
    flexGrow: 1,
    padding: theme.spacing(3, 5),
  },
  appstoreButton: {
    width: 135,
    height: 40,
    marginLeft: 4,
  },
}));

interface DashboardLayoutProps {
  count: number;
  countInDateRange: number;
  countsByLocation: {name: string; value: number}[];
  countsByType: {name: string; value: number}[];
  countsByTag: {name: string; value: number}[];
  userDateMatrix: null | UserDateMatrix;
  countsByContributor: {name: string; value: number}[];
  dateRangeStart: null | Date;
  dateRangeEnd: null | Date;
  detailedLocationOpened: boolean;
  onDateRangeStartChange: (date: null | Date) => void;
  onDateRangeEndChange: (date: null | Date) => void;
  onDetailedLocationOpen: () => void;
  onDetailedLocationClose: () => void;
}

const DashboardLayout: React.FC<DashboardLayoutProps> = ({
  count,
  countInDateRange,
  countsByLocation,
  countsByType,
  countsByTag,
  userDateMatrix,
  countsByContributor,
  dateRangeStart,
  dateRangeEnd,
  detailedLocationOpened,
  onDateRangeStartChange,

  onDateRangeEndChange,
  onDetailedLocationOpen,
  onDetailedLocationClose,
}) => {
  const classes = useStyles();
  const {t: tran} = useTranslation();
  const controlCard = (
    <ControlCard
      dateRangeStart={dateRangeStart}
      dateRangeEnd={dateRangeEnd}
      expanded={detailedLocationOpened}
      countInDateRange={countInDateRange}
      countsByLocation={countsByLocation}
      onDateRangeStartChange={onDateRangeStartChange}
      onDateRangeEndChange={onDateRangeEndChange}
      onExpand={onDetailedLocationOpen}
      onCollapse={onDetailedLocationClose}
    ></ControlCard>
  );

  const photoCountCard = (
    <Card>
      <CardContent>
        <StandaloneNumber
          caption={tran('dashboard.photoInThisAlbum')}
          value={count}
        />
      </CardContent>
    </Card>
  );

  const locationChartCard = (
    <PieChartCard
      title={tran('dashboard.photosByLocation')}
      entries={countsByLocation}
    />
  );
  const typeChartCard = (
    <PieChartCard
      title={tran('dashboard.photosByType')}
      entries={countsByType}
    />
  );
  const tagCloudCard = (
    <Card>
      <CardContent>
        <TagCloud
          minSize={8}
          maxSize={24}
          tags={countsByTag.map((entry) => ({
            value: entry.name,
            count: entry.value,
          }))}
          disableRandomColor={true}
          onClick={() => {}}
        />
      </CardContent>
    </Card>
  );
  const dailyUploadCard = <DailyUploadCard userDateMatrix={userDateMatrix} />;
  const contributorChartCard = (
    <ContributorCard entries={countsByContributor} />
  );

  let westCards = [];
  let eastCards = [];
  let southCards = [];
  if (detailedLocationOpened) {
    westCards = [controlCard];
    eastCards = [
      photoCountCard,
      locationChartCard,
      typeChartCard,
      tagCloudCard,
    ];
    southCards = [dailyUploadCard, contributorChartCard];
  } else {
    westCards = [controlCard, locationChartCard];
    eastCards = [photoCountCard, typeChartCard, tagCloudCard];
    southCards = [dailyUploadCard, contributorChartCard];
  }

  return (
    <div className={classes.root}>
      <div className={classes.result}>
        <NxpHeader
          titleContent={capitalize(tran('common.dashboard'))}
          actionContent={
            <>
              <a
                href="https://apps.apple.com/app/nexplore-fotolio/id1573681395"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  className={classes.appstoreButton}
                  src={appstoreBadge}
                  alt="AppStore"
                />
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=com.nexplore.fotolio"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  className={classes.appstoreButton}
                  src={playstoreBadge}
                  alt="Playstore"
                />
              </a>
            </>
          }
        />
        <Grid item container spacing={2} direction="row">
          <Grid item container xs={6}>
            <Grid container spacing={2} direction="column">
              {westCards.map((card) => (
                <Grid item>{card}</Grid>
              ))}
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <Grid container spacing={2} direction="column">
              {eastCards.map((card) => (
                <Grid item>{card}</Grid>
              ))}
            </Grid>
          </Grid>

          {southCards.map((card) => (
            <Grid item container xs={12}>
              <Grid container spacing={2} direction="column">
                <Grid item xs={12}>
                  {card}
                </Grid>
              </Grid>
            </Grid>
          ))}
        </Grid>
      </div>
    </div>
  );
};

export default DashboardLayout;

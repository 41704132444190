import {BrowserRouter, Redirect, Route, Switch} from 'react-router-dom';

import Explorer from '../../pages/Explorer';
import Dashboard from '../../pages/Dashboard';
import Albums from '../../pages/Albums';
import AppProtectedRoute from './AppProtectedRoute';
import Tags from '../../pages/Settings/Tags';
import Search from '../../pages/Search';
import Lightboxes from '../../pages/Lightboxes';
import LightboxDetail from '../../pages/LightboxDetail';
import ModuleRoleAssignment from '../../pages/ModuleRoleAssignment';
import ModuleRoleAccessRightSettings from '../../pages/ModuleRoleAccessRightSettings';
import ObjectRoleAssignment from '../../pages/ObjectRoleAssignment';
import ObjectRoleAccessRightSettings from '../../pages/ObjectRoleAccessRightSettings';
import PhotoMap from '../../pages/Map';
import {Nxp404} from '../Nxp404';
// export for easy testing
export const RouteList = () => (
  <Switch>
    <Route exact path="/">
      <Redirect to="/entities/1/albums" />
    </Route>
    <AppProtectedRoute
      exact
      requireActiveAlbrum
      path="/entities/:entityId/albums/:albumId/explorer"
    >
      <Explorer />
    </AppProtectedRoute>
    <AppProtectedRoute
      exact
      requireActiveAlbrum
      path="/entities/:entityId/albums/:albumId/lightboxes"
    >
      <Lightboxes />
    </AppProtectedRoute>
    <AppProtectedRoute
      exact
      requireActiveAlbrum
      path="/entities/:entityId/albums/:albumId/lightboxes/:lightboxId"
    >
      <LightboxDetail />
    </AppProtectedRoute>
    <AppProtectedRoute exact path="/entities/:entityId/albums">
      <Albums />
    </AppProtectedRoute>
    <AppProtectedRoute
      exact
      requireActiveAlbrum
      path="/entities/:entityId/albums/:albumId/dashboard"
    >
      <Dashboard />
    </AppProtectedRoute>
    <AppProtectedRoute
      exact
      requireActiveAlbrum
      path="/entities/:entityId/albums/:albumId/search"
    >
      　<Search />
    </AppProtectedRoute>
    <AppProtectedRoute exact path="/entities/:entityId/settings">
      <Tags />
    </AppProtectedRoute>
    <AppProtectedRoute exact path="/entities/:entityId/module-roles">
      <ModuleRoleAssignment />
    </AppProtectedRoute>
    <AppProtectedRoute
      exact
      path="/entities/:entityId/module-role-access-right"
    >
      <ModuleRoleAccessRightSettings />
    </AppProtectedRoute>
    <AppProtectedRoute exact path="/entities/:entityId/albums/:albumId/map">
      <PhotoMap />
    </AppProtectedRoute>
    <AppProtectedRoute exact path="/entities/:entityId/albums/:albumId/roles">
      <ObjectRoleAssignment />
    </AppProtectedRoute>
    <AppProtectedRoute
      exact
      path="/entities/:entityId/albums/:albumId/role-access-right"
    >
      <ObjectRoleAccessRightSettings />
    </AppProtectedRoute>
    <AppProtectedRoute>
      <Nxp404 />
    </AppProtectedRoute>
  </Switch>
);

const AppRouter = () => {
  return (
    <BrowserRouter>
      <RouteList />
    </BrowserRouter>
  );
};

export default AppRouter;

import React from 'react';
import clsx from 'clsx';
import {makeStyles, MenuItem, Select, SelectProps} from '@material-ui/core';

export interface NxpSelectItem {
  value: string | number | undefined;
  name: string;
}

export interface NxpSelectBaseProps extends SelectProps {
  selectItems?: NxpSelectItem[];
}

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 100,
    border: `1px solid ${theme.palette.border.main}`,
    borderRadius: theme.shape.borderRadius,
    '&.Mui-error': {
      borderColor: theme.palette.error.main,
    },
    '& .MuiSelect-root': {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      '&.MuiSelect-select[tabindex="0"]': {
        color: theme.palette.grey[700],
      },
    },
  },
  placeholder: {
    color: theme.palette.grey[700],
  },
}));

const NxpSelectBase = React.forwardRef<unknown, NxpSelectBaseProps>(
  function NxpSelectBase(
    {className, id, selectItems, children, ...restProps},
    ref
  ) {
    const classes = useStyles();
    return (
      <>
        <Select
          ref={ref}
          className={clsx('NxpSelect', classes.root, className)}
          labelId={`${id}-label`}
          disableUnderline={true}
          displayEmpty
          MenuProps={{
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
            getContentAnchorEl: null,
          }}
          inputProps={{id}}
          {...restProps}
        >
          {restProps?.placeholder ? (
            <MenuItem value="" disabled className={classes.placeholder}>
              {restProps?.placeholder}
            </MenuItem>
          ) : null}

          {selectItems
            ? selectItems.map((item) => {
                return (
                  <MenuItem
                    key={item.value === undefined ? '' : item.value}
                    value={item.value}
                  >
                    {item.name}
                  </MenuItem>
                );
              })
            : children}
        </Select>
      </>
    );
  }
);

export default NxpSelectBase;

import {useSnackbar} from 'notistack';
import React, {useState} from 'react';
import {Tag} from '../../../services/tag';
import {SortOrder} from '../../../utils/typing/SortOrder';
import CreateTag from './CreateTag';
import TagsLayout from './TagsLayout';
import {useTranslation} from 'react-i18next';
import {capitalize} from 'lodash';
const tags: Tag[] = [
  {
    id: 10,
    name: 'test',
    type: 'USER',
    createdOn: new Date(),
    updatedOn: new Date(),
  },
  {
    id: 11,
    name: 'worker',
    type: 'USER',
    createdOn: new Date(),
    updatedOn: new Date(),
  },
  {
    id: 12,
    name: 'truck',
    type: 'USER',
    createdOn: new Date(),
    updatedOn: new Date(),
  },
  {
    id: 13,
    name: 'steel',
    type: 'USER',
    createdOn: new Date(),
    updatedOn: new Date(),
  },
  {
    id: 14,
    name: 'cement',
    type: 'USER',
    createdOn: new Date(),
    updatedOn: new Date(),
  },
  {
    id: 15,
    name: 'machine',
    type: 'USER',
    createdOn: new Date(),
    updatedOn: new Date(),
  },
];

interface TagsContainerProps {}

const TagsContainer: React.FC<TagsContainerProps> = () => {
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const {t: tran} = useTranslation();
  const handleCreateClick = () => setCreateModalOpen(true);

  const handleCreateModalClose = () => setCreateModalOpen(false);

  const handleTagCreated = (tag: Tag) => {
    console.log(tag);
  };

  const {enqueueSnackbar} = useSnackbar();
  const [form, setForm] = useState<Tag>();

  const handleEdit = (tagId: number) => {
    const tag = tags.find((tag) => tag.id === tagId);
    if (tag) {
      setForm({
        ...tag,
      });
    }
  };

  const handleChange = (tag: Tag) => {
    setForm({...tag});
  };

  const handleCancel = () => {
    setForm(undefined);
  };

  const handleDelete = () => {
    setForm(undefined);
    enqueueSnackbar(
      `${capitalize(tran('common.tag'))} ${capitalize(
        tran('common.delete')
      )} ${capitalize(tran('common.success'))}`,
      {variant: 'success'}
    );
  };

  const handleSave = () => {
    setForm(undefined);
    enqueueSnackbar(
      `${capitalize(tran('common.tag'))} ${capitalize(
        tran('common.save')
      )} ${capitalize(tran('common.success'))}`,
      {variant: 'success'}
    );
  };

  const handleSort = (sort: SortOrder) => {};

  return (
    <>
      <CreateTag
        modalOpen={createModalOpen}
        onModalClose={handleCreateModalClose}
        onTagCreated={handleTagCreated}
      />
      <TagsLayout
        tags={tags}
        form={form}
        onCreateClick={handleCreateClick}
        onEdit={handleEdit}
        onChange={handleChange}
        onCancel={handleCancel}
        onDelete={handleDelete}
        onSave={handleSave}
        onSort={handleSort}
      />
    </>
  );
};

export default TagsContainer;

import {Photo} from '../../services/photo';
import {AsyncLazyLoadResult} from '../../utils/typing/AsyncLazyLoadResult';
import {ExplorerFilterViewBy} from './ExplorerFilter/ExplorerFilterContainer';

interface ExplorerState {
  photoList: AsyncLazyLoadResult<Photo[]>;
  filter: {
    viewBy: ExplorerFilterViewBy;
    filterValue?: string;
    filterRefreshTracker?: Date;
  };
}

type ExplorerAction =
  | {
      type: 'photoCreated';
      photos: Photo[];
    }
  | {
      type: 'photoUpdated';
      photo: Photo;
    }
  | {
      type: 'photoDeleted';
      photoId: number;
    }
  | {
      type: 'filterChange';
      viewBy: ExplorerFilterViewBy;
      filterValue?: string;
    }
  | {
      type: 'photoResultLoading';
    }
  | {
      type: 'photoResultError';
      viewBy: ExplorerFilterViewBy;
      filterValue: string;
      error: Error;
    }
  | {
      type: 'photoResultLoaded';
      viewBy: ExplorerFilterViewBy;
      filterValue: string;
      photoListData: Photo[];
      noMoreItems: boolean;
    };

export default function explorerReducer(
  state: ExplorerState,
  action: ExplorerAction
): ExplorerState {
  switch (action.type) {
    case 'photoCreated': {
      return {
        ...state,
        photoList: {
          ...state.photoList,
          data: [...action.photos, ...(state.photoList.data || [])],
        },
        filter: {
          ...state.filter,
          filterRefreshTracker: new Date(),
        },
      };
    }
    case 'photoUpdated': {
      return {
        ...state,
        photoList: {
          ...state.photoList,
          data: state.photoList.data?.map((item) =>
            item.id === action.photo.id ? action.photo : item
          ),
        },
        filter: {
          ...state.filter,
          filterRefreshTracker: new Date(),
        },
      };
    }
    case 'photoDeleted': {
      return {
        ...state,
        photoList: {
          ...state.photoList,
          data: state.photoList.data?.filter(
            (item) => item.id !== action.photoId
          ),
        },
        filter: {
          ...state.filter,
          filterRefreshTracker: new Date(),
        },
      };
    }
    case 'filterChange':
      return {
        ...state,
        filter: {
          ...state.filter,
          viewBy: action.viewBy,
          filterValue: action.filterValue,
        },
        photoList: {
          noMoreItems: false,
          loading: true,
          error: undefined,
          data: [],
        },
      };
    case 'photoResultLoading':
      return {
        ...state,
        photoList: {
          ...state.photoList,
          loading: true,
        },
      };
    case 'photoResultError':
      if (
        action.viewBy === state.filter?.viewBy &&
        action.filterValue === state.filter.filterValue
      ) {
        return {
          ...state,
          photoList: {
            ...state.photoList,
            loading: false,
            error: action.error,
          },
        };
      }
      return state;
    case 'photoResultLoaded':
      if (
        action.viewBy === state.filter?.viewBy &&
        action.filterValue === state.filter.filterValue
      ) {
        return {
          ...state,
          photoList: {
            noMoreItems: action.noMoreItems,
            loading: false,
            error: undefined,
            data: [
              ...(state.photoList.data ? state.photoList.data : []),
              ...action.photoListData,
            ],
          },
        };
      }
      return state;
    default:
      return state;
  }
}

import {apiRequest} from '../utils/backend';
import {ServiceConfig} from '../utils/backend/apiRequest';
import {User} from './app';

export interface ModuleRole {
  id: number;
  name: string;
  code: string;
  description: string;
  users: User[];
}

export const getModuleRolesWithAssignees = async (
  serviceConfig: ServiceConfig
): Promise<ModuleRole[]> => {
  const getModuleRolesApiResponse = await apiRequest<ModuleRole[]>(
    `/module-role-assignees`,
    serviceConfig
  );
  return getModuleRolesApiResponse;
};

export const createModuleRole = async (
  data: {
    name: string;
    code: string;
    description: string;
    userIds: string[];
  },
  serviceConfig: ServiceConfig
): Promise<ModuleRole> => {
  return await apiRequest<ModuleRole>(`/module-roles`, serviceConfig, {
    method: 'POST',
    body: JSON.stringify(data),
  });
};

export const editModuleRole = async (
  moduleRoleId: number,
  name: string,
  description: string,
  serviceConfig: ServiceConfig
): Promise<ModuleRole> => {
  return await apiRequest<ModuleRole>(
    `/module-roles/${moduleRoleId}`,
    serviceConfig,
    {
      method: 'PATCH',
      body: JSON.stringify({
        name,
        description,
      }),
    }
  );
};

export const deleteModuleRole = async (
  moduleRoleId: number,
  serviceConfig: ServiceConfig
): Promise<Boolean> => {
  await apiRequest<Record<string, never>>(
    `/module-roles/${moduleRoleId}`,
    serviceConfig,
    {
      method: 'DELETE',
    }
  );
  return true;
};

export const applyAssigneeList = async (
  moduleRoleId: number,
  userIds: string[],
  serviceConfig: ServiceConfig
): Promise<ModuleRole> => {
  return await apiRequest<ModuleRole>(
    `/module-roles/${moduleRoleId}/assignees`,
    serviceConfig,
    {
      method: 'PATCH',
      body: JSON.stringify({
        userIds,
      }),
    }
  );
};

import React from 'react';
import {NxpButton} from '../../../../components/NxpButton';

import {NxpDialog} from '../../../../components/NxpDialog';
import {NxpFormGrid} from '../../../../components/NxpFormGrid';
import {CreateTagForm} from './CreateTagContainer';
import {useTranslation} from 'react-i18next';
import {capitalize} from 'lodash';

interface CreateTagModalProps {
  modalOpen: boolean;
  form: CreateTagForm;
  onFormStateChange: (fieldName: string, value: unknown) => void;
  onSave: () => void;
  onModalClose: () => void;
}

const CreateTagModal: React.FC<CreateTagModalProps> = ({
  modalOpen,
  form,
  onFormStateChange,
  onSave,
  onModalClose,
}) => {
  const {t: tran} = useTranslation();
  return (
    <NxpDialog
      maxWidth="xs"
      onClose={onModalClose}
      open={modalOpen}
      titleContent={`${capitalize(tran('common.add'))} ${capitalize(
        tran('common.tag')
      )}`}
      actionContent={
        <NxpButton onClick={onSave}>{tran('common.save')}</NxpButton>
      }
    >
      <NxpFormGrid
        editing={true}
        gridColumnCount={1}
        formState={form}
        onFormStateChange={onFormStateChange}
        fields={[
          {
            itemColumnSpan: 1,
            fieldName: 'name',
            fieldType: 'text',
            // onFormStateChange: handleFormGridStateChange,
            label: `${capitalize(tran('common.tag'))} ${capitalize(
              tran('common.name')
            )}`,
            required: true,
          },
        ]}
      />
    </NxpDialog>
  );
};

export default CreateTagModal;

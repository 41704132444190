import React, {ReactNode, useCallback, useState} from 'react';

import {SnackbarProvider} from 'notistack';

import {UserAccessRightList} from '../../services/app';
import AppContext, {AppContextCache} from './AppContext';

interface AppContextProviderProps {
  initAppContextCache?: AppContextCache;
  initUserAccessRightList?: UserAccessRightList; // added to facilitate testing
  notistackRef: React.RefObject<SnackbarProvider>;
  children: ReactNode;
}

const AppContextProvider: React.FC<AppContextProviderProps> = ({
  initAppContextCache,
  initUserAccessRightList,
  notistackRef,
  children,
}) => {
  const [userAccessRightList, setUserAccessRightList] =
    useState<UserAccessRightList>(
      (initUserAccessRightList || {}) as UserAccessRightList
    );

  // const [entityId, setEntityId] = useState<string>('');
  // const [userList, setUserList] = useState<User[]>([] as User[]);
  // const [photoTypeOptions, setPhotoTypeOptions] = useState<NxpSelectItem[]>();
  // const [predefinedTagOptions, setPredefinedTagOptions] = useState<
  //   NxpSelectItem[]
  // >();
  // const [locationOptions, setLocationOptions] = useState<NxpTreeSelectItem[]>();
  // const [locationDictionary, setLocationDictionary] = useState<{}>();

  // const [activeAlbum, setActiveAlbum] = useState<Album>();
  // const handleAlbumUpdate = useCallback((album: Album) => {
  //   setActiveAlbum(album);
  // }, []);

  // const [lightboxes, setLightboxes] = useState<Lightbox[]>();
  // const handleLightboxesUpdate = useCallback(
  //   (lightboxes: Lightbox[]) => setLightboxes(lightboxes),
  //   []
  // );

  const [appContextCache, setAppContextCache] = useState<AppContextCache>(
    initAppContextCache || {
      entityId: '',
      userList: [],
    }
  );

  const handleAppContextCacheItemUpdate = useCallback(
    <K extends keyof AppContextCache>(
      cacheItem: K,
      itemValue: AppContextCache[K]
    ) => {
      setAppContextCache((prevState) => ({
        ...prevState,
        [cacheItem]: itemValue,
      }));
    },
    []
  );

  const hasUserAccessRight = (
    accessRight: keyof UserAccessRightList,
    objectType?: string,
    objectId?: number
  ) => {
    if (!userAccessRightList[accessRight]) return false;

    if (!objectType) {
      // if object type is not provided, any would do.
      return userAccessRightList[accessRight].length > 0;
    }

    for (const right of userAccessRightList[accessRight]) {
      if (right.source === 'hardcode' || right.source === 'module') {
        return true;
      } else if (right.source === objectType) {
        if (right.source === 'album') {
          if (!objectId) {
            objectId = appContextCache.activeAlbum?.id;
          }
          if (right.album === objectId) {
            return true;
          }
        } else if (right.source === 'lightbox') {
          if (right.lightbox === objectId) {
            return true;
          }
        }
      }
    }
    return false;
  };

  return (
    <AppContext.Provider
      value={{
        userAccessRightList,
        onUserAccessRightListUpdate: setUserAccessRightList,
        hasUserAccessRight,
        ...appContextCache,
        onAppContextCacheUpdate: setAppContextCache,
        onAppContextCacheItemUpdate: handleAppContextCacheItemUpdate,
        // entityId,
        // onEntityIdUpdate: setEntityId,
        // userList,
        // onUserListUpdate: setUserList,
        // photoTypeOptions,
        // onPhotoTypeOptionsUpdate: setPhotoTypeOptions,
        // predefinedTagOptions,
        // onPredefinedTagOptionsUpdate: setPredefinedTagOptions,
        // locationOptions,
        // onLocationOptionsUpdate: setLocationOptions,
        // locationDictionary,
        // onLocationDictionaryUpdate: setLocationDictionary,
        // activeAlbum,
        // onAlbumUpdate: handleAlbumUpdate,
        // lightboxes,
        // onLightboxesUpdate: handleLightboxesUpdate,
        notistackRef,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export default AppContextProvider;
